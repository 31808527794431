import { Box, LoadingOverlay } from "@mantine/core"
import { ChartConfiguration, Chart as ChartJs } from "chart.js/auto"
import { useEffect, useRef } from "react"

import { getZIndex } from "@ensol/shared/styles/zIndex"

type Props = {
  data: ChartConfiguration
  isLoading?: boolean
  onDataClick?: (clickedDataIndex: number) => void
  aspectRatio?: number
}

export const Chart = ({
  data,
  aspectRatio,
  onDataClick,
  isLoading = false,
}: Props) => {
  const canvasRef = useRef<HTMLCanvasElement>(null)
  const chartRef = useRef<ChartJs | null>(null)

  useEffect(() => {
    if (canvasRef.current !== null) {
      chartRef.current = new ChartJs(canvasRef.current, {
        ...data,
        options: {
          ...data.options,
          aspectRatio,
          onClick: (event) => {
            if (onDataClick === undefined || chartRef.current === null) {
              return
            }
            const clickedData = chartRef.current.getElementsAtEventForMode(
              event as unknown as Event, // chart.js typing is not good here
              "nearest",
              { intersect: true },
              true,
            )

            if (clickedData.length === 0) {
              return
            }

            onDataClick(clickedData[0].index)
          },
        },
      })
    }
    return () => {
      if (chartRef.current) {
        chartRef.current.destroy()
      }
    }
  }, [aspectRatio, data, onDataClick])

  return (
    <Box pos="relative" style={{ aspectRatio }}>
      <LoadingOverlay visible={isLoading} zIndex={getZIndex("overlay")} />
      <canvas ref={canvasRef} />
    </Box>
  )
}
