import { Button, ButtonProps } from "@mantine/core"
import { IconExternalLink } from "@tabler/icons-react"
import { Link } from "react-router-dom"

import classes from "./Menu.module.css"

type Props = {
  id?: string
  name: string
  to: string
  isExternal?: boolean
  onClick?: () => void
} & ButtonProps

export const MenuItem = ({ id, name, to, isExternal, ...props }: Props) => {
  const dataTest = `${id}NavLink`

  if (isExternal) {
    return (
      <Button
        classNames={classes}
        component="a"
        href={to}
        target="_blank"
        rightSection={<IconExternalLink size={16} />}
        data-test={dataTest}
        {...props}
      >
        {name}
      </Button>
    )
  }

  return (
    <Button
      classNames={classes}
      component={Link}
      to={to}
      data-test={dataTest}
      {...props}
    >
      {name}
    </Button>
  )
}
