import dayjs, { UnitType } from "dayjs"

import { TimeFrame } from "@ensol/types/reports"

import {
  formatDate,
  SHORT_DISPLAY_DATE_FORMAT,
} from "@ensol/shared/utils/format"

export const computeTimeFrameDuration = (
  timeFrame: TimeFrame,
  unit: UnitType,
) => dayjs(timeFrame.endDate).diff(dayjs(timeFrame.startDate), unit)

export const isDateInTimeFrame = (date: string, timeFrame: TimeFrame | null) =>
  timeFrame === null ||
  (dayjs(date).isAfter(timeFrame.startDate) &&
    dayjs(date).isBefore(timeFrame.endDate))

export const formatTimeFrame = (timeFrame: TimeFrame) =>
  `${formatDate(timeFrame.startDate, SHORT_DISPLAY_DATE_FORMAT)} - ${formatDate(
    timeFrame.endDate,
    SHORT_DISPLAY_DATE_FORMAT,
  )}`
