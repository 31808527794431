import * as z from "zod"
import { transformSchemaToNullish } from "../utils"

export const pendingStepFormSchema = z.object({
  consuelNotes: z.string().optional(),
  consuelRequestForConnectionPath: z.string().nullable().optional(),
  electricityBillPath: z.string().nullable().optional(),
  consuelReference: z.string().nullable().optional(),
  consuelOtherDocumentsPaths: z.array(z.string()),
})

export const submittedStepFormSchema = pendingStepFormSchema.extend({
  consuelReference: z.string().min(1, "Champ obligatoire"),
  consuelSummonsWeek: z.date().nullable().optional(),
  consuelFirstNoticeOfVisitPath: z.string().nullable().optional(),
  consuelComplianceReportPath: z.string().nullable().optional(),
  consuelApprovalDate: z.date().nullable().optional(),
  consuelCertificatePath: z.string().nullable().optional(),
})

export const visitNeededStepFormSchema = submittedStepFormSchema.extend({
  consuelSummonsWeek: z.date({ invalid_type_error: "Champ obligatoire" }),
  consuelSummonsDate: z.date({ invalid_type_error: "Champ obligatoire" }),
  consuelFirstNoticeOfVisitPath: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
  consuelSecondNoticeOfVisitPath: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
})

export const visitScheduledStepFormSchema = visitNeededStepFormSchema.extend({
  consuelNonComplianceReportPaths: z.array(z.string()),
})

export const workNeededStepFormSchema = visitScheduledStepFormSchema.extend({
  isValidationVisitNecessary: z.boolean({
    invalid_type_error: "Champ obligatoire",
  }),
  consuelWorksDate: z.date({ invalid_type_error: "Champ obligatoire" }),
  consuelWorksInstallerId: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
  consuelWorksLocations: z.array(z.string()),
  consuelWorksReasons: z.array(z.string()),
})

export type ConsuelStepInput =
  | z.infer<typeof pendingStepFormSchema>
  | z.infer<typeof submittedStepFormSchema>
  | z.infer<typeof visitNeededStepFormSchema>
  | z.infer<typeof visitScheduledStepFormSchema>
  | z.infer<typeof workNeededStepFormSchema>

export const consuelProcessSchema = transformSchemaToNullish(
  workNeededStepFormSchema,
)
