import { submissionPendingStepFormSchema } from "@ensol/types/forms/projects/greendeal"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { GreendealForm } from "@ensol/entool/pages/OpsProject/processes/Greendeal/components/GreendealForm"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"

export const SubmissionPending = ({
  project,
  ...props
}: StepComponentProps) => {
  return (
    <GreendealForm
      schema={submissionPendingStepFormSchema}
      initialValues={getInitialValues(submissionPendingStepFormSchema, project)}
      project={project}
      actions={[
        { action: "doneByClient", label: "Fait par le client" },
        { action: "validate", label: "Demande soumise" },
      ]}
      {...props}
    />
  )
}
