import { SimpleGrid, Textarea, Input, Stack, MultiSelect } from "@mantine/core"
import { IconBolt, IconInfoCircle, IconSend } from "@tabler/icons-react"

import { EnedisStepInput } from "@ensol/types/forms/projects/enedis"

import { Link } from "@ensol/shared/components/Link"
import { EnedisStep } from "@ensol/shared/entities/projects/processes"
import { getProspectFileName } from "@ensol/shared/utils/files"
import { DISPLAY_DATE_FORMAT } from "@ensol/shared/utils/format"

import { CopiableText } from "@ensol/entool/components/CopiableText"
import { NotFound } from "@ensol/entool/components/NotFound"
import { Section } from "@ensol/entool/components/Section"
import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import {
  StepActions,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { Field } from "@ensol/entool/components/form/Field"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { UpdateElectricalMeterPhotos } from "@ensol/entool/components/form/House/UpdateElectricalMeterPhotos"
import { UpdateExistingInstallation } from "@ensol/entool/components/form/House/UpdateExistingInstallation"
import { FormField } from "@ensol/entool/components/form/Project/FormField"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { RGE_CERTIFICATE_URL } from "@ensol/entool/config"
import { ENEDIS_INCOMPLETE_FILE_OPTIONS } from "@ensol/entool/utils/projects/options"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const EnedisForm = <Input extends EnedisStepInput>({
  schema,
  initialValues,
  project,
  children,
  ...props
}: ProcessFormProps<Input> & StepActionsProps) => {
  const form = useProcessForm<Input>({
    schema,
    initialValues,
    projectId: project.id,
    processId: props.processId,
  })
  const currentStep = project[props.processId]
  const { house, activeQuote } = project.installation
  const { prospect } = house

  return (
    <StepActions
      project={project}
      validateStep={() => schema.parse(form.values)}
      {...props}
    >
      {children}
      <Section>
        <SimpleGrid cols={2} spacing="32" mt="16" mb="32">
          <FormField initialValues={initialValues} propertyName="enedisNotes">
            <Textarea
              autosize
              w={FIELD_WIDTH}
              minRows={3}
              {...form.getInputProps("enedisNotes")}
            />
          </FormField>
        </SimpleGrid>
      </Section>
      <Section
        title="Informations pour la demande"
        icon={IconInfoCircle}
        titleOrder={3}
        isCollapsible={currentStep !== EnedisStep.PENDING}
        isCollapsed={true}
      >
        <SimpleGrid cols={2} spacing="32" mt="16" mb="60">
          <Stack gap="24">
            <Field name="Latitude">
              <CopiableText text={house.lat.toFixed(12)} w={FIELD_WIDTH} />
            </Field>
            <Field name="Longitude">
              <CopiableText text={house.lng.toFixed(12)} w={FIELD_WIDTH} />
            </Field>
            <Field name="PDL">
              {house.pdl !== null ? (
                <CopiableText text={house.pdl} w={FIELD_WIDTH} />
              ) : (
                <NotFound type="text" />
              )}
            </Field>
            <UpdateElectricalMeterPhotos house={house} />
            <Field name="Accusé de reception DP">
              {project.preliminaryRequestAcknowledgmentPath !== null ? (
                <MultiFilePreview
                  files={[
                    { path: project.preliminaryRequestAcknowledgmentPath },
                  ]}
                  fileName={getProspectFileName(
                    "accuse-reception-DP",
                    prospect,
                  )}
                />
              ) : (
                <NotFound type="file" />
              )}
            </Field>
            <Field name="Autorisation d'urbanisme">
              {project.preliminaryRequestApprovalPaths.length > 0 ? (
                <MultiFilePreview
                  files={project.preliminaryRequestApprovalPaths.map(
                    (path) => ({
                      path,
                    }),
                  )}
                  fileName={getProspectFileName("autorisation-DP", prospect)}
                />
              ) : (
                <NotFound type="file" />
              )}
            </Field>
            <Field name="Lien certificat RGE">
              <Link
                w={FIELD_WIDTH}
                label="Voir le certificat RGE"
                to={RGE_CERTIFICATE_URL}
                isExternal
              />
            </Field>
          </Stack>
          <Stack gap="24">
            <Field name="Devis">
              {activeQuote !== null && activeQuote.quotePath !== null ? (
                <MultiFilePreview
                  files={[{ path: activeQuote.quotePath }]}
                  fileName={getProspectFileName("devis", prospect)}
                />
              ) : (
                <NotFound type="file" />
              )}
            </Field>
            <Field name="Mandat">
              {house.mandatePath !== null ? (
                <MultiFilePreview
                  files={[{ path: house.mandatePath }]}
                  fileName={getProspectFileName("mandat", prospect)}
                />
              ) : (
                <NotFound type="file" />
              )}
            </Field>
            <FormField
              initialValues={initialValues}
              propertyName="electricityBillPath"
            >
              <FileField
                previewProps={{
                  fileName: getProspectFileName("facture", prospect),
                }}
                inputProps={{ accept: ["application/pdf"] }}
                {...form.getInputProps("electricityBillPath")}
              />
            </FormField>
          </Stack>
        </SimpleGrid>
        <UpdateExistingInstallation house={house} />
      </Section>
      <Section title="Demande de raccordement" icon={IconSend} titleOrder={3}>
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="enedisReference"
              isRequired
            >
              <Input
                w={FIELD_WIDTH}
                {...form.getInputProps("enedisReference")}
              />
            </FormField>
          </Stack>
          {("enedisIncompleteFileReasons" in initialValues ||
            "enedisIncompleteFileDetails" in initialValues) && (
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="enedisIncompleteFileReasons"
                isRequired
              >
                <MultiSelect
                  w={FIELD_WIDTH}
                  searchable
                  hidePickedOptions
                  data={ENEDIS_INCOMPLETE_FILE_OPTIONS}
                  {...form.getInputProps("enedisIncompleteFileReasons")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="enedisIncompleteFileDetails"
                isRequired
              >
                <Textarea
                  autosize
                  w={FIELD_WIDTH}
                  minRows={3}
                  {...form.getInputProps("enedisIncompleteFileDetails")}
                />
              </FormField>
            </Stack>
          )}
        </SimpleGrid>
      </Section>
      {"enedisActivationDate" in initialValues && (
        <Section title="Activation" icon={IconBolt} titleOrder={3}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <FormField
              initialValues={initialValues}
              propertyName="enedisActivationDate"
              isRequired
            >
              <DateInput
                w={FIELD_WIDTH}
                valueFormat={DISPLAY_DATE_FORMAT}
                clearable
                {...form.getInputProps("enedisActivationDate")}
              />
            </FormField>
            <Field name="Attestation Consuel">
              {project.consuelCertificatePath !== null ? (
                <MultiFilePreview
                  files={[{ path: project.consuelCertificatePath }]}
                  fileName={getProspectFileName(
                    "attestation-consuel",
                    prospect,
                  )}
                />
              ) : (
                <NotFound
                  message="L'attestation consuel est requise pour la mise en service"
                  type="file"
                />
              )}
            </Field>
          </SimpleGrid>
        </Section>
      )}
    </StepActions>
  )
}
