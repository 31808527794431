import { Stack } from "@mantine/core"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"

type Props = {
  activeQuote: InstallationsResponses.Quote
  quoteFileName: string
}

export const ActiveQuoteDocument = ({ activeQuote, quoteFileName }: Props) => {
  if (activeQuote.quotePath === null) {
    return null
  }

  return (
    <Stack data-testid="quote-preview" w="100%">
      <MultiFilePreview
        files={[{ path: activeQuote.quotePath }]}
        fileName={quoteFileName}
        w="100%"
      />
    </Stack>
  )
}
