import { Checkbox, SimpleGrid, Stack, Text } from "@mantine/core"
import _ from "lodash"

import {
  EQUIPMENT_CATEGORIES,
  HouseEquipment,
} from "@ensol/shared/entities/houses/equipments"

type Props = {
  value?: HouseEquipment[]
  onChange: (value: string[]) => void
}

export const HouseEquipments = ({ value, onChange }: Props) => {
  return (
    <Checkbox.Group value={value} onChange={onChange}>
      <Stack gap={32}>
        {EQUIPMENT_CATEGORIES.map((category) => (
          <Stack gap={12} key={category.title}>
            <Text fz="lg" fw={500}>
              {category.title}
            </Text>
            <SimpleGrid cols={2}>
              {category.options.map((option) => (
                <Checkbox
                  key={option.value}
                  label={option.label}
                  value={option.value}
                  data-test={_.camelCase(option.value)}
                  styles={{ label: { fontSize: 14 } }}
                />
              ))}
            </SimpleGrid>
          </Stack>
        ))}
      </Stack>
    </Checkbox.Group>
  )
}
