import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import { MountingType } from "@ensol/shared/entities/installations/batteries"
import { withMargin } from "@ensol/shared/entities/installations/costs"
import { SubsidyType } from "@ensol/shared/entities/installations/subsidies"
import { getBattery } from "@ensol/shared/material/batteries"
import { BrandType } from "@ensol/shared/material/brands"

import { CostsBuilder } from "./CostsBuilder"

const ENPHASE_ACCESSORIES_PRICE_IN_EURO: Record<MountingType, number> = {
  [MountingType.ON_FLOOR]: 421.2,
  [MountingType.ON_WALL]: 133,
}
const HUAWEI_ACCESSORIES_PRICE_IN_EURO = 71.52
const BATTERY_ADMIN_FEE_COST_IN_EURO = 149

export class BatteryCostsBuilder extends CostsBuilder<
  InstallationsResponses.BatteryInstallation,
  SubsidyType
> {
  constructor(installation: InstallationsResponses.BatteryInstallation) {
    super(installation)
  }

  public computeVatRate() {
    return 0.2
  }

  public computeBatteryUnitPrice() {
    const battery = getBattery(this.installation.type)
    return withMargin(battery.price)
  }

  public computeBatteryAccessoriesPrice() {
    const { type, mountingType } = this.installation

    const battery = getBattery(type)

    if (battery.brand === BrandType.ENPHASE) {
      return withMargin(ENPHASE_ACCESSORIES_PRICE_IN_EURO[mountingType])
    }

    if (battery.brand === BrandType.HUAWEI) {
      return withMargin(HUAWEI_ACCESSORIES_PRICE_IN_EURO)
    }
    return 0
  }

  public computeMaterialCosts() {
    return (
      this.computeBatteryUnitPrice() + this.computeBatteryAccessoriesPrice()
    )
  }

  public computeWorkforceCosts() {
    const battery = getBattery(this.installation.type)

    return withMargin(battery.installationCost)
  }

  public computeAdminCosts() {
    return BATTERY_ADMIN_FEE_COST_IN_EURO
  }

  public computeSubsidy() {
    return null
  }
}
