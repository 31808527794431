import { GoogleCalendarEventType } from "@ensol/types/endpoints/google"

import { ProjectEventStatus } from "@ensol/shared/entities/projects/events"
import { colors } from "@ensol/shared/styles/colors"

export const EVENT_STATUSES_COLORS: Record<ProjectEventStatus, string> = {
  [ProjectEventStatus.PROPOSAL]: colors.yellow[200],
  [ProjectEventStatus.TO_SCHEDULE]: colors.red[300],
  [ProjectEventStatus.SCHEDULED]: colors.blue[500],
  [ProjectEventStatus.REJECTED]: colors.red[500],
  [ProjectEventStatus.COMPLETED]: colors.green[600],
} as const

export const GOOGLE_CALENDAR_TYPE_BORDER_COLORS: Record<
  GoogleCalendarEventType,
  string
> = {
  [GoogleCalendarEventType.AUDIT]: colors.blue[500],
  [GoogleCalendarEventType.SAV]: colors.red[500],
  [GoogleCalendarEventType.INSTALLER]: "black",
}

export const EXTERNAL_EVENT_COLOR = "black" as const
export const PUBLIC_HOLIDAY_BORDER_COLOR = colors.green[500]

export const EVENT_WARNING_BORDER_COLOR = "red.5" as const

export const EVENT_STATUSES_NAMES: Record<ProjectEventStatus, string> = {
  [ProjectEventStatus.PROPOSAL]: "Date proposée",
  [ProjectEventStatus.TO_SCHEDULE]: "Prêt à planifier",
  [ProjectEventStatus.SCHEDULED]: "Prévu",
  [ProjectEventStatus.REJECTED]: "Invitation refusée",
  [ProjectEventStatus.COMPLETED]: "Terminé",
} as const

export const EVENT_GOOGLE_CALENDAR_TYPES_NAMES: Record<
  GoogleCalendarEventType,
  string
> = {
  [GoogleCalendarEventType.INSTALLER]: "Calendrier installateur",
  [GoogleCalendarEventType.AUDIT]: "Audit",
  [GoogleCalendarEventType.SAV]: "SAV",
} as const
