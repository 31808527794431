import { Stack } from "@mantine/core"
import dayjs from "dayjs"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import {
  cetelemFieldsSchema,
  conditionalCetelemCoBorrowerValidation,
} from "@ensol/types/forms/projects/cetelem"
import { UnpackArray } from "@ensol/types/utils"

import {
  SALES_TASKS_CATEGORIES,
  SalesTaskCategory,
  SalesTaskCategoryConfig,
} from "@ensol/shared/entities/projects/salesTasks"
import {
  formatDate,
  SHORT_DISPLAY_DATE_FORMAT,
} from "@ensol/shared/utils/format"

import { IssuesBadges } from "@ensol/entool/components/entities/Project/IssuesBadges"
import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"
import { PROJECT_FIELDS_LABELS } from "@ensol/entool/utils/form/fieldLabels/projects"
import { formatZodValidationError } from "@ensol/entool/utils/form/validation"
import { ProjectsProjectsOrderByOptions } from "@ensol/entool/utils/projects/types"

import { ActionDone } from "./cetelemIncomplete/ActionDone"
import { AnswerByMail } from "./cetelemIncomplete/AnswerByMail"

type TaskConfig = {
  Component: React.ComponentType<{
    project: UnpackArray<ProjectResponses.ProjectsList>
  }>
  getLastEventDate?: (
    project: UnpackArray<ProjectResponses.ProjectsList>,
  ) => Date
  orderBy?: ProjectsProjectsOrderByOptions
}

const TASKS_COMPONENTS: Record<SalesTaskCategory, TaskConfig> = {
  signedToComplete: {
    Component: ({ project }) => (
      <ProcessInfos
        infos={[{ name: "Notes Ops", value: project.preliminaryRequestNotes }]}
      />
    ),
    getLastEventDate: ({ lastEventDateByProperty }) =>
      lastEventDateByProperty.status,
  },
  cetelemFieldsMissing: {
    Component: ({ project }) => (
      <ProcessInfos
        infos={[
          {
            dataType: "long-text",
            value: formatZodValidationError(
              cetelemFieldsSchema
                .superRefine((data, ctx) => {
                  if (data.cetelemHasCoBorrower) {
                    conditionalCetelemCoBorrowerValidation(data, ctx)
                  }
                })
                .safeParse(project).error?.issues ?? [],
              PROJECT_FIELDS_LABELS,
            ),
          },
        ]}
      />
    ),
  },
  cetelemIncomplete: {
    Component: ({ project }) => (
      <>
        <ProcessInfos
          infos={[
            { name: "Remarque", value: project.fundingSourceStatusNotes },
          ]}
        />
        <Stack gap="8" mt="8">
          <AnswerByMail {...project} />
          <ActionDone {...project} />
        </Stack>
      </>
    ),
  },
  technicalVisitNonSchedulable: {
    Component: ({ project }) => (
      <ProcessInfos
        infos={[
          {
            name: "À rappeler le",
            value: formatDate(
              project.technicalVisitSchedulingHoldEndDate,
              SHORT_DISPLAY_DATE_FORMAT,
            ),
            color: dayjs().isAfter(project.technicalVisitSchedulingHoldEndDate)
              ? "red.5"
              : undefined,
          },
          {
            name: "Raison principale",
            dataType: "component",
            value: (
              <IssuesBadges
                issues={
                  project.schedulingIssueMainReason
                    ? [project.schedulingIssueMainReason]
                    : []
                }
              />
            ),
          },
          {
            name: "Sous raison",
            dataType: "component",
            value: (
              <IssuesBadges
                issues={
                  project.schedulingIssueSubReason
                    ? [project.schedulingIssueSubReason]
                    : []
                }
              />
            ),
          },
        ]}
      />
    ),
    getLastEventDate: ({ lastEventDateByProperty }) =>
      lastEventDateByProperty.technicalVisitStep,
    orderBy: [["technicalVisitSchedulingHoldEndDate"], ["asc"]],
  },
  installationNonSchedulable: {
    Component: ({ project }) => (
      <ProcessInfos
        infos={[
          {
            name: "À rappeler le",
            value: formatDate(
              project.installationSchedulingHoldEndDate,
              SHORT_DISPLAY_DATE_FORMAT,
            ),
            color: dayjs().isAfter(project.installationSchedulingHoldEndDate)
              ? "red.5"
              : undefined,
          },
          {
            name: "Raison principale",
            dataType: "component",
            value: (
              <IssuesBadges
                issues={
                  project.schedulingIssueMainReason
                    ? [project.schedulingIssueMainReason]
                    : []
                }
              />
            ),
          },
          {
            name: "Sous raison",
            dataType: "component",
            value: (
              <IssuesBadges
                issues={
                  project.schedulingIssueSubReason
                    ? [project.schedulingIssueSubReason]
                    : []
                }
              />
            ),
          },
        ]}
      />
    ),
    getLastEventDate: ({ lastEventDateByProperty }) =>
      lastEventDateByProperty.installationStep,
    orderBy: [["installationSchedulingHoldEndDate"], ["asc"]],
  },
  googleReviewToAsk: {
    Component: ({ project }) => (
      <ProcessInfos
        infos={[
          {
            name: "Date installation",
            value: formatDate(
              project.installationEndDate,
              SHORT_DISPLAY_DATE_FORMAT,
            ),
          },
          { name: "Plateforme", value: project.feedback?.reviewPlatform },
        ]}
      />
    ),
  },
}

export const SALES_TASKS_VIEWS: (SalesTaskCategoryConfig & TaskConfig)[] =
  SALES_TASKS_CATEGORIES.map((props) => ({
    ...props,
    ...TASKS_COMPONENTS[props.id],
  }))
