import { withMargin } from "@ensol/shared/entities/installations/costs"

export enum ExtraWorkType {
  PANELS_REMOVAL = "PANELS_REMOVAL",
  PANELS_RECYCLING = "PANELS_RECYCLING",
  PV_PANELS_REMOVAL = "PV_PANELS_REMOVAL",
  PV_PANELS_RECYCLING = "PV_PANELS_RECYCLING",
  HOT_WATER_TANK_REMOVAL = "HOT_WATER_TANK_REMOVAL",
  HOT_WATER_TANK_RECYCLING = "HOT_WATER_TANK_RECYCLING",
  SIMPLE_GROUNDING = "SIMPLE_GROUNDING",
  COMPLEX_GROUNDING = "COMPLEX_GROUNDING",
  EARTHEN_TRENCH_LESS_15M = "EARTHEN_TRENCH_LESS_15M",
  EARTHEN_TRENCH_MORE_15M = "EARTHEN_TRENCH_MORE_15M",
  CONCRETE_TRENCH_LESS_15M = "CONCRETE_TRENCH_LESS_15M",
  CONCRETE_TRENCH_MORE_15M = "CONCRETE_TRENCH_MORE_15M",
  MODULAR_CLOCK_INSTALLATION = "MODULAR_CLOCK_INSTALLATION",
  SHELLY = "SHELLY",
  AIR_DUCT_RELOCATION = "AIR_DUCT_RELOCATION",
  POWERLINE_SOCKET = "POWERLINE_SOCKET",
  ECOJOKO = "ECOJOKO",
  CUSTOM = "CUSTOM",
}

export enum ExtraWorkDomain {
  ROOF = "ROOF",
  ELECTRICAL_INSTALLATION = "ELECTRICAL_INSTALLATION",
  CABLE_ROUTING = "CABLE_ROUTING",
}

type ExtraWorkInfo = {
  domain?: ExtraWorkDomain
  category: string
  name: string
  description: string
  price: number
}

export const EXTRA_WORKS: Record<ExtraWorkType, ExtraWorkInfo> = {
  PANELS_REMOVAL: {
    domain: ExtraWorkDomain.ROOF,
    category: "Panneaux thermiques",
    name: "Retrait de panneaux thermiques",
    description: "Démontage et vidange des panneaux",
    price: withMargin(350),
  },
  PANELS_RECYCLING: {
    domain: ExtraWorkDomain.ROOF,
    category: "Panneaux thermiques",
    name: "Recyclage de panneaux thermiques",
    description: "Recyclage des panneaux thermiques",
    price: withMargin(150),
  },
  PV_PANELS_REMOVAL: {
    domain: ExtraWorkDomain.ROOF,
    category: "Panneaux photovoltaïques",
    name: "Retrait de panneaux photovoltaïques",
    description: "Démontage des panneaux existants",
    price: withMargin(350),
  },
  PV_PANELS_RECYCLING: {
    domain: ExtraWorkDomain.ROOF,
    category: "Panneaux photovoltaïques",
    name: "Recyclage de panneaux photovoltaïques",
    description: "Recyclage des panneaux photovoltaïques",
    price: withMargin(150),
  },
  HOT_WATER_TANK_REMOVAL: {
    domain: ExtraWorkDomain.ROOF,
    category: "Ballon d'eau chaude",
    name: "Retrait d'un ballon d'eau chaude",
    description: "Démontage, vidange d'un ballon d'eau chaude",
    price: withMargin(200),
  },
  HOT_WATER_TANK_RECYCLING: {
    domain: ExtraWorkDomain.ROOF,
    category: "Ballon d'eau chaude",
    name: "Recyclage d'un ballon d'eau chaude",
    description: "Recyclage d'un ballon d'eau chaude",
    price: withMargin(100),
  },
  SIMPLE_GROUNDING: {
    domain: ExtraWorkDomain.ELECTRICAL_INSTALLATION,
    category: "Mise à la terre",
    name: "Mise à la terre simple",
    description: "Remise aux normes de la mise à la terre < 100 ohm",
    price: withMargin(150),
  },
  COMPLEX_GROUNDING: {
    domain: ExtraWorkDomain.ELECTRICAL_INSTALLATION,
    category: "Mise à la terre",
    name: "Mise à la terre complexe",
    description: "Remise aux normes de la mise à la terre < 100 ohm",
    price: withMargin(300),
  },
  EARTHEN_TRENCH_LESS_15M: {
    domain: ExtraWorkDomain.CABLE_ROUTING,
    category: "Tranchée",
    name: "Tranchée dans de la terre < 15m",
    description: "Réalisation d'une tranchée < 15m",
    price: withMargin(250),
  },
  EARTHEN_TRENCH_MORE_15M: {
    domain: ExtraWorkDomain.CABLE_ROUTING,
    category: "Tranchée",
    name: "Tranchée dans de la terre 15-30m",
    description: "Réalisation d'une tranchée 15-30m",
    price: withMargin(500),
  },
  CONCRETE_TRENCH_LESS_15M: {
    domain: ExtraWorkDomain.CABLE_ROUTING,
    category: "Tranchée",
    name: "Tranchée dans du béton < 15m",
    description: "Réalisation d'une tranchée sur sol béton < 15m",
    price: withMargin(500),
  },
  CONCRETE_TRENCH_MORE_15M: {
    domain: ExtraWorkDomain.CABLE_ROUTING,
    category: "Tranchée",
    name: "Tranchée dans du béton 15-30m",
    description: "Réalisation d'une tranchée sur sol béton 15-30m ",
    price: withMargin(650),
  },
  MODULAR_CLOCK_INSTALLATION: {
    domain: ExtraWorkDomain.ELECTRICAL_INSTALLATION,
    category: "Horloge modulaire",
    name: "Pose d'une horloge modulaire",
    description:
      "Installation d'une horloge modulaire - régulation d'un ballon d'eau chaude",
    price: withMargin(200),
  },
  SHELLY: {
    domain: ExtraWorkDomain.ELECTRICAL_INSTALLATION,
    category: "Pose d'un Shelly",
    name: "Pose d'une horloge connectée (Shelly)",
    description:
      "Installation d'une horloge connectée - Régulation d'un ballon d'eau chaude",
    price: withMargin(200),
  },
  AIR_DUCT_RELOCATION: {
    domain: ExtraWorkDomain.ROOF,
    category: "Toiture",
    name: "Déplacement d'un conduit d'aération (VMC)",
    description: "Déplacement et raccordement d'un conduit d'aération (VMC)",
    price: withMargin(200),
  },
  POWERLINE_SOCKET: {
    domain: ExtraWorkDomain.ELECTRICAL_INSTALLATION,
    category: "Installation électrique",
    name: "Pose d'une prise CPL",
    description: "Pose d'une prise CPL - connexion filaire à la passerelle",
    price: withMargin(100),
  },
  ECOJOKO: {
    domain: ExtraWorkDomain.ELECTRICAL_INSTALLATION,
    category: "Installation électrique",
    name: "Pose d'un Ecojoko ",
    description: "Pose d'un Ecojoko - dispositif de suivi de consommation",
    price: withMargin(200),
  },
  CUSTOM: {
    category: "",
    name: "Autre",
    description: "",
    price: 0,
  },
}

export const getExtraWork = (type: ExtraWorkType): ExtraWorkInfo =>
  EXTRA_WORKS[type]
