import {
  ActionIcon,
  Button,
  ButtonProps,
  MantineStyleProps,
  Tooltip,
} from "@mantine/core"
import { IconExternalLink } from "@tabler/icons-react"
import { ReactNode } from "react"
import { Link as ReactRouterLink } from "react-router-dom"

type Props = {
  label: string
  to: string
  Icon?: ReactNode
  isExternal?: boolean
  isIconButton?: boolean
} & MantineStyleProps &
  ButtonProps

export const Link = ({
  label,
  to,
  Icon,
  isExternal = false,
  isIconButton = false,
  color,
  ...props
}: Props) => {
  if (isIconButton) {
    return (
      <Tooltip label={label}>
        <ActionIcon
          component={ReactRouterLink}
          to={to}
          target={isExternal ? "_blank" : undefined}
          variant="transparent"
          color={color}
        >
          {Icon}
        </ActionIcon>
      </Tooltip>
    )
  }

  return (
    <Button
      component={ReactRouterLink}
      leftSection={Icon}
      rightSection={isExternal && <IconExternalLink size={14} />}
      variant="outline"
      to={to}
      target={isExternal ? "_blank" : undefined}
      role="link"
      color={color}
      {...props}
    >
      {label}
    </Button>
  )
}
