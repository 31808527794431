import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { HouseSignedInstallations } from "@ensol/types/installation"
import { SimulationResults, SimulationWarnings } from "@ensol/types/simulation"

import {
  computeAllCosts,
  computeEffectiveMargin,
} from "@ensol/shared/entities/installations/costs"
import {
  CURRENT_CONNECTIONS,
  CurrentType,
} from "@ensol/shared/material/currentType"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

import {
  checkInverterCompatibility,
  computePanelsCapacity,
  computeSignedInstallationsCapacity,
  computeSignedInstallationsPanelsCount,
} from "./energy"
import {
  computeFinancialData,
  computeMonthsBeforeBreakEvenDate,
} from "./savings"

type SimulationInput = {
  monthlyBill: number
  yearlyConsumption: number
  estimatedYearlyProduction: number
  autoConsumptionPercent: number
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation | null
  extraPanelsInstallation: InstallationsResponses.ExtraPanelsInstallation | null
  batteryInstallation: InstallationsResponses.BatteryInstallation | null
  evChargerInstallation: InstallationsResponses.EvChargerInstallation | null
  productsCount: number
  signedInstallations?: HouseSignedInstallations
  house: Pick<
    HousesResponses.House<{ include: { roofSections: true } }>,
    "constructionYear" | "roofType" | "currentType" | "roofSections"
  >
  totalDiscount: number
  electricityPriceAnnualRaise?: number
}

type WarningsInput = {
  photovoltaicInstallation?: InstallationsResponses.PhotovoltaicInstallation
  panelsCount: number
  installedCapacity: number
  house: Pick<HousesResponses.House, "currentType">
  installationCost: number
  finalCost: number
}

const computeWarnings = ({
  photovoltaicInstallation,
  panelsCount,
  installedCapacity,
  house: { currentType },
  installationCost,
  finalCost,
}: WarningsInput): SimulationWarnings => {
  const effectiveMargin = computeEffectiveMargin({
    installationCost,
    finalCost,
  })

  const totalDiscountWarning =
    effectiveMargin < 0.15
      ? `La marge effective (${Math.round(
          effectiveMargin * 100,
        )}%) est inférieure à 15%.`
      : undefined

  if (!photovoltaicInstallation) {
    return {
      installedCapacity: undefined,
      totalDiscount: totalDiscountWarning,
    }
  }

  const currentConnection = CURRENT_CONNECTIONS[currentType]

  const { DCACPercent } = checkInverterCompatibility({
    panelsCount,
    installedCapacity,
    inverterType: photovoltaicInstallation.inverterType,
    currentType,
  })

  return {
    installedCapacity:
      installedCapacity > 6 && currentType === CurrentType.SINGLE_PHASE
        ? `Installation ${currentConnection.name.toLowerCase()} de ${roundDecimalNumber(installedCapacity)}kWc contre 6kWc max installables. Le surplus (production-consommation) sera écrêté à la revente. Les économies réelles seront donc diminuées. Attention au ratio DC/AC de l'onduleur (${DCACPercent}%).`
        : undefined,
    totalDiscount: totalDiscountWarning,
  }
}

export const runSimulation = ({
  monthlyBill,
  yearlyConsumption,
  estimatedYearlyProduction,
  autoConsumptionPercent,
  productsCount,
  photovoltaicInstallation,
  extraPanelsInstallation,
  batteryInstallation,
  evChargerInstallation,
  house,
  totalDiscount,
  signedInstallations,
  electricityPriceAnnualRaise,
}: SimulationInput): SimulationResults => {
  const installedCapacity =
    (photovoltaicInstallation
      ? computePanelsCapacity(photovoltaicInstallation)
      : extraPanelsInstallation
        ? computePanelsCapacity(extraPanelsInstallation)
        : 0) + computeSignedInstallationsCapacity(signedInstallations)

  const panelsCount =
    (photovoltaicInstallation?.panelsCount ??
      extraPanelsInstallation?.panelsCount ??
      0) + computeSignedInstallationsPanelsCount(signedInstallations)

  const {
    installationCost,
    finalCost,
    finalCostBeforeTax,
    finalCostWithSubsidies,
    subsidies,
  } = computeAllCosts({
    photovoltaicInstallation,
    extraPanelsInstallation,
    batteryInstallation,
    evChargerInstallation,
    house,
    totalDiscount,
    productsCount,
  })

  const subsidyType =
    photovoltaicInstallation?.subsidyType ??
    signedInstallations?.photovoltaicInstallation.subsidyType ??
    undefined

  // We want to take only photovoltaic & battery installation costs into account to compute
  // break even date because other products do not generate direct energy savings
  const {
    finalCostWithSubsidies: finalCostForPhotovoltaicAndBatteryWithSubsidies,
  } = computeAllCosts({
    photovoltaicInstallation,
    extraPanelsInstallation,
    batteryInstallation,
    evChargerInstallation: null,
    house,
    totalDiscount,
    productsCount,
  })
  const monthsBeforeBreakEvenDate = computeMonthsBeforeBreakEvenDate({
    monthlyBill,
    yearlyConsumption,
    installedCapacity,
    estimatedYearlyProduction,
    autoConsumptionPercent,
    finalCostForPhotovoltaicAndBatteryWithSubsidies,
    subsidyType,
    electricityPriceAnnualRaise,
  })

  const financialData = computeFinancialData({
    monthlyBill,
    yearlyConsumption,
    installedCapacity,
    estimatedYearlyProduction,
    autoConsumptionPercent,
    finalCostWithSubsidies,
    currentType: house.currentType,
    subsidyType,
    electricityPriceAnnualRaise,
  })

  const warnings = computeWarnings({
    photovoltaicInstallation:
      photovoltaicInstallation ?? signedInstallations?.photovoltaicInstallation,
    panelsCount,
    installedCapacity,
    house,
    installationCost,
    finalCost,
  })

  return {
    subsidies,
    installedCapacity,
    autoConsumptionPercent,
    yearlyConsumption,
    estimatedYearlyProduction,
    totalDiscount,
    installationCost,
    finalCost,
    finalCostBeforeTax,
    finalCostWithSubsidies,
    monthsBeforeBreakEvenDate,
    warnings,
    ...financialData,
  }
}
