import { ExtraWorks } from "@ensol/types/installation"

import { ProductCosts } from "@ensol/shared/entities/installations/costs"
import { computeExtraWorksCosts } from "@ensol/shared/entities/installations/costs/extraWorks"
import {
  SubsidyType,
  Subsidy,
} from "@ensol/shared/entities/installations/subsidies"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

export abstract class CostsBuilder<
  InstallationType extends { extraWorks: ExtraWorks },
  InstallationSubsidyType extends SubsidyType,
> {
  constructor(protected installation: InstallationType) {}

  protected abstract computeVatRate(): number
  protected abstract computeSubsidy(): Subsidy<InstallationSubsidyType> | null
  protected abstract computeMaterialCosts(): number
  protected abstract computeWorkforceCosts(): number
  protected abstract computeAdminCosts(): number

  public computeInstallationCost() {
    const materialCostHT = this.computeMaterialCosts()
    const workforceCostHT = this.computeWorkforceCosts()
    const extraWorksCostHT = computeExtraWorksCosts(this.installation)
    const adminCostHT = this.computeAdminCosts()

    const totalCostHT =
      materialCostHT + extraWorksCostHT + workforceCostHT + adminCostHT

    return roundDecimalNumber(totalCostHT * (1 + this.computeVatRate()))
  }

  public computeAllCosts(): ProductCosts & {
    subsidy: Subsidy<InstallationSubsidyType> | null
  } {
    const vatRate = this.computeVatRate()
    const installationCost = this.computeInstallationCost()

    const installationCostBeforeTax = roundDecimalNumber(
      installationCost / (1 + vatRate),
    )

    const subsidy = this.computeSubsidy()

    const installationCostWithSubsidies = roundDecimalNumber(
      installationCost - (subsidy?.subsidyAmount ?? 0),
    )

    return {
      installationCost,
      installationCostBeforeTax,
      installationCostWithSubsidies,
      subsidy,
      vatRate,
    }
  }
}
