import { Card, CardProps, Group, Text, ThemeIcon } from "@mantine/core"
import { IconZoomQuestion } from "@tabler/icons-react"

type Props = {
  message?: string
  type: "text" | "file"
} & CardProps

export const NotFound = ({ message, type, ...props }: Props) => (
  <Card
    w={props.w ?? "300"}
    {...props}
    h={props.h ?? (type === "file" ? 150 : 40)}
    padding="4"
    radius="md"
    withBorder
    style={({ colors }) => ({ border: `1px dashed ${colors.gray[3]}` })}
  >
    <Group justify="center" align="center" h="100%" gap={8}>
      <ThemeIcon size="md" color="gray">
        <IconZoomQuestion />
      </ThemeIcon>
      <Text size="xs" c="gray.6" style={{ textAlign: "center" }}>
        {message ?? "Information manquante"}
      </Text>
    </Group>
  </Card>
)
