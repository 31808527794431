import { Stack } from "@mantine/core"
import { useEffect, useState } from "react"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { Coords } from "@ensol/types/forms/houses"

import { useDrawMap } from "@ensol/shared/thirdParties/google/maps"

import { httpClient } from "@ensol/entool/backend/axios"
import { SunshineRastersSwitch } from "@ensol/entool/components/entities/Installation/SunshineRastersSwitch"

import { MapWrapper } from "./MapWrapper"

type Props = {
  coords: Coords
  onPlacePinPoint?: (coords: Coords) => void
  panelsLayout?: InstallationsResponses.Installation["panelsLayout"]
  mapId: string
  zoomLevel?: number
  hidePin?: boolean
  withSunshineRastersSwitch?: boolean
  style?: React.CSSProperties
}

const FETCH_DATA_LAYERS_CONFIG = {
  httpClient,
  radiusMeters: 12,
}

export const Map = ({
  coords,
  mapId,
  onPlacePinPoint,
  panelsLayout,
  zoomLevel,
  hidePin,
  withSunshineRastersSwitch = false,
  style,
}: Props) => {
  const [showSunshineRasters, setShowSunshineRasters] = useState(false)

  const isEditable = !!onPlacePinPoint
  const withDataLayers = !!panelsLayout || showSunshineRasters

  const {
    isMapLoaded,
    areSunshineRastersReady,
    areSunshineRastersNotAvailable,
    rastersImageryDate,
    onDrawSunshineRasters,
  } = useDrawMap({
    apiKey: import.meta.env.VITE_GOOGLE_API_KEY,
    coords,
    onClickMap: onPlacePinPoint,
    panelsLayoutPaths: panelsLayout?.paths,
    withControls: isEditable,
    withOrthorectifiedMap:
      panelsLayout?.withOrthorectifiedMap === true || showSunshineRasters
        ? true
        : false,
    mapId,
    fetchDataLayersConfig: withDataLayers
      ? FETCH_DATA_LAYERS_CONFIG
      : undefined,
    zoomLevel,
    hidePin,
  })

  useEffect(() => {
    if (areSunshineRastersReady) {
      onDrawSunshineRasters(showSunshineRasters, "annualFlux")
    }
  }, [showSunshineRasters, areSunshineRastersReady, onDrawSunshineRasters])

  return (
    <Stack gap="4">
      {withSunshineRastersSwitch && (
        <SunshineRastersSwitch
          areSunshineRastersNotAvailable={areSunshineRastersNotAvailable}
          areSunshineRastersReady={areSunshineRastersReady}
          rastersImageryDate={rastersImageryDate}
          isHeatmapShown={showSunshineRasters}
          onChange={() => setShowSunshineRasters(!showSunshineRasters)}
          py={8}
        />
      )}
      <MapWrapper
        mapId={mapId}
        style={{ width: "100%", borderRadius: 16, ...style }}
        isLoading={!isMapLoaded}
      />
    </Stack>
  )
}
