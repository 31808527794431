import { Button, ButtonProps, Collapse, Stack, StackProps } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconChevronDown, IconChevronUp } from "@tabler/icons-react"
import { ReactNode } from "react"

type Props = {
  name: ReactNode
  isOpened: boolean
  children: ReactNode
  buttonProps?: ButtonProps
} & StackProps

export const FoldableBox = ({
  name,
  isOpened,
  children,
  buttonProps,
  ...props
}: Props) => {
  const [opened, { toggle }] = useDisclosure(isOpened)

  return (
    <Stack mt="8" align="flex-start" gap="4" {...props}>
      <Button
        variant="transparent"
        color="black"
        p="8"
        size="sm"
        w="100%"
        onClick={toggle}
        justify="space-between"
        rightSection={
          opened ? <IconChevronUp size={14} /> : <IconChevronDown size={14} />
        }
        {...buttonProps}
      >
        {name}
      </Button>
      <Collapse in={opened} w="100%">
        {children}
      </Collapse>
    </Stack>
  )
}
