import { Group, Radio } from "@mantine/core"
import _ from "lodash"
import { CSSProperties, forwardRef, ReactNode } from "react"

import { OptionType, Option } from "@ensol/entool/utils/form/radio"

// Option 3 on this : https://oida.dev/typescript-react-generic-forward-refs/
declare module "react" {
  function forwardRef<T, P = unknown>(
    render: (props: P, ref: React.Ref<T>) => React.ReactNode | null,
  ): (props: P & React.RefAttributes<T>) => React.ReactNode | null
}

type Props<T extends OptionType, Metadata = null> = {
  options: Option<T, Metadata>[]
  OptionsWrapper?: React.FC<{ children: ReactNode }>
  value?: T
  onChange: (value: T | null) => void
  disabled?: boolean
  nullable?: boolean
  error?: string | ReactNode
  w?: CSSProperties["width"]
}

const RadioGroupInner: <T extends OptionType, Metadata = null>(
  props: Props<T, Metadata>,
  ref: React.ForwardedRef<HTMLDivElement>,
) => JSX.Element = (
  {
    w,
    options,
    OptionsWrapper = ({ children }) => <Group gap="16">{children}</Group>,
    value,
    onChange,
    disabled = false,
    nullable = false,
    error,
  },
  ref,
) => (
  <Radio.Group
    w={w}
    ref={ref}
    value={String(value)}
    error={error}
    styles={{
      root: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        flex: 1,
      },
      error: { marginTop: 16 },
    }}
    mih={36}
  >
    <OptionsWrapper>
      {options.map((option) => (
        <Radio
          key={option.value.toString()}
          value={String(option.value)}
          label={option.label}
          description={option.subtitle}
          disabled={disabled || !!option.disabled}
          data-test={_.camelCase(option.value.toString())}
          onClick={() =>
            onChange(
              value === option.value
                ? nullable
                  ? null
                  : option.value
                : option.value,
            )
          }
          styles={{
            radio: { cursor: "pointer" },
            label: { fontSize: 14, cursor: "pointer" },
            description: { fontSize: 12 },
          }}
        />
      ))}
    </OptionsWrapper>
  </Radio.Group>
)

export const RadioGroup = forwardRef(RadioGroupInner)
