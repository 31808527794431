import {
  Stack,
  Divider,
  SimpleGrid,
  TitleOrder,
  Textarea,
  Title,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconHome } from "@tabler/icons-react"
import { ReactNode, useState } from "react"

import { HousesResponses } from "@ensol/types/endpoints/houses"

import { getFullName } from "@ensol/shared/entities/prospects"

import { Section } from "@ensol/entool/components/Section"
import { Checkbox } from "@ensol/entool/components/form/Checkbox"
import { Field } from "@ensol/entool/components/form/Field"
import { HouseEquipments } from "@ensol/entool/components/form/House/HouseEquipments"
import { PdlAutocomplete } from "@ensol/entool/components/form/House/PdlAutocomplete"
import { NumberInput } from "@ensol/entool/components/form/NumberInput"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { booleanOptions } from "@ensol/entool/utils/form/options"
import {
  EXISTING_INSTALLATION_AGE_OPTIONS,
  EXISTING_INSTALLATION_CONTRACT_OPTIONS,
  FLOORS_OPTIONS,
  POTENTIAL_SHADINGS_OPTIONS,
  ROOF_FRAMING_MATERIALS_OPTIONS,
  UNREGULATED_CONSTRUCTION_OPTIONS,
} from "@ensol/entool/utils/house/options"

import fromFrontPhotoExample from "../assets/fromFrontPhotoExample.png"
import fromStreetPhotoExample from "../assets/fromStreetPhotoExample.png"

import { HousePhotosField } from "./HousePhotosField"

type Props<Values> = {
  house: HousesResponses.House<{ include: { prospect: true } }>
  form: UseFormReturnType<Values>
  titleOrder?: TitleOrder
}

export const HouseForm = <
  Values extends {
    existingInstallationCapacity: number | null
    existingInstallationContract: string | null
    existingInstallationAge: string | null
    constructionYear: number
    surface: number | null
    floors: number | null
    isIndividualHouse: boolean | null
    hasAsbestos: boolean | null
    isHistoricBuilding: boolean | null
    fromStreetPhotos: string[]
    fromFrontPhotos: string[]
    electricMeterPhotos: string[]
    pdl: string
    contractId: string | null
    electricalPanelPhotos: string[]
    isAtticAccessible: boolean | null
    atticPhotos: string[]
    roofFramingMaterial: string | null
    roofPhotos: string[]
    potentialShading: string | null
    isPowerLineNearby: boolean | null
    isPodNeeded: boolean | null
    otherPhotos: string[]
    equipments: string[]
    hasUnRegulatedConstruction: string | null
  },
>({
  house: {
    prospect,
    address,
    streetAddress,
    existingInstallationCapacity,
    existingInstallationContract,
    existingInstallationAge,
  },
  form,
  titleOrder = 3,
}: Props<Values>) => {
  const [hasExistingInstallation, setHasExistingInstallation] = useState(
    existingInstallationCapacity !== null ||
      existingInstallationContract !== null ||
      existingInstallationAge !== null,
  )

  return (
    <Section title="Maison" icon={IconHome} titleOrder={titleOrder}>
      <Stack gap="24">
        <SubSection title="Informations générales">
          <SimpleGrid
            cols={{ base: 1, md: 2 }}
            spacing={28}
            mt="8"
            mb={{ base: 8, md: 16 }}
          >
            <Field name="Année de construction" withAsterisk>
              <NumberInput
                isNullable={false}
                w={FIELD_WIDTH}
                {...form.getInputProps("constructionYear")}
              />
            </Field>
            <Field name="Surface">
              <NumberInput
                w={FIELD_WIDTH}
                suffix="m²"
                {...form.getInputProps("surface")}
              />
            </Field>
            <Field name="Nombre d'étages">
              <RadioGroup
                options={FLOORS_OPTIONS}
                {...form.getInputProps("floors")}
              />
            </Field>
            <Field name="Maison individuelle ?">
              <RadioGroup
                options={booleanOptions}
                {...form.getInputProps("isIndividualHouse")}
              />
            </Field>
            <Field name="Présence d'amiante ?">
              <RadioGroup
                options={booleanOptions}
                {...form.getInputProps("hasAsbestos")}
              />
            </Field>
            <Field name="Zone ABF ?">
              <RadioGroup
                options={booleanOptions}
                {...form.getInputProps("isHistoricBuilding")}
              />
            </Field>
          </SimpleGrid>
        </SubSection>
        <SubSection title="Extérieur de la maison">
          <SimpleGrid
            cols={{ base: 1, md: 2 }}
            spacing={28}
            mt="8"
            mb={{ base: 8, md: 16 }}
          >
            <HousePhotosField
              houseStreetAddress={streetAddress}
              label="Photos depuis la rue"
              fileNameExtension="vue-rue"
              isRequired
              placeholderImage={fromStreetPhotoExample}
              {...form.getInputProps("fromStreetPhotos")}
            />
            <HousePhotosField
              houseStreetAddress={streetAddress}
              label="Photos depuis l'environnement privé"
              fileNameExtension="vue-jardin"
              isRequired
              placeholderImage={fromFrontPhotoExample}
              {...form.getInputProps("fromFrontPhotos")}
            />
            <Stack>
              <Field
                name="Y'a il des constructions non régularisées ?"
                withAsterisk
              >
                <RadioGroup
                  options={UNREGULATED_CONSTRUCTION_OPTIONS}
                  {...form.getInputProps("hasUnRegulatedConstruction")}
                />
              </Field>
              {form.values.hasUnRegulatedConstruction === "yes" && (
                <>
                  <Divider />
                  <Field
                    withAsterisk
                    name="Commentaires pour les constructions non régularisées"
                  >
                    <Textarea
                      autosize
                      w={FIELD_WIDTH}
                      minRows={3}
                      {...form.getInputProps("unRegulatedConstructionNote")}
                    />
                  </Field>
                </>
              )}
            </Stack>
          </SimpleGrid>
        </SubSection>
        <SubSection title="Compteur électrique">
          <SimpleGrid
            cols={{ base: 1, md: 2 }}
            spacing={28}
            mt="8"
            mb={{ base: 8, md: 16 }}
          >
            <HousePhotosField
              houseStreetAddress={streetAddress}
              label="Photos du compteur"
              fileNameExtension="compteur-électrique"
              isRequired
              {...form.getInputProps("electricMeterPhotos")}
            />
            <Stack gap="28">
              <Field name="PDL" withAsterisk>
                <PdlAutocomplete
                  w={FIELD_WIDTH}
                  holderName={getFullName(prospect)}
                  address={address}
                  {...form.getInputProps("pdl")}
                  onChange={(pdl, contractId) => {
                    form.getInputProps("pdl").onChange(pdl)
                    form.getInputProps("contractId").onChange(contractId)
                  }}
                />
              </Field>
              <Field name="Compteur Linky ?">
                <RadioGroup
                  options={booleanOptions}
                  {...form.getInputProps("hasLinky")}
                />
              </Field>
            </Stack>
          </SimpleGrid>
        </SubSection>
        <SubSection title="Tableau électrique domestique">
          <SimpleGrid
            cols={{ base: 1, md: 2 }}
            spacing={28}
            mt="8"
            mb={{ base: 8, md: 16 }}
          >
            <HousePhotosField
              houseStreetAddress={streetAddress}
              label="Photos tableau électrique"
              fileNameExtension="tableau-environs"
              isRequired
              {...form.getInputProps("electricalPanelPhotos")}
            />
          </SimpleGrid>
        </SubSection>
        <SubSection title="Combles">
          <SimpleGrid
            cols={{ base: 1, md: 2 }}
            spacing={28}
            mt="8"
            mb={{ base: 8, md: 16 }}
          >
            <Field name="Accès aux combles possible ?">
              <RadioGroup
                options={booleanOptions}
                {...form.getInputProps("isAtticAccessible")}
              />
            </Field>
            <Field name="Matériau de la charpente">
              <RadioGroup
                options={ROOF_FRAMING_MATERIALS_OPTIONS}
                {...form.getInputProps("roofFramingMaterial")}
              />
            </Field>
            <HousePhotosField
              houseStreetAddress={streetAddress}
              label="Photos des combles"
              fileNameExtension="combles"
              isRequired={false}
              {...form.getInputProps("atticPhotos")}
            />
          </SimpleGrid>
        </SubSection>
        <SubSection title="Toiture">
          <SimpleGrid
            cols={{ base: 1, md: 2 }}
            spacing={28}
            mt="8"
            mb={{ base: 8, md: 16 }}
          >
            <Stack gap="28">
              <HousePhotosField
                houseStreetAddress={streetAddress}
                label="Photos des pans de toiture où les panneaux seront installés"
                fileNameExtension="toiture"
                isRequired
                {...form.getInputProps("roofPhotos")}
              />
            </Stack>
            <Stack gap="28">
              <Field name="Présence ombrage potentiel ?">
                <RadioGroup
                  options={POTENTIAL_SHADINGS_OPTIONS}
                  {...form.getInputProps("potentialShading")}
                />
              </Field>
              <Field name="Présence de ligne électrique à moins de 5m de la toiture ?">
                <RadioGroup
                  options={booleanOptions}
                  {...form.getInputProps("isPowerLineNearby")}
                />
              </Field>
              <Field name="Une nacelle est nécessaire ?">
                <RadioGroup
                  options={booleanOptions}
                  {...form.getInputProps("isPodNeeded")}
                />
              </Field>
            </Stack>
          </SimpleGrid>
        </SubSection>
        <SubSection title="Équipements">
          <Stack mt="8">
            <HouseEquipments {...form.getInputProps("equipments")} />
          </Stack>
        </SubSection>
        <SubSection title="Autre" noBorder>
          <SimpleGrid
            cols={{ base: 1, md: 2 }}
            spacing={28}
            mt="8"
            mb={{ base: 8, md: 16 }}
          >
            <HousePhotosField
              houseStreetAddress={streetAddress}
              label="Autres photos"
              fileNameExtension="autres"
              isRequired={false}
              {...form.getInputProps("otherPhotos")}
            />
            <Stack gap="24">
              <Checkbox
                checked={hasExistingInstallation}
                onChange={(event) =>
                  setHasExistingInstallation(event.currentTarget.checked)
                }
                label="La maison possède déjà des panneaux ?"
              />
              {hasExistingInstallation && (
                <>
                  <Divider />
                  <Field name="Puissance installée existante (kWc)">
                    <NumberInput
                      w={FIELD_WIDTH}
                      suffix="kWc"
                      hideControls
                      {...form.getInputProps("existingInstallationCapacity")}
                    />
                  </Field>
                  <Field name="Contrat de revente">
                    <RadioGroup
                      nullable
                      options={EXISTING_INSTALLATION_CONTRACT_OPTIONS}
                      {...form.getInputProps("existingInstallationContract")}
                    />
                  </Field>
                  <Field name="Ancienneté de l'installation actuelle">
                    <RadioGroup
                      nullable
                      options={EXISTING_INSTALLATION_AGE_OPTIONS}
                      {...form.getInputProps("existingInstallationAge")}
                    />
                  </Field>
                </>
              )}
            </Stack>
          </SimpleGrid>
        </SubSection>
      </Stack>
    </Section>
  )
}

const SubSection = ({
  title,
  noBorder,
  children,
}: {
  title: string
  noBorder?: boolean
  children: ReactNode
}) => (
  <Stack>
    <Title order={4}>{title}</Title>
    {children}
    {!noBorder && <Divider />}
  </Stack>
)
