import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"

import { ProjectMilestone } from "./types"

const PROJECT_STATUSES: Record<
  ProjectStatus,
  ProjectMilestone<ProjectStatus>
> = {
  [ProjectStatus.CREATED]: {
    id: ProjectStatus.CREATED,
    label: "Créé",
    category: "Créé",
    color: "blue",
    nextSteps: [],
  },
  [ProjectStatus.SIGNED]: {
    id: ProjectStatus.SIGNED,
    label: "Après signature",
    category: "Après signature",
    color: "blue",
    nodePosition: { x: 0, y: 50 },
    nextSteps: [ProjectStatus.TECHNICAL_VISIT_SCHEDULED],
  },
  [ProjectStatus.TECHNICAL_VISIT_SCHEDULED]: {
    id: ProjectStatus.TECHNICAL_VISIT_SCHEDULED,
    label: "Visite technique planifiée",
    category: "Visite technique planifiée",
    color: "blue",
    nodePosition: { x: 200, y: 40 },
    nextSteps: [ProjectStatus.TECHNICAL_VISIT_COMPLETED],
  },
  [ProjectStatus.TECHNICAL_VISIT_COMPLETED]: {
    id: ProjectStatus.TECHNICAL_VISIT_COMPLETED,
    color: "blue",
    label: "Visite technique réalisée",
    category: "Visite technique réalisée",
    nodePosition: { x: 400, y: 40 },
    nextSteps: [ProjectStatus.INSTALLATION_SCHEDULED],
  },
  [ProjectStatus.INSTALLATION_SCHEDULED]: {
    id: ProjectStatus.INSTALLATION_SCHEDULED,
    label: "Installation planifiée",
    category: "Installation planifiée",
    color: "blue",
    nodePosition: { x: 600, y: 50 },
    nextSteps: [ProjectStatus.INSTALLATION_COMPLETED],
  },
  [ProjectStatus.INSTALLATION_COMPLETED]: {
    id: ProjectStatus.INSTALLATION_COMPLETED,
    label: "Installation réalisée",
    category: "Installation réalisée",
    color: "blue",
    nodePosition: { x: 800, y: 50 },
    nextSteps: [ProjectStatus.CONSUEL_VALIDATED],
  },
  [ProjectStatus.CONSUEL_VALIDATED]: {
    id: ProjectStatus.CONSUEL_VALIDATED,
    label: "Consuel validé",
    category: "Consuel validé",
    color: "blue",
    nodePosition: { x: 1000, y: 50 },
    nextSteps: [ProjectStatus.DONE],
  },
  [ProjectStatus.DONE]: {
    id: ProjectStatus.DONE,
    label: "Terminé",
    category: "Terminé",
    color: "green",
    nodePosition: { x: 1200, y: 50 },
    nextSteps: [],
  },
  [ProjectStatus.ABORTED]: {
    id: ProjectStatus.ABORTED,
    label: "Annulé",
    category: "Annulé",
    color: "red",
    nextSteps: [],
  },
}

export const OPS_PROJECT_STATUSES: ProjectMilestone<ProjectStatus>[] =
  Object.values(PROJECT_STATUSES).filter(
    (status) => status.id !== ProjectStatus.CREATED,
  )

export const getProjectStatusLabel = (status: ProjectStatus): string =>
  PROJECT_STATUSES[status].label
