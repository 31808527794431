import { Stack } from "@mantine/core"
import { useParams } from "react-router-dom"

import { GENDERS, getFullName } from "@ensol/shared/entities/prospects"

import { BreadCrumb } from "@ensol/entool/components/BreadCrumb"
import { ProjectHeader } from "@ensol/entool/components/entities/Project/Header"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useGetProjectQuery } from "@ensol/entool/queries/projects"
import { CONTENT_PADDING_IN_PX } from "@ensol/entool/styles/constants"

import { ProjectTechnicalOverview } from "./ProjectTechnicalOverview"

export const ProjectTechnicalOverviewPage = () => {
  const { projectId } = useParams()
  const projectQuery = useGetProjectQuery(projectId!)

  return (
    <Stack flex="1" gap={0} bg="gray.0">
      <QueryWrapper query={projectQuery}>
        {({ data: project }) => (
          <>
            <ProjectHeader
              project={project}
              BreadCrumbs={[
                <BreadCrumb
                  key="prospectName"
                  label={`${project.installation.house.prospect.gender ? GENDERS[project.installation.house.prospect.gender].short : ""} ${getFullName(project.installation.house.prospect)}`}
                />,
              ]}
            />
            <ProjectTechnicalOverview
              project={project}
              p={CONTENT_PADDING_IN_PX}
            />
          </>
        )}
      </QueryWrapper>
    </Stack>
  )
}
