import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { BatteryQuoteDetails } from "@ensol/types/quote"

import { MountingType } from "@ensol/shared/entities/installations/batteries"
import { BatteryCostsBuilder } from "@ensol/shared/entities/installations/costs/products/BatteryCostsBuilder"
import { computeExtraWorksQuoteDetails } from "@ensol/shared/entities/quotes/details/extraWorks"
import { getBattery } from "@ensol/shared/material/batteries"
import { BrandType } from "@ensol/shared/material/brands"

export const computeBatteryQuoteDetails = (
  batteryInstallation: InstallationsResponses.BatteryInstallation,
): BatteryQuoteDetails => {
  const costsBuilder = new BatteryCostsBuilder(batteryInstallation)
  const {
    vatRate,
    installationCost,
    installationCostBeforeTax,
    installationCostWithSubsidies,
  } = costsBuilder.computeAllCosts()
  const battery = getBattery(batteryInstallation.type)

  return {
    material: {
      battery: {
        name: "Batterie",
        description: `${battery.name} - Capacité ${battery.capacity} kWh\nGarantie ${battery.warranty} ans`,
        quantity: 1,
        price: costsBuilder.computeBatteryUnitPrice(),
      },
      accessories: {
        name: "Accessoires batterie",
        description:
          battery.brand === BrandType.ENPHASE
            ? batteryInstallation.mountingType === MountingType.ON_WALL
              ? "Kit de communication et câbles"
              : "Kit de communication, câbles et support au sol"
            : battery.brand === BrandType.HUAWEI
              ? "Câbles et support mural"
              : "",
        quantity: 1,
        price: costsBuilder.computeBatteryAccessoriesPrice(),
      },
    },
    extraWorks: computeExtraWorksQuoteDetails(batteryInstallation.extraWorks),
    workforce: {
      installation: {
        name: "Installation batterie",
        description: "Forfait pose et mise en service de la batterie",
        quantity: 1,
        price: costsBuilder.computeWorkforceCosts(),
      },
      adminFees: {
        name: "Forfait administratif batterie",
        description: "Attestation de conformité Consuel",
        quantity: 1,
        price: costsBuilder.computeAdminCosts(),
      },
    },
    vatRate,
    totals: {
      installationCost,
      installationCostBeforeTax,
      installationCostWithSubsidies,
    },
  }
}
