import * as z from "zod"

import { transformSchemaToNullish } from "../utils"

export const preliminaryValidationStepFormSchema = z.object({
  preliminaryRequestNotes: z.string().optional(),
  preliminaryRequestLastContactDate: z.date().nullable().optional(),
  preliminaryRequestExpectedValidationDate: z.date().nullable().optional(),
})

export const toSubmitStepFormSchema =
  preliminaryValidationStepFormSchema.extend({
    preliminaryRequestSubmissionDate: z.date({
      invalid_type_error: "Champ obligatoire",
    }),
    preliminaryRequestDeadlineDate: z.date({
      invalid_type_error: "Champ obligatoire",
    }),
  })

export const submittedStepFormSchema = toSubmitStepFormSchema.extend({
  preliminaryRequestReference: z.string().min(1, "Champ obligatoire"),
  preliminaryRequestAcknowledgedDate: z.date({
    invalid_type_error: "Champ obligatoire",
  }),
  preliminaryRequestAcknowledgmentPath: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
  preliminaryRequestABFAcknowledgmentPath: z.string().nullable().optional(),
  preliminaryRequestAcknowledgmentExtraPaths: z.array(z.string()),
  preliminaryRequestShouldSendReminderMail: z.boolean({
    invalid_type_error: "Champ obligatoire",
  }),
})

export const acknowledgmentStepFormSchema = submittedStepFormSchema.extend({
  preliminaryRequestApprovalDate: z.date().nullable().optional(),
  preliminaryRequestRequestedChangesPaths: z.array(z.string()),
  preliminaryRequestRequestedChangesReasons: z.array(z.string()),
  preliminaryRequestRequestedChanges: z.array(z.string()),
  preliminaryRequestApprovalPaths: z.array(z.string()),
  preliminaryRequestRejectionPaths: z.array(z.string()),
})

export const waitingForStakeholderStepFormSchema =
  acknowledgmentStepFormSchema.extend({
    preliminaryRequestAwaitedStakeholder: z.string().nullable().optional(),
  })

export const approvedStepFormSchema = acknowledgmentStepFormSchema.extend({
  preliminaryRequestApprovalPaths: z
    .array(z.string())
    .min(1, "Champ obligatoire"),
  preliminaryRequestApprovalDate: z.date({
    invalid_type_error: "Champ obligatoire",
  }),
})

export type PreliminaryRequestStepInput =
  | z.infer<typeof preliminaryValidationStepFormSchema>
  | z.infer<typeof toSubmitStepFormSchema>
  | z.infer<typeof submittedStepFormSchema>
  | z.infer<typeof acknowledgmentStepFormSchema>
  | z.infer<typeof approvedStepFormSchema>

export const preliminaryRequestSchema = transformSchemaToNullish(
  approvedStepFormSchema.merge(waitingForStakeholderStepFormSchema),
)
