import { Subsidy } from "@ensol/shared/entities/installations/subsidies"

export type FinancialGraphData = {
  year: number
  amount: number
}

export type YearlyEnergySavings = {
  totalSavings: number
  autoConsumedSavings: number
  resellSavings: number
}

export type FinancialData = {
  billReductionRatio: number
  panelsEnergyProductionCost: number
  firstYearSavings: YearlyEnergySavings
  tenthYearSavings: number
  thirtiethYearSavings: number
  graphs: {
    savingsPerYear: FinancialGraphData[]
    yearlyBillsWithoutPanels: FinancialGraphData[]
    yearlyBillsWithPanels: FinancialGraphData[]
  }
}

export type SimulationWarnings = {
  installedCapacity?: string
  totalDiscount?: string
}

export type SimulationResults = {
  subsidies: Subsidy[]
  installedCapacity: number
  autoConsumptionPercent: number
  yearlyConsumption: number
  estimatedYearlyProduction: number
  totalDiscount: number
  installationCost: number
  finalCost: number
  finalCostBeforeTax: number
  finalCostWithSubsidies: number
  monthsBeforeBreakEvenDate: number
  warnings: SimulationWarnings
} & FinancialData

// Currently we have only PVGIS as data source but eventually
// real data source will be added for existing installations
export const ProductionDataSource = {
  PVGIS: "PVGIS",
} as const

export type ProductionDataSource = keyof typeof ProductionDataSource

export const ConsumptionDataSource = {
  BILL: "BILL",
  SWITCHGRID: "SWITCHGRID",
  REAL: "REAL",
} as const

export type ConsumptionDataSource = keyof typeof ConsumptionDataSource
