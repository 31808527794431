import dayjs from "dayjs"

import { EnergyResponses } from "@ensol/types/endpoints/energy"

import { Section } from "@ensol/entool/components/Section"
import { StatCard } from "@ensol/entool/components/StatCard"
import { ReportWrapper } from "@ensol/entool/pages/Installation/components/ReportWrapper"
import { StickyStatsSection } from "@ensol/entool/pages/Installation/components/StickyStatsSection"
import { AutoConsumptionReport } from "@ensol/entool/pages/Installation/sections/8-AutoConsumption/AutoConsumptionReport"
import { YearlyEnergyGraph } from "@ensol/entool/pages/Installation/sections/8-AutoConsumption/YearlyEnergyGraph"

type Props = {
  slug: string
  energyStats?: EnergyResponses.Stats
  isEnergyStatsLoading: boolean
  areReportsOutdated: boolean
}

export const AutoConsumption = ({
  slug,
  energyStats,
  isEnergyStatsLoading,
  areReportsOutdated,
}: Props) => {
  if (!isEnergyStatsLoading && !energyStats) {
    return null
  }

  const dataTimeFrame = energyStats?.dataTimeFrame

  return (
    <>
      <StickyStatsSection>
        <StatCard
          label="Taux d’auto-consommation"
          stat={energyStats?.autoConsumptionPercent}
          unit="%"
          color="purple.4"
          isLoading={isEnergyStatsLoading}
        />
        <StatCard
          label="Taux d’indépendance"
          stat={energyStats?.autoProductionPercent}
          unit="%"
          isLoading={isEnergyStatsLoading}
        />
      </StickyStatsSection>
      <ReportWrapper
        title="Détail de l’autoconsommation"
        isLoading={isEnergyStatsLoading}
        isOutdated={areReportsOutdated}
        timeFrame={dataTimeFrame}
        ReportComponent={(props) => (
          <AutoConsumptionReport slug={slug} {...props} />
        )}
      />
      <Section
        title="Profil énergétique annuel"
        mih={300}
        isContentLoading={isEnergyStatsLoading}
      >
        {dataTimeFrame && (
          <YearlyEnergyGraph
            slug={slug}
            startDate={dayjs.utc(dataTimeFrame.startDate)}
            isOutdated={areReportsOutdated}
          />
        )}
      </Section>
    </>
  )
}
