export const FundingSource = {
  CETELEM: "CETELEM",
  ALMA: "ALMA",
  ZILO: "ZILO",
  EQUITY: "EQUITY",
} as const

export type FundingSource = keyof typeof FundingSource

export const FundingSourceStatus = {
  PENDING: "PENDING",
  REFUSED: "REFUSED",
  APPROVED: "APPROVED",
  CANCELLED: "CANCELLED",
  INCOMPLETE: "INCOMPLETE",
} as const

export type FundingSourceStatus = keyof typeof FundingSourceStatus

export const FUNDING_SOURCE_DEFAULT_STATUS: Record<
  FundingSource,
  FundingSourceStatus
> = {
  [FundingSource.CETELEM]: FundingSourceStatus.PENDING,
  [FundingSource.ALMA]: FundingSourceStatus.APPROVED,
  [FundingSource.ZILO]: FundingSourceStatus.APPROVED,
  [FundingSource.EQUITY]: FundingSourceStatus.APPROVED,
}
