import { BrandType, EvChargerBrandType } from "@ensol/shared/material/brands"
import { CurrentType } from "@ensol/shared/material/currentType"

type EvChargerInfo = {
  name: string
  longName: string
  power: number
  brand: EvChargerBrandType
  price: number
  currentType: CurrentType
  warranty: number
  pictureUrl: string
  techDocUrl: string
}

export const EvChargerType = {
  WALLBOX_PULSAR_PLUS_SOCKET_MONO: "WALLBOX_PULSAR_PLUS_SOCKET_MONO",
  WALLBOX_PULSAR_PLUS_SOCKET_TRI: "WALLBOX_PULSAR_PLUS_SOCKET_TRI",
  WALLBOX_COPPER_SB_MONO: "WALLBOX_COPPER_SB_MONO",
  WALLBOX_COPPER_SB_TRI: "WALLBOX_COPPER_SB_TRI",
  ENPHASE_IQ_EV_CHARGER_2_MONO: "ENPHASE_IQ_EV_CHARGER_2_MONO",
  ENPHASE_IQ_EV_CHARGER_2_TRI: "ENPHASE_IQ_EV_CHARGER_2_TRI",
} as const

export type EvChargerType = keyof typeof EvChargerType

export const EV_CHARGERS: Record<EvChargerType, EvChargerInfo> = {
  WALLBOX_PULSAR_PLUS_SOCKET_MONO: {
    name: "Pulsar Plus Monophasée (sans pied)",
    longName: "Wallbox Pulsar Plus Socket 1,4 à 7,4kW 1P - IP55",
    power: 7.4,
    brand: BrandType.WALLBOX,
    price: 609.59,
    currentType: CurrentType.SINGLE_PHASE,
    warranty: 2,
    pictureUrl: "/wallboxEvCharger.png",
    techDocUrl:
      "https://support.wallbox.com/wp-content/uploads/2021/12/FR_Pulsar_Plus_Fiches_techniques_Francais.pdf",
  },
  WALLBOX_PULSAR_PLUS_SOCKET_TRI: {
    name: "Pulsar Plus Triphasée (sans pied)",
    longName: "Wallbox Pulsar Plus Socket 11 à 22 kW 1P - IP55",
    power: 22,
    brand: BrandType.WALLBOX,
    price: 691.37,
    currentType: CurrentType.TRI_PHASE,
    warranty: 2,
    pictureUrl: "/wallboxEvCharger.png",
    techDocUrl:
      "https://support.wallbox.com/wp-content/uploads/2021/12/FR_Pulsar_Plus_Fiches_techniques_Francais.pdf",
  },
  WALLBOX_COPPER_SB_MONO: {
    name: "Copper SB Monophasée (avec pied)",
    longName: "Wallbox borne Copper SB 1,4 à 7,4kW 1P - IP55",
    power: 7.4,
    brand: BrandType.WALLBOX,
    currentType: CurrentType.SINGLE_PHASE,
    price: 772.74,
    warranty: 2,
    pictureUrl: "/wallboxEvCharger.png",
    techDocUrl:
      "https://support.wallbox.com/wp-content/uploads/ht_kb/2021/05/Copper-SB_RevC_Fiche_technique_Francais.pdf",
  },
  WALLBOX_COPPER_SB_TRI: {
    name: "Copper SB Triphasée (avec pied)",
    longName: "Wallbox borne Copper SB 11 à 22 kW 1P - IP55",
    power: 22,
    brand: BrandType.WALLBOX,
    currentType: CurrentType.TRI_PHASE,
    price: 854.52,
    warranty: 2,
    pictureUrl: "/wallboxEvCharger.png",
    techDocUrl:
      "https://support.wallbox.com/wp-content/uploads/ht_kb/2021/05/Copper-SB_RevC_Fiche_technique_Francais.pdf",
  },
  ENPHASE_IQ_EV_CHARGER_2_MONO: {
    name: "IQ Charger Monophasée (sans pied)",
    longName: "Enphase IQ EV Charger 2",
    power: 7.4,
    brand: BrandType.ENPHASE,
    currentType: CurrentType.SINGLE_PHASE,
    price: 953.63,
    warranty: 5,
    pictureUrl: "/enphaseEvCharger.png",
    techDocUrl:
      "https://enphase.com/fr-fr/download/iq-ev-charger-2-fiche-technique",
  },
  ENPHASE_IQ_EV_CHARGER_2_TRI: {
    name: "IQ Charger Triphasée (sans pied)",
    longName: "Enphase IQ EV Charger 2",
    power: 22,
    brand: BrandType.ENPHASE,
    currentType: CurrentType.TRI_PHASE,
    price: 953.63,
    warranty: 5,
    pictureUrl: "/enphaseEvCharger.png",
    techDocUrl:
      "https://enphase.com/fr-fr/download/iq-ev-charger-2-fiche-technique",
  },
}

export const getEvCharger = (type: EvChargerType): EvChargerInfo =>
  EV_CHARGERS[type]

type SmartMeterInfo = {
  name: string
  price: number
}

const SMART_METER_MONO: SmartMeterInfo = {
  name: "Monophasé - pour délestage / charge solaire",
  price: 88.8,
}

const SMART_METER_TRI: SmartMeterInfo = {
  name: "Triphasé - pour délestage / charge solaire",
  price: 172.32,
}

export const getEvChargerSmartMeter = (
  evChargerType: EvChargerType,
): SmartMeterInfo | null => {
  const evCharger = getEvCharger(evChargerType)

  if (evCharger.brand === BrandType.ENPHASE) {
    return null
  }

  return evCharger.currentType === CurrentType.SINGLE_PHASE
    ? SMART_METER_MONO
    : SMART_METER_TRI
}
