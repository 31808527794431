import { Button } from "@mantine/core"
import { IconTrash } from "@tabler/icons-react"
import { ComponentType, ReactNode } from "react"

import { Section } from "@ensol/entool/components/Section"
import { MaterialImage } from "@ensol/entool/pages/Installation/components/MaterialImage"

export type ProductFormProps<ProductInstallationType> = {
  productInstallation: ProductInstallationType | null
  onChange: (data: ProductInstallationType | null) => void
  setError: (error: string) => void
  clearError: () => void
  DeleteButton: ReactNode
}

type Props<ProductInstallationType, ExtraFormProps> = {
  type: "Photovoltaic" | "Panels" | "Battery" | "EvCharger"
  title: string
  addButtonLabel: string
  deleteButtonLabel: string
  onAdd: () => void
  pictureUrl: string
  ProductForm: ComponentType<
    ProductFormProps<ProductInstallationType> & ExtraFormProps
  >
  formProps: Omit<ProductFormProps<ProductInstallationType>, "DeleteButton"> &
    ExtraFormProps
}

export const ProductWrapper = <ProductInstallationType, ExtraFormProps>({
  type,
  title,
  addButtonLabel,
  deleteButtonLabel,
  onAdd,
  pictureUrl,
  ProductForm,
  formProps,
}: Props<ProductInstallationType, ExtraFormProps>) =>
  formProps.productInstallation !== null ? (
    <ProductForm
      {...formProps}
      DeleteButton={
        <Button
          color="red"
          variant="light"
          leftSection={<IconTrash size={16} />}
          onClick={() => formProps.onChange(null)}
        >
          {deleteButtonLabel}
        </Button>
      }
    />
  ) : (
    <Section
      title={title}
      Actions={
        <Button onClick={onAdd} data-test={`add${type}Button`}>
          {addButtonLabel}
        </Button>
      }
    >
      <MaterialImage pictureUrl={pictureUrl} imgMaw={200} />
    </Section>
  )
