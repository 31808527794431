import { SimpleGrid, Stack } from "@mantine/core"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { Section } from "@ensol/entool/components/Section"

import { DocumentsSubSection } from "../DocumentsSubSection"

import { AdditionalInformation } from "./AdditionalInformation"
import { Notes } from "./Notes"
import { PanelsLayout } from "./PanelsLayout"
import { RequiredChanges } from "./RequiredChanges"
import { RequiredWorks } from "./RequiredWorks"

type Props = {
  project: ProjectResponses.Project
}

const DOCUMENTS_CONFIG = [
  {
    getFilePaths: (project: ProjectResponses.Project) =>
      project.salesVisitK2ReportPath ? [project.salesVisitK2ReportPath] : [],
    fileName: "Rapport K2 Visite commerciale",
  },
  {
    getFilePaths: (project: ProjectResponses.Project) =>
      project.technicalVisitK2ReportsPath,
    fileName: "Rapport K2 / ESDEC VT",
  },
  {
    getFilePaths: (project: ProjectResponses.Project) =>
      project.technicalVisitReportsPath,
    fileName: "CR VT",
  },
  {
    getFilePaths: (project: ProjectResponses.Project) =>
      project.installationReportPath ? [project.installationReportPath] : [],
    fileName: "CR Installation",
  },
]

export const ProjectSection = ({ project }: Props) => {
  return (
    <Section title="Projet" isCollapsible>
      <SimpleGrid spacing={24} cols={{ base: 1, sm: 2 }}>
        <Stack gap={24}>
          <PanelsLayout project={project} />
          <AdditionalInformation house={project.installation.house} />
          {project.technicalVisitRequiredWorks.length > 0 && (
            <RequiredWorks
              requiredWorks={project.technicalVisitRequiredWorks}
            />
          )}
          {project.technicalVisitRequiredChanges.length > 0 && (
            <RequiredChanges
              requiredChanges={project.technicalVisitRequiredChanges}
            />
          )}
          <DocumentsSubSection project={project} config={DOCUMENTS_CONFIG} />
        </Stack>

        <Notes project={project} />
      </SimpleGrid>
    </Section>
  )
}
