import { Group, Badge, MantineStyleProps, Text } from "@mantine/core"

type Props = {
  issues: string[]
} & MantineStyleProps

export const IssuesBadges = ({ issues, ...props }: Props) => (
  <Group gap="4" {...props}>
    {issues.length > 0 ? (
      issues.map((issue) => (
        <Badge key={issue} color="red" variant="outline">
          {issue}
        </Badge>
      ))
    ) : (
      <Text size="sm" fw={500}>
        -
      </Text>
    )}
  </Group>
)
