import { IconCalendarPause, IconX } from "@tabler/icons-react"
import * as z from "zod"

import { pendingStepsFormSchema } from "@ensol/types/forms/projects/installation"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"
import { validateAction } from "@ensol/entool/utils/projects/validateAction"

import { InstallationForm } from "./components/InstallationForm"

export const Pending = ({ project, processId }: StepComponentProps) => {
  const {
    preliminaryRequestExpectedValidationDate,
    preliminaryRequestApprovalDate,
    installationStartDate,
  } = project

  return (
    <InstallationForm
      schema={pendingStepsFormSchema}
      initialValues={getInitialValues(pendingStepsFormSchema, project)}
      project={project}
      processId={processId}
      relatedProcesses={["technicalVisitStep", "preliminaryRequestStep"]}
      actions={[
        {
          action: "cannotSchedule",
          label: "Impossible à planifier",
          color: "red",
          Icon: IconX,
        },
        {
          action: "hold",
          label: "Non planifiable, à rappeler",
          color: "orange",
          Icon: IconCalendarPause,
        },
        {
          action: "validate",
          label: "Planifier l'installation",
          validateAction: validateAction(
            z.object({
              installerId: z.string({
                invalid_type_error: "Champ obligatoire",
              }),
              installationStartDate: z.date({
                invalid_type_error: "Champ obligatoire",
              }),
              installationEndDate: z.date({
                invalid_type_error: "Champ obligatoire",
              }),
              isPLCNeeded: z.boolean({
                invalid_type_error: "Champ obligatoire",
              }),
            }),
          ),
          confirmationMessage:
            preliminaryRequestApprovalDate === null &&
            installationStartDate !== null &&
            preliminaryRequestExpectedValidationDate !== null &&
            installationStartDate <= preliminaryRequestExpectedValidationDate
              ? "La date de début d'installation est avant la date de validation prévue de la demande préalable. Continuer ?"
              : undefined,
        },
      ]}
    />
  )
}
