import { Stack, Group, Button, Text } from "@mantine/core"
import { modals } from "@mantine/modals"
import { ReactNode, useEffect } from "react"
import { BlockerFunction, useBlocker } from "react-router-dom"

/**
 * Hook to block the user from leaving the page when a condition is met.
 * Inspired by `usePrompt` from `react-router-dom`.
 * https://github.com/remix-run/react-router/blob/821ae3fc6ae6a6ac9d752d2e5f88218547beca95/packages/react-router/lib/dom/lib.tsx#L2110
 * @param when
 */
export const useBeforeLeavePage = (
  when: BlockerFunction,
  actions: {
    label: string
    leftSection?: ReactNode
    onClick: () => void
  }[] = [],
) => {
  const blocker = useBlocker(when)

  useEffect(() => {
    if (blocker.state === "blocked") {
      modals.open({
        title: "Données non-sauvegardées",
        children: (
          <Stack>
            <Text>
              Vous êtes sur le point de quitter une page non sauvegardée.
              Voulez-vous quitter la page ? Toute progression sera
              définitivement perdue.
            </Text>
            <Group justify="flex-end">
              <Button
                variant="default"
                color="black"
                onClick={() => {
                  blocker.reset()
                  modals.closeAll()
                }}
              >
                Annuler
              </Button>
              <Button
                color="red"
                onClick={() => {
                  blocker.proceed()
                  modals.closeAll()
                }}
              >
                Quitter
              </Button>
              {actions.map(({ label, leftSection, onClick }) => (
                <Button
                  key={label}
                  color="blue"
                  leftSection={leftSection}
                  onClick={() => {
                    onClick()
                    blocker.proceed()
                    modals.closeAll()
                  }}
                >
                  {label}
                </Button>
              ))}
            </Group>
          </Stack>
        ),
      })
    }
  }, [blocker, actions])
}
