import { Anchor, Box, Card, Group, Stack } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import {
  IconLayoutSidebarRightCollapseFilled,
  IconTimelineEvent,
} from "@tabler/icons-react"
import { Link } from "react-router-dom"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { getFullName } from "@ensol/shared/entities/prospects"
import { Role } from "@ensol/shared/entities/users"
import { devices } from "@ensol/shared/styles/theme"

import { DisplayRoleGuard } from "@ensol/entool/components/DisplayRoleGuard"
import { IconButton } from "@ensol/entool/components/IconButton"
import { ClientInfo } from "@ensol/entool/pages/OpsProjectsOverview/Row/ClientInfo"
import { DueDateReminder } from "@ensol/entool/pages/OpsProjectsOverview/Row/DueDateReminder"
import { ProjectKeyFigures } from "@ensol/entool/pages/OpsProjectsOverview/Row/ProjectKeyFigures"
import {
  PROJECT_TASK_CONFIG,
  STATUS_SECTION_CONFIG,
} from "@ensol/entool/pages/OpsProjectsOverview/config"
import { ProjectDrawerTab } from "@ensol/entool/pages/OpsProjectsOverview/useProjectDrawer"

import { ProjectIndicators } from "./ProjectIndicators"
import { ProjectTaskDetails } from "./ProjectTaskDetails"

type Props = {
  project: ProjectResponses.ProjectOverview
  isDrawerOpen: boolean
  openDrawer: (
    project: ProjectResponses.ProjectOverview,
    tab: ProjectDrawerTab,
  ) => void
}

export const ProjectRow = ({ project, isDrawerOpen, openDrawer }: Props) => {
  const isMobile = useMediaQuery(devices.sm)
  const { prospect } = project
  const { isActionNeeded, taskDetails } = project
  const { getReferenceDates } = STATUS_SECTION_CONFIG[project.status]

  return (
    <Card
      withBorder
      bg={isDrawerOpen ? "blue.1" : "white"}
      p="0"
      style={({ colors }) => ({
        borderColor: isDrawerOpen ? colors.blue[4] : colors.gray[4],
      })}
    >
      <Box
        style={{
          display: "flex",
          flexDirection: isMobile ? "column" : "row",
          justifyContent: "space-between",
          gap: isMobile ? 16 : 32,
        }}
      >
        <DisplayRoleGuard roles={[Role.EMPLOYEE, Role.ADMIN]}>
          <Group align="flex-start" justify="space-between" flex="1" p="12">
            <Stack gap="4" flex={1} align="flex-start">
              <Group gap="4" align="center" flex={1}>
                <ProjectIndicators project={project} />
                <ClientInfo project={project} />
                {taskDetails?.dueDate && (
                  <DueDateReminder
                    project={project}
                    dueDateConfig={taskDetails.dueDate}
                    isActionNeeded={isActionNeeded}
                  />
                )}
              </Group>
              {taskDetails !== null && (
                <ProjectTaskDetails
                  taskDetails={PROJECT_TASK_CONFIG[taskDetails.task]}
                  type={taskDetails.type}
                  project={project}
                />
              )}
            </Stack>
            <ProjectKeyFigures
              project={project}
              getReferenceDates={getReferenceDates}
            />
          </Group>
          <Group gap="8" p="12">
            <IconButton
              Icon={<IconTimelineEvent size={18} />}
              onClick={() => openDrawer(project, ProjectDrawerTab.DETAILS)}
              ariaLabel="Ouvrir les details du projet"
            />
            <IconButton
              Icon={<IconLayoutSidebarRightCollapseFilled size={22} />}
              onClick={() => openDrawer(project, ProjectDrawerTab.NOTES)}
              ariaLabel="Ouvrir les notes du projet"
            />
          </Group>
        </DisplayRoleGuard>
        <DisplayRoleGuard roles={[Role.INSTALLER]}>
          <Anchor
            p="12"
            flex={1}
            component={Link}
            to={`/projects/${project.id}/technical-overview`}
            size="md"
            fw="500"
            c="black"
          >
            <Group gap="4" flex={1}>
              <ProjectIndicators project={project} />
              {getFullName(prospect)}
            </Group>
          </Anchor>
        </DisplayRoleGuard>
      </Box>
    </Card>
  )
}
