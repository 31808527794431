import { Textarea } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { installerSchema } from "@ensol/types/forms/projects/installer"

import { devices } from "@ensol/shared/styles/theme"

import { SubSection } from "@ensol/entool/components/SubSection"
import { ProjectNotes } from "@ensol/entool/components/entities/Project/ProjectNotes"
import { FormField } from "@ensol/entool/components/form/Project/FormField"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"
import { useProcessForm } from "@ensol/entool/utils/projects/useProcessForm"

type Props = {
  project: ProjectResponses.Project
}

export const Notes = ({ project }: Props) => {
  const initialValues = getInitialValues(installerSchema, project)
  const isMobile = useMediaQuery(devices.sm)
  const form = useProcessForm({
    schema: installerSchema,
    initialValues,
    projectId: project.id,
    processId: "technicalVisitStep",
  })

  return (
    <SubSection title="Notes">
      {!isMobile && <ProjectNotes projectId={project.id} mah={400} />}
      <FormField
        initialValues={initialValues}
        propertyName="technicalVisitInstallerConclusion"
      >
        <Textarea
          autosize
          minRows={3}
          w="100%"
          {...form.getInputProps("technicalVisitInstallerConclusion")}
        />
      </FormField>
    </SubSection>
  )
}
