import { Group, MantineSize, Text } from "@mantine/core"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import {
  InstallationCharacteristic,
  computeInstallationCharacteristics,
} from "@ensol/shared/entities/installations/characteristics"
import { CURRENT_CONNECTIONS } from "@ensol/shared/material/currentType"

type Props = {
  installation: ProjectResponses.Project["installation"]
  installers: string[]
  size?: MantineSize
}

export const InstallationCharacteristics = ({
  installation,
  installers,
  size,
}: Props) => {
  const {
    photovoltaicInstallation,
    batteryInstallation,
    evChargerInstallation,
    extraWorks,
  } = computeInstallationCharacteristics(installation)

  return (
    <>
      <CharacteristicRow
        size={size}
        label="Courant"
        value={CURRENT_CONNECTIONS[installation.house.currentType].name}
      />
      {photovoltaicInstallation.map(({ label, value }) => (
        <CharacteristicRow
          key={label}
          size={size}
          label={label}
          value={value}
        />
      ))}
      {batteryInstallation.map(({ label, value }) => (
        <CharacteristicRow
          key={label}
          size={size}
          label={label}
          value={value}
        />
      ))}
      {evChargerInstallation.map(({ label, value }) => (
        <CharacteristicRow
          key={label}
          size={size}
          label={label}
          value={value}
        />
      ))}
      {extraWorks.map(({ label, value }) => (
        <CharacteristicRow
          key={label}
          size={size}
          label={label}
          value={value}
        />
      ))}
      {installers.length > 0 && (
        <CharacteristicRow
          size={size}
          label={`Installateur${installers.length > 1 ? "s" : ""}`}
          value={installers.join(", ")}
        />
      )}
    </>
  )
}

const CharacteristicRow = ({
  label,
  value,
  size = "sm",
}: InstallationCharacteristic & { size?: MantineSize }) => {
  if (value === null) {
    return null
  }

  return (
    <Group gap="4">
      <Text size={size} c="gray.6">
        {label} :
      </Text>
      <Text size={size} fw="bold">
        {value}
      </Text>
    </Group>
  )
}
