import { Stack, TextInput } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import { CetelemFieldsInput } from "@ensol/types/forms/projects/cetelem"
import { Nullable } from "@ensol/types/utils"

import { STANDARD_DATE_FORMAT } from "@ensol/shared/utils/format"

import { RadioGroup } from "@ensol/entool/components/form//RadioGroup"
import { FIELD_WIDTH } from "@ensol/entool/components/form//constants"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { NumberInput } from "@ensol/entool/components/form/NumberInput"
import { FormField } from "@ensol/entool/components/form/Project/FormField"
import { booleanOptions } from "@ensol/entool/utils/form/options"
import { GENDER_OPTIONS } from "@ensol/entool/utils/prospects/options"

type Props<Values> = {
  form: UseFormReturnType<Values>
  initialValues: Values
}

export const CetelemFields = <Values extends Nullable<CetelemFieldsInput>>({
  form,
  initialValues,
}: Props<Values>) => {
  return (
    <>
      <FormField initialValues={initialValues} propertyName="cetelemReference">
        <TextInput
          w={FIELD_WIDTH}
          {...form.getInputProps("cetelemReference")}
        />
      </FormField>
      <FormField
        initialValues={initialValues}
        propertyName="cetelemFinancingRequestAmount"
      >
        <NumberInput
          w={FIELD_WIDTH}
          suffix="€"
          {...form.getInputProps("cetelemFinancingRequestAmount")}
        />
      </FormField>

      <Stack>
        <FormField
          initialValues={initialValues}
          propertyName="cetelemHasCoBorrower"
        >
          <RadioGroup
            options={booleanOptions}
            {...form.getInputProps("cetelemHasCoBorrower")}
            onChange={(value) => {
              form.getInputProps("cetelemHasCoBorrower").onChange(value)
              if (value === false) {
                form.setValues((prev) => ({
                  ...prev,
                  cetelemCoBorrowerGender: null,
                  cetelemCoBorrowerFirstName: "",
                  cetelemCoBorrowerLastName: "",
                  cetelemCoBorrowerMaidenName: "",
                  cetelemCoBorrowerBirthDate: null,
                  cetelemCoBorrowerBirthPlace: "",
                }))
              }
            }}
          />
        </FormField>
        {form.values.cetelemHasCoBorrower && (
          <>
            <FormField
              propertyName="cetelemCoBorrowerGender"
              initialValues={initialValues}
            >
              <RadioGroup
                options={GENDER_OPTIONS}
                {...form.getInputProps("cetelemCoBorrowerGender")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="cetelemCoBorrowerLastName"
            >
              <TextInput
                w={FIELD_WIDTH}
                {...form.getInputProps("cetelemCoBorrowerLastName")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="cetelemCoBorrowerFirstName"
            >
              <TextInput
                w={FIELD_WIDTH}
                {...form.getInputProps("cetelemCoBorrowerFirstName")}
              />
            </FormField>
            <FormField
              propertyName="cetelemCoBorrowerMaidenName"
              initialValues={initialValues}
            >
              <TextInput
                w={FIELD_WIDTH}
                {...form.getInputProps("cetelemCoBorrowerMaidenName")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="cetelemCoBorrowerBirthDate"
            >
              <DateInput
                w={FIELD_WIDTH}
                valueFormat={STANDARD_DATE_FORMAT}
                clearable
                maxDate={new Date()}
                {...form.getInputProps("cetelemCoBorrowerBirthDate")}
              />
            </FormField>
            <FormField
              propertyName="cetelemCoBorrowerBirthPlace"
              initialValues={initialValues}
            >
              <TextInput
                w={FIELD_WIDTH}
                {...form.getInputProps("cetelemCoBorrowerBirthPlace")}
              />
            </FormField>
          </>
        )}
      </Stack>
    </>
  )
}
