import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import { colors } from "@ensol/shared/styles/colors"

import { createHatchedPattern, ENERGY_PER_HOUR_SCALE } from "./shared"

export const getAutoConsumptionGraphConfig = ({
  products,
  averageDailyProduction,
  averageDailyConsumption,
  averageDailyAutoConsumption,
  projectedDailyAutoConsumptionWithBatteryCharge,
  projectedDailyAutoConsumptionWithBatteryDischarge,
}: Pick<
  InstallationsResponses.EnergyReport,
  | "products"
  | "averageDailyProduction"
  | "averageDailyConsumption"
  | "averageDailyAutoConsumption"
  | "projectedDailyAutoConsumptionWithBatteryCharge"
  | "projectedDailyAutoConsumptionWithBatteryDischarge"
>) => ({
  type: "line" as const,
  data: {
    datasets: [
      ...(products.battery !== null
        ? [
            {
              label: "Charge batterie",
              data: projectedDailyAutoConsumptionWithBatteryCharge,
              fill: "2",
              backgroundColor: createHatchedPattern({
                stripeColor: colors.gray[200],
                backgroundColor: colors.blue[500],
              }),
            },
            {
              label: "Décharge batterie",
              data: projectedDailyAutoConsumptionWithBatteryDischarge,
              fill: "2",
              backgroundColor: createHatchedPattern({
                stripeColor: colors.red[700],
                backgroundColor: colors.pink[300],
              }),
            },
          ]
        : []),
      {
        label: "Auto-consommation",
        data: averageDailyAutoConsumption,
        fill: "origin",
        backgroundColor: colors.purple[500],
      },
      {
        label: "Achat réseau",
        data: averageDailyConsumption,
        fill: "origin",
        backgroundColor: colors.pink[300],
      },
      {
        label: "Surplus",
        data: averageDailyProduction,
        fill: "origin",
        backgroundColor: colors.blue[500],
      },
    ],
  },
  options: {
    animation: false as const,
    tension: 0.1,
    pointRadius: 0,
    showLine: false,
    plugins: {
      tooltip: { enabled: false },
      legend: {
        display: true,
        position: "bottom" as const,
        align: "center" as const,
        labels: {
          usePointStyle: true,
          pointStyle: "circle",
          boxWidth: 12,
          boxHeight: 12,
          padding: 24,
          font: { weight: "bold" as const, size: 14 },
        },
      },
    },
    scales: ENERGY_PER_HOUR_SCALE,
  },
})
