import { Badge } from "@mantine/core"

import { QuoteStatus, STATUSES } from "@ensol/shared/entities/quotes"

type Props = {
  status: QuoteStatus
}

export const QuoteStatusBadge = ({ status }: Props) => {
  const { label, color } = STATUSES[status]

  return (
    <Badge size="sm" color={color} variant="light">
      {label}
    </Badge>
  )
}
