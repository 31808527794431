import { Breadcrumbs, Group, Stack } from "@mantine/core"
import { ReactNode } from "react"

import { colors } from "@ensol/shared/styles/colors"
import { getZIndex } from "@ensol/shared/styles/zIndex"

import {
  CONTAINER_MIN_WIDTH,
  useResponsiveDimensions,
} from "@ensol/entool/styles/constants"

export type HeaderProps = {
  BreadCrumbs?: ReactNode[]
  Infos?: ReactNode
  BottomSection?: ReactNode
  RightSection?: ReactNode
  height?: React.CSSProperties["height"]
}

const DEFAULT_HEADER_HEIGHT_IN_PX = 60

export const Header = ({
  BreadCrumbs = [],
  Infos,
  RightSection,
  BottomSection,
  height,
}: HeaderProps) => {
  const { navbarHeight } = useResponsiveDimensions()

  return (
    <Stack
      pos="sticky"
      bg="white"
      py="12"
      px="24"
      h={height ?? DEFAULT_HEADER_HEIGHT_IN_PX}
      justify="center"
      top={navbarHeight}
      miw={CONTAINER_MIN_WIDTH}
      style={{
        zIndex: getZIndex("stickyHeader"),
        borderBottom: `1px solid ${colors.gray[400]}`,
      }}
      gap="8"
    >
      <Group
        gap="24"
        mih="0"
        wrap="nowrap"
        align="center"
        justify="space-between"
      >
        <Stack gap="12">
          <Group gap="12" wrap="nowrap">
            <Breadcrumbs>{BreadCrumbs}</Breadcrumbs>
            <Group wrap="nowrap" gap="4">
              {Infos}
            </Group>
          </Group>
        </Stack>
        <Group wrap="nowrap" style={{ flexShrink: 0 }}>
          {RightSection}
        </Group>
      </Group>
      {BottomSection && BottomSection}
    </Stack>
  )
}
