import { showNotification } from "@mantine/notifications"
import { QueryClient } from "@tanstack/react-query"
import { isAxiosError } from "axios"

import { isEnsolError, isZodError } from "@ensol/shared/utils/errors"

import { FIELDS_LABELS } from "@ensol/entool/utils/form/fieldLabels"
import { formatZodValidationError } from "@ensol/entool/utils/form/validation"

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      staleTime: 120000,
      retry: (failureCount, error) => {
        if (import.meta.env.VITE_ENVIRONMENT === "development") {
          console.error(error)
        }
        if (failureCount > 2) return false

        if (isAxiosError(error)) {
          // Do not retry on 4xx errors
          return ![400, 401, 403, 404].includes(error.response?.status ?? 0)
        }
        return true
      },
    },
    mutations: {
      onError: (error: unknown) => {
        if (import.meta.env.VITE_ENVIRONMENT === "development") {
          console.error(error)
        }
        if (isZodError(error)) {
          showNotification({
            title: "Des informations sont manquantes ou incorrectes",
            message: formatZodValidationError(error.issues, FIELDS_LABELS),
            color: "red",
          })

          return
        }

        if (isEnsolError(error)) {
          // Ensol API errors will be handled in components
          return
        }

        showNotification({
          title: "Une erreur est survenue",
          message: "Merci de contacter l'équipe technique",
          color: "red",
        })
      },
    },
  },
})
