export type BrandInfo = {
  name: string
  logoUrl: string
}

const PanelBrandType = {
  DUAL_SUN: "DUAL_SUN",
  VOLTEC: "VOLTEC",
} as const
export type PanelBrandType = keyof typeof PanelBrandType

export const InverterBrandType = {
  ENPHASE: "ENPHASE",
  HUAWEI: "HUAWEI",
} as const
export type InverterBrandType = keyof typeof InverterBrandType

const EvChargerBrandType = {
  WALLBOX: "WALLBOX",
  ENPHASE: "ENPHASE",
} as const
export type EvChargerBrandType = keyof typeof EvChargerBrandType

export const BrandType = {
  ...PanelBrandType,
  ...InverterBrandType,
  ...EvChargerBrandType,
} as const

export type BrandType = keyof typeof BrandType

export const BRANDS: Record<BrandType, BrandInfo> = {
  DUAL_SUN: {
    name: "DualSun",
    logoUrl: "/dualsun.png",
  },
  VOLTEC: {
    name: "Voltec",
    logoUrl: "/voltec.png",
  },
  ENPHASE: {
    name: "Enphase",
    logoUrl: "/enphase.png",
  },
  HUAWEI: {
    name: "Huawei",
    logoUrl: "/huawei.png",
  },
  WALLBOX: {
    name: "Wallbox",
    logoUrl: "/wallbox.png",
  },
} as const
