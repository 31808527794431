import { Group, Loader, Paper, PaperProps, Stack, Text } from "@mantine/core"
import { ReactNode } from "react"

import { ColorShade } from "@ensol/shared/styles/colors"

type Props = {
  label: string
  stat?: string | number | ReactNode | null
  unit?: string
  color?: ColorShade
  isLoading?: boolean
  extra?: ReactNode
} & PaperProps

export const StatCard = ({
  label,
  stat,
  unit,
  color,
  extra,
  isLoading = false,
  ...props
}: Props) => (
  <Paper withBorder {...props}>
    <Stack p="16" gap="8">
      <Text c="gray.7" lh={1.2}>
        {label}
      </Text>
      {isLoading ? (
        <Loader size={24} type="dots" />
      ) : (
        <Group gap="4">
          <Text fz="xl" fw={600} c={color ?? "blue"}>
            {stat ?? "-"}
          </Text>
          {unit && (
            <Text c={color ?? "blue"} size="sm">
              {unit}
            </Text>
          )}
          {extra}
        </Group>
      )}
    </Stack>
  </Paper>
)
