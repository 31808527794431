import { preparationPendingStepFormSchema } from "@ensol/types/forms/projects/hardware"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { HardwareForm } from "@ensol/entool/pages/OpsProject/processes/Hardware/components/HardwareForm"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"

export const PreparationPending = ({
  project,
  ...props
}: StepComponentProps) => (
  <HardwareForm
    schema={preparationPendingStepFormSchema}
    initialValues={getInitialValues(preparationPendingStepFormSchema, project)}
    project={project}
    actions={[{ action: "validate", label: "Livraison en attente" }]}
    {...props}
  />
)
