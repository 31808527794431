import { useMediaQuery } from "@mantine/hooks"
import { ReactNode } from "react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { devices } from "@ensol/shared/styles/theme"

import { DesktopHeader } from "./DesktopHeader"
import { MobileHeader } from "./MobileHeader"

type Props = {
  project: ProjectResponses.Project
  BreadCrumbs?: ReactNode[]
}

export const ProjectHeader = (props: Props) => {
  const isMobile = useMediaQuery(devices.sm)

  if (isMobile) {
    return <MobileHeader {...props} />
  }

  return <DesktopHeader {...props} />
}
