import { Text, Stack, Button, Group, ThemeIcon } from "@mantine/core"
import {
  IconAt,
  IconChevronDown,
  IconChevronUp,
  IconHomeBolt,
  IconInfoCircle,
  IconPhone,
  IconUsers,
} from "@tabler/icons-react"
import { ReactNode, useState } from "react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { Header } from "@ensol/entool/components/Header"
import { MapLink } from "@ensol/entool/components/MapLink"
import { InstallationCharacteristics } from "@ensol/entool/components/entities/Installation/InstallationCharacteristics"
import { ProjectOwners } from "@ensol/entool/components/entities/Project/ProjectOwners"
import { WorksAndChangesIndicators } from "@ensol/entool/components/entities/Project/WorksAndChangesIndicators"
import { getProjectInstallers } from "@ensol/entool/utils/projects/installers"

type Props = {
  project: ProjectResponses.Project
  BreadCrumbs?: ReactNode[]
}

enum Tab {
  INFORMATIONS = "Informations",
  INSTALLATION = "Installation",
}

export const MobileHeader = ({ project, BreadCrumbs }: Props) => {
  const [tab, setTab] = useState<Tab | null>(null)
  const { installation } = project
  const { house } = installation
  const { prospect } = house

  return (
    <Header
      height="auto"
      BreadCrumbs={BreadCrumbs}
      Infos={
        <WorksAndChangesIndicators
          technicalVisitRequiredChanges={project.technicalVisitRequiredChanges}
          technicalVisitRequiredWorks={project.technicalVisitRequiredWorks}
          technicalVisitEndOfWorksDate={project.technicalVisitEndOfWorksDate}
        />
      }
      BottomSection={
        <>
          <Group>
            <TabButton
              icon={<IconInfoCircle size={14} />}
              activeTab={tab}
              setActiveTab={setTab}
              tabName={Tab.INFORMATIONS}
            />
            <TabButton
              icon={<IconHomeBolt size={14} />}
              activeTab={tab}
              setActiveTab={setTab}
              tabName={Tab.INSTALLATION}
            />
          </Group>
          <Stack gap={8} px={4}>
            {tab === "Installation" && (
              <InstallationCharacteristics
                size="md"
                installation={project.installation}
                installers={getProjectInstallers(project)}
              />
            )}
            {tab === "Informations" && (
              <>
                <MapLink
                  address={house.address}
                  h="fit-content"
                  textProps={{
                    size: "md",
                    c: "gray.6",
                    ta: "left",
                    style: { textWrap: "pretty" },
                  }}
                />
                <Button
                  h="fit-content"
                  href={`mailto:${prospect.email}`}
                  component="a"
                  variant="transparent"
                  p="0"
                  justify="flex-start"
                >
                  <Group gap="4">
                    <IconAt size={16} />
                    <Text size="md" c="gray.6">
                      {prospect.email}
                    </Text>
                  </Group>
                </Button>
                <Button
                  h="fit-content"
                  href={`tel:${prospect.phone}`}
                  component="a"
                  variant="transparent"
                  p="0"
                  justify="flex-start"
                >
                  <Group gap="4">
                    <IconPhone size={16} />
                    <Text size="md" c="gray.6">
                      {prospect.phone}
                    </Text>
                  </Group>
                </Button>
                <Group gap="4">
                  <ThemeIcon size={18} color="blue">
                    <IconUsers />
                  </ThemeIcon>
                  <Text size="md" c="gray.6">
                    Responsables :{" "}
                  </Text>
                  <ProjectOwners project={project} />
                </Group>
              </>
            )}
          </Stack>
        </>
      }
    />
  )
}

type TabButtonProps = {
  icon: ReactNode
  activeTab: Tab | null
  setActiveTab: (tab: Tab | null) => void
  tabName: Tab
}

const TabButton = ({
  icon,
  activeTab,
  setActiveTab,
  tabName,
}: TabButtonProps) => (
  <Button
    h={24}
    fw="bold"
    flex={1}
    variant="light"
    color="blue"
    leftSection={icon}
    rightSection={
      activeTab === tabName ? (
        <IconChevronUp size={14} />
      ) : (
        <IconChevronDown size={14} />
      )
    }
    onClick={() => {
      setActiveTab(activeTab === tabName ? null : tabName)
    }}
  >
    {tabName}
  </Button>
)
