import { MantineStyleProps, Stack } from "@mantine/core"
import { ReactNode } from "react"

import { BreadCrumb } from "@ensol/entool/components/BreadCrumb"
import { Header } from "@ensol/entool/components/Header"

import { ContentContainer } from "./ContentContainer"

type Props = {
  title: string
  Actions?: ReactNode
  contentContainerProps?: MantineStyleProps
  children: ReactNode
}

export const PageLayout = ({
  title,
  Actions,
  contentContainerProps,
  children,
}: Props) => {
  return (
    <Stack flex="1" miw="0" p="0" gap={8}>
      <Header
        BreadCrumbs={[<BreadCrumb key={title} label={title} />]}
        RightSection={Actions}
      />
      <ContentContainer
        flex="1"
        mih="0"
        display="flex"
        {...contentContainerProps}
      >
        {children}
      </ContentContainer>
    </Stack>
  )
}
