import { Checkbox } from "@mantine/core"
import { ChangeEvent } from "react"

import { PROJECT_FIELDS_LABELS } from "@ensol/entool/utils/form/fieldLabels/projects"

type Props<Values> = {
  initialValues: Values
  propertyName: keyof typeof PROJECT_FIELDS_LABELS
  name?: string
  value?: boolean
  onChange: (value: ChangeEvent<HTMLInputElement>) => void
}

export const FormCheckbox = <Values extends Record<string, unknown>>({
  initialValues,
  propertyName,
  name,
  value,
  onChange,
}: Props<Values>) => {
  if (!(propertyName in initialValues)) {
    return null
  }

  return (
    <Checkbox
      label={name ?? PROJECT_FIELDS_LABELS[propertyName]}
      checked={value}
      onChange={onChange}
      styles={{ label: { fontSize: 14 } }}
    />
  )
}
