import { SimpleGrid, Stack, Text, Textarea } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { installerSchema } from "@ensol/types/forms/projects/installer"

import { colors } from "@ensol/shared/styles/colors"
import { devices } from "@ensol/shared/styles/theme"

import { NotFound } from "@ensol/entool/components/NotFound"
import { Section } from "@ensol/entool/components/Section"
import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import { ProjectNotes } from "@ensol/entool/components/entities/Project/ProjectNotes"
import { FormField } from "@ensol/entool/components/form/Project/FormField"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"
import { useProcessForm } from "@ensol/entool/utils/projects/useProcessForm"

import { DocumentsSubSection } from "../DocumentsSubSection"

import { AdditionalInformation } from "./AdditionalInformation"
import { RequiredChanges } from "./RequiredChanges"
import { RequiredWorks } from "./RequiredWorks"

type Props = {
  project: ProjectResponses.Project
}

const DOCUMENTS_CONFIG = [
  {
    getFilePaths: (project: ProjectResponses.Project) =>
      project.salesVisitK2ReportPath ? [project.salesVisitK2ReportPath] : [],
    fileName: "Rapport K2 Visite commerciale",
  },
  {
    getFilePaths: (project: ProjectResponses.Project) =>
      project.technicalVisitK2ReportsPath,
    fileName: "Rapport K2 / ESDEC VT",
  },
  {
    getFilePaths: (project: ProjectResponses.Project) =>
      project.technicalVisitReportsPath,
    fileName: "CR VT",
  },
  {
    getFilePaths: (project: ProjectResponses.Project) =>
      project.installationReportPath ? [project.installationReportPath] : [],
    fileName: "CR Installation",
  },
]

export const ProjectSection = ({ project }: Props) => {
  const initialValues = getInitialValues(installerSchema, project)
  const isMobile = useMediaQuery(devices.sm)
  const form = useProcessForm({
    schema: installerSchema,
    initialValues,
    projectId: project.id,
    processId: "technicalVisitStep",
  })

  return (
    <Section title="Projet" isCollapsible>
      <SimpleGrid cols={{ base: 1, sm: 2 }}>
        <Stack>
          <Text fz="lg" fw={500}>
            Calepinage
          </Text>
          {project.salesVisitPanelsLayoutPath ||
          project.technicalVisitPanelsLayoutPath ? (
            <MultiFilePreview
              w="100%"
              maw="600px"
              h="250px"
              files={[
                ...(project.technicalVisitPanelsLayoutPath
                  ? [
                      {
                        path: project.technicalVisitPanelsLayoutPath,
                        tag: {
                          label: "Visite technique",
                          color: colors.purple[500],
                        },
                      },
                    ]
                  : []),
                ...(project.salesVisitPanelsLayoutPath
                  ? [
                      {
                        path: project.salesVisitPanelsLayoutPath,
                        tag: {
                          label: "Visite commerciale",
                          color: colors.blue[500],
                        },
                      },
                    ]
                  : []),
              ]}
              fileName="calepinage.pdf"
            />
          ) : (
            <NotFound w="100%" maw="600px" h="250" type="file" />
          )}

          <AdditionalInformation house={project.installation.house} />
          {project.technicalVisitRequiredWorks.length > 0 && (
            <RequiredWorks
              requiredWorks={project.technicalVisitRequiredWorks}
            />
          )}
          {project.technicalVisitRequiredChanges.length > 0 && (
            <RequiredChanges
              requiredChanges={project.technicalVisitRequiredChanges}
            />
          )}
          <DocumentsSubSection project={project} config={DOCUMENTS_CONFIG} />
        </Stack>
        <Stack>
          <Text fz="lg" fw={500}>
            Notes
          </Text>
          {!isMobile && <ProjectNotes projectId={project.id} mah={400} />}
          <FormField
            initialValues={initialValues}
            propertyName="technicalVisitInstallerConclusion"
          >
            <Textarea
              autosize
              minRows={3}
              w="100%"
              {...form.getInputProps("technicalVisitInstallerConclusion")}
            />
          </FormField>
        </Stack>
      </SimpleGrid>
    </Section>
  )
}
