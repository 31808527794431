import { useMediaQuery } from "@mantine/hooks"
import { useState } from "react"

import { FundingSource } from "@ensol/shared/entities/projects/fundingSource"
import { ProcessId } from "@ensol/shared/entities/projects/processes"
import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"
import { devices } from "@ensol/shared/styles/theme"

import { useListProjectsQuery } from "@ensol/entool/queries/projects"
import { useProjectsSearch } from "@ensol/entool/utils/projects/search"

export const useProjects = (processId?: ProcessId) => {
  const [showOnlyScheduledInstallations, setShowOnlyScheduledInstallations] =
    useState(false)
  const [fundingSource, setFundingSource] = useState<FundingSource | null>(null)

  const isMobile = useMediaQuery(devices.sm)
  const {
    search,
    installerId,
    supplierId,
    salesOwnerId,
    operationsOwnerId,
    adminOwnerId,
  } = useProjectsSearch()

  const projectsQuery = useListProjectsQuery({
    filters: {
      search,
      installerId: installerId ?? undefined,
      supplierId: supplierId ?? undefined,
      salesOwnerId: salesOwnerId ?? undefined,
      operationsOwnerId: operationsOwnerId ?? undefined,
      adminOwnerId: adminOwnerId ?? undefined,
      statuses: [{ status: ProjectStatus.CREATED, isIncluded: false }],
      onlyScheduledInstallations:
        processId === "preliminaryRequestStep"
          ? showOnlyScheduledInstallations
          : false,
      fundingSource:
        processId === "paymentStep" && fundingSource
          ? fundingSource
          : undefined,
    },
    enabled: isMobile !== undefined && (isMobile ? search !== "" : true),
  })

  return {
    projectsQuery,
    showOnlyScheduledInstallations,
    setShowOnlyScheduledInstallations,
    fundingSource,
    setFundingSource,
    isSearching: search !== "",
  }
}
