import {
  Loader,
  Select,
  SimpleGrid,
  Stack,
  Table,
  Text,
  Title,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconAddressBook } from "@tabler/icons-react"

import { TownHallsResponses } from "@ensol/types/endpoints/townHalls"

import { Link } from "@ensol/shared/components/Link"

import { Section } from "@ensol/entool/components/Section"
import { Field } from "@ensol/entool/components/form/Field"
import { FormField } from "@ensol/entool/components/form/Project/FormField"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"

type Props<Values> = {
  form: UseFormReturnType<Values>
  townHalls: TownHallsResponses.TownHall[] | undefined
  selectedTownHall: TownHallsResponses.TownHall | undefined
  isGetTownHallsPending: boolean
}

export const TownHallForm = <Values extends { townHallId: string | null }>({
  form,
  townHalls,
  selectedTownHall,
  isGetTownHallsPending,
}: Props<Values>) => {
  if (!isGetTownHallsPending && !townHalls) {
    return
  }
  return (
    <Section title="Mairie" icon={IconAddressBook} titleOrder={3}>
      {isGetTownHallsPending ? (
        <Stack w="100%" m="32" align="center">
          <Loader size="sm" color="gray" />
        </Stack>
      ) : (
        <SimpleGrid cols={2} spacing="48" mt="16">
          <Stack gap="24">
            <FormField
              initialValues={form.values}
              propertyName="townHallId"
              isRequired
            >
              <Select
                w={FIELD_WIDTH}
                data={townHalls?.map(({ id, cityName, postcode }) => ({
                  value: id,
                  label: `${cityName} ${postcode ? `(${postcode})` : ""}`,
                }))}
                searchable
                {...form.getInputProps("townHallId")}
              />
            </FormField>
            <Field name="Lien portail">
              {selectedTownHall?.portalUrl ? (
                <Link
                  w={FIELD_WIDTH}
                  label="Accéder au portail de mairie"
                  to={selectedTownHall.portalUrl}
                  isExternal
                />
              ) : (
                <Text size="sm">-</Text>
              )}
            </Field>
            <Field name="Notes">
              <Text size="sm">
                {selectedTownHall?.notes ? selectedTownHall.notes : "-"}
              </Text>
            </Field>
          </Stack>
          {selectedTownHall?.contacts &&
            selectedTownHall.contacts.length > 0 && (
              <Stack gap="24">
                <Title order={5}>Contacts</Title>
                <Table
                  striped
                  data={{
                    head: ["Nom", "Email", "Téléphone", "Notes"],
                    body: selectedTownHall.contacts.map(
                      ({ name, email, phone, notes }) => [
                        name ? name : "-",
                        email ? email : "-",
                        phone ? phone : "-",
                        notes ? notes : "-",
                      ],
                    ),
                  }}
                />
              </Stack>
            )}
        </SimpleGrid>
      )}
    </Section>
  )
}
