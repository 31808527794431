import _ from "lodash"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import { computeDiscountBeforeTax } from "@ensol/shared/entities/installations/costs/discounts"
import { EvChargerCostsBuilder } from "@ensol/shared/entities/installations/costs/products/EvChargerCostsBuilder"
import { Subsidy } from "@ensol/shared/entities/installations/subsidies"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

import { BatteryCostsBuilder } from "./products/BatteryCostsBuilder"
import { ExtraPanelsCostsBuilder } from "./products/ExtraPanelsCostsBuilder"
import { PhotovoltaicCostsBuilder } from "./products/PhotovoltaicCostsBuilder"

// MARGINS (%)
const ENSOL_MARGIN = 0.25 // %

export const withMargin = (
  value: number,
  margin: number = ENSOL_MARGIN,
): number => roundDecimalNumber(value * (1 + margin))

type EffectiveMarginInput = {
  installationCost: number
  finalCost: number
}

export const computeEffectiveMargin = ({
  installationCost,
  finalCost,
}: EffectiveMarginInput): number => {
  const installationCostWithoutMargin = installationCost / (1 + ENSOL_MARGIN)
  return finalCost / installationCostWithoutMargin - 1
}

type AllCostsInput = {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation | null
  extraPanelsInstallation: InstallationsResponses.ExtraPanelsInstallation | null
  batteryInstallation: InstallationsResponses.BatteryInstallation | null
  evChargerInstallation: InstallationsResponses.EvChargerInstallation | null
  productsCount: number
  house: Pick<
    HousesResponses.House<{ include: { roofSections: true } }>,
    "constructionYear" | "currentType" | "roofType" | "roofSections"
  >
  totalDiscount: number
}

export type ProductCosts = {
  installationCost: number
  installationCostBeforeTax: number
  installationCostWithSubsidies: number
  vatRate: number
}

type AllCosts = {
  installationCost: number
  finalCost: number
  finalCostBeforeTax: number
  finalCostWithSubsidies: number
  subsidies: Subsidy[]
}

export const computeAllCosts = ({
  photovoltaicInstallation,
  extraPanelsInstallation,
  batteryInstallation,
  evChargerInstallation,
  productsCount,
  house,
  totalDiscount,
}: AllCostsInput): AllCosts => {
  const subsidies = new Array<Subsidy>()
  const productsCosts = new Array<{
    installationCost: number
    installationCostBeforeTax: number
    installationCostWithSubsidies: number
    vatRate: number
  }>()

  const costsBuilders = [
    photovoltaicInstallation !== null
      ? new PhotovoltaicCostsBuilder(
          photovoltaicInstallation,
          house,
          totalDiscount,
        )
      : null,
    extraPanelsInstallation !== null
      ? new ExtraPanelsCostsBuilder(extraPanelsInstallation)
      : null,
    batteryInstallation !== null
      ? new BatteryCostsBuilder(batteryInstallation)
      : null,
    evChargerInstallation !== null
      ? new EvChargerCostsBuilder(evChargerInstallation, productsCount)
      : null,
  ]

  costsBuilders.map((costsBuilder) => {
    if (costsBuilder === null) {
      return
    }

    const { subsidy, ...costs } = costsBuilder.computeAllCosts()
    productsCosts.push(costs)
    if (subsidy) {
      subsidies.push(subsidy)
    }
  })

  const installationCost = _.sumBy(
    productsCosts,
    ({ installationCost }) => installationCost,
  )
  const installationCostBeforeTax = _.sumBy(
    productsCosts,
    ({ installationCostBeforeTax }) => installationCostBeforeTax,
  )
  const installationCostWithSubsidies = _.sumBy(
    productsCosts,
    ({ installationCostWithSubsidies }) => installationCostWithSubsidies,
  )

  return {
    installationCost,
    finalCost: installationCost - totalDiscount,
    finalCostBeforeTax: roundDecimalNumber(
      installationCostBeforeTax -
        computeDiscountBeforeTax({
          totalDiscount,
          totalInstallationCost: installationCost,
          productsCosts,
        }),
    ),
    finalCostWithSubsidies: installationCostWithSubsidies - totalDiscount,
    subsidies,
  }
}
