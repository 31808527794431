import { Group, Select, Stack, Text } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconInfoCircle } from "@tabler/icons-react"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import {
  HouseUpdateType,
  updateRoofTypeConfigSchema,
} from "@ensol/types/forms/houses"

import { Field } from "@ensol/entool/components/form/Field"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useUpdateHouseMutation } from "@ensol/entool/queries/houses"
import { useDebounce } from "@ensol/entool/utils/helpers/useDebounce"
import { ROOF_TYPE_OPTIONS } from "@ensol/entool/utils/house/options"

type Props = {
  house: HousesResponses.House<{ include: { roofSections: true } }>
}

export const UpdateRoofType = ({ house }: Props) => {
  const { mutateAsync: updateHouse } = useUpdateHouseMutation(house.id)
  const debouncedUpdateHouse = useDebounce(updateHouse, 250)

  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(updateRoofTypeConfigSchema),
    onValuesChange: async (values) => debouncedUpdateHouse(values),
    initialValues: {
      updateType: HouseUpdateType.UPDATE_ROOF_TYPE,
      roofType: house.roofType,
    },
  })

  const hasFlatRoofOrGroundInstallation = house.roofSections.some(
    (roofSection) =>
      roofSection.hasGroundInstallation || roofSection.hasFlatRoof,
  )

  return (
    <Field name="Couverture" withAsterisk>
      <Stack gap="4">
        {hasFlatRoofOrGroundInstallation && (
          <Group align="center" gap="4">
            <IconInfoCircle size={16} />

            <Text size="sm">
              L&apos;installation comporte au moins un pan de toit plat/une pose
              au sol
            </Text>
          </Group>
        )}
        <Select
          w={FIELD_WIDTH}
          data={ROOF_TYPE_OPTIONS}
          {...form.getInputProps("roofType")}
        />
      </Stack>
    </Field>
  )
}
