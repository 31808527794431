import { ThemeIcon } from "@mantine/core"
import { IconAlertCircle, IconCircleDotted } from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { FundingSource } from "@ensol/shared/entities/projects/fundingSource"

import { FundingSourceInfoIndicator } from "@ensol/entool/components/entities/Project/FundingSourceInfoIndicator"
import { WorksAndChangesIndicators } from "@ensol/entool/components/entities/Project/WorksAndChangesIndicators"

export const ProjectIndicators = ({
  project: {
    technicalVisitRequiredChanges,
    isActionNeeded,
    technicalVisitRequiredWorks,
    technicalVisitEndOfWorksDate,
    fundingSource,
    fundingSourceStatus,
  },
}: {
  project: ProjectResponses.ProjectOverview
}) => {
  return (
    <>
      <ThemeIcon color={isActionNeeded ? "red" : "gray"}>
        {isActionNeeded ? <IconAlertCircle /> : <IconCircleDotted />}
      </ThemeIcon>
      <WorksAndChangesIndicators
        technicalVisitRequiredChanges={technicalVisitRequiredChanges}
        technicalVisitRequiredWorks={technicalVisitRequiredWorks}
        technicalVisitEndOfWorksDate={technicalVisitEndOfWorksDate}
      />
      {fundingSource && fundingSource !== FundingSource.EQUITY && (
        <FundingSourceInfoIndicator
          fundingSource={fundingSource}
          fundingSourceStatus={fundingSourceStatus}
        />
      )}
    </>
  )
}
