import {
  Card as MantineCard,
  Badge,
  Stack,
  Text,
  MantineStyleProp,
} from "@mantine/core"
import dayjs from "dayjs"
import React from "react"
import { Link } from "react-router-dom"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { UnpackArray } from "@ensol/types/utils"

import { getFullName } from "@ensol/shared/entities/prospects"

import { COLUMN_WIDTH_IN_PX, COLUMN_PADDING_IN_PX } from "./config"

type Props = {
  project: UnpackArray<ProjectResponses.ProjectsList>
  to?: string
  style?: MantineStyleProp
  children: React.ReactNode
} & (
  | {
      lastEventDate: undefined
      warnDelayInDays: undefined
      shouldDisplayDaysSinceLastEvent: false
    }
  | {
      lastEventDate: Date
      warnDelayInDays: number
      shouldDisplayDaysSinceLastEvent: boolean
    }
)

export const ProjectCard = ({
  project,
  to,
  lastEventDate,
  warnDelayInDays,
  shouldDisplayDaysSinceLastEvent,
  style,
  children,
}: Props) => {
  const { prospect, house } = project

  const daysSinceLastEvent = dayjs().diff(lastEventDate, "day")

  return (
    <MantineCard
      component={to ? Link : undefined}
      w={COLUMN_WIDTH_IN_PX - COLUMN_PADDING_IN_PX * 2}
      shadow="sm"
      padding="md"
      radius="md"
      withBorder
      to={to ?? ""}
      style={{
        cursor: to ? "pointer" : "default",
        overflow: "unset",
        ...style,
      }}
    >
      {shouldDisplayDaysSinceLastEvent && (
        <Badge
          color={daysSinceLastEvent >= warnDelayInDays ? "red" : "blue"}
          variant="light"
          style={{
            textTransform: "none",
            position: "absolute",
            right: 12,
            top: 12,
          }}
        >
          {daysSinceLastEvent === 0
            ? "Aujourd'hui"
            : `${daysSinceLastEvent} jour${daysSinceLastEvent > 1 ? "s" : ""}`}
        </Badge>
      )}
      <Stack gap="0" pt={shouldDisplayDaysSinceLastEvent ? 20 : 0}>
        <Text fw={600} truncate="end">
          {getFullName(prospect)}
        </Text>
        <Text>
          {house.postcode} - {house.city}
        </Text>
      </Stack>
      {children}
    </MantineCard>
  )
}
