import { SimulationResults } from "@ensol/types/simulation"

import { computeYearlyBillsForecast } from "@ensol/shared/entities/installations/savings"
import { colors } from "@ensol/shared/styles/colors"

import { Section } from "@ensol/entool/components/Section"
import { BillEvolutionGraph } from "@ensol/entool/pages/Installation/components/BillEvolutionGraph"

type Props = {
  computedSimulationResults: SimulationResults
  manualSimulationResults?: SimulationResults
  yearlyConsumption: number
  monthlyBill: number
  electricityPriceAnnualRaise: number
  isBoostActive: boolean
}

export const BillEvolutionSection = ({
  computedSimulationResults,
  manualSimulationResults,
  yearlyConsumption,
  monthlyBill,
  electricityPriceAnnualRaise,
  isBoostActive,
}: Props) => {
  const computedBillReductionRatio =
    computedSimulationResults.billReductionRatio
  const manualBillReductionRatio =
    manualSimulationResults?.billReductionRatio ?? computedBillReductionRatio
  computedSimulationResults.billReductionRatio

  return (
    <Section title="Évolution de votre facture">
      <BillEvolutionGraph
        datasets={[
          {
            label: "Facture actuelle",
            data: computeYearlyBillsForecast({
              yearlyConsumption,
              monthlyBill,
              electricityPriceAnnualRaise,
              config: { dataPointsCount: 5, stepInYears: 5 },
            }),
            backgroundColor: colors.red[300],
          },
          {
            label: "Après installation",
            data: computeYearlyBillsForecast({
              yearlyConsumption,
              monthlyBill:
                monthlyBill *
                (1 -
                  (isBoostActive
                    ? computedBillReductionRatio
                    : manualBillReductionRatio)),
              electricityPriceAnnualRaise,
              config: { dataPointsCount: 5, stepInYears: 5 },
            }),
            backgroundColor: colors.blue[500],
          },
          ...(isBoostActive && manualSimulationResults
            ? [
                {
                  label: "Avec l'accompagnement Ensol",
                  data: computeYearlyBillsForecast({
                    yearlyConsumption,
                    monthlyBill: monthlyBill * (1 - manualBillReductionRatio),
                    electricityPriceAnnualRaise,
                    config: { dataPointsCount: 5, stepInYears: 5 },
                  }),
                  backgroundColor: colors.green[500],
                },
              ]
            : []),
        ]}
        withLegend
        shouldShowAmounts
      />
    </Section>
  )
}
