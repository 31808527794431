import { SimpleGrid } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import dayjs from "dayjs"

import { MonthlyConsumptions } from "@ensol/types/installation"

import { NumberInput } from "@ensol/entool/components/form/NumberInput"

type Props<Values> = {
  form: UseFormReturnType<Values>
  computedMonthlyConsumptions: MonthlyConsumptions
}

export const MonthlyConsumptionInput = <
  Values extends {
    monthlyConsumptions: MonthlyConsumptions
  },
>({
  form,
  computedMonthlyConsumptions,
}: Props<Values>) => {
  const computedConsumptionMonthsIndexes = computedMonthlyConsumptions.reduce(
    (monthIndexes, { month }, index) => ({
      ...monthIndexes,
      [month]: index,
    }),
    {} as Record<string, number>,
  )

  return (
    <SimpleGrid cols={12} spacing={8} ml="48">
      {form.values.monthlyConsumptions.map(({ month }, index) => (
        <NumberInput
          key={month}
          size="xs"
          label={`${dayjs(month).format("MMM")} (kWh)`}
          w={60}
          min={0}
          step={100}
          hideControls
          isNullable={false}
          style={{
            gridRow: 1,
            gridColumn:
              month in computedConsumptionMonthsIndexes
                ? computedConsumptionMonthsIndexes[month] + 1
                : undefined,
          }}
          {...form.getInputProps(`monthlyConsumptions.${index}.value`)}
        />
      ))}
    </SimpleGrid>
  )
}
