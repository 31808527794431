export const ProjectStatus = {
  CREATED: "CREATED",
  SIGNED: "SIGNED",
  TECHNICAL_VISIT_SCHEDULED: "TECHNICAL_VISIT_SCHEDULED",
  TECHNICAL_VISIT_COMPLETED: "TECHNICAL_VISIT_COMPLETED",
  INSTALLATION_SCHEDULED: "INSTALLATION_SCHEDULED",
  INSTALLATION_COMPLETED: "INSTALLATION_COMPLETED",
  CONSUEL_VALIDATED: "CONSUEL_VALIDATED",
  DONE: "DONE",
  ABORTED: "ABORTED",
} as const

export type ProjectStatus = keyof typeof ProjectStatus

export const getStatusIndex = (status: ProjectStatus) =>
  Object.values(ProjectStatus).findIndex(
    (projectStatus) => projectStatus === status,
  )
