import { cetelemFormSchema } from "@ensol/types/forms/projects/payment"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"

import { CetelemForm } from "./components/CetelemForm"
import { getCetelemFormInitialValues } from "./util"

export const RequestToSend = ({ project, processId }: StepComponentProps) => {
  return (
    <CetelemForm
      project={project}
      schema={cetelemFormSchema}
      initialValues={getCetelemFormInitialValues(project)}
      processId={processId}
      actions={[
        {
          action: "validate",
          label: "Envoyer la demande Cetelem au client",
          confirmationMessage:
            "Cette action va envoyer la demande de financement Cetelem au client, êtes-vous sûr de vouloir continuer ?",
        },
      ]}
    />
  )
}
