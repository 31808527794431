import { Group } from "@mantine/core"
import { IconMailFilled, IconPhoneFilled } from "@tabler/icons-react"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"

import { getFormalName } from "@ensol/shared/entities/prospects"
import { formatPhoneNum } from "@ensol/shared/utils/format"

import { BreadCrumb } from "@ensol/entool/components/BreadCrumb"
import { Header, HeaderProps } from "@ensol/entool/components/Header"
import { InfoIconPopover } from "@ensol/entool/components/InfoIconPopover"
import { HubspotLink } from "@ensol/entool/components/entities/Prospect/HubspotLink"
import { EditProspectButton } from "@ensol/entool/components/form/Prospect/EditProspectButton"

type Props = HeaderProps & {
  prospect: ProspectResponses.Prospect
  onProspectEditSuccess?: () => void
}

export const ProspectHeader = ({
  prospect,
  BreadCrumbs = [],
  Infos,
  onProspectEditSuccess,
  ...props
}: Props) => (
  <Header
    {...props}
    BreadCrumbs={[
      <BreadCrumb key="prospects" to="/prospects" label="Prospects" />,
      <Group gap="4" key="prospectName" data-test="headerTitle" wrap="nowrap">
        <BreadCrumb
          to={`/prospects/${prospect.id}`}
          label={getFormalName(prospect)}
        />
        <EditProspectButton
          prospect={prospect}
          onSuccess={onProspectEditSuccess}
        />
      </Group>,
      ...BreadCrumbs,
    ]}
    Infos={
      <>
        <InfoIconPopover
          Icon={IconMailFilled}
          name="Email"
          value={prospect.email}
        />
        <InfoIconPopover
          Icon={IconPhoneFilled}
          name="Téléphone"
          value={formatPhoneNum(prospect.phone)}
        />
        {Infos}
        <HubspotLink
          hubspotContactId={prospect.hubspotContactId}
          isIconButton={true}
        />
      </>
    }
  />
)
