import {
  Box,
  Group,
  ThemeIcon,
  Loader,
  Switch,
  Text,
  ActionIcon,
  Tooltip,
  BoxProps,
} from "@mantine/core"
import { IconInfoCircle, IconX } from "@tabler/icons-react"
import dayjs from "dayjs"
import { useMemo } from "react"

type Props = {
  areSunshineRastersNotAvailable: boolean
  areSunshineRastersReady: boolean
  rastersImageryDate?: Date
  isHeatmapShown: boolean
  onChange: () => void
} & BoxProps

export const SunshineRastersSwitch = ({
  areSunshineRastersNotAvailable,
  areSunshineRastersReady,
  rastersImageryDate,
  isHeatmapShown,
  onChange,
  ...props
}: Props) => (
  <Box {...props}>
    {areSunshineRastersNotAvailable ? (
      <Group gap={2}>
        <ThemeIcon c="red" size="xs">
          <IconX />
        </ThemeIcon>
        <Text c="red" fz="sm" lh={2}>
          Ensoleillement indisponible
        </Text>
      </Group>
    ) : !areSunshineRastersReady ? (
      <Group gap={6}>
        <Loader size={14} />
        <Text c="gray.5" fz="sm" lh={2}>
          Chargement de l&apos;ensoleillement...
        </Text>
      </Group>
    ) : (
      <Group gap={6}>
        <Switch
          checked={isHeatmapShown}
          onChange={onChange}
          label="Afficher l'ensoleillement"
        />
        <ImageryDateInfo solarImageryDate={rastersImageryDate} />
      </Group>
    )}
  </Box>
)

const ImageryDateInfo = ({ solarImageryDate }: { solarImageryDate?: Date }) => {
  const tooltipLabel = useMemo(() => {
    const solarLabel = solarImageryDate
      ? `Prise de vue: ${dayjs(solarImageryDate).format("MMMM YYYY")}`
      : ""

    if (!solarLabel) return "Date indisponible."
    return solarLabel
  }, [solarImageryDate])

  return (
    <Tooltip label={tooltipLabel} multiline w={170}>
      <ActionIcon color="gray" style={{ borderRadius: 24 }}>
        <IconInfoCircle />
      </ActionIcon>
    </Tooltip>
  )
}
