import { cetelemFormSchema } from "@ensol/types/forms/projects/payment"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"

import { CetelemForm } from "./components/CetelemForm"
import { getCetelemFormInitialValues } from "./util"

export const WaitingLastPayment = ({
  project,
  processId,
}: StepComponentProps) => {
  return (
    <CetelemForm
      schema={cetelemFormSchema}
      initialValues={getCetelemFormInitialValues(project)}
      project={project}
      processId={processId}
      actions={[
        {
          action: "validate",
          label: "Paiement final reçu",
        },
      ]}
    />
  )
}
