import { ProjectResponses } from "@ensol/types/endpoints/projects"
import {
  cetelemFieldsSchema,
  conditionalCetelemCoBorrowerValidation,
} from "@ensol/types/forms/projects/cetelem"
import { UnpackArray } from "@ensol/types/utils"

import {
  FundingSource,
  FundingSourceStatus,
} from "@ensol/shared/entities/projects/fundingSource"
import {
  getStepIndex,
  InstallationStep,
  PreliminaryRequestStep,
  TechnicalVisitStep,
} from "@ensol/shared/entities/projects/processes"
import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"

export type SalesTaskCategory =
  | "signedToComplete"
  | "cetelemFieldsMissing"
  | "cetelemIncomplete"
  | "technicalVisitNonSchedulable"
  | "installationNonSchedulable"
  | "googleReviewToAsk"

export type SalesTaskCategoryConfig = {
  id: SalesTaskCategory
  name: string
  filter: (project: UnpackArray<ProjectResponses.ProjectsList>) => boolean
  getLink: (
    project: UnpackArray<ProjectResponses.ProjectsList>,
  ) => string | undefined
  shouldBeReminded: boolean
}

export const SALES_TASKS_CATEGORIES: SalesTaskCategoryConfig[] = [
  {
    id: "signedToComplete",
    name: "Projets signés à compléter",
    filter: (project) =>
      project.status === ProjectStatus.SIGNED &&
      project.preliminaryRequestStep ===
        PreliminaryRequestStep.WAITING_FOR_INFO,
    getLink: (project) => `/sales/${project.id}`,
    shouldBeReminded: true,
  },
  {
    id: "cetelemFieldsMissing",
    name: "Champs Cetelem non renseignés",
    filter: (project) =>
      project.fundingSource === FundingSource.CETELEM &&
      project.status !== ProjectStatus.CREATED &&
      !cetelemFieldsSchema
        .superRefine((data, ctx) => {
          if (data.cetelemHasCoBorrower) {
            conditionalCetelemCoBorrowerValidation(data, ctx)
          }
        })
        .safeParse(project).success,

    getLink: (project) => `/sales/${project.id}`,
    shouldBeReminded: true,
  },
  {
    id: "cetelemIncomplete",
    name: "Dossier Cetelem incomplet",
    filter: ({ fundingSource, fundingSourceStatus }) =>
      fundingSource === FundingSource.CETELEM &&
      fundingSourceStatus === FundingSourceStatus.INCOMPLETE,
    getLink: () => undefined,
    shouldBeReminded: true,
  },
  {
    id: "technicalVisitNonSchedulable",
    name: "VT non planifiable",
    filter: ({ technicalVisitStep }) =>
      technicalVisitStep === TechnicalVisitStep.CANNOT_SCHEDULE,
    getLink: (project) => `/projects/${project.id}?tab=technicalVisitStep`,
    shouldBeReminded: true,
  },
  {
    id: "installationNonSchedulable",
    name: "Installation non planifiable",
    filter: ({ installationStep }) =>
      installationStep === InstallationStep.CANNOT_SCHEDULE,
    getLink: (project) => `/projects/${project.id}?tab=installationStep`,
    shouldBeReminded: true,
  },
  {
    id: "googleReviewToAsk",
    name: "Avis Google à demander",
    filter: (project) =>
      getStepIndex(project.installationStep, "installationStep") >=
        getStepIndex(InstallationStep.CERTIFICATE_SENT, "installationStep") &&
      !project.feedback?.reviewRating &&
      project.feedback?.willNotPostReview !== true,
    getLink: (project) => `/feedbacks/${project.id}`,
    shouldBeReminded: false,
  },
]
