import { useForm, zodResolver } from "@mantine/form"
import { useEffect } from "react"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import {
  PhotovoltaicInstallationAndExtraWorks,
  photovoltaicInstallationAndExtraWorksSchema,
} from "@ensol/types/forms/installations"
import { HouseSignedInstallations } from "@ensol/types/installation"

import { DEFAULT_PHOTOVOLTAIC_INSTALLATION } from "@ensol/shared/entities/installations/defaults"
import {
  computePanelsCapacity,
  computePanelsCount,
} from "@ensol/shared/entities/installations/energy"

import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { isFormValid } from "@ensol/entool/utils/form/validation"
import { computeHouseRoofSectionsWithPanels } from "@ensol/entool/utils/house/roofSections"
import { usePhotovoltaicMaterialOptions } from "@ensol/entool/utils/installations/photovoltaic"

import { MaterialSection } from "./MaterialSection"
import { PanelsSection } from "./PanelsSection"
import { SettingsSection } from "./SettingsSection"

type Props = {
  photovoltaicInstallation: PhotovoltaicInstallationAndExtraWorks
  house: HousesResponses.House<{
    include: { roofSections: true }
  }>
  onChange: (
    data: InstallationsResponses.PhotovoltaicInstallation | null,
  ) => void
  setError: (error: string) => void
  clearError: () => void
  panelsLayout?: InstallationsResponses.Installation["panelsLayout"]
  signedInstallations?: HouseSignedInstallations
  yearlyConsumption?: number
  totalDiscount: number
}

export const PhotovoltaicInstallation = ({
  photovoltaicInstallation,
  house,
  onChange,
  setError,
  clearError,
  panelsLayout,
  signedInstallations,
  yearlyConsumption,
  totalDiscount,
}: Props) => {
  const form = useForm({
    onValuesChange: (values) => onChange(values),
    validate: zodResolver(photovoltaicInstallationAndExtraWorksSchema),
    initialValues: {
      ...photovoltaicInstallation,
      roofSectionsWithPanels: computeHouseRoofSectionsWithPanels(
        house.roofSections,
        photovoltaicInstallation.roofSectionsWithPanels,
      ),
    },
  })

  const {
    panelType,
    panelsCount,
    inverterType,
    optimizerType,
    roofSectionsWithPanels,
  } = form.values

  const {
    panelsOptions,
    invertersOptions,
    optimizerOptions,
    minOptimizerCount,
  } = usePhotovoltaicMaterialOptions({
    panelsCount,
    panelType,
    inverterType,
    currentType: house.currentType,
    installedCapacity: computePanelsCapacity({
      panelsCount,
      panelType,
    }),
    optimizerType: optimizerType ?? undefined,
  })

  const installedCapacity = computePanelsCapacity({ panelsCount, panelType })

  useEffect(() => {
    const panelsCount = computePanelsCount(roofSectionsWithPanels)
    if (form.values.panelsCount !== panelsCount) {
      form.setFieldValue("panelsCount", panelsCount)
    }
  }, [form, roofSectionsWithPanels])

  useEffect(() => {
    const selectedOptimizeOption = findSelectedOption(
      optimizerType,
      optimizerOptions,
    )
    if (selectedOptimizeOption?.metadata?.irrelevant) {
      form.getInputProps("optimizerType").onChange(null)
    } else if (selectedOptimizeOption?.disabled) {
      const firstCompatibleOptimizer = optimizerOptions.find(
        (optimizer) => !optimizer.disabled,
      )
      if (firstCompatibleOptimizer) {
        form.setFieldValue("optimizerType", firstCompatibleOptimizer.value)
      } else {
        form.setFieldError("optimizerType", "Optimiseur invalide")
      }
    } else {
      form.clearFieldError("optimizerType")
    }

    const selectedInverterOption = findSelectedOption(
      inverterType,
      invertersOptions,
    )
    if (selectedInverterOption?.metadata?.incompatible) {
      const firstRecommendedInverter = invertersOptions.find(
        (inverter) => inverter.metadata?.recommended,
      )
      form.setFieldValue(
        "inverterType",
        firstRecommendedInverter?.value ??
          DEFAULT_PHOTOVOLTAIC_INSTALLATION.inverterType,
      )
    }
  }, [
    form,
    panelType,
    optimizerType,
    panelsOptions,
    optimizerOptions,
    inverterType,
    invertersOptions,
  ])

  // Validate the form and update error state of the main installation form
  useEffect(() => {
    if (!isFormValid(form)) {
      setError("photovoltaic installation is invalid")
    } else {
      clearError()
    }
  })

  return (
    <>
      <PanelsSection
        title="Installation Photovoltaïque"
        house={house}
        installationForm={form}
        panelsLayout={panelsLayout}
        signedInstallations={signedInstallations}
        yearlyConsumption={yearlyConsumption}
      />
      <MaterialSection
        form={form}
        panelsOptions={panelsOptions}
        invertersOptions={invertersOptions}
        totalPanelsCount={panelsCount}
        installedCapacity={installedCapacity}
        currentType={house.currentType}
      />
      <SettingsSection
        form={form}
        house={house}
        totalDiscount={totalDiscount}
        optimizerOptions={optimizerOptions}
        minOptimizerCount={minOptimizerCount}
      />
    </>
  )
}
