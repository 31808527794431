import { PreliminaryRequestStep } from "@ensol/shared/entities/projects/processes"

import { StepComponentGetter } from "@ensol/entool/components/entities/Project/ProcessView"

import { WaitingForInfo } from "./1-WaitingForInfo"
import { IncompleteToSubmit } from "./10-IncompleteToSubmit"
import { IncompleteSubmitted } from "./11-IncompleteSubmitted"
import { Rejected } from "./12-Rejected"
import { RejectedWaitingForStakeholder } from "./13-RejectedWaitingForStakeholder"
import { RejectedToSubmit } from "./14-RejectedToSubmit"
import { RejectedSubmitted } from "./15-RejectedSubmitted"
import { Aborted } from "./16-Aborted"
import { Approved } from "./17-Approved"
import { AuthorisationReceived } from "./18-AuthorisationReceived"
import { PreliminaryValidation } from "./2-PreliminaryValidation"
import { SubmissionOnHold } from "./3-SubmissionOnHold"
import { WaitingForSolteo } from "./4-WaitingForSolteo"
import { ToSubmit } from "./5-ToSubmit"
import { Submitted } from "./6-Submitted"
import { Acknowledgment } from "./7-Acknowledgment"
import { Incomplete } from "./8-Incomplete"
import { IncompleteWaitingForStakeholder } from "./9-IncompleteWaitingForStakeholder"

export const getPreliminaryRequestStepComponent: StepComponentGetter = (
  currentStep,
) => {
  switch (currentStep) {
    case PreliminaryRequestStep.WAITING_FOR_INFO:
      return { StepComponent: WaitingForInfo, isCancellable: false }
    case PreliminaryRequestStep.PRELIMINARY_VALIDATION:
      return { StepComponent: PreliminaryValidation, isCancellable: false }
    case PreliminaryRequestStep.SUBMISSION_ON_HOLD:
      return { StepComponent: SubmissionOnHold, isCancellable: false }
    case PreliminaryRequestStep.WAITING_FOR_SOLTEO:
      return { StepComponent: WaitingForSolteo, isCancellable: false }
    case PreliminaryRequestStep.TO_SUBMIT:
      return { StepComponent: ToSubmit }
    case PreliminaryRequestStep.SUBMITTED:
      return { StepComponent: Submitted }
    case PreliminaryRequestStep.ACKNOWLEDGMENT:
      return { StepComponent: Acknowledgment }
    case PreliminaryRequestStep.INCOMPLETE:
      return { StepComponent: Incomplete }
    case PreliminaryRequestStep.INCOMPLETE_WAITING_FOR_STAKEHOLDER:
      return { StepComponent: IncompleteWaitingForStakeholder }
    case PreliminaryRequestStep.INCOMPLETE_TO_SUBMIT:
      return { StepComponent: IncompleteToSubmit }
    case PreliminaryRequestStep.INCOMPLETE_SUBMITTED:
      return { StepComponent: IncompleteSubmitted }
    case PreliminaryRequestStep.REJECTED:
      return { StepComponent: Rejected }
    case PreliminaryRequestStep.REJECTED_WAITING_FOR_STAKEHOLDER:
      return { StepComponent: RejectedWaitingForStakeholder }
    case PreliminaryRequestStep.REJECTED_TO_SUBMIT:
      return { StepComponent: RejectedToSubmit }
    case PreliminaryRequestStep.REJECTED_SUBMITTED:
      return { StepComponent: RejectedSubmitted }
    case PreliminaryRequestStep.ABORTED:
      return { StepComponent: Aborted, isCancellable: false }
    case PreliminaryRequestStep.APPROVED:
      return { StepComponent: Approved }
    case PreliminaryRequestStep.AUTHORISATION_RECEIVED:
      return { StepComponent: AuthorisationReceived }

    default:
      return {
        StepComponent: null,
      }
  }
}
