import { Alert, SimpleGrid, Stack, Textarea, TitleOrder } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconBriefcase, IconInfoCircle, IconNote } from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { CetelemFieldsInput } from "@ensol/types/forms/projects/cetelem"
import { Nullable } from "@ensol/types/utils"

import { Link } from "@ensol/shared/components/Link"
import { FundingSource } from "@ensol/shared/entities/projects/fundingSource"
import { Team } from "@ensol/shared/entities/users"
import { getProspectFileName } from "@ensol/shared/utils/files"

import { Section } from "@ensol/entool/components/Section"
import { ProjectNotes } from "@ensol/entool/components/entities/Project/ProjectNotes"
import { Field } from "@ensol/entool/components/form/Field"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { FundingSourceField } from "@ensol/entool/components/form/Project/FundingSourceField"
import { UserSelect } from "@ensol/entool/components/form/UserSelect"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { CETELEM_PORTAL_URL } from "@ensol/entool/config"

import { CetelemFields } from "./CetelemFields"

type Props<Values> = {
  project: ProjectResponses.Project
  form: UseFormReturnType<Values>
  titleOrder: TitleOrder
}

const PROJECT_NOTES_PLACEHOLDER = `Niveau de chaleur : faible / moyen / élevé
Date installation souhaitée : asap / mois
Infos projet ou client : ...`

export const ProjectForm = <
  Values extends {
    salesOwnerId: string | null
    salesVisitPanelsLayoutPath: string | null
    technicalVisitSalesComments: string
    fundingSource: FundingSource | null
  } & Nullable<CetelemFieldsInput>,
>({
  project,
  form,
  titleOrder,
}: Props<Values>) => {
  const { prospect } = project.installation.house
  const panelsLayoutFileName = getProspectFileName(
    "calepinage-visite-commerciale",
    prospect,
  )
  return (
    <>
      <Section title="Notes" icon={IconNote} titleOrder={titleOrder}>
        <SimpleGrid cols={{ base: 1, md: 2 }} spacing={{ base: 16, md: 62 }}>
          <ProjectNotes
            projectId={project.id}
            mah={600}
            inputPlaceholder={PROJECT_NOTES_PLACEHOLDER}
          />
        </SimpleGrid>
      </Section>
      <Section title="Projet" icon={IconBriefcase} titleOrder={titleOrder}>
        <SimpleGrid cols={{ base: 1, md: 2 }} spacing={{ base: 16, md: 62 }}>
          <Stack gap="16">
            <Field name="PDF Calepinage K2" withAsterisk>
              <FileField
                previewProps={{
                  fileName: panelsLayoutFileName,
                }}
                inputProps={{ accept: ["application/pdf"] }}
                {...form.getInputProps("salesVisitK2ReportPath")}
              />
            </Field>
            <Field name="Image calepinage" withAsterisk>
              <FileField
                previewProps={{
                  fileName: panelsLayoutFileName,
                }}
                inputProps={{ accept: ["image/jpeg", "image/png"] }}
                {...form.getInputProps("salesVisitPanelsLayoutPath")}
              />
            </Field>
            <Field name="Commentaires pour la visite technique">
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("technicalVisitSalesComments")}
              />
            </Field>
          </Stack>
          <Stack gap="16">
            <Field name="Responsable" withAsterisk>
              <UserSelect
                w={FIELD_WIDTH}
                teams={[Team.ACCOUNT_EXECUTIVE, Team.INSIDE_SALES]}
                withDefault
                {...form.getInputProps("salesOwnerId")}
              />
            </Field>
            <FundingSourceField
              fundingSourceStatus={project.fundingSourceStatus}
              form={form}
            />
            {form.values.fundingSource === FundingSource.CETELEM && (
              <>
                <Alert
                  w={FIELD_WIDTH}
                  variant="light"
                  color="blue"
                  title="L'emprunteur (principal) désigné dans la demande de crédit doit correspondre avec le client dont le nom apparaît en haut de cette page. Modifiez le nom du client si besoin en cliquant sur l'icône du crayon en haut de la page."
                  icon={<IconInfoCircle size={30} />}
                />
                <Link
                  w={FIELD_WIDTH}
                  label="Lien vers le portail Cetelem"
                  to={CETELEM_PORTAL_URL}
                  isExternal
                />
                <CetelemFields form={form} initialValues={form.values} />
              </>
            )}
          </Stack>
        </SimpleGrid>
      </Section>
    </>
  )
}
