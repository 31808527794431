import { Stack, Progress, Group, Text, SimpleGrid } from "@mantine/core"
import { IconBolt } from "@tabler/icons-react"

import { formatNumber } from "@ensol/shared/utils/format"

import { useInstallationDiagnostic } from "@ensol/entool/pages/Installation/components/Overview/Summary/SimulationSummary/useInstallationDiagnostic"

import { SummarySection, SummaryItem } from "../shared"

type Props = {
  yearlyProduction: number
  yearlyConsumption: number
  autoConsumptionPercent: number
  hasBattery: boolean
}

export const AutoConsumptionSummary = ({
  yearlyProduction,
  yearlyConsumption,
  autoConsumptionPercent,
  hasBattery,
}: Props) => {
  const diagnostic = useInstallationDiagnostic(
    yearlyProduction,
    yearlyConsumption,
    hasBattery,
  )
  const productionConsumptionPercentage = Math.round(
    (yearlyProduction / yearlyConsumption) * 100,
  )

  return (
    <SummarySection
      title="Votre autoconsommation"
      Icon={IconBolt}
      badge={`${autoConsumptionPercent}%`}
    >
      <Stack>
        <SimpleGrid cols={2}>
          <SummaryItem
            title="Production"
            value={`${formatNumber(yearlyProduction)} kWh`}
            direction="column"
            color={diagnostic.color}
          />
          <SummaryItem
            title="Consommation"
            direction="column"
            value={`${formatNumber(yearlyConsumption)} kWh`}
          />
        </SimpleGrid>
        <Stack pos="relative" gap="4">
          <Group justify="space-between">
            <Text c="gray.6">Ratio prod/conso</Text>
            <Text c={diagnostic.color} fw="bold">
              {productionConsumptionPercentage}%
            </Text>
          </Group>
          <Progress
            size="sm"
            value={productionConsumptionPercentage}
            color={diagnostic.color}
            my={4}
            style={{ backgroundColor: "black" }}
          />
          <Group gap="4">
            <Text size="sm" c="gray.5" fw="bold">
              Diagnostic :
            </Text>
            <Text size="sm" fw="bold">
              {diagnostic.label}
            </Text>
          </Group>
        </Stack>
      </Stack>
    </SummarySection>
  )
}
