import { Group, ThemeIcon, Text } from "@mantine/core"
import { ReactNode } from "react"

type Props = {
  color: string
  Icon: ReactNode
  content: string
  children?: ReactNode
}

export const Message = ({ color, Icon, content, children }: Props) => (
  <Group gap="4" wrap="nowrap" align="flex-start">
    <ThemeIcon color={color} variant="transparent" size="xs" mt="2">
      {Icon}
    </ThemeIcon>
    <Text c={color}>{content}</Text>
    {children}
  </Group>
)
