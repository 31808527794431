export const Role = {
  ADMIN: "ADMIN",
  EMPLOYEE: "EMPLOYEE",
  INSTALLER: "INSTALLER",
  CLIENT: "CLIENT",
} as const

export type Role = keyof typeof Role

export const Team = {
  INSIDE_SALES: "INSIDE_SALES",
  ACCOUNT_EXECUTIVE: "ACCOUNT_EXECUTIVE",
  CUSTOMER_SUCCESS: "CUSTOMER_SUCCESS",
  ADMIN: "ADMIN",
  SITE_MANAGER: "SITE_MANAGER",
  TECHNICAL_EXPERT: "TECHNICAL_EXPERT",
  OPERATIONS: "OPERATIONS",
} as const

export type Team = keyof typeof Team
