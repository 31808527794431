import { SimulationResults } from "@ensol/types/simulation"

import { formatCurrency, roundDecimalNumber } from "@ensol/shared/utils/format"

import { StatCard } from "@ensol/entool/components/StatCard"
import { StickyStatsSection } from "@ensol/entool/pages/Installation/components/StickyStatsSection"

type Props = {
  simulationResults?: SimulationResults
  isEnergyStatsLoading: boolean
}

export const StatsSection = ({
  simulationResults,
  isEnergyStatsLoading,
}: Props) => {
  const firstYearSavings = simulationResults?.firstYearSavings.totalSavings
  const billReductionRatio = simulationResults?.billReductionRatio

  return (
    <StickyStatsSection>
      <StatCard
        label="Économie totale annuelle"
        stat={firstYearSavings && formatCurrency(firstYearSavings, 0)}
        isLoading={isEnergyStatsLoading}
      />
      <StatCard
        label="Réduction facture"
        stat={
          billReductionRatio &&
          `${roundDecimalNumber(billReductionRatio * 100, 0)}%`
        }
        isLoading={isEnergyStatsLoading}
        color="green.4"
      />
    </StickyStatsSection>
  )
}
