import { Group, Stack, StackProps, Text } from "@mantine/core"
import { ReactNode } from "react"

type Props = StackProps & {
  name: ReactNode
  subtitle?: string
  helpText?: string
  withAsterisk?: boolean
  children: ReactNode
}

export const Field = ({
  name,
  subtitle,
  helpText,
  children,
  withAsterisk = false,
  ...props
}: Props) => {
  return (
    <Stack gap={4} {...props}>
      <Stack gap="0">
        <Group gap={4}>
          {typeof name === "string" ? (
            <Text fw={500} c="gray.7">
              {name}
            </Text>
          ) : (
            name
          )}
          {withAsterisk && " *"}
        </Group>
        {subtitle && (
          <Text size="sm" c="gray.6">
            {subtitle}
          </Text>
        )}
      </Stack>
      <Stack gap="0">{children}</Stack>
      {helpText && (
        <Text size="sm" c="gray.6">
          {helpText}
        </Text>
      )}
    </Stack>
  )
}
