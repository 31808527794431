import { typedKeys } from "@ensol/types/utils"

export const PreliminaryRequestStep = {
  WAITING_FOR_INFO: "WAITING_FOR_INFO",
  PRELIMINARY_VALIDATION: "PRELIMINARY_VALIDATION",
  SUBMISSION_ON_HOLD: "SUBMISSION_ON_HOLD",
  WAITING_FOR_SOLTEO: "WAITING_FOR_SOLTEO",
  TO_SUBMIT: "TO_SUBMIT",
  SUBMITTED: "SUBMITTED",
  ACKNOWLEDGMENT: "ACKNOWLEDGMENT",
  INCOMPLETE: "INCOMPLETE",
  INCOMPLETE_WAITING_FOR_STAKEHOLDER: "INCOMPLETE_WAITING_FOR_STAKEHOLDER",
  INCOMPLETE_TO_SUBMIT: "INCOMPLETE_TO_SUBMIT",
  INCOMPLETE_SUBMITTED: "INCOMPLETE_SUBMITTED",
  REJECTED: "REJECTED",
  REJECTED_WAITING_FOR_STAKEHOLDER: "REJECTED_WAITING_FOR_STAKEHOLDER",
  REJECTED_TO_SUBMIT: "REJECTED_TO_SUBMIT",
  REJECTED_SUBMITTED: "REJECTED_SUBMITTED",
  ABORTED: "ABORTED",
  APPROVED: "APPROVED",
  AUTHORISATION_RECEIVED: "AUTHORISATION_RECEIVED",
} as const

export type PreliminaryRequestStep = keyof typeof PreliminaryRequestStep

export const TechnicalVisitStep = {
  PENDING: "PENDING",
  SCHEDULING_ON_HOLD: "SCHEDULING_ON_HOLD",
  CANNOT_SCHEDULE: "CANNOT_SCHEDULE",
  REQUESTED: "REQUESTED",
  INVITATION_REJECTED: "INVITATION_REJECTED",
  SCHEDULED: "SCHEDULED",
  COMPLETED: "COMPLETED",
  BEING_PROCESSED: "BEING_PROCESSED",
  UPDATE_INSTALLATION: "UPDATE_INSTALLATION",
  REJECTED: "REJECTED",
  VALIDATED: "VALIDATED",
} as const

export type TechnicalVisitStep = keyof typeof TechnicalVisitStep

export const GreendealStep = {
  EMAIL_PENDING: "EMAIL_PENDING",
  SUBMISSION_PENDING: "SUBMISSION_PENDING",
  SUBMITTED: "SUBMITTED",
  DONE_BY_CLIENT: "DONE_BY_CLIENT",
  FILE_COMPLETED: "FILE_COMPLETED",
  VALIDATED: "VALIDATED",
} as const

export type GreendealStep = keyof typeof GreendealStep

export const InstallationStep = {
  PENDING: "PENDING",
  SCHEDULING_ON_HOLD: "SCHEDULING_ON_HOLD",
  CANNOT_SCHEDULE: "CANNOT_SCHEDULE",
  REQUESTED: "REQUESTED",
  SCHEDULED: "SCHEDULED",
  PROJECT_PREPARED: "PROJECT_PREPARED",
  IN_PROGRESS: "IN_PROGRESS",
  NEW_VISIT_NEEDED: "NEW_VISIT_NEEDED",
  COMPLETED: "COMPLETED",
  UPDATE_INSTALLATION: "UPDATE_INSTALLATION",
  CERTIFICATE_SENT: "CERTIFICATE_SENT",
  CERTIFICATE_SIGNED: "CERTIFICATE_SIGNED",
} as const

export type InstallationStep = keyof typeof InstallationStep

export const PaymentStep = {
  DEPOSIT_INVOICE_WAITING: "DEPOSIT_INVOICE_WAITING",
  DEPOSIT_INVOICE_TO_SEND: "DEPOSIT_INVOICE_TO_SEND",
  CETELEM_REQUEST_TO_SEND: "CETELEM_REQUEST_TO_SEND",
  CETELEM_REQUEST_SENT: "CETELEM_REQUEST_SENT",
  WAITING_FOR_DEPOSIT_PAYMENT: "WAITING_FOR_DEPOSIT_PAYMENT",
  CETELEM_WAITING_FOR_MAIN_PAYMENT: "CETELEM_WAITING_FOR_MAIN_PAYMENT",
  DEPOSIT_INVOICE_PAID: "DEPOSIT_INVOICE_PAID",
  CETELEM_PAID_WAITING_CONSUEL: "CETELEM_PAID_WAITING_CONSUEL",
  FINAL_INVOICE_TO_SEND: "FINAL_INVOICE_TO_SEND",
  CETELEM_CONSUEL_TO_SEND: "CETELEM_CONSUEL_TO_SEND",
  WAITING_FOR_FINAL_PAYMENT: "WAITING_FOR_FINAL_PAYMENT",
  CETELEM_WAITING_LAST_PAYMENT: "CETELEM_WAITING_LAST_PAYMENT",
  FINAL_INVOICE_PAID: "FINAL_INVOICE_PAID",
  CETELEM_PAID: "CETELEM_PAID",
} as const

export type PaymentStep = keyof typeof PaymentStep

export const HardwareStep = {
  PREORDER_PENDING: "PREORDER_PENDING",
  PREORDER_DONE: "PREORDER_DONE",
  ORDER_PENDING: "ORDER_PENDING",
  PREPARATION_PENDING: "PREPARATION_PENDING",
  DELIVERY_PENDING: "DELIVERY_PENDING",
  ORDER_DELIVERED: "ORDER_DELIVERED",
  ORDER_CHECKED: "ORDER_CHECKED",
  ORDER_INSTALLED: "ORDER_INSTALLED",
} as const

export type HardwareStep = keyof typeof HardwareStep

export const EnedisStep = {
  PENDING: "PENDING",
  SUBMITTED: "SUBMITTED",
  VALIDATED: "VALIDATED",
  INCOMPLETE: "INCOMPLETE",
  ACTIVATION_PENDING: "ACTIVATION_PENDING",
  ACTIVATED: "ACTIVATED",
} as const

export type EnedisStep = keyof typeof EnedisStep

export const ConsuelStep = {
  PENDING: "PENDING",
  WAITING_FOR_SOLTEO: "WAITING_FOR_SOLTEO",
  SUBMITTED: "SUBMITTED",
  VISIT_NEEDED: "VISIT_NEEDED",
  VISIT_SCHEDULED: "VISIT_SCHEDULED",
  VISIT_WAITING_VALIDATION: "VISIT_WAITING_VALIDATION",
  QUALITY_PV_VISIT_NEEDED: "QUALITY_PV_VISIT_NEEDED",
  QUALITY_PV_VISIT_SCHEDULED: "QUALITY_PV_VISIT_SCHEDULED",
  QUALITY_PV_DOC_TO_SEND: "QUALITY_PV_DOC_TO_SEND",
  QUALITY_PV_WAITING_VALIDATION: "QUALITY_PV_WAITING_VALIDATION",
  WORK_NEEDED: "WORK_NEEDED",
  WORK_SCHEDULED: "WORK_SCHEDULED",
  WORK_DONE: "WORK_DONE",
  VALIDATED: "VALIDATED",
} as const

export type ConsuelStep = keyof typeof ConsuelStep

export const EdfOaStep = {
  EMAIL_SENT: "EMAIL_SENT",
  CERTIFICATE_TO_SEND: "CERTIFICATE_TO_SEND",
  CERTIFICATE_SENT: "CERTIFICATE_SENT",
} as const

export type EdfOaStep = keyof typeof EdfOaStep

export const FollowUpStep = {
  INSTALLATION_TO_VALIDATE: "INSTALLATION_TO_VALIDATE",
  LESS_THAN_1_MONTH_PRODUCING: "LESS_THAN_1_MONTH_PRODUCING",
  ONBOARDING_PENDING: "ONBOARDING_PENDING",
  ONBOARDING_DONE: "ONBOARDING_DONE",
  MORE_THAN_3_MONTHS_PRODUCING: "MORE_THAN_3_MONTHS_PRODUCING",
  MORE_THAN_6_MONTHS_PRODUCING: "MORE_THAN_6_MONTHS_PRODUCING",
  MORE_THAN_12_MONTHS_PRODUCING: "MORE_THAN_12_MONTHS_PRODUCING",
} as const

export type FollowUpStep = keyof typeof FollowUpStep

export const PROCESSES = {
  preliminaryRequestStep: PreliminaryRequestStep,
  technicalVisitStep: TechnicalVisitStep,
  installationStep: InstallationStep,
  paymentStep: PaymentStep,
  hardwareStep: HardwareStep,
  greendealStep: GreendealStep,
  enedisStep: EnedisStep,
  consuelStep: ConsuelStep,
  edfOaStep: EdfOaStep,
  followUpStep: FollowUpStep,
}

export type ProcessId = keyof typeof PROCESSES

// Object.keys return type is string[], so we need to cast it
export const PROJECT_MILESTONE_FIELDS: (ProcessId | "status")[] = [
  "status",
  ...typedKeys(PROCESSES),
]

export type ProcessStep<T extends ProcessId> = {
  preliminaryRequestStep: PreliminaryRequestStep
  technicalVisitStep: TechnicalVisitStep
  installationStep: InstallationStep
  paymentStep: PaymentStep
  hardwareStep: HardwareStep
  greendealStep: GreendealStep
  enedisStep: EnedisStep
  consuelStep: ConsuelStep
  edfOaStep: EdfOaStep
  followUpStep: FollowUpStep
}[T]

export const getStepIndex = (
  step: ProcessStep<ProcessId> | null,
  processId: ProcessId,
) =>
  Object.values(PROCESSES[processId]).findIndex(
    (processStep) => processStep === step,
  )
