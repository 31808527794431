import { preorderPendingStepFormSchema } from "@ensol/types/forms/projects/hardware"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { HardwareForm } from "@ensol/entool/pages/OpsProject/processes/Hardware/components/HardwareForm"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"

export const PreorderPending = ({ project, ...props }: StepComponentProps) => {
  return (
    <HardwareForm
      schema={preorderPendingStepFormSchema}
      initialValues={getInitialValues(preorderPendingStepFormSchema, project)}
      project={project}
      actions={[{ action: "validate", label: "Précommande faite" }]}
      {...props}
    />
  )
}
