import { Prospect } from "@ensol/types/prisma-client"

export const FILE_UPLOAD_FIELD_NAME = "file"

export const getFileBaseUrl = (apiBaseUrl: string) => `${apiBaseUrl}/files`

export const getProspectFileName = (
  documentName: string,
  prospect: Pick<Prospect, "firstName" | "lastName">,
) =>
  removeDiacritics(`${documentName}-${prospect.firstName}-${prospect.lastName}`)

const removeDiacritics = (str: string) =>
  str.normalize("NFD").replace(/[\u0300-\u036f]/g, "")

export const getExtension = (fileName: string) =>
  fileName.split(".").pop()?.toLowerCase()

export const getContentType = (fileName: string) => {
  const extension = getExtension(fileName)

  switch (extension) {
    case "pdf":
      return "application/pdf"
    case "jpg":
    case "jpeg":
      return "image/jpeg"
    case "png":
      return "image/png"
    case "heic":
      return "image/heic"
    case "csv":
      return "text/csv"
    default:
      return "application/octet-stream"
  }
}
