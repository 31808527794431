import { z } from "zod"

import { cetelemFormSchema } from "@ensol/types/forms/projects/payment"
import { checkField } from "@ensol/types/forms/projects/shared"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { validateAction } from "@ensol/entool/utils/projects/validateAction"

import { CetelemForm } from "./components/CetelemForm"
import { getCetelemFormInitialValues } from "./util"

export const RequestSent = ({ project, processId }: StepComponentProps) => {
  return (
    <CetelemForm
      initialValues={getCetelemFormInitialValues(project)}
      schema={cetelemFormSchema}
      processId={processId}
      project={project}
      actions={[
        {
          action: "validate",
          label: "Envoyer la demande à Cetelem",
          validateAction: validateAction(
            z.object({
              arePennylaneInvoicesGenerated: checkField,
            }),
          ),
        },
      ]}
    />
  )
}
