import { showNotification } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"

import { isEnsolError } from "@ensol/shared/utils/errors"

import { httpClient } from "@ensol/entool/backend/axios"
import { queryClient } from "@ensol/entool/backend/queryClient"

export const useCreateQuoteMutation = (installationId: string) => {
  return useMutation({
    mutationFn: () => {
      return httpClient.post("/quotes", { installationId })
    },
    onError: async (error) => {
      if (isEnsolError(error)) {
        showNotification({
          title: "Erreur lors la génération du devis",
          message: error.message,
          color: "red",
        })
      } else {
        showNotification({
          title: "Erreur lors la génération du devis",
          message: "Merci de réessayer",
          color: "red",
        })
      }
    },
    onSuccess: async () => {
      showNotification({ message: "Devis créé !" })
      await queryClient.invalidateQueries({
        queryKey: ["installation", installationId, "quotes"],
      })
    },
  })
}

export const useGenerateQuoteSigningUrlMutation = (
  quoteId: number,
  installationId: string,
) => {
  return useMutation<string | undefined>({
    mutationKey: ["quotes", quoteId, "signing-url"],
    mutationFn: () =>
      httpClient
        .post(`/quotes/${quoteId}/signing-url`)
        .then((response) => response.data),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ["installation", installationId, "quotes"],
      })
    },
    onError: (error) => {
      if (isEnsolError(error)) {
        showNotification({
          message: error.message,
          color: "red",
        })
      } else {
        showNotification({
          message:
            "Nous n'avons pas pu générer l'url de signature du devis, merci de réessayer",
          color: "red",
        })
      }
    },
  })
}

export const useUpdateActiveQuoteMutation = (installationId: string) => {
  return useMutation({
    mutationFn: (targetQuoteId: number) => {
      return httpClient.put(`/installations/${installationId}/active-quote`, {
        targetQuoteId,
      })
    },
    onSuccess: async () => {
      showNotification({ message: "Devis remplacé !" })
      await queryClient.invalidateQueries({
        queryKey: ["installation", installationId, "quotes"],
      })
    },
  })
}
