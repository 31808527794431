import { Stack, Text } from "@mantine/core"
import { Helmet } from "react-helmet-async"
import { useParams } from "react-router-dom"

import { getFullName } from "@ensol/shared/entities/prospects"

import { Section } from "@ensol/entool/components/Section"
import { ProspectHeader } from "@ensol/entool/components/entities/Prospect/ProspectHeader"
import { CreateHouseButton } from "@ensol/entool/components/form/House/Create/CreateButton"
import { ContentContainer } from "@ensol/entool/components/layout/ContentContainer"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { House } from "@ensol/entool/pages/Prospect/House"
import { useGetProspectQuery } from "@ensol/entool/queries/prospects"
import { CONTENT_PADDING_IN_PX } from "@ensol/entool/styles/constants"

export const ProspectPage = () => {
  const { prospectId } = useParams()
  const prospectQuery = useGetProspectQuery(prospectId as string)

  return (
    <QueryWrapper query={prospectQuery}>
      {({ data: prospect }) => (
        <Stack flex="1" gap="0">
          <Helmet>
            <title>{getFullName(prospect)} | Entool</title>
          </Helmet>
          <ProspectHeader
            prospect={prospect}
            RightSection={<CreateHouseButton prospectId={prospect.id} />}
          />
          <ContentContainer
            flex="1"
            px={CONTENT_PADDING_IN_PX}
            py={CONTENT_PADDING_IN_PX}
            mt="0"
            bg="gray.0"
          >
            <Stack gap={24}>
              {prospect.houses.length === 0 && (
                <Section pb={8}>
                  <Stack gap={0} align="flex-start">
                    <Text fz="xl" fw={500}>
                      Ce prospect n&apos;a pas encore de maison
                    </Text>
                    <CreateHouseButton prospectId={prospect.id} />
                  </Stack>
                </Section>
              )}
              {prospect.houses.map((house) => (
                <House key={house.id} house={house} />
              ))}
            </Stack>
          </ContentContainer>
        </Stack>
      )}
    </QueryWrapper>
  )
}
