import { Select, SimpleGrid, Stack, Textarea } from "@mantine/core"
import { DateTimePicker } from "@mantine/dates"
import {
  IconAdjustmentsAlt,
  IconCalendarX,
  IconListCheck,
  IconMap,
  IconSolarPanel,
} from "@tabler/icons-react"

import {
  requiredWorksFormSchema,
  TechnicalVisitStepInput,
} from "@ensol/types/forms/projects/technicalVisit"

import { getProjectCharacteristicsTextSummary } from "@ensol/shared/entities/projects/characteristics"
import {
  TechnicalVisitStep,
  getStepIndex,
} from "@ensol/shared/entities/projects/processes"
import { getProspectFileName } from "@ensol/shared/utils/files"
import {
  DISPLAY_DATE_FORMAT,
  DISPLAY_DATE_TIME_FORMAT,
} from "@ensol/shared/utils/format"

import { CopiableText } from "@ensol/entool/components/CopiableText"
import { Section } from "@ensol/entool/components/Section"
import { ProjectNotes } from "@ensol/entool/components/entities/Project/ProjectNotes"
import {
  StepActions,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { Field } from "@ensol/entool/components/form/Field"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { MultiFileField } from "@ensol/entool/components/form/File/MultiFileField"
import { UpdateRoofType } from "@ensol/entool/components/form/House/UpdateRoofType"
import { NumberInput } from "@ensol/entool/components/form/NumberInput"
import { FormCheckbox } from "@ensol/entool/components/form/Project/FormCheckbox"
import { FormField } from "@ensol/entool/components/form/Project/FormField"
import { RequiredChangesForm } from "@ensol/entool/components/form/Project/RequiredChangesForm"
import { RequiredWorksForm } from "@ensol/entool/components/form/Project/RequiredWorksForm"
import { SchedulingIssueFormField } from "@ensol/entool/components/form/Project/SchedulingIssueFormField"
import { UpdateInstallationSection } from "@ensol/entool/components/form/Project/UpdateInstallationSection"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useInstallersOptions } from "@ensol/entool/queries/installers"
import { booleanOptions } from "@ensol/entool/utils/form/options"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"
import {
  INTEGRATION_KITS_OPTIONS,
  SCHEDULING_ISSUES_REASONS_OPTIONS,
  TECHNICAL_VISIT_DIFFICULTY_OPTIONS,
} from "@ensol/entool/utils/projects/options"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const TechnicalVisitForm = <Input extends TechnicalVisitStepInput>({
  schema,
  initialValues,
  project,
  children,
  ...props
}: ProcessFormProps<Input> & StepActionsProps) => {
  const form = useProcessForm<Input>({
    schema,
    initialValues,
    projectId: project.id,
    processId: props.processId,
  })
  const installersOptions = useInstallersOptions()
  const projectCharacteristicsSummary =
    getProjectCharacteristicsTextSummary(project)

  const { prospect } = project.installation.house
  const currentStep = project[props.processId]

  const isScheduled =
    getStepIndex(currentStep, "technicalVisitStep") >=
    getStepIndex(TechnicalVisitStep.SCHEDULED, "technicalVisitStep")

  return (
    <StepActions
      project={project}
      validateStep={() => schema.parse(form.values)}
      {...props}
    >
      {currentStep === TechnicalVisitStep.UPDATE_INSTALLATION && (
        <UpdateInstallationSection installationId={project.installation.id} />
      )}
      {project.technicalVisitRequiredChanges.length > 0 &&
        "technicalVisitRequiredChanges" in initialValues && (
          <RequiredChangesForm
            form={form}
            initialValues={{
              technicalVisitRequiredChanges:
                project.technicalVisitRequiredChanges,
            }}
          />
        )}

      {project.technicalVisitRequiredWorks.length > 0 &&
        "technicalVisitRequiredWorks" in initialValues && (
          <RequiredWorksForm
            form={form}
            initialValues={getInitialValues(requiredWorksFormSchema, project)}
            prospect={prospect}
          />
        )}
      {currentStep === TechnicalVisitStep.SCHEDULED && (
        <Section title="Vérifications" icon={IconListCheck} titleOrder={3}>
          <FormCheckbox
            initialValues={initialValues}
            propertyName="isTechnicalVisitReady"
            {...form.getInputProps("isTechnicalVisitReady")}
          />
        </Section>
      )}
      {(currentStep === TechnicalVisitStep.SCHEDULING_ON_HOLD ||
        currentStep === TechnicalVisitStep.CANNOT_SCHEDULE) && (
        <Section title="Non planifiable" icon={IconCalendarX} titleOrder={3}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <Stack gap="24">
              <SchedulingIssueFormField
                options={SCHEDULING_ISSUES_REASONS_OPTIONS}
                form={form}
                initialValues={{
                  schedulingIssueMainReason: project.schedulingIssueMainReason,
                  schedulingIssueSubReason: project.schedulingIssueSubReason,
                }}
              />
              <FormField
                initialValues={initialValues}
                propertyName="technicalVisitSchedulingHoldEndDate"
              >
                <DateTimePicker
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_TIME_FORMAT}
                  clearable
                  {...form.getInputProps("technicalVisitSchedulingHoldEndDate")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="technicalVisitLastContactDate"
              >
                <DateInput
                  w={FIELD_WIDTH}
                  valueFormat={DISPLAY_DATE_FORMAT}
                  clearable
                  {...form.getInputProps("technicalVisitLastContactDate")}
                />
              </FormField>
            </Stack>
            <ProjectNotes projectId={project.id} mah={300} />
          </SimpleGrid>
        </Section>
      )}
      <Section
        title={
          isScheduled
            ? "Gestion visite technique"
            : "Planification de la visite technique"
        }
        icon={IconAdjustmentsAlt}
        titleOrder={3}
      >
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitStartDate"
              isRequired
            >
              <DateTimePicker
                w={FIELD_WIDTH}
                valueFormat={DISPLAY_DATE_TIME_FORMAT}
                clearable
                {...form.getInputProps("technicalVisitStartDate")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitExpectedValidationDate"
            >
              <DateInput
                w={FIELD_WIDTH}
                valueFormat={DISPLAY_DATE_FORMAT}
                clearable
                {...form.getInputProps("technicalVisitExpectedValidationDate")}
              />
            </FormField>

            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitReportsPath"
              isRequired
            >
              <MultiFileField
                previewProps={{
                  fileName: getProspectFileName("compte-rendu-VT", prospect),
                }}
                inputProps={{ accept: ["application/pdf"] }}
                {...form.getInputProps("technicalVisitReportsPath")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitReportReceivedDate"
              isRequired
            >
              <DateInput
                w={FIELD_WIDTH}
                valueFormat={DISPLAY_DATE_FORMAT}
                clearable
                {...form.getInputProps("technicalVisitReportReceivedDate")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitInstallerId"
              isRequired
            >
              <Select
                w={FIELD_WIDTH}
                data={installersOptions}
                {...form.getInputProps("technicalVisitInstallerId")}
              />
            </FormField>
            <FormField initialValues={initialValues} propertyName="installerId">
              <Select
                w={FIELD_WIDTH}
                data={installersOptions}
                {...form.getInputProps("installerId")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="installationEstimatedDuration"
              isRequired
            >
              <NumberInput
                w={FIELD_WIDTH}
                suffix=" jours"
                {...form.getInputProps("installationEstimatedDuration")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="installationClientAvailabilityDate"
            >
              <DateInput
                w={FIELD_WIDTH}
                valueFormat={DISPLAY_DATE_FORMAT}
                clearable
                {...form.getInputProps("installationClientAvailabilityDate")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            {!isScheduled && (
              <Field name="Informations installation">
                <CopiableText
                  text={projectCharacteristicsSummary}
                  w={FIELD_WIDTH}
                />
              </Field>
            )}
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitDifficulty"
            >
              <RadioGroup
                options={TECHNICAL_VISIT_DIFFICULTY_OPTIONS}
                {...form.getInputProps("technicalVisitDifficulty")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitInstallerConclusion"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("technicalVisitInstallerConclusion")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="technicalVisitClientConclusion"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("technicalVisitClientConclusion")}
              />
            </FormField>
          </Stack>
        </SimpleGrid>
      </Section>
      {"technicalVisitK2ReportsPath" in initialValues && (
        <Section title="Calepinages" icon={IconMap} titleOrder={3}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="technicalVisitK2ReportsPath"
              >
                <MultiFileField
                  previewProps={{
                    fileName: getProspectFileName("rapport-k2-VT", prospect),
                  }}
                  inputProps={{
                    accept: {
                      "application/pdf": [".pdf"],
                      "application/octet-stream": [".k2o"],
                    },
                  }}
                  {...form.getInputProps("technicalVisitK2ReportsPath")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="technicalVisitPanelsLayoutPath"
              >
                <FileField
                  previewProps={{
                    fileName: getProspectFileName("calepinage-VT", prospect),
                  }}
                  inputProps={{ accept: ["image/png", "image/jpeg"] }}
                  {...form.getInputProps("technicalVisitPanelsLayoutPath")}
                />
              </FormField>
            </Stack>
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="salesVisitK2ReportPath"
              >
                <FileField
                  previewProps={{
                    fileName: getProspectFileName("rapport-K2-VC", prospect),
                  }}
                  inputProps={{
                    accept: {
                      "application/pdf": [".pdf"],
                    },
                  }}
                  {...form.getInputProps("salesVisitK2ReportPath")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="salesVisitPanelsLayoutPath"
              >
                <FileField
                  previewProps={{
                    fileName: getProspectFileName("calepinage-VC", prospect),
                  }}
                  inputProps={{ accept: ["image/png", "image/jpeg"] }}
                  {...form.getInputProps("salesVisitPanelsLayoutPath")}
                />
              </FormField>
            </Stack>
          </SimpleGrid>
        </Section>
      )}
      <Section title="Matériel" icon={IconSolarPanel} titleOrder={3}>
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <UpdateRoofType house={project.installation.house} />
            <FormField
              initialValues={initialValues}
              propertyName="integrationKitType"
            >
              <Select
                w={FIELD_WIDTH}
                data={INTEGRATION_KITS_OPTIONS}
                {...form.getInputProps("integrationKitType")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="isPLCNeeded"
              isRequired
            >
              <RadioGroup
                options={booleanOptions}
                {...form.getInputProps("isPLCNeeded")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="hardwareNotes"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("hardwareNotes")}
              />
            </FormField>
          </Stack>
        </SimpleGrid>
      </Section>
      {children}
    </StepActions>
  )
}
