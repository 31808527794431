import { Group, Text } from "@mantine/core"

type Props = {
  label: string
  value?: string | null
}

export const InfoDisplay = ({ label, value }: Props) => {
  return (
    <Group gap="4" c="gray.6">
      <Text>{label} : </Text>
      <Text size="md" fw="bold" style={{ textWrap: "wrap" }}>
        {value ?? "En attente"}
      </Text>
    </Group>
  )
}
