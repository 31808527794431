import {
  Stack,
  SegmentedControl,
  Group,
  ActionIcon,
  Tooltip,
} from "@mantine/core"
import {
  IconAlertTriangleFilled,
  IconLink,
  IconLinkOff,
} from "@tabler/icons-react"
import { useEffect, useState } from "react"

import { MonthlyConsumptions } from "@ensol/types/installation"
import { ConsumptionDataSource } from "@ensol/types/simulation"

import {
  computeMonthlyBill,
  estimateDefaultEnergyPrice,
} from "@ensol/shared/entities/installations/energy"
import { colors } from "@ensol/shared/styles/colors"

import { Message } from "@ensol/entool/components/Message"
import { Section } from "@ensol/entool/components/Section"
import { ConsumptionSectionProps } from "@ensol/entool/pages/Installation/sections/2-Consumption"

import { ConsumptionSourceSelect } from "./ConsumptionSourceSelect"
import { MonthlyBillInput } from "./MonthlyBillInput"
import { MonthlyConsumptionInput } from "./MonthlyConsumptionInput"
import { MonthlyProductionGraph } from "./MonthlyProductionGraph"
import { YearlyConsumptionInput } from "./YearlyConsumptionInput"
import { useEnergyConsumptionQueries } from "./useEnergyConsumption"

const INPUT_MODES = {
  yearly: "Annuel",
  monthly: "Mensuel",
} as const
export type InputMode = keyof typeof INPUT_MODES

export const ConsumptionDataConfiguration = <
  Values extends {
    consumptionDataSource: ConsumptionDataSource
    yearlyConsumption: number
    monthlyConsumptions: MonthlyConsumptions
    monthlyBill: number
  },
>({
  form,
  house,
  masterInstallationId,
}: ConsumptionSectionProps<Values>) => {
  const { consumptionDataSource, monthlyConsumptions } = form.values
  const [inputMode, setInputMode] = useState<InputMode>(
    monthlyConsumptions.length > 0 &&
      consumptionDataSource === ConsumptionDataSource.BILL
      ? "monthly"
      : "yearly",
  )
  const [linkBillAndConsumption, setLinkBillAndConsumption] = useState(
    consumptionDataSource !== ConsumptionDataSource.SWITCHGRID,
  )

  const {
    isConsumptionLoading,
    computedConsumptionData,
    consumptionDataSourceOptions,
    warning,
  } = useEnergyConsumptionQueries({
    form,
    house,
    masterInstallationId,
    inputMode,
  })

  // Automatically update yearly consumption when configuring monthly consumption
  useEffect(() => {
    if (
      inputMode === "monthly" &&
      computedConsumptionData &&
      monthlyConsumptions.length > 0
    ) {
      const yearlyConsumption = computedConsumptionData.yearlyConsumption
      form.getInputProps("yearlyConsumption").onChange(yearlyConsumption)
      if (linkBillAndConsumption) {
        form
          .getInputProps("monthlyBill")
          .onChange(computeMonthlyBill(yearlyConsumption))
      }
    }
  }, [
    form,
    inputMode,
    monthlyConsumptions,
    linkBillAndConsumption,
    computedConsumptionData,
  ])

  // Reset consumption source if selected source data is not available
  useEffect(() => {
    if (!isConsumptionLoading && !computedConsumptionData) {
      form
        .getInputProps("consumptionDataSource")
        .onChange(ConsumptionDataSource.BILL)
    }
  }, [form, isConsumptionLoading, computedConsumptionData])

  return (
    <Section title="Données de consommation">
      <Stack>
        <ConsumptionSourceSelect
          form={form}
          setLinkBillAndConsumption={setLinkBillAndConsumption}
          setInputMode={setInputMode}
          consumptionDataSourceOptions={consumptionDataSourceOptions}
        />
        {warning && (
          <Message
            color="red.3"
            Icon={<IconAlertTriangleFilled />}
            content={warning}
          />
        )}
        <Stack>
          {consumptionDataSource === ConsumptionDataSource.BILL && (
            <SegmentedControl
              w={180}
              size="sm"
              data={Object.entries(INPUT_MODES).map(([value, label]) => ({
                value,
                label,
              }))}
              value={inputMode}
              onChange={(value: string) => {
                const inputMode = value as InputMode

                if (inputMode === "yearly") {
                  form.getInputProps("monthlyConsumptions").onChange([])
                }

                setInputMode(inputMode)
              }}
            />
          )}
          <Group align="flex-start">
            <YearlyConsumptionInput
              form={form}
              linkBillAndConsumption={linkBillAndConsumption}
              isDisabled={
                inputMode === "monthly" ||
                consumptionDataSource !== ConsumptionDataSource.BILL
              }
            />
            <Tooltip
              label={`Lier consommation & facture au prix EDF moyen: ${estimateDefaultEnergyPrice(
                new Date().getFullYear(),
              )} €/kWh`}
            >
              <ActionIcon
                size="sm"
                radius="lg"
                color="blue.8"
                mt="36"
                disabled={consumptionDataSource !== ConsumptionDataSource.BILL}
                onClick={() => setLinkBillAndConsumption((linked) => !linked)}
              >
                {linkBillAndConsumption ? <IconLink /> : <IconLinkOff />}
              </ActionIcon>
            </Tooltip>
            <MonthlyBillInput
              form={form}
              linkBillAndConsumption={linkBillAndConsumption}
              isDisabled={linkBillAndConsumption && inputMode === "monthly"}
            />
          </Group>
          <MonthlyProductionGraph
            isLoading={isConsumptionLoading}
            monthlyConsumptions={
              computedConsumptionData?.monthlyConsumptions.map(
                ({ month, value }) => ({
                  month,
                  value,
                  color:
                    inputMode === "yearly" ||
                    monthlyConsumptions
                      .map(({ month }) => month)
                      .includes(month)
                      ? colors.blue[500]
                      : colors.blue[200],
                }),
              ) ?? []
            }
            onMonthClick={
              inputMode === "monthly"
                ? (monthlyConsumption) =>
                    !monthlyConsumptions
                      .map(({ month }) => month)
                      .includes(monthlyConsumption.month) &&
                    form.insertListItem(
                      "monthlyConsumptions",
                      monthlyConsumption,
                    )
                : undefined
            }
          />
          {inputMode === "monthly" && (
            <MonthlyConsumptionInput
              form={form}
              computedMonthlyConsumptions={
                computedConsumptionData?.monthlyConsumptions ?? []
              }
            />
          )}
        </Stack>
      </Stack>
    </Section>
  )
}
