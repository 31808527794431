import { PaymentStep } from "@ensol/shared/entities/projects/processes"

import { StepComponentGetter } from "@ensol/entool/components/entities/Project/ProcessView"

import { DepositWaiting } from "./DepositWaiting"
import { RequestToSend } from "./cetelem/1-RequestToSend"
import { RequestSent } from "./cetelem/2-RequestSent"
import { WaitingMainPayment } from "./cetelem/3-WaitingMainPayment"
import { PaidWaitingForConsuel } from "./cetelem/4-PaidWaitingForConsuel"
import { ConsuelToSend } from "./cetelem/5-ConsuelToSend"
import { WaitingLastPayment } from "./cetelem/6-WaitingLastPayment"
import { FinalPaid } from "./cetelem/7-FinalPaid"
import { DepositToSend } from "./standard/1-DepositToSend"
import { WaitingDeposit } from "./standard/2-WaitingDeposit"
import { DepositPaid } from "./standard/3-DepositPaid"
import { FinalInvoiceToSend } from "./standard/4-FinalInvoiceToSend"
import { WaitingFinalInvoice } from "./standard/5-WaitingFinalInvoice"
import { FinalInvoicePaid } from "./standard/6-FinalInvoicePaid"

export const getPaymentStepComponent: StepComponentGetter = (currentStep) => {
  switch (currentStep) {
    case PaymentStep.DEPOSIT_INVOICE_WAITING:
      return { StepComponent: DepositWaiting, isCancellable: false }
    case PaymentStep.DEPOSIT_INVOICE_TO_SEND:
      return { StepComponent: DepositToSend }
    case PaymentStep.CETELEM_REQUEST_TO_SEND:
      return { StepComponent: RequestToSend }
    case PaymentStep.CETELEM_REQUEST_SENT:
      return { StepComponent: RequestSent }
    case PaymentStep.WAITING_FOR_DEPOSIT_PAYMENT:
      return { StepComponent: WaitingDeposit }
    case PaymentStep.CETELEM_WAITING_FOR_MAIN_PAYMENT:
      return { StepComponent: WaitingMainPayment }
    case PaymentStep.DEPOSIT_INVOICE_PAID:
      return { StepComponent: DepositPaid }
    case PaymentStep.CETELEM_PAID_WAITING_CONSUEL:
      return { StepComponent: PaidWaitingForConsuel }
    case PaymentStep.FINAL_INVOICE_TO_SEND:
      return { StepComponent: FinalInvoiceToSend }
    case PaymentStep.CETELEM_CONSUEL_TO_SEND:
      return { StepComponent: ConsuelToSend }
    case PaymentStep.WAITING_FOR_FINAL_PAYMENT:
      return { StepComponent: WaitingFinalInvoice }
    case PaymentStep.CETELEM_WAITING_LAST_PAYMENT:
      return { StepComponent: WaitingLastPayment }
    case PaymentStep.FINAL_INVOICE_PAID:
      return { StepComponent: FinalInvoicePaid }
    case PaymentStep.CETELEM_PAID:
      return { StepComponent: FinalPaid }
    default:
      return { StepComponent: null }
  }
}
