import { showNotification } from "@mantine/notifications"
import { useMutation, useQuery } from "@tanstack/react-query"
import { AxiosError } from "axios"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { ProspectResponses } from "@ensol/types/endpoints/prospects"
import { RoofSectionConfig, UpdateHouseInput } from "@ensol/types/forms/houses"
import { RoofSection } from "@ensol/types/prisma-client"

import { httpClient } from "@ensol/entool/backend/axios"
import { queryClient } from "@ensol/entool/backend/queryClient"

export const useGetHouseQuery = (houseId?: string) => {
  return useQuery<
    HousesResponses.House<{
      include: {
        switchgridConsent: true
        switchgridOrder: true
        prospect: true
        roofSections: true
        installations: true
      }
    }>
  >({
    queryKey: ["houses", houseId],
    queryFn: async () => {
      const response = await httpClient.get(`/houses/${houseId}`)
      return response.data
    },
    enabled: !!houseId,
  })
}

export const updateHouse = async (houseId: string, house: UpdateHouseInput) => {
  const response = await httpClient.put(`/houses/${houseId}`, house)
  return response.data
}

export const useUpdateHouseMutation = (
  houseId: string,
  onSuccess?: (
    data: HousesResponses.House<{ include: { roofSections: true } }>,
  ) => void,
) => {
  return useMutation<
    ProspectResponses.HouseWithRoofSections,
    AxiosError,
    UpdateHouseInput
  >({
    mutationFn: async (house) => updateHouse(houseId, house),
    onSuccess,
    onError: () => {
      showNotification({
        message:
          "Erreur lors de la mise à jour de la maison, les données n'ont pas été sauvegardées",
        color: "red",
      })
    },
  })
}

export const useUpsertRoofSectionMutation = (
  houseId: string,
  onSuccess?: (data: RoofSection) => void,
) => {
  return useMutation<RoofSection, AxiosError, RoofSectionConfig>({
    mutationFn: async (roofSection) => {
      const response = await httpClient.post(
        `houses/${houseId}/roof-sections`,
        roofSection,
      )
      return response.data
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({ queryKey: ["houses", houseId] })
      showNotification({
        message: "Le pan a été enregistré",
        color: "blue",
      })
      onSuccess?.(data)
    },
    onError: () => {
      showNotification({
        message:
          "Erreur lors de la mise à jour du pan de toit, les données n'ont pas été sauvegardées",
        color: "red",
      })
    },
  })
}
