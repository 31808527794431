import { useEffect } from "react"
import { useSearchParams } from "react-router-dom"

import { InstallationPageSection } from "@ensol/entool/utils/installations/useInstallationMenu"

export const useInstallationNavigation = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const currentSection = searchParams.get(
    "section",
  ) as InstallationPageSection | null

  useEffect(() => {
    if (currentSection === null) {
      setSearchParams({ section: InstallationPageSection.DESCRIPTION })
    }
  }, [currentSection, setSearchParams])

  return { currentSection }
}
