import { Loader, Stack, Text, ThemeIcon } from "@mantine/core"
import {
  IconAlertTriangleFilled,
  IconCircleCheckFilled,
  IconCircleXFilled,
  IconInfoCircle,
} from "@tabler/icons-react"

import { EnergyResponses } from "@ensol/types/endpoints/energy"
import { HousesResponses } from "@ensol/types/endpoints/houses"

import { OrderStatus } from "@ensol/shared/entities/houses/switchgrid"
import {
  DISPLAY_DATE_TIME_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"
import { formatTimeFrame } from "@ensol/shared/utils/timeFrame"

import { Message } from "@ensol/entool/components/Message"
import { Tooltip } from "@ensol/entool/components/Tooltip"
import { useSwitchgridConsumption } from "@ensol/entool/queries/energy"

type Props = {
  house: HousesResponses.House<{
    include: { switchgridOrder: true; switchgridConsent: true }
  }>
}

export const OrderStatusMessages = ({ house }: Props) => {
  const { data: switchgridData } = useSwitchgridConsumption(house)

  const { switchgridOrder } = house
  if (switchgridOrder === null) {
    return null
  }

  const status = switchgridOrder.orderStatus

  if (status === OrderStatus.PENDING) {
    return (
      <Message
        color="gray.5"
        Icon={<Loader color="gray" size="xs" />}
        content={`La récupération des données est en cours, les résultats devraient être disponibles au plus tard le ${formatDate(switchgridOrder.createdAt, DISPLAY_DATE_TIME_FORMAT)}`}
      />
    )
  }

  if (status === OrderStatus.SOME_REQUESTS_SUCCEEDED) {
    return (
      <Message
        color="red.3"
        Icon={<IconAlertTriangleFilled />}
        content="Certaines données sont encore en chargement mais vous pouvez commencer
          à utiliser la source de données Enedis"
      >
        <DataTimeFrameTooltip switchgridData={switchgridData} />
      </Message>
    )
  }

  if (status === OrderStatus.RETRY_LATER) {
    return (
      <Message
        color="red.3"
        Icon={<IconAlertTriangleFilled />}
        content="Certaines données sont manquantes mais vous pouvez commencer à
          utiliser la source de données Enedis. Renouvelez la demande dans
          environ 24h pour essayer de récupérer les données manquantes"
      >
        <DataTimeFrameTooltip switchgridData={switchgridData} />
      </Message>
    )
  }

  if (status === OrderStatus.FAILED) {
    return (
      <Message
        color="red"
        Icon={<IconCircleXFilled />}
        content="La demande des données n'a pas pu aboutir, contactez l'équipe énergie pour plus d'informations ou réessayez plus tard"
      />
    )
  }

  if (status === OrderStatus.SUCCEEDED) {
    return (
      <Message
        color="green.5"
        Icon={<IconCircleCheckFilled />}
        content="Les données du client ont été récupérées"
      >
        <DataTimeFrameTooltip switchgridData={switchgridData} />
      </Message>
    )
  }
}

const DataTimeFrameTooltip = ({
  switchgridData,
}: {
  switchgridData?: EnergyResponses.Consumption
}) => {
  if (!switchgridData) {
    return null
  }

  const { enedisHourlyDataTimeFrame, enedisDailyDataTimeFrame } = switchgridData

  return (
    <Tooltip
      label={
        <Stack gap={4}>
          <Text>
            {enedisHourlyDataTimeFrame !== null
              ? `Période des données heure par heure : ${formatTimeFrame(enedisHourlyDataTimeFrame)}`
              : "Pas de données heure par heure"}
          </Text>
          <Text>
            {enedisDailyDataTimeFrame !== null
              ? `Période des données jour par jour : ${formatTimeFrame(enedisDailyDataTimeFrame)}`
              : "Pas de données jour par jour"}
          </Text>
        </Stack>
      }
    >
      <ThemeIcon color="gray.6">
        <IconInfoCircle size={16} />
      </ThemeIcon>
    </Tooltip>
  )
}
