import { ButtonProps, MantineStyleProps } from "@mantine/core"

import { Link } from "@ensol/shared/components/Link"

import { getHubspotProspectLink } from "@ensol/entool/utils/hubspot"

type Props = {
  hubspotContactId: string | null
  emptyContent?: React.ReactNode | string
  isIconButton?: boolean
} & MantineStyleProps &
  ButtonProps

export const HubspotLink = ({
  hubspotContactId,
  emptyContent = null,
  isIconButton = false,
  ...props
}: Props) => {
  if (hubspotContactId === null || hubspotContactId === undefined) {
    return emptyContent
  }

  return (
    <Link
      label="Contact Hubspot"
      to={getHubspotProspectLink(hubspotContactId)}
      color="black"
      Icon={<img src="/hubspotLogo.webp" height={20} />}
      isExternal
      isIconButton={isIconButton}
      {...props}
    />
  )
}
