import {
  Badge,
  Button,
  Group,
  Modal,
  ScrollArea,
  Stack,
  Text,
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconFileDollar, IconHistory } from "@tabler/icons-react"

import { isQuoteOutdated } from "@ensol/shared/entities/quotes"

import { GenerateQuoteButton } from "@ensol/entool/components/entities/Installation/Quote/GenerateQuoteButton"
import { QuoteStatusBadge } from "@ensol/entool/components/entities/Installation/QuoteStatusBadge"
import { useGetInstallationQuotesQuery } from "@ensol/entool/queries/installations"

import { ActiveQuoteActions } from "./ActiveQuoteActions"
import { ActiveQuoteDocument } from "./ActiveQuoteDocument"
import { PreviousQuotes } from "./PreviousQuotes"

type Props = {
  installationId: string
  installationDate: Date
  quoteFileName: string
  isInstallationValid: boolean
}

export const Quote = ({
  installationId,
  installationDate,
  quoteFileName,
  isInstallationValid,
}: Props) => {
  const { data: installationQuotes, isPending } =
    useGetInstallationQuotesQuery(installationId)
  const [
    isPreviousQuotesModalOpen,
    { open: openPreviousQuotesModal, close: closePreviousQuotesModal },
  ] = useDisclosure()

  if (isPending || installationQuotes === undefined) {
    return null
  }
  const { activeQuote, previousQuotes } = installationQuotes
  const isOutdated = isQuoteOutdated(installationDate, activeQuote)

  return (
    <Stack px="16" pt="16">
      <Group justify="space-between">
        <Group gap={6}>
          <IconFileDollar size={16} />
          <Text fz={16} fw="500">
            Devis
          </Text>
          {!activeQuote?.status ? (
            <Badge size="sm" color="gray.6" variant="light">
              Non créé
            </Badge>
          ) : !isOutdated ? (
            <QuoteStatusBadge status={activeQuote.status} />
          ) : (
            <Badge size="sm" color="yellow" variant="light">
              Obsolète
            </Badge>
          )}
        </Group>
        {previousQuotes.length > 0 && (
          <Button
            variant="light"
            size="xs"
            color="gray.6"
            leftSection={<IconHistory size={16} />}
            onClick={openPreviousQuotesModal}
          >
            Historique
          </Button>
        )}
      </Group>
      {activeQuote && (
        <ActiveQuoteDocument
          activeQuote={activeQuote}
          quoteFileName={quoteFileName}
        />
      )}
      <Stack gap="8">
        <GenerateQuoteButton
          activeQuote={activeQuote}
          installationId={installationId}
          installationDate={installationDate}
          isInstallationValid={isInstallationValid}
        />
        {activeQuote && !isOutdated && (
          <ActiveQuoteActions
            activeQuote={activeQuote}
            installationId={installationId}
          />
        )}
      </Stack>
      {previousQuotes.length > 0 && (
        <Modal
          radius="md"
          opened={isPreviousQuotesModalOpen}
          onClose={closePreviousQuotesModal}
          scrollAreaComponent={ScrollArea.Autosize}
          title={
            <Text size="lg" fw="bold">
              Historique des devis
            </Text>
          }
          centered
        >
          <PreviousQuotes
            installationId={installationId}
            previousQuotes={previousQuotes}
            quoteFileName={quoteFileName}
            onClose={closePreviousQuotesModal}
          />
        </Modal>
      )}
    </Stack>
  )
}
