import { cetelemFormSchema } from "@ensol/types/forms/projects/payment"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"

import { CetelemForm } from "./components/CetelemForm"
import { getCetelemFormInitialValues } from "./util"

export const PaidWaitingForConsuel = ({
  project,
  processId,
}: StepComponentProps) => {
  return (
    <CetelemForm
      schema={cetelemFormSchema}
      initialValues={getCetelemFormInitialValues(project)}
      project={project}
      processId={processId}
      actions={[
        {
          action: "validate",
          label: "Consuel validé",
          confirmationMessage:
            "Cette étape devrait être passée automatiquement lorsque que le Consuel est validé, êtes-vous sûr de vouloir la passer manuellement ?",
        },
      ]}
    />
  )
}
