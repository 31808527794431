import { Button } from "@mantine/core"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { UnpackArray } from "@ensol/types/utils"

import { FundingSourceStatus } from "@ensol/shared/entities/projects/fundingSource"

import { useUpdateProcessDataMutation } from "@ensol/entool/queries/projects"

export const ActionDone = (
  project: UnpackArray<ProjectResponses.ProjectsList>,
) => {
  const { mutateAsync: updateProject } = useUpdateProcessDataMutation(
    "sales-visit",
    project.id,
  )
  return (
    <Button
      onClick={() =>
        project.fundingSource &&
        updateProject({
          fundingSourceStatus: FundingSourceStatus.PENDING,
        })
      }
    >
      Action réalisée
    </Button>
  )
}
