import { Stack, StackProps, Tabs, Text } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { IconInfoCircle, IconMessageCircle } from "@tabler/icons-react"
import { useState } from "react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { devices } from "@ensol/shared/styles/theme"

import { ProjectNotes } from "@ensol/entool/components/entities/Project/ProjectNotes"
import { CONTENT_PADDING_IN_PX } from "@ensol/entool/styles/constants"

import { HardwareSection } from "./HardwareSection"
import { HouseSection } from "./HouseSection"
import { ProcessesStatusSection } from "./ProcessesStatusSection"
import { ProjectSection } from "./ProjectSection"

type Props = {
  project: ProjectResponses.Project
} & StackProps

enum Tab {
  DETAILS = "Détails",
  CHAT = "Chat",
}

export const ProjectTechnicalOverview = ({ project, ...props }: Props) => {
  const isMobile = useMediaQuery(devices.sm)

  if (isMobile) {
    return <MobileTechnicalOverview project={project} />
  }

  return <TechnicalOverviewView project={project} {...props} />
}

const MobileTechnicalOverview = ({ project }: Props) => {
  const [tab, setTab] = useState<Tab>(Tab.DETAILS)

  return (
    <Tabs
      onChange={(value) => setTab(value as Tab)}
      value={tab}
      defaultValue="informations"
      styles={{
        root: {
          display: "flex",
          flexDirection: "column",
          minHeight: 0,
          flex: 1,
        },
      }}
    >
      <Tabs.List bg="white" grow justify="space-around">
        <Tabs.Tab
          value={Tab.DETAILS}
          leftSection={<IconInfoCircle size={16} />}
        >
          <Text size="md">{Tab.DETAILS}</Text>
        </Tabs.Tab>
        <Tabs.Tab
          value={Tab.CHAT}
          leftSection={<IconMessageCircle size={16} />}
        >
          <Text size="md">{Tab.CHAT}</Text>
        </Tabs.Tab>
      </Tabs.List>
      <Tabs.Panel
        value={Tab.DETAILS}
        p={CONTENT_PADDING_IN_PX}
        h="100%"
        style={{ overflow: "auto" }}
      >
        <TechnicalOverviewView project={project} />
      </Tabs.Panel>
      <Tabs.Panel
        value={Tab.CHAT}
        p={CONTENT_PADDING_IN_PX}
        style={{ height: "100%", minHeight: 0, display: "flex" }}
      >
        {tab === Tab.CHAT && (
          <Stack
            bg="white"
            style={{ borderRadius: 8 }}
            justify="flex-end"
            p={8}
            flex={1}
          >
            <ProjectNotes projectId={project.id} />
          </Stack>
        )}
      </Tabs.Panel>
    </Tabs>
  )
}

const TechnicalOverviewView = ({ project, ...props }: Props) => {
  return (
    <Stack gap="24" flex="1" {...props} bg="gray.0">
      <ProjectSection project={project} />
      <HouseSection house={project.installation.house} />
      <ProcessesStatusSection project={project} />
      <HardwareSection project={project} />
    </Stack>
  )
}
