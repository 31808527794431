import { Button } from "@mantine/core"

import { SwitchgridResponses } from "@ensol/types/endpoints/switchgrid"

import { OrderStatus } from "@ensol/shared/entities/houses/switchgrid"

import { useCreateOrderMutation } from "@ensol/entool/queries/switchgrid"

type Props = {
  houseId: string
  switchgridOrder: SwitchgridResponses.SwitchgridOrder | null
}

export const OrderButton = ({ switchgridOrder, houseId }: Props) => {
  const { mutateAsync: createOrder, isPending: isOrderCreating } =
    useCreateOrderMutation(houseId)

  if (switchgridOrder === null) {
    return (
      <Button
        onClick={() => createOrder()}
        loading={isOrderCreating}
        variant="light"
      >
        Demander les données
      </Button>
    )
  }

  return (
    <Button
      onClick={() => createOrder()}
      loading={isOrderCreating}
      variant="light"
    >
      {switchgridOrder.orderStatus === OrderStatus.SUCCEEDED
        ? "Rafraîchir les données"
        : "Demander à nouveau les données"}
    </Button>
  )
}
