import { preliminaryValidationStepFormSchema } from "@ensol/types/forms/projects/preliminaryRequest"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { PreliminaryRequestForm } from "@ensol/entool/pages/OpsProject/processes/PreliminaryRequest/components/PreliminaryRequestForm"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"

import { PRELIMINARY_REQUEST_PROPERTIES } from "./config"

export const SubmissionOnHold = ({ project, ...props }: StepComponentProps) => (
  <PreliminaryRequestForm
    schema={preliminaryValidationStepFormSchema}
    initialValues={{
      ...pickDefinedProperties(PRELIMINARY_REQUEST_PROPERTIES, project),
      ...getInitialValues(preliminaryValidationStepFormSchema, project),
    }}
    project={project}
    actions={[
      {
        action: "validate",
        label: "Projet complet",
        confirmationMessage:
          "Cette action va créer le projet sur Solteo avec les informations présentes dans le formulaire, êtes-vous sûr de vouloir continuer ?",
      },
    ]}
    {...props}
  />
)
