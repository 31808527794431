import { Button, ButtonProps, Group, Text, TextProps } from "@mantine/core"
import { IconMap2 } from "@tabler/icons-react"

type Props = {
  address: string
  textProps?: TextProps
} & ButtonProps

export const MapLink = ({ address, textProps, ...props }: Props) => (
  <Button
    href={`http://maps.google.com/?q=${address}`}
    target="_blank"
    component="a"
    variant="transparent"
    p="0"
    justify="flex-start"
    {...props}
  >
    <Group gap="4" wrap="nowrap">
      <IconMap2
        size={16}
        style={{ flexShrink: 0, alignSelf: "flex-start", marginTop: "4px" }}
      />
      <Text size="sm" {...textProps}>
        {address}
      </Text>
    </Group>
  </Button>
)
