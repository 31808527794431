import { ColorSwatch, Group, Text } from "@mantine/core"

import { GoogleCalendarEventType } from "@ensol/types/endpoints/google"

import { ProjectEventStatus } from "@ensol/shared/entities/projects/events"

import {
  EVENT_STATUSES_COLORS,
  PUBLIC_HOLIDAY_BORDER_COLOR,
  GOOGLE_CALENDAR_TYPE_BORDER_COLORS,
  EVENT_STATUSES_NAMES,
  EVENT_GOOGLE_CALENDAR_TYPES_NAMES,
} from "@ensol/entool/utils/projects/events"

export const Legend = () => {
  return (
    <Group style={{ flexWrap: "nowrap" }}>
      <Text size="sm" fw="bold">
        Légende :{" "}
      </Text>
      {Object.values(ProjectEventStatus).map((status) => (
        <LegendItem
          key={status}
          color={EVENT_STATUSES_COLORS[status]}
          label={EVENT_STATUSES_NAMES[status]}
        />
      ))}
      {Object.values(GoogleCalendarEventType).map((type) => (
        <LegendItem
          key={type}
          borderColor={GOOGLE_CALENDAR_TYPE_BORDER_COLORS[type]}
          label={EVENT_GOOGLE_CALENDAR_TYPES_NAMES[type]}
        />
      ))}
      <LegendItem
        label="Jour ferié"
        borderColor={PUBLIC_HOLIDAY_BORDER_COLOR}
      />
    </Group>
  )
}

type LegendItemProps = {
  label: string
  color?: string
  borderColor?: string
}

const LegendItem = ({
  label,
  color = "white",
  borderColor,
}: LegendItemProps) => (
  <Group gap="4">
    <ColorSwatch
      size="20"
      color={color}
      styles={{
        root: borderColor ? { border: `2px solid ${borderColor}` } : undefined,
        shadowOverlay: borderColor ? { display: "none" } : undefined,
      }}
    />
    <Text size="xs">{label}</Text>
  </Group>
)
