import { useMemo } from "react"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { typedKeys } from "@ensol/types/utils"

import {
  MOUNTING_TYPE_LABELS,
  MountingType,
} from "@ensol/shared/entities/installations/batteries"
import {
  BATTERIES,
  BatteryType,
  getBattery,
} from "@ensol/shared/material/batteries"
import { InverterInfo } from "@ensol/shared/material/photovoltaic/inverters"

import { Option } from "@ensol/entool/utils/form/radio"

const BATTERY_OPTIONS: Option<BatteryType>[] = typedKeys(BATTERIES).map(
  (batteryType) => {
    const { name } = BATTERIES[batteryType]

    return {
      label: name,
      value: batteryType,
    }
  },
)

type BatteryMaterialOptionsInput = {
  inverterInfo?: InverterInfo
  batteryType: BatteryType | null
}

export type BatteryMaterialOptions = Option<
  BatteryType,
  { incompatible: boolean; mountingTypeOptions: Option<MountingType>[] }
>[]

export const useBatteryMaterialOptions = ({
  inverterInfo,
  batteryType,
}: BatteryMaterialOptionsInput): BatteryMaterialOptions => {
  const batteryOptions = useMemo(() => {
    return BATTERY_OPTIONS.map((battery) => {
      const batteryInfo = BATTERIES[battery.value]
      const isWrongBrand =
        inverterInfo !== undefined && batteryInfo.brand !== inverterInfo.brand

      const disabled = isWrongBrand || batteryInfo.disabled
      const mountingTypeOptions: Option<MountingType>[] =
        batteryInfo.mountingTypes.map((mountingType) => ({
          label: MOUNTING_TYPE_LABELS[mountingType],
          value: mountingType,
        }))
      return {
        ...battery,
        disabled,
        metadata: { incompatible: isWrongBrand, mountingTypeOptions },
      }
    }).filter((battery) => !battery.disabled || battery.value === batteryType)
  }, [batteryType, inverterInfo])

  return batteryOptions
}

export const computeBatteryInstallationName = ({
  type,
}: InstallationsResponses.BatteryInstallation) => {
  const battery = getBattery(type)

  return `Batterie ${battery.capacity} kWh`
}
