import { schedulingOnHoldStepFormSchema } from "@ensol/types/forms/projects/technicalVisit"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"

import { BeforeVisitCompletedForm } from "./components/BeforeVisitCompletedForm"

export const CannotSchedule = ({ project, processId }: StepComponentProps) => (
  <BeforeVisitCompletedForm
    schema={schedulingOnHoldStepFormSchema}
    initialValues={getInitialValues(schedulingOnHoldStepFormSchema, project)}
    project={project}
    processId={processId}
    actions={[{ action: "validate", label: "Repasser en prêt à planifier" }]}
  />
)
