import { useForm, zodResolver } from "@mantine/form"
import { z } from "zod"

import { CustomActionModalProps } from "@ensol/entool/components/entities/Project/StepActions"
import {
  useCompleteProcessStepMutation,
  useUpdateProcessDataMutation,
} from "@ensol/entool/queries/projects"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"

const schema = z.object({
  technicalVisitRejectedReasons: z
    .array(z.string())
    .min(1, "Champ obligatoire"),
})

type RejectActionModalInput = z.infer<typeof schema>

export const useRejectActionModal = ({
  project,
  processId,
  onClose,
}: CustomActionModalProps) => {
  const initialValues = getInitialValues(schema, project)
  const form = useForm({
    validate: zodResolver(schema),
    initialValues,
  })

  const { mutateAsync: updateProjectAsync, isPending: isUpdatingProject } =
    useUpdateProcessDataMutation<RejectActionModalInput>(
      "technical-visit",
      project.id,
    )

  const { mutateAsync: completeStep, isPending: isCompletingStep } =
    useCompleteProcessStepMutation()

  const handleSubmit = async (values: RejectActionModalInput) => {
    await updateProjectAsync(values)

    await completeStep({ projectId: project.id, processId, action: "reject" })

    onClose()
  }

  const isLoading = isUpdatingProject || isCompletingStep

  return {
    form,
    isLoading,
    handleSubmit,
    initialValues,
  }
}
