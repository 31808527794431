import { Badge, Flex, Group, Stack, Text } from "@mantine/core"
import { TablerIcon } from "@tabler/icons-react"
import { ReactNode } from "react"

import { ColorGroup, colors } from "@ensol/shared/styles/colors"

type SummarySectionProps = {
  title: string
  Icon: TablerIcon
  badge?: string
  children: ReactNode
}
export const SummarySection = ({
  title,
  Icon,
  badge,
  children,
}: SummarySectionProps) => (
  <Stack
    p={16}
    gap={8}
    style={{ borderBottom: `1px solid ${colors.gray[300]}` }}
  >
    <Group gap={6} mb={4}>
      <Icon size={16} />
      <Text size="lg" fw="500">
        {title}
        {badge ? " : " : ""}
      </Text>
      {badge && (
        <Badge variant="light" fz={12}>
          {badge}
        </Badge>
      )}
    </Group>
    <Stack gap={6}>{children}</Stack>
  </Stack>
)

type InstallationResultItemProps = {
  title: string
  value: string | number
  warning?: string
  direction?: React.CSSProperties["flexDirection"]
  color?: ColorGroup
}

export const SummaryItem = ({
  title,
  value,
  warning,
  direction = "row",
  color,
}: InstallationResultItemProps) => (
  <Stack gap={6}>
    <Flex direction={direction} justify="space-between">
      <Text c="gray.6" size="md">
        {title}
      </Text>
      <Text fw="bold" size="md" c={color}>
        {value}
      </Text>
    </Flex>
    {warning && (
      <Text c="red.3" size="xs" mb={4}>
        {warning}
      </Text>
    )}
  </Stack>
)
