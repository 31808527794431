import { Group, SimpleGrid, Title } from "@mantine/core"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { formatDate } from "@ensol/shared/utils/format"

import { Section } from "@ensol/entool/components/Section"
import { SubSection } from "@ensol/entool/components/SubSection"
import { booleanOptions } from "@ensol/entool/utils/form/options"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { ROOF_TYPE_OPTIONS } from "@ensol/entool/utils/house/options"
import {
  HARDWARE_DELIVERY_MODE_OPTIONS,
  INTEGRATION_KITS_OPTIONS,
} from "@ensol/entool/utils/projects/options"

import { InfoDisplay } from "./InfoDisplay"
import { ProcessBadge } from "./ProcessBadge"

type Props = {
  project: ProjectResponses.Project
}
export const HardwareSection = ({ project }: Props) => {
  return (
    <Section
      title={
        <Group gap={8}>
          <Title order={2}>Matériel</Title>
          <ProcessBadge
            processStep={project.hardwareStep}
            processId="hardwareStep"
          />
        </Group>
      }
      isCollapsible
    >
      <SimpleGrid
        spacing={24}
        cols={{ base: 1, sm: 2 }}
        style={{ alignItems: "flex-start" }}
      >
        <SubSection title="Planification">
          <InfoDisplay label="Fournisseur" value={project.supplier?.name} />
          <InfoDisplay
            label="Mode de livraison"
            value={
              findSelectedOption(
                project.hardwareDeliveryMode,
                HARDWARE_DELIVERY_MODE_OPTIONS,
              )?.label
            }
          />
          <InfoDisplay
            label="Adresse de livraison"
            value={project.hardwareDeliveryAddress}
          />
          <InfoDisplay
            label="Date de livraison"
            value={formatDate(project.orderDeliveryDate)}
          />
        </SubSection>
        <SubSection title="Description">
          <InfoDisplay
            label="Couverture"
            value={
              findSelectedOption(
                project.installation.house.roofType,
                ROOF_TYPE_OPTIONS,
              )?.label
            }
          />
          <InfoDisplay
            label="Kit d’intégration"
            value={
              findSelectedOption(
                project.integrationKitType,
                INTEGRATION_KITS_OPTIONS,
              )?.label
            }
          />
          <InfoDisplay
            label="Besoin de CPL ?"
            value={
              findSelectedOption(project.isPLCNeeded, booleanOptions)?.label
            }
          />
        </SubSection>
      </SimpleGrid>
    </Section>
  )
}
