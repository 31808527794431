import { schedulingOnHoldStepFormSchema } from "@ensol/types/forms/projects/installation"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"

import { InstallationForm } from "./components/InstallationForm"

export const CannotSchedule = ({ project, processId }: StepComponentProps) => (
  <InstallationForm
    schema={schedulingOnHoldStepFormSchema}
    initialValues={getInitialValues(schedulingOnHoldStepFormSchema, project)}
    project={project}
    processId={processId}
    relatedProcesses={["technicalVisitStep", "preliminaryRequestStep"]}
    actions={[{ action: "validate", label: "Repasser en prêt à planifier" }]}
  />
)
