import { completedStepFormSchema } from "@ensol/types/forms/projects/technicalVisit"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { TechnicalVisitForm } from "@ensol/entool/pages/OpsProject/processes/TechnicalVisit/components/TechnicalVisitForm"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"

export const Completed = ({ project, ...props }: StepComponentProps) => (
  <TechnicalVisitForm
    schema={completedStepFormSchema}
    initialValues={getInitialValues(completedStepFormSchema, project)}
    project={project}
    {...props}
    actions={[{ action: "validate", label: "Passer en cours de traitement" }]}
  />
)
