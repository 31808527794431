import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { typedKeys } from "@ensol/types/utils"

import {
  EV_CHARGERS,
  EvChargerType,
  getEvCharger,
} from "@ensol/shared/material/evCharger"

import { Option } from "@ensol/entool/utils/form/radio"

export const EV_CHARGER_OPTIONS: Option<EvChargerType>[] = typedKeys(
  EV_CHARGERS,
).map((evChargerType) => {
  const { name } = EV_CHARGERS[evChargerType]

  return {
    label: name,
    value: evChargerType,
  }
})

export const computeEvChargerInstallationName = ({
  type,
}: InstallationsResponses.EvChargerInstallation) => {
  const evCharger = getEvCharger(type)

  return `Borne ${evCharger.power} kW`
}
