import { Anchor, Text } from "@mantine/core"
import { Link } from "react-router-dom"

type BreadCrumbLinkProps = {
  label: string
  to?: string
}

const BREADCRUMB_STYLE_PROPS = {
  fz: "18",
  fw: "500",
  c: "black",
  style: { whiteSpace: "nowrap" },
}

export const BreadCrumb = ({ label, to }: BreadCrumbLinkProps) => {
  if (to === undefined) {
    return <Text {...BREADCRUMB_STYLE_PROPS}>{label}</Text>
  }

  return (
    <Anchor component={Link} to={to} {...BREADCRUMB_STYLE_PROPS}>
      {label}
    </Anchor>
  )
}
