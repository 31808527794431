import { Popover, ActionIcon, Group } from "@mantine/core"
import { useDisclosure, useClickOutside } from "@mantine/hooks"
import { TablerIcon } from "@tabler/icons-react"

import { CopiableText } from "@ensol/entool/components/CopiableText"
import { PopoverContent } from "@ensol/entool/components/PopoverContent"

type Props = {
  Icon: TablerIcon
  name: string
  value: string
  Link?: React.ReactNode
}

export const InfoIconPopover = ({ Icon, name, value, Link }: Props) => {
  const [opened, { open, close }] = useDisclosure(false)
  const ref = useClickOutside(close)

  return (
    <Popover withArrow position="top" offset={0} opened={opened}>
      <Popover.Target>
        <ActionIcon
          variant="transparent"
          onClick={open}
          c={opened ? "blue.4" : "gray.5"}
        >
          <Icon />
        </ActionIcon>
      </Popover.Target>
      <PopoverContent ref={ref} title={name}>
        <Group gap="8">
          <CopiableText Icon={Icon} text={value} />
          {Link}
        </Group>
      </PopoverContent>
    </Popover>
  )
}
