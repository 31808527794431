import { IconCashBanknote } from "@tabler/icons-react"

import {
  FundingSource,
  FundingSourceStatus,
} from "@ensol/shared/entities/projects/fundingSource"

import { Tooltip } from "@ensol/entool/components/Tooltip"

import { FundingSourceInfo } from "./FundingSourceInfo"

type Props = {
  fundingSource: FundingSource | null
  fundingSourceStatus: FundingSourceStatus | null
}

export const FundingSourceInfoIndicator = ({
  fundingSource,
  fundingSourceStatus,
}: Props) => {
  return (
    <Tooltip
      label={
        <FundingSourceInfo
          fundingSource={fundingSource}
          fundingSourceStatus={fundingSourceStatus}
        />
      }
    >
      <IconCashBanknote color="green" size={20} />
    </Tooltip>
  )
}
