import ChartDataLabels from "chartjs-plugin-datalabels"

import { colors } from "@ensol/shared/styles/colors"
import { formatCurrency } from "@ensol/shared/utils/format"

import { Chart } from "@ensol/entool/components/Chart"

type Props = {
  datasets: {
    label?: string
    data: { year: number; value: number }[]
    backgroundColor: string
  }[]
  withLegend: boolean
  shouldShowAmounts: boolean
}

export const BillEvolutionGraph = ({
  datasets,
  withLegend,
  shouldShowAmounts,
}: Props) => (
  <Chart
    aspectRatio={7 / 3}
    data={{
      type: "bar",
      data: {
        labels: datasets[0].data.map(({ year }) => year),
        datasets: datasets.map(({ label, data, backgroundColor }) => ({
          label,
          data: data.map(({ value }) => value),
          backgroundColor,
          maxBarThickness: 45,
        })),
      },
      plugins: shouldShowAmounts ? [ChartDataLabels] : [],
      options: {
        animation: false,
        plugins: {
          legend: {
            display: withLegend,
            position: "bottom",
            align: "center",
            labels: {
              usePointStyle: true,
              pointStyle: "circle",
              boxWidth: 12,
              boxHeight: 12,
              padding: 24,
              font: { weight: "bold", size: 14 },
            },
          },
          tooltip: { enabled: false },
          ...(shouldShowAmounts
            ? {
                datalabels: {
                  anchor: "center",
                  align: "center",
                  color: "white",
                  font: { size: 10 },
                  formatter: (value) => formatCurrency(value),
                },
              }
            : {}),
        },
        scales: {
          x: {
            border: { display: false },
            grid: { display: false },
            ticks: {
              font: { weight: "bold", size: 12 },
              color: colors.gray[500],
            },
          },
          y: {
            ticks: {
              stepSize: 500,
              font: { size: 12 },
              color: colors.gray[500],
              callback: (value) => formatCurrency(Number(value)),
            },
            border: { display: false, dash: [5, 5] },
            grid: { color: colors.gray[200], lineWidth: 2 },
            beginAtZero: true,
          },
        },
      },
    }}
  />
)
