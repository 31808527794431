import { Stack, Textarea, SimpleGrid } from "@mantine/core"
import {
  IconFileAnalytics,
  IconHomeCheck,
  IconListCheck,
} from "@tabler/icons-react"

import { FollowUpStepInput } from "@ensol/types/forms/projects/followUp"
import { Nullable } from "@ensol/types/utils"

import { FollowUpStep } from "@ensol/shared/entities/projects/processes"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"
import { DISPLAY_DATE_FORMAT } from "@ensol/shared/utils/format"

import { Section } from "@ensol/entool/components/Section"
import { ConstructorAppLink } from "@ensol/entool/components/entities/Installation/ConstructorAppLink"
import { InsightsBadges } from "@ensol/entool/components/entities/Installation/InsightsBadges"
import {
  StepActions,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { Field } from "@ensol/entool/components/form/Field"
import { ExternalPlantSelect } from "@ensol/entool/components/form/Installation/ExternalPlantSelect"
import { FormCheckbox } from "@ensol/entool/components/form/Project/FormCheckbox"
import { FormField } from "@ensol/entool/components/form/Project/FormField"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { MonthlyReportSelect } from "@ensol/entool/pages/OpsProject/processes/FollowUp/components/MonthlyReportSelect"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const FollowUpForm = <Input extends Nullable<FollowUpStepInput>>({
  project,
  schema,
  initialValues,
  ...props
}: ProcessFormProps<Input> & StepActionsProps) => {
  const form = useProcessForm<Input>({
    schema,
    initialValues,
    projectId: project.id,
    processId: props.processId,
  })

  const { installation } = project
  const { slug, photovoltaicInstallation } = installation
  const currentStep = project[props.processId]

  const inverter =
    photovoltaicInstallation !== null
      ? getInverter(photovoltaicInstallation.inverterType)
      : null

  return (
    <StepActions
      project={project}
      validateStep={() => schema.parse(form.values)}
      {...props}
    >
      {currentStep === FollowUpStep.INSTALLATION_TO_VALIDATE && (
        <Section title="Vérifications" icon={IconListCheck} titleOrder={3}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <Stack gap="24">
              <FormCheckbox
                initialValues={initialValues}
                propertyName="isPanelsLayoutValid"
                {...form.getInputProps("isPanelsLayoutValid")}
              />
              <FormCheckbox
                initialValues={initialValues}
                propertyName="areClampsWorking"
                {...form.getInputProps("areClampsWorking")}
              />
              <FormCheckbox
                initialValues={initialValues}
                propertyName="hasNoAlerts"
                {...form.getInputProps("hasNoAlerts")}
              />
            </Stack>
          </SimpleGrid>
        </Section>
      )}
      <Section title="Suivi" icon={IconHomeCheck} titleOrder={3}>
        <SimpleGrid cols={2} spacing="32" mt="16">
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="followUpNotes"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("followUpNotes")}
              />
            </FormField>
            {inverter && (
              <FormField
                initialValues={initialValues}
                propertyName="followUpExternalPlantIdentifier"
                isRequired
              >
                <ExternalPlantSelect
                  w="400"
                  installationId={project.installationId}
                  inverterBrand={inverter.brand}
                  {...form.getInputProps("followUpExternalPlantIdentifier")}
                />
              </FormField>
            )}
            {project.followUpExternalPlantIdentifier &&
              photovoltaicInstallation && (
                <Field name="Application constructeur">
                  <ConstructorAppLink
                    inverterType={photovoltaicInstallation.inverterType}
                    externalPlantIdentifier={
                      project.followUpExternalPlantIdentifier
                    }
                    w={FIELD_WIDTH}
                  />
                </Field>
              )}
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="followUpProductionStartDate"
              isRequired
            >
              <DateInput
                w={FIELD_WIDTH}
                valueFormat={DISPLAY_DATE_FORMAT}
                clearable
                minDate={project.installationEndDate ?? undefined}
                {...form.getInputProps("followUpProductionStartDate")}
              />
            </FormField>
            <Field name="Conseil énergétique">
              <InsightsBadges insights={project.installation.insights} />
            </Field>
            <FormCheckbox
              initialValues={initialValues}
              propertyName="shouldSendMonthlyReports"
              {...form.getInputProps("shouldSendMonthlyReports")}
            />
          </Stack>
        </SimpleGrid>
      </Section>
      <Section
        title="Rapports mensuels"
        icon={IconFileAnalytics}
        titleOrder={3}
      >
        <MonthlyReportSelect installationId={installation.id} slug={slug} />
      </Section>
    </StepActions>
  )
}
