import { SimpleGrid } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"

import { CurrentType } from "@ensol/shared/material/currentType"
import { InverterType } from "@ensol/shared/material/photovoltaic/inverters"
import { PanelType } from "@ensol/shared/material/photovoltaic/panels"

import { Section } from "@ensol/entool/components/Section"
import { InfoBadge } from "@ensol/entool/pages/Installation/components/InfoBadge"
import {
  InverterOption,
  PanelOption,
} from "@ensol/entool/utils/installations/photovoltaic"

import { InverterSelector } from "./InverterSelector"
import { PanelSelector } from "./PanelSelector"

type MaterialSectionForm = {
  panelType: PanelType
  panelsCount: number
  inverterType: InverterType
}

type Props<Values> = {
  form: UseFormReturnType<Values>
  panelsOptions: PanelOption[]
  invertersOptions: InverterOption[]
  installedCapacity: number
  totalPanelsCount: number
  currentType: CurrentType
  isExtraPanelsInstallation?: boolean
}

export const MaterialSection = <Values extends MaterialSectionForm>({
  form,
  panelsOptions,
  invertersOptions,
  installedCapacity,
  totalPanelsCount,
  currentType,
  isExtraPanelsInstallation = false,
}: Props<Values>) => {
  const { panelType, panelsCount, inverterType } = form.values

  return (
    <Section
      title="Kit Photovoltaïque"
      Actions={<InfoBadge>Puissance totale: {installedCapacity} kWc</InfoBadge>}
    >
      <SimpleGrid cols={2} spacing={32}>
        <PanelSelector
          panelType={panelType}
          onChange={form.getInputProps("panelType").onChange}
          panelsCount={panelsCount}
          panelsOptions={panelsOptions}
        />
        <InverterSelector
          inverterType={inverterType}
          panelsCount={panelsCount}
          totalPanelsCount={totalPanelsCount}
          installedCapacity={installedCapacity}
          currentType={currentType}
          invertersOptions={invertersOptions}
          onChange={form.getInputProps("inverterType").onChange}
          isDisabled={isExtraPanelsInstallation}
        />
      </SimpleGrid>
    </Section>
  )
}
