import { BrandType, InverterBrandType } from "@ensol/shared/material/brands"
import { CurrentType } from "@ensol/shared/material/currentType"

export type InverterInfo = {
  name: string
  pictureUrl: string
  brand: InverterBrandType
  power: number
  price: number
  techDocUrl: string
  efficiency: number
  warranty: number
  isCentralInverter: boolean
  allowedCurrentTypes: CurrentType[]
  disabled: boolean
}

export const InverterType = {
  ENPHASE_IQ_8_AC: "ENPHASE_IQ_8_AC",
  ENPHASE_IQ_8_P: "ENPHASE_IQ_8_P",
  HUAWEI_SUN_2000_2_KTL_L_1: "HUAWEI_SUN_2000_2_KTL_L_1",
  HUAWEI_SUN_2000_3_KTL_L_1: "HUAWEI_SUN_2000_3_KTL_L_1",
  HUAWEI_SUN_2000_4_KTL_L_1: "HUAWEI_SUN_2000_4_KTL_L_1",
  HUAWEI_SUN_2000_5_KTL_L_1: "HUAWEI_SUN_2000_5_KTL_L_1",
  HUAWEI_SUN_2000_6_KTL_L_1: "HUAWEI_SUN_2000_6_KTL_L_1",
  HUAWEI_SUN_2000_3_KTL_M_1: "HUAWEI_SUN_2000_3_KTL_M_1",
  HUAWEI_SUN_2000_4_KTL_M_1: "HUAWEI_SUN_2000_4_KTL_M_1",
  HUAWEI_SUN_2000_5_KTL_M_1: "HUAWEI_SUN_2000_5_KTL_M_1",
  HUAWEI_SUN_2000_6_KTL_M_1: "HUAWEI_SUN_2000_6_KTL_M_1",
  HUAWEI_SUN_2000_8_KTL_M_1: "HUAWEI_SUN_2000_8_KTL_M_1",
  HUAWEI_SUN_2000_10_KTL_M_1: "HUAWEI_SUN_2000_10_KTL_M_1",
  HUAWEI_SUN_2000_8_KTL_L_C0: "HUAWEI_SUN_2000_8_KTL_L_C0",
  HUAWEI_SUN_2000_10_KTL_L_C0: "HUAWEI_SUN_2000_10_KTL_L_C0",
  HUAWEI_SUN_2000_12_KTL_M_B0: "HUAWEI_SUN_2000_12_KTL_M_B0",
  HUAWEI_SUN_2000_15_KTL_M_B0: "HUAWEI_SUN_2000_15_KTL_M_B0",
  HUAWEI_SUN_2000_17_KTL_M_B0: "HUAWEI_SUN_2000_17_KTL_M_B0",
  HUAWEI_SUN_2000_20_KTL_M_B0: "HUAWEI_SUN_2000_20_KTL_M_B0",
  HUAWEI_SUN_2000_25_KTL_M_B0: "HUAWEI_SUN_2000_25_KTL_M_B0",
  HUAWEI_SUN_2000_20_KTL_M_2: "HUAWEI_SUN_2000_20_KTL_M_2",
  HUAWEI_SUN_2000_30_KTL_M_3: "HUAWEI_SUN_2000_30_KTL_M_3",
  HUAWEI_SUN_2000_12_KTL_M_5: "HUAWEI_SUN_2000_12_KTL_M_5",
  HUAWEI_SUN_2000_15_KTL_M_5: "HUAWEI_SUN_2000_15_KTL_M_5",
  HUAWEI_SUN_2000_20_KTL_M_5: "HUAWEI_SUN_2000_20_KTL_M_5",
} as const

export type InverterType = keyof typeof InverterType

export const INVERTERS: Record<InverterType, InverterInfo> = {
  ENPHASE_IQ_8_AC: {
    brand: BrandType.ENPHASE,
    name: "Micro-Onduleur Enphase IQ8AC",
    pictureUrl: "/inverter.png",
    price: 170,
    power: 0.36,
    efficiency: 0.967,
    warranty: 25,
    allowedCurrentTypes: [CurrentType.SINGLE_PHASE, CurrentType.TRI_PHASE],
    isCentralInverter: false,
    techDocUrl:
      "https://enphase.com/fr-fr/download/iq8mc-iq8ac-iq8hc-microinverter-fiche-technique",
    disabled: false,
  },
  ENPHASE_IQ_8_P: {
    brand: BrandType.ENPHASE,
    name: "Micro-Onduleur Enphase IQ8P",
    pictureUrl: "/enphaseIQ8PInverter.png",
    price: 190,
    power: 0.475,
    efficiency: 0.97,
    warranty: 25,
    allowedCurrentTypes: [CurrentType.SINGLE_PHASE, CurrentType.TRI_PHASE],
    isCentralInverter: false,
    techDocUrl:
      "https://enphase.com/fr-fr/download/iq8p-microinverter-fiche-technique",
    disabled: false,
  },
  HUAWEI_SUN_2000_2_KTL_L_1: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei SUN2000-2KTL-L1",
    pictureUrl: "/huaweiInverter.png",
    price: 985,
    power: 2,
    efficiency: 0.967,
    warranty: 20,
    allowedCurrentTypes: [CurrentType.SINGLE_PHASE],
    isCentralInverter: true,
    techDocUrl:
      "https://support.huawei.com/enterprise/fr/doc/EDOC1100136173/b1978087/sun2000-technical-specifications",
    disabled: false,
  },
  HUAWEI_SUN_2000_3_KTL_L_1: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei SUN2000-3KTL-L1",
    pictureUrl: "/huaweiInverter.png",
    price: 1090,
    power: 3,
    efficiency: 0.973,
    warranty: 20,
    allowedCurrentTypes: [CurrentType.SINGLE_PHASE],
    isCentralInverter: true,
    techDocUrl:
      "https://support.huawei.com/enterprise/fr/doc/EDOC1100136173/b1978087/sun2000-technical-specifications",
    disabled: false,
  },
  HUAWEI_SUN_2000_4_KTL_L_1: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei SUN2000-4KTL-L1",
    pictureUrl: "/huaweiInverter.png",
    price: 1260,
    power: 4,
    efficiency: 0.975,
    warranty: 20,
    allowedCurrentTypes: [CurrentType.SINGLE_PHASE],
    isCentralInverter: true,
    techDocUrl:
      "https://support.huawei.com/enterprise/fr/doc/EDOC1100136173/b1978087/sun2000-technical-specifications",
    disabled: false,
  },
  HUAWEI_SUN_2000_5_KTL_L_1: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei SUN2000-5KTL-L1",
    pictureUrl: "/huaweiInverter.png",
    price: 1390,
    power: 5,
    efficiency: 0.978,
    warranty: 20,
    allowedCurrentTypes: [CurrentType.SINGLE_PHASE],
    isCentralInverter: true,
    techDocUrl:
      "https://support.huawei.com/enterprise/fr/doc/EDOC1100136173/b1978087/sun2000-technical-specifications",
    disabled: false,
  },
  HUAWEI_SUN_2000_6_KTL_L_1: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei SUN2000-6KTL-L1",
    pictureUrl: "/huaweiInverter.png",
    price: 1510,
    power: 6,
    efficiency: 0.978,
    warranty: 20,
    allowedCurrentTypes: [CurrentType.SINGLE_PHASE],
    isCentralInverter: true,
    techDocUrl:
      "https://support.huawei.com/enterprise/fr/doc/EDOC1100136173/b1978087/sun2000-technical-specifications",
    disabled: false,
  },

  HUAWEI_SUN_2000_3_KTL_M_1: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei Sun2000-3KTL-M1",
    pictureUrl: "/huaweiInverter.png",
    price: 1450,
    power: 3,
    efficiency: 0.967,
    warranty: 20,
    allowedCurrentTypes: [CurrentType.TRI_PHASE],
    isCentralInverter: true,
    techDocUrl:
      "https://support.huawei.com/enterprise/fr/doc/EDOC1100163578/dcce8835/sun2000-technical-specifications",
    disabled: false,
  },
  HUAWEI_SUN_2000_4_KTL_M_1: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei Sun2000-4KTL-M1",
    pictureUrl: "/huaweiInverter.png",
    price: 1700,
    power: 4,
    efficiency: 0.971,
    warranty: 20,
    allowedCurrentTypes: [CurrentType.TRI_PHASE],
    isCentralInverter: true,
    techDocUrl:
      "https://support.huawei.com/enterprise/fr/doc/EDOC1100163578/dcce8835/sun2000-technical-specifications",
    disabled: false,
  },
  HUAWEI_SUN_2000_5_KTL_M_1: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei Sun2000-5KTL-M1",
    pictureUrl: "/huaweiInverter.png",
    price: 1860,
    power: 5,
    efficiency: 0.975,
    warranty: 20,
    allowedCurrentTypes: [CurrentType.TRI_PHASE],
    isCentralInverter: true,
    techDocUrl:
      "https://support.huawei.com/enterprise/fr/doc/EDOC1100163578/dcce8835/sun2000-technical-specifications",
    disabled: false,
  },
  HUAWEI_SUN_2000_6_KTL_M_1: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei Sun2000-6KTL-M1",
    pictureUrl: "/huaweiInverter.png",
    price: 1950,
    power: 6,
    efficiency: 0.977,
    warranty: 20,
    allowedCurrentTypes: [CurrentType.TRI_PHASE],
    isCentralInverter: true,
    techDocUrl:
      "https://support.huawei.com/enterprise/fr/doc/EDOC1100163578/dcce8835/sun2000-technical-specifications",
    disabled: false,
  },
  HUAWEI_SUN_2000_8_KTL_M_1: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei Sun2000-8KTL-M1",
    pictureUrl: "/huaweiInverter.png",
    price: 2250,
    power: 8,
    efficiency: 0.98,
    warranty: 20,
    allowedCurrentTypes: [CurrentType.TRI_PHASE],
    isCentralInverter: true,
    techDocUrl:
      "https://support.huawei.com/enterprise/fr/doc/EDOC1100163578/dcce8835/sun2000-technical-specifications",
    disabled: false,
  },
  HUAWEI_SUN_2000_10_KTL_M_1: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei Sun2000-10KTL-M1",
    pictureUrl: "/huaweiInverter.png",
    price: 2450,
    power: 10,
    efficiency: 0.981,
    warranty: 20,
    allowedCurrentTypes: [CurrentType.TRI_PHASE],
    isCentralInverter: true,
    techDocUrl:
      "https://support.huawei.com/enterprise/fr/doc/EDOC1100163578/dcce8835/sun2000-technical-specifications",
    disabled: false,
  },
  HUAWEI_SUN_2000_8_KTL_L_C0: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei SUN2000-8K-LC0",
    pictureUrl: "/huaweiInverter.png",
    price: 1900,
    power: 8,
    efficiency: 0.978,
    warranty: 20,
    allowedCurrentTypes: [CurrentType.SINGLE_PHASE],
    isCentralInverter: true,
    techDocUrl:
      "https://support.huawei.com/enterprise/fr/doc/EDOC1100325442/e0e7d788/technical-specifications",
    disabled: false,
  },
  HUAWEI_SUN_2000_10_KTL_L_C0: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei Sun2000-10K-LC0",
    pictureUrl: "/huaweiInverter.png",
    price: 2450,
    power: 10,
    efficiency: 0.981,
    warranty: 20,
    allowedCurrentTypes: [CurrentType.SINGLE_PHASE],
    isCentralInverter: true,
    techDocUrl:
      "https://solar.huawei.com/en/professionals/all-products/SUN2000-8-10K-LC0/specs",
    disabled: false,
  },
  HUAWEI_SUN_2000_12_KTL_M_B0: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei SUN2000-12KTL-MB0",
    pictureUrl: "/huaweiInverter.png",
    power: 12,
    price: 1823.13,
    techDocUrl:
      "https://www.estg.eu/en-gb/p/01076368-006/huawei-inverter-sun200012kmb0?queryID=3cb908efe4ddc453029d9ecc9fff35af",
    efficiency: 0.979,
    warranty: 10,
    isCentralInverter: true,
    allowedCurrentTypes: [CurrentType.TRI_PHASE],
    disabled: false,
  },
  HUAWEI_SUN_2000_15_KTL_M_B0: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei SUN2000-15KTL-MB0",
    pictureUrl: "/huaweiInverter.png",
    power: 15,
    price: 2058.13,
    techDocUrl:
      "https://www.estg.eu/en-gb/p/01076370-006/huawei-inverter-sun200015kmb0?queryID=3cb908efe4ddc453029d9ecc9fff35af",
    efficiency: 0.98,
    warranty: 10,
    isCentralInverter: true,
    allowedCurrentTypes: [CurrentType.TRI_PHASE],
    disabled: false,
  },
  HUAWEI_SUN_2000_17_KTL_M_B0: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei SUN2000-17K-MB0",
    pictureUrl: "/huaweiInverter.png",
    power: 17,
    price: 2100.43,
    techDocUrl:
      "https://www.estg.eu/en-gb/p/01076371-006/huawei-inverter-sun200017kmb0?queryID=3cb908efe4ddc453029d9ecc9fff35af",
    efficiency: 0.981,
    warranty: 10,
    isCentralInverter: true,
    allowedCurrentTypes: [CurrentType.TRI_PHASE],
    disabled: false,
  },
  HUAWEI_SUN_2000_20_KTL_M_B0: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei SUN2000-20K-MB0",
    pictureUrl: "/huaweiInverter.png",
    power: 20,
    price: 2217.93,
    techDocUrl:
      "https://www.estg.eu/en-gb/p/01076372-006/huawei-inverter-sun200020kmb0?queryID=3cb908efe4ddc453029d9ecc9fff35af",
    efficiency: 0.981,
    warranty: 10,
    isCentralInverter: true,
    allowedCurrentTypes: [CurrentType.TRI_PHASE],
    disabled: false,
  },
  HUAWEI_SUN_2000_25_KTL_M_B0: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei SUN2000-25K-MB0",
    pictureUrl: "/huaweiInverter.png",
    power: 25,
    price: 2272.45,
    techDocUrl:
      "https://www.estg.eu/en-gb/p/01076373-006/huawei-inverter-sun200025kmb0?queryID=3cb908efe4ddc453029d9ecc9fff35af",
    efficiency: 0.982,
    warranty: 10,
    isCentralInverter: true,
    allowedCurrentTypes: [CurrentType.TRI_PHASE],
    disabled: false,
  },
  HUAWEI_SUN_2000_20_KTL_M_2: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei SUN2000-20KTL-M2",
    pictureUrl: "/huaweiInverter.png",
    price: 2850,
    power: 20,
    efficiency: 0.983,
    warranty: 20,
    allowedCurrentTypes: [CurrentType.TRI_PHASE],
    isCentralInverter: true,
    techDocUrl:
      "https://solar.huawei.com/download?p=%2F-%2Fmedia%2FSolarV4%2Fsolar-version2%2Fasia-pacific%2Fau%2Fprofessionals%2Fall-products%2Fresidential%2Fsmart-energy-controller%2FSUN2000-8_10_12_15_17_20KTL-M2.pdf",
    disabled: true,
  },
  HUAWEI_SUN_2000_30_KTL_M_3: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei SUN2000-30KTL-M3",
    pictureUrl: "/huaweiInverter.png",
    price: 3664,
    power: 30,
    efficiency: 0.984,
    warranty: 20,
    allowedCurrentTypes: [CurrentType.TRI_PHASE],
    isCentralInverter: true,
    techDocUrl:
      "https://solar.huawei.com/en-GB/download?p=%2F-%2Fmedia%2FSolar%2Fattachment%2Fpdf%2Ffr%2Fdatasheet%2FSUN2000-30-40KTL-M3.pdf",
    disabled: true,
  },
  HUAWEI_SUN_2000_12_KTL_M_5: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei SUN2000-12KTL-M5",
    pictureUrl: "/huaweiInverter.png",
    power: 12,
    price: 2500,
    techDocUrl:
      "https://www.estg.eu/fr-fr/amfile/file/download/file/7997/product/96324/",
    efficiency: 0.979,
    warranty: 20,
    isCentralInverter: true,
    allowedCurrentTypes: [CurrentType.TRI_PHASE],
    disabled: true,
  },
  HUAWEI_SUN_2000_15_KTL_M_5: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei SUN2000-15KTL-M5",
    pictureUrl: "/huaweiInverter.png",
    power: 15,
    price: 2600,
    techDocUrl:
      "https://www.estg.eu/fr-fr/amfile/file/download/file/7997/product/96324/",
    efficiency: 0.98,
    warranty: 20,
    isCentralInverter: true,
    allowedCurrentTypes: [CurrentType.TRI_PHASE],
    disabled: true,
  },
  HUAWEI_SUN_2000_20_KTL_M_5: {
    brand: BrandType.HUAWEI,
    name: "Onduleur Huawei SUN2000-20KTL-M5",
    pictureUrl: "/huaweiInverter.png",
    price: 2850,
    power: 20,
    efficiency: 0.981,
    warranty: 20,
    allowedCurrentTypes: [CurrentType.TRI_PHASE],
    isCentralInverter: true,
    techDocUrl:
      "https://solarshop.baywa-re.fr/core/media/media.nl?id=12870785&c=6376560&h=u8v6Efg7wAuUYRAe-dP_pZ33MZ5rF6AM_0AoYWZiICg3kn51&_xt=.pdf",
    disabled: true,
  },
}

export const getInverter = (inverterType: InverterType): InverterInfo =>
  INVERTERS[inverterType]
