import { UseFormReturnType } from "@mantine/form"

import { computeMonthlyBill } from "@ensol/shared/entities/installations/energy"

import { Field } from "@ensol/entool/components/form/Field"
import { NumberInput } from "@ensol/entool/components/form/NumberInput"

type Props<Values> = {
  form: UseFormReturnType<Values>
  linkBillAndConsumption: boolean
  isDisabled: boolean
}

export const YearlyConsumptionInput = <
  Values extends {
    yearlyConsumption: number
    monthlyBill: number
  },
>({
  form,
  linkBillAndConsumption,
  isDisabled,
}: Props<Values>) => (
  <Field name="Consommation annuelle">
    <NumberInput
      w={200}
      min={0}
      step={1000}
      ml="auto"
      suffix=" kWh/an"
      isNullable={false}
      disabled={isDisabled}
      data-test="yearlyConsumption"
      {...form.getInputProps("yearlyConsumption")}
      onChange={(value) => {
        form.getInputProps("yearlyConsumption").onChange(value ?? 0)
        if (linkBillAndConsumption) {
          form
            .getInputProps("monthlyBill")
            .onChange(computeMonthlyBill(value ?? 0))
        }
      }}
    />
  </Field>
)
