import {
  Title,
  SimpleGrid,
  Group,
  ModalBaseProps,
  Paper,
  Stack,
} from "@mantine/core"
import { TablerIcon } from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { EmailEvent } from "@ensol/shared/entities/projects/emails"
import { ProcessId } from "@ensol/shared/entities/projects/processes"
import { getZIndex } from "@ensol/shared/styles/zIndex"

import { Section } from "@ensol/entool/components/Section"
import { ProcessesMilestoneReport } from "@ensol/entool/components/entities/Project/ProcessView/MilestoneReport"
import { StepAction } from "@ensol/entool/components/entities/Project/StepActions/StepAction"
import {
  PROJECT_HEADER_HEIGHT_IN_PX,
  useResponsiveDimensions,
} from "@ensol/entool/styles/constants"

import { EmailSender } from "./EmailSender"

export type StepActionsProps = {
  project: ProjectResponses.Project
  processId: ProcessId
  actions: StepActionProps[]
  emails?: { eventName: EmailEvent; label: string }[]
  relatedProcesses?: ProcessId[]
  validateStep?: () => void
  children?: React.ReactNode
}

export type CustomActionModalProps = {
  project: ProjectResponses.Project
  processId: ProcessId
} & ModalBaseProps

type BaseStepActionProps = {
  label?: string
  color?: string
  Icon?: TablerIcon
  validateAction?: (project: ProjectResponses.Project) => void
  onClick?: (project: ProjectResponses.Project) => void
}

type CustomStepActionProps = BaseStepActionProps & {
  action?: never
  CustomActionModal: React.FC<CustomActionModalProps>
  confirmationMessage?: never
}

type StandardStepActionProps = BaseStepActionProps & {
  action: string
  CustomActionModal?: never
  confirmationMessage?: string
}

export type StepActionProps = CustomStepActionProps | StandardStepActionProps

export type StepComponentProps = Pick<StepActionsProps, "project" | "processId">

export const StepActions = ({
  project,
  processId,
  actions,
  emails = [],
  relatedProcesses = [],
  validateStep,
  children,
}: StepActionsProps) => {
  const { navbarHeight } = useResponsiveDimensions()

  return (
    <Stack gap="24">
      <Stack
        mx={-2}
        pt="8"
        bg="gray.0"
        pos="sticky"
        top={PROJECT_HEADER_HEIGHT_IN_PX + navbarHeight}
        style={{ zIndex: getZIndex("stickyHeader") }}
      >
        <Paper shadow="xs" py="16" px="24" radius={8}>
          <Group justify="space-between" align="center">
            <Group>
              <Title order={2} display="flex" style={{ gap: 8 }}>
                Étape en cours
              </Title>
              {emails.map(({ eventName, label }) => (
                <EmailSender
                  key={eventName}
                  eventName={eventName}
                  project={project}
                >
                  {label}
                </EmailSender>
              ))}
            </Group>
            <Group>
              {actions.map((action) => (
                <StepAction
                  key={action.label}
                  project={project}
                  processId={processId}
                  emails={emails}
                  validateStep={validateStep}
                  actionProps={action}
                />
              ))}
            </Group>
          </Group>
        </Paper>
      </Stack>
      {relatedProcesses.length > 0 && (
        <Section title="Processus lié(s) à cette étape" titleOrder={3}>
          <SimpleGrid cols={2} spacing="16">
            <ProcessesMilestoneReport
              processes={relatedProcesses}
              project={project}
            />
          </SimpleGrid>
        </Section>
      )}
      {children}
    </Stack>
  )
}
