import {
  MantineThemeOverride,
  MantineThemeColorsOverride,
  MantineTheme,
  ButtonProps,
} from "@mantine/core"
import _ from "lodash"

import { colors } from "@ensol/shared/styles/colors"

const convertColorsIntoMantineThemeOverride = (colors: {
  [name: string]: { [shade: number]: string }
}): MantineThemeColorsOverride =>
  _.mapValues(colors, (shades) => Object.values(shades))

const breakpoints = {
  xs: "480px",
  sm: "768px",
  md: "1024px",
  lg: "1280px",
  xl: "1440px",
  xxl: "1600px",
}
type Breakpoint = keyof typeof breakpoints

export const devices = Object.entries(breakpoints).reduce(
  (devices, [device, breakpoint]) => ({
    ...devices,
    [device]: `(max-width: ${breakpoint})`,
  }),
  {} as Record<Breakpoint, string>,
)

export const theme: MantineThemeOverride = {
  colors: convertColorsIntoMantineThemeOverride(colors),
  breakpoints,
  fontSizes: {
    xs: "10px",
    sm: "12px",
    md: "14px",
    lg: "16px",
    xl: "18px",
  },
  headings: {
    fontWeight: "500",
    sizes: {
      h1: { fontSize: "18px" },
      h2: { fontSize: "18px" },
      h3: { fontSize: "16px" },
      h4: { fontSize: "14px" },
      h5: { fontSize: "12px" },
      h6: { fontSize: "10px" },
    },
  },
  primaryShade: 4,
  primaryColor: "blue",
  components: {
    Button: {
      styles: (_theme: MantineTheme, props: ButtonProps) => ({
        label: { fontSize: props.size === "xs" ? 12 : undefined },
      }),
    },
    ActionIcon: {
      defaultProps: {
        variant: "subtle",
        size: "sm",
      },
    },
    ThemeIcon: {
      defaultProps: {
        variant: "subtle",
        size: "sm",
      },
    },
    Card: {
      styles: (theme: MantineTheme) => ({
        root: {
          borderRadius: 8,
          border: `1px solid ${theme.colors.gray[2]}`,
        },
      }),
    },
    Notification: {
      styles: {
        description: { whiteSpace: "break-spaces" },
      },
    },
    Badge: {
      styles: {
        label: { textTransform: "none" },
      },
    },
    Table: {
      styles: {
        th: { padding: 12 },
        td: { padding: 16, paddingInline: 12 },
      },
    },
    Modal: {
      styles: {
        title: { fontSize: 18, fontWeight: "bold" },
      },
    },
    Paper: {
      defaultProps: {
        shadow: "none",
        radius: 8,
      },
    },
    Breadcrumbs: {
      styles: (theme: MantineTheme) => ({
        separator: {
          fontSize: 18,
          fontWeight: 500,
          color: theme.colors.gray[4],
        },
      }),
    },
  },
}
