import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { cetelemFormSchema } from "@ensol/types/forms/projects/payment"

import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"

export const getCetelemFormInitialValues = (
  project: ProjectResponses.Project,
) => ({
  ...getInitialValues(cetelemFormSchema.innerType(), project),
  cetelemFinancingRequestAmount: project.cetelemFinancingRequestAmount
    ? project.cetelemFinancingRequestAmount
    : (project.installation.activeQuote?.details?.totals.finalCost ?? null),
})
