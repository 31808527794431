import { useForm, zodResolver } from "@mantine/form"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import {
  updateTownHallSchema,
  HouseUpdateType,
} from "@ensol/types/forms/houses"

import { useUpdateHouseMutation } from "@ensol/entool/queries/houses"
import { useDebounce } from "@ensol/entool/utils/helpers/useDebounce"

export const useTownHallForm = (house: HousesResponses.House) => {
  const { mutateAsync: updateHouse } = useUpdateHouseMutation(house.id)

  const debouncedUpdateHouse = useDebounce(updateHouse, 250)

  const townHallForm = useForm({
    validateInputOnChange: true,
    validate: zodResolver(updateTownHallSchema),
    onValuesChange: async (values) => debouncedUpdateHouse(values),
    initialValues: {
      updateType: HouseUpdateType.UPDATE_TOWN_HALL,
      townHallId: house.townHallId,
    },
  })
  return townHallForm
}
