import { ColorGroup } from "@ensol/shared/styles/colors"

export const useInstallationDiagnostic = (
  yearlyProduction: number,
  yearlyConsumption: number,
  hasBattery: boolean,
): { label: string; color: ColorGroup } => {
  const productionConsumptionPercentage = Math.round(
    (yearlyProduction / yearlyConsumption) * 100,
  )
  const targetPercentage = hasBattery ? 100 : 85
  const tolerancePercentage =
    yearlyConsumption < 6000 ? 10 : yearlyConsumption > 20000 ? 5 : 7

  if (
    productionConsumptionPercentage >
    targetPercentage + tolerancePercentage
  ) {
    return {
      label: "Surévalué",
      color: "yellow",
    }
  }

  if (
    productionConsumptionPercentage <
    targetPercentage - tolerancePercentage
  ) {
    return {
      label: "Sous-optimisé",
      color: "red",
    }
  }

  return {
    label: "Optimal",
    color: "green",
  }
}
