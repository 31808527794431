import { Group, Text } from "@mantine/core"

import { DownloadFileButton } from "@ensol/entool/components/entities/File/DownloadFileButton"

type Props = {
  filePath: string
  fileName: string
}

export const DownloadDocument = ({ filePath, fileName }: Props) => (
  <Group gap={8}>
    <DownloadFileButton filePath={filePath} fileName={fileName} type="pdf" />
    <Text fw="bold">{fileName}</Text>
  </Group>
)
