import { createCanvas } from "canvas"

import { formatNumber } from "@ensol/shared/utils/format"

export const ENERGY_PER_HOUR_SCALE = {
  y: {
    title: {
      display: true,
      text: "Énergie (Wh)",
      font: { weight: "bold" as const, size: 14 },
      padding: 8,
    },
    border: { display: false },
    grid: { display: false },
    min: 0,
    ticks: {
      stepSize: 250,
      callback: (value: string | number) => formatNumber(Number(value)),
    },
  },
  x: {
    type: "linear" as const,
    display: true,
    border: { display: false },
    grid: { display: false },
    min: 0,
    max: 23,
    ticks: {
      stepSize: 5,
      callback: (value: string | number) => `${value}h`,
    },
  },
}

export const createHatchedPattern = ({
  stripeColor,
  backgroundColor,
}: {
  stripeColor: string
  backgroundColor?: string
}) => {
  const width = 6
  const height = 6
  const canvas = createCanvas(width, height)

  const context = canvas.getContext("2d")
  context.strokeStyle = stripeColor
  if (backgroundColor) {
    context.fillStyle = backgroundColor
    context.fillRect(0, 0, width, height)
  }
  context.lineWidth = 4
  context.beginPath()
  context.moveTo(0, 0)
  context.lineTo(0, 6)
  context.stroke()
  return context.createPattern(canvas, "repeat") ?? undefined
}
