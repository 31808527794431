import frLocale from "@fullcalendar/core/locales/fr"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import FullCalendar from "@fullcalendar/react"
import resourceTimelinePlugin from "@fullcalendar/resource-timeline"
import timeGridPlugin from "@fullcalendar/timegrid"
import { Stack } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import dayjs from "dayjs"
import _ from "lodash"

import { GoogleCalendarEventType } from "@ensol/types/endpoints/google"

import { ProjectEventType } from "@ensol/shared/entities/projects/events"
import { devices } from "@ensol/shared/styles/theme"

import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { EventModal } from "@ensol/entool/pages/ProjectsEvents/Calendar/EventModal"
import { Legend } from "@ensol/entool/pages/ProjectsEvents/Calendar/Legend"
import {
  formatProjectsEvents,
  formatPublicHolidayEvents,
  formatGoogleCalendarEvents,
} from "@ensol/entool/pages/ProjectsEvents/Calendar/formatEvents"
import { useCalendar } from "@ensol/entool/pages/ProjectsEvents/Calendar/useCalendar"
import {
  useGetGoogleCalendarEventsQuery,
  useGetPublicHolidaysQuery,
} from "@ensol/entool/queries/calendars"
import {
  useGetInstallersEventsQuery,
  useInstallersOptions,
} from "@ensol/entool/queries/installers"
import { useGetProjectsEventsQuery } from "@ensol/entool/queries/projects"
import { CONTENT_PADDING_IN_PX } from "@ensol/entool/styles/constants"

import { useCalendarShortcuts } from "./useCalendarShortcuts"
import { useEventUpdate } from "./useEventUpdate"

type Props = {
  eventTypes: ProjectEventType[]
  installers: string[]
  coverageZones: string[]
  showInstallerEvents: boolean
  showEventsWithoutInstaller: boolean
  showWeekEnds: boolean
  showPublicHolidays: boolean
  showSavEvents: boolean
  showAuditEvents: boolean
}

export const EMPTY_INSTALLER_RESOURCE_ID = "empty-installer"

export const Calendar = ({
  eventTypes,
  installers,
  coverageZones,
  showInstallerEvents,
  showEventsWithoutInstaller,
  showWeekEnds,
  showPublicHolidays,
  showSavEvents,
  showAuditEvents,
}: Props) => {
  const isMobile = useMediaQuery(devices.sm)
  const calendarRef = useCalendarShortcuts()
  const updateEvent = useEventUpdate()
  const {
    currentCalendarView,
    onDatesSet,
    openedEventId,
    openEvent,
    closeEvent,
    period,
  } = useCalendar()
  const installersOptions = useInstallersOptions()

  const installersFilter =
    currentCalendarView === "resourceTimelineMonth"
      ? installersOptions.map(({ value }) => value)
      : installers

  const resources = installersOptions
    .filter(
      ({ metadata }) =>
        _.intersection(metadata?.coverageZones, coverageZones).length > 0,
    )
    .map(({ value, label, metadata }) => ({
      id: value,
      title: label,
      priority: metadata?.priority,
    }))

  const projectsEventsQuery = useGetProjectsEventsQuery({
    types: eventTypes,
    installers: installersFilter,
    showEventsWithoutInstaller,
    period,
  })
  const installersEventsQuery = useGetInstallersEventsQuery({
    installers: installersFilter,
    period,
  })
  const savEventsQuery = useGetGoogleCalendarEventsQuery(
    showSavEvents,
    period,
    GoogleCalendarEventType.SAV,
  )
  const auditEventsQuery = useGetGoogleCalendarEventsQuery(
    showAuditEvents,
    period,
    GoogleCalendarEventType.AUDIT,
  )
  const publicHolidaysQuery = useGetPublicHolidaysQuery(showPublicHolidays)

  return (
    <QueryWrapper query={projectsEventsQuery} returnEmptyResults>
      {({ data: projectsEventsResponse }) => (
        <QueryWrapper query={installersEventsQuery} returnEmptyResults>
          {({ data: installersEventsResponse }) => {
            const projectsEvents = formatProjectsEvents(projectsEventsResponse)
            const installersEvents = showInstallerEvents
              ? formatGoogleCalendarEvents({
                  events: installersEventsResponse,
                  calendarType: GoogleCalendarEventType.INSTALLER,
                })
              : []
            const publicHolidaysEvents =
              showPublicHolidays && publicHolidaysQuery.isSuccess
                ? formatPublicHolidayEvents(publicHolidaysQuery.data)
                : []

            const savEvents =
              showSavEvents && savEventsQuery.isSuccess
                ? formatGoogleCalendarEvents({
                    events: savEventsQuery.data,
                    calendarType: GoogleCalendarEventType.SAV,
                  })
                : []

            const auditEvents =
              showAuditEvents && auditEventsQuery.isSuccess
                ? formatGoogleCalendarEvents({
                    events: auditEventsQuery.data,
                    calendarType: GoogleCalendarEventType.AUDIT,
                  })
                : []

            const openedEvent = projectsEvents.find(
              ({ id }) => id === openedEventId,
            )

            return (
              <Stack
                flex="1"
                h="100%"
                justify="center"
                pl={CONTENT_PADDING_IN_PX}
                pr={isMobile ? CONTENT_PADDING_IN_PX : 0}
              >
                {openedEvent && (
                  <EventModal event={openedEvent} onClose={closeEvent} />
                )}
                <FullCalendar
                  ref={calendarRef}
                  schedulerLicenseKey={
                    import.meta.env.VITE_FULLCALENDAR_LICENSE_KEY
                  }
                  height="100%"
                  plugins={[
                    timeGridPlugin,
                    dayGridPlugin,
                    interactionPlugin,
                    resourceTimelinePlugin,
                  ]}
                  locales={[frLocale]}
                  initialView={currentCalendarView}
                  datesSet={onDatesSet}
                  headerToolbar={
                    isMobile
                      ? {
                          start: "title",
                          end: "dayGridMonth,timeGridWeek,resourceTimelineMonth,prev,next",
                        }
                      : {
                          start:
                            "dayGridMonth,timeGridWeek,resourceTimelineMonth",
                          center: "title",
                          end: "today,prev,next",
                        }
                  }
                  buttonText={{
                    resourceTimelineMonth: "Installateurs",
                  }}
                  resourceAreaWidth={180}
                  slotMinWidth={150}
                  slotLabelFormat={
                    currentCalendarView === "timeGridWeek"
                      ? {
                          hour: "numeric",
                          minute: "2-digit",
                          omitZeroMinute: true,
                          meridiem: false,
                        }
                      : [{ weekday: "long", day: "numeric" }]
                  }
                  weekends={showWeekEnds}
                  expandRows={true}
                  slotMinTime="07:00:00"
                  slotMaxTime="19:00:00"
                  scrollTime={
                    openedEvent
                      ? { days: dayjs(openedEvent.start).get("date") }
                      : null
                  }
                  allDayText=""
                  events={[
                    ...projectsEvents,
                    ...installersEvents,
                    ...publicHolidaysEvents,
                    ...savEvents,
                    ...auditEvents,
                  ]}
                  resourceOrder="priority"
                  resources={[
                    ...(showEventsWithoutInstaller
                      ? [
                          {
                            id: EMPTY_INSTALLER_RESOURCE_ID,
                            title: "Sans installateur",
                            priority: Number.MAX_SAFE_INTEGER,
                          },
                        ]
                      : []),
                    ...resources,
                  ]}
                  eventClick={openEvent}
                  eventResizableFromStart={true}
                  eventDrop={updateEvent}
                  eventResize={updateEvent}
                />
                {!isMobile && <Legend />}
              </Stack>
            )
          }}
        </QueryWrapper>
      )}
    </QueryWrapper>
  )
}
