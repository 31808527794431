import { Group, Stack } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconDeviceFloppy } from "@tabler/icons-react"
import { useLocalStorage } from "@uidotdev/usehooks"
import { useEffect, useMemo } from "react"
import { Helmet } from "react-helmet-async"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import {
  generalUpdateHouseSchema,
  GeneralUpdateHouseInput,
  HouseUpdateType,
} from "@ensol/types/forms/houses"
import {
  InstallationFormInput,
  updateInstallationSchema,
} from "@ensol/types/forms/installations"
import {
  HouseSignedInstallations,
  MonthlyConsumptions,
} from "@ensol/types/installation"
import {
  ConsumptionDataSource,
  ProductionDataSource,
} from "@ensol/types/simulation"

import { getCoords } from "@ensol/shared/entities/houses/coords"
import { HouseEquipment } from "@ensol/shared/entities/houses/equipments"
import { DEFAULT_ELECTRICITY_PRICE_ANNUAL_RAISE } from "@ensol/shared/entities/installations/energy"
import { computeTotalDiscount } from "@ensol/shared/entities/installations/savings"
import { getFullName } from "@ensol/shared/entities/prospects"

import { ContentContainer } from "@ensol/entool/components/layout/ContentContainer"
import { useInstallationEnergyStats } from "@ensol/entool/queries/energy"
import {
  CONTENT_PADDING_IN_PX,
  useResponsiveDimensions,
} from "@ensol/entool/styles/constants"
import { getSubformActions } from "@ensol/entool/utils/form/subforms"
import { isFormValid } from "@ensol/entool/utils/form/validation"
import { InstallationPageSection } from "@ensol/entool/utils/installations/useInstallationMenu"
import { useBeforeLeavePage } from "@ensol/entool/utils/navigation"

import { InstallationHeader } from "./Header"
import {
  InstallationOverview,
  OVERVIEW_WIDTH_IN_PX,
} from "./components/Overview"
import { HouseDescriptionForm } from "./sections/1-Description"
import { ConsumptionSection } from "./sections/2-Consumption"
import { HouseEquipmentsForm } from "./sections/3-Equipments"
import { ExtraPanelsInstallation } from "./sections/4-Photovoltaic/ExtraPanelsInstallation"
import { PhotovoltaicInstallation } from "./sections/4-Photovoltaic/PhotovoltaicInstallation"
import { BatteryInstallation } from "./sections/5-Battery/BatteryInstallation"
import { EvChargerInstallation } from "./sections/6-EvCharger/EvChargerInstallation"
import { Settings } from "./sections/7-Settings"
import { AutoConsumption } from "./sections/8-AutoConsumption"
import { Savings } from "./sections/9-Savings"
import { useInstallationNavigation } from "./useInstallationNavigation"
import { useSimulationResults } from "./useSimulationResults"
import { useUpdateProductsCount } from "./useUpdateProductsCount"

type Props = {
  installationId: string
  installationDate: Date
  name: string
  slug: string
  productionDataSource: ProductionDataSource
  consumptionDataSource: ConsumptionDataSource
  yearlyConsumption: number
  monthlyConsumptions?: MonthlyConsumptions
  monthlyBill: number
  manualAutoConsumptionPercent: number
  computedAutoConsumptionPercent: number
  horizonProfileFilePath: string | null
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation | null
  extraPanelsInstallation: InstallationsResponses.ExtraPanelsInstallation | null
  batteryInstallation: InstallationsResponses.BatteryInstallation | null
  evChargerInstallation: InstallationsResponses.EvChargerInstallation | null
  productsCount: number
  signedInstallations?: HouseSignedInstallations
  panelsLayout?: InstallationsResponses.Installation["panelsLayout"]
  referrerId?: string | null
  discount?: number | null
  prospect: InstallationsResponses.Installation["prospect"]
  house: HousesResponses.House<{
    include: {
      switchgridConsent: true
      switchgridOrder: true
      roofSections: true
    }
  }>
  projectId: string | null
  onSave: (input: {
    house: GeneralUpdateHouseInput
    installation: InstallationFormInput
  }) => void
  isSaving: boolean
  isUpsell: boolean
}

export const Installation = ({
  installationId,
  installationDate,
  name,
  slug,
  productionDataSource,
  consumptionDataSource,
  monthlyBill,
  yearlyConsumption,
  monthlyConsumptions,
  manualAutoConsumptionPercent,
  computedAutoConsumptionPercent,
  horizonProfileFilePath,
  photovoltaicInstallation,
  extraPanelsInstallation,
  signedInstallations,
  batteryInstallation,
  evChargerInstallation,
  panelsLayout,
  discount,
  referrerId,
  prospect,
  house,
  projectId,
  onSave,
  isSaving,
  productsCount,
  isUpsell,
}: Props) => {
  const { contentPadding } = useResponsiveDimensions()
  const { currentSection } = useInstallationNavigation()
  const [isOverviewVisible, setIsOverviewVisible] = useLocalStorage(
    "isInstallationOverviewVisible",
    true,
  )

  const houseForm = useForm<GeneralUpdateHouseInput>({
    validate: zodResolver(generalUpdateHouseSchema),
    validateInputOnChange: true,
    initialValues: {
      lat: house.lat,
      lng: house.lng,
      placeId: house.placeId,
      address: house.address,
      streetAddress: house.streetAddress,
      city: house.city,
      postcode: house.postcode,
      country: house.country,
      region: house.region,
      updateType: HouseUpdateType.GENERAL,
      constructionYear: house.constructionYear,
      currentType: house.currentType,
      hasLinky: house.hasLinky,
      roofType: house.roofType,
      surface: house.surface,
      floors: house.floors,
      isIndividualHouse: house.isIndividualHouse,
      hasAsbestos: house.hasAsbestos,
      isHistoricBuilding: house.isHistoricBuilding,
      isAtticAccessible: house.isAtticAccessible,
      roofFramingMaterial: house.roofFramingMaterial,
      equipments: house.equipments as HouseEquipment[],
    },
  })

  const installationForm = useForm<InstallationFormInput>({
    validate: zodResolver(updateInstallationSchema),
    validateInputOnChange: true,
    initialValues: {
      name,
      productionDataSource,
      consumptionDataSource,
      horizonProfileFilePath,
      monthlyBill,
      yearlyConsumption,
      monthlyConsumptions: monthlyConsumptions ?? [],
      manualAutoConsumptionPercent,
      computedAutoConsumptionPercent,
      photovoltaicInstallation,
      extraPanelsInstallation,
      batteryInstallation,
      evChargerInstallation,
      discount,
      referrerId,
      houseId: house.id,
      prospectId: prospect.id,
      productsCount,
      electricityPriceAnnualRaise: DEFAULT_ELECTRICITY_PRICE_ANNUAL_RAISE,
      isUpsell,
    },
  })

  useUpdateProductsCount(installationForm)

  const totalDiscount = useMemo(
    () => computeTotalDiscount(installationForm.values.discount),
    [installationForm.values.discount],
  )

  const masterInstallationId =
    signedInstallations?.photovoltaicInstallation?.installationId ??
    installationId

  const { data: energyStats, isPending: isEnergyStatsLoading } =
    useInstallationEnergyStats({
      installationId: masterInstallationId,
      coords: getCoords(houseForm.values),
      installation: installationForm.values,
      switchgridOrderId: house.switchgridOrder?.orderId ?? null,
      signedInstallations,
    })

  const isInstallationValid =
    isFormValid(houseForm) && isFormValid(installationForm)

  const simulationResults = useSimulationResults({
    energyStats,
    autoConsumptionPercent:
      installationForm.values.manualAutoConsumptionPercent,
    house,
    installationDate,
    installationValues: installationForm.values,
    isInstallationValid,
    signedInstallations,
  })

  const handleSave = async () => {
    onSave({
      house: houseForm.values,
      installation: installationForm.values,
    })
    installationForm.resetDirty()
    houseForm.resetDirty()
  }

  useBeforeLeavePage(
    ({ currentLocation, nextLocation }) =>
      currentLocation.pathname !== nextLocation.pathname &&
      (installationForm.isDirty() || houseForm.isDirty()),
    [
      {
        label: "Enregistrer et quitter",
        leftSection: <IconDeviceFloppy size={18} />,
        onClick: handleSave,
      },
    ],
  )

  const houseWithFormValues = { ...house, ...houseForm.values }

  // Update auto consumption percent (manual and computed)
  // to the new new computed value if it changed
  useEffect(() => {
    if (
      energyStats &&
      energyStats.autoConsumptionPercent !==
        installationForm.values.computedAutoConsumptionPercent
    ) {
      installationForm.setValues({
        computedAutoConsumptionPercent: energyStats.autoConsumptionPercent,
        manualAutoConsumptionPercent: energyStats.autoConsumptionPercent,
      })
    }
  }, [energyStats, installationForm])

  const areReportsOutdated = installationForm.isDirty() || houseForm.isDirty()

  return (
    <Stack flex="1" gap="0" mih="100vh" h="fit-content">
      <Helmet>
        <title>
          {getFullName(prospect)} - {name} | Entool
        </title>
      </Helmet>
      <InstallationHeader
        installationId={installationId}
        projectId={projectId}
        name={installationForm.values.name}
        address={houseForm.values.address}
        prospect={prospect}
        form={installationForm}
        isInstallationValid={isInstallationValid}
        onSave={handleSave}
        isSaving={isSaving}
        isOverviewVisible={isOverviewVisible}
        toggleOverview={() => setIsOverviewVisible((isVisible) => !isVisible)}
      />
      <ContentContainer flex="1" m="0" pt={CONTENT_PADDING_IN_PX} bg="gray.0">
        <Group flex="1" align="flex-start" justify="space-between" pos="sticky">
          <Stack
            w={
              isOverviewVisible
                ? `calc(100% - ${OVERVIEW_WIDTH_IN_PX + contentPadding}px)`
                : "100%"
            }
          >
            <Stack gap="xl" mb={24}>
              <SectionWrapper
                section={InstallationPageSection.DESCRIPTION}
                currentActiveSection={currentSection}
              >
                <HouseDescriptionForm
                  form={houseForm}
                  panelsLayout={panelsLayout}
                />
              </SectionWrapper>
              <SectionWrapper
                section={InstallationPageSection.EQUIPMENTS}
                currentActiveSection={currentSection}
              >
                <HouseEquipmentsForm
                  form={houseForm}
                  signedInstallations={signedInstallations}
                />
              </SectionWrapper>
              <SectionWrapper
                section={InstallationPageSection.CONSUMPTION}
                currentActiveSection={currentSection}
              >
                <ConsumptionSection
                  form={installationForm}
                  house={houseWithFormValues}
                  masterInstallationId={masterInstallationId}
                />
              </SectionWrapper>
              <SectionWrapper
                section={InstallationPageSection.PHOTOVOLTAIC}
                currentActiveSection={currentSection}
              >
                {installationForm.values.photovoltaicInstallation ? (
                  <PhotovoltaicInstallation
                    photovoltaicInstallation={
                      installationForm.values.photovoltaicInstallation
                    }
                    house={houseWithFormValues}
                    panelsLayout={panelsLayout}
                    yearlyConsumption={
                      installationForm.values.yearlyConsumption
                    }
                    totalDiscount={totalDiscount}
                    signedInstallations={signedInstallations}
                    {...getSubformActions(
                      installationForm,
                      "photovoltaicInstallation",
                    )}
                  />
                ) : (
                  signedInstallations?.photovoltaicInstallation && (
                    <ExtraPanelsInstallation
                      extraPanelsInstallation={
                        installationForm.values.extraPanelsInstallation
                      }
                      house={houseWithFormValues}
                      panelsLayout={panelsLayout}
                      yearlyConsumption={
                        installationForm.values.yearlyConsumption
                      }
                      signedInstallations={signedInstallations}
                      {...getSubformActions(
                        installationForm,
                        "extraPanelsInstallation",
                      )}
                    />
                  )
                )}
              </SectionWrapper>
              <SectionWrapper
                section={InstallationPageSection.BATTERY}
                currentActiveSection={currentSection}
              >
                <BatteryInstallation
                  installationValues={installationForm.values}
                  isInstallationValid={isInstallationValid}
                  installationDate={installationDate}
                  masterInstallationId={masterInstallationId}
                  house={houseWithFormValues}
                  signedInstallations={signedInstallations}
                  batteryInstallation={
                    installationForm.values.batteryInstallation
                  }
                  inverterType={
                    installationForm.values.photovoltaicInstallation
                      ?.inverterType
                  }
                  {...getSubformActions(
                    installationForm,
                    "batteryInstallation",
                  )}
                />
              </SectionWrapper>
              <SectionWrapper
                section={InstallationPageSection.EV_CHARGER}
                currentActiveSection={currentSection}
              >
                <EvChargerInstallation
                  evChargerInstallation={
                    installationForm.values.evChargerInstallation
                  }
                  {...getSubformActions(
                    installationForm,
                    "evChargerInstallation",
                  )}
                  productsCount={installationForm.values.productsCount}
                  monthlyBill={installationForm.values.monthlyBill}
                  yearlyProduction={installationForm.values.yearlyConsumption}
                  billReductionRatio={simulationResults?.billReductionRatio}
                />
              </SectionWrapper>
              <SectionWrapper
                section={InstallationPageSection.SETTINGS}
                currentActiveSection={currentSection}
              >
                <Settings
                  form={installationForm}
                  house={houseWithFormValues}
                  slug={slug}
                />
              </SectionWrapper>
              <SectionWrapper
                section={InstallationPageSection.AUTO_CONSUMPTION}
                currentActiveSection={currentSection}
              >
                <AutoConsumption
                  slug={slug}
                  energyStats={energyStats}
                  isEnergyStatsLoading={isEnergyStatsLoading}
                  areReportsOutdated={areReportsOutdated}
                />
              </SectionWrapper>
              <SectionWrapper
                section={InstallationPageSection.SAVINGS}
                currentActiveSection={currentSection}
              >
                <Savings
                  slug={slug}
                  simulationResults={simulationResults}
                  setManualAutoConsumptionPercent={(value) => {
                    installationForm.setValues({
                      manualAutoConsumptionPercent: value,
                    })
                    // We reset the dirty state here to avoid the reports
                    // to be outdated because of this property as they are updated
                    // in real time with manualAutoConsumptionPercent
                    installationForm.resetDirty()
                  }}
                  installationValues={installationForm.values}
                  house={houseWithFormValues}
                  signedInstallations={signedInstallations}
                  isInstallationValid={isInstallationValid}
                  installationDate={installationDate}
                  energyStats={energyStats}
                  isEnergyStatsLoading={isEnergyStatsLoading}
                  areReportsOutdated={areReportsOutdated}
                />
              </SectionWrapper>
            </Stack>
          </Stack>
          {isOverviewVisible && (
            <InstallationOverview
              house={houseWithFormValues}
              prospect={prospect}
              installationId={installationId}
              installationDate={installationDate}
              form={installationForm}
              isInstallationValid={isInstallationValid}
              areEnergyStatsAvailable={
                energyStats !== undefined && energyStats !== null
              }
              isLoading={isEnergyStatsLoading}
              simulationResults={simulationResults}
            />
          )}
        </Group>
      </ContentContainer>
    </Stack>
  )
}

const SectionWrapper = ({
  section,
  currentActiveSection,
  children,
}: {
  section: InstallationPageSection
  currentActiveSection: InstallationPageSection | null
  children?: React.ReactNode
}) => (
  <Stack display={currentActiveSection === section ? "flex" : "none"}>
    {children}
  </Stack>
)
