import { ButtonProps } from "@mantine/core"

import { InverterType } from "@ensol/types/prisma"

import { Link } from "@ensol/shared/components/Link"
import { BrandType } from "@ensol/shared/material/brands"
import {
  getEnphaseUrl,
  FOLLOW_UP_APPLICATIONS,
} from "@ensol/shared/material/photovoltaic/applications"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"

type Props = {
  inverterType: InverterType
  externalPlantIdentifier: string
} & ButtonProps

export const ConstructorAppLink = ({
  inverterType,
  externalPlantIdentifier,
  ...props
}: Props) => {
  const inverter = getInverter(inverterType)

  if (inverter.brand === BrandType.ENPHASE) {
    return (
      <Link
        label="Enlighten"
        to={getEnphaseUrl(externalPlantIdentifier)}
        color="orange"
        Icon={<img src="/enphaseLogo.png" height={16} />}
        isExternal
        {...props}
      />
    )
  }

  if (inverter.brand === BrandType.HUAWEI) {
    return (
      <Link
        label="FusionSolar"
        to={FOLLOW_UP_APPLICATIONS.HUAWEI.url}
        color="indigo"
        Icon={<img src="/solarFusionLogo.png" height={16} />}
        isExternal
        {...props}
      />
    )
  }

  return null
}
