import { Overlay, Stack, Text, ThemeIcon } from "@mantine/core"
import { IconRefresh } from "@tabler/icons-react"

import { getZIndex } from "@ensol/shared/styles/zIndex"

export const ReportOutdatedOverlay = () => (
  <Overlay
    color="#FFF"
    backgroundOpacity={0.9}
    blur={8}
    zIndex={getZIndex("overlay")}
    center
  >
    <Stack w="300" gap="8" align="center">
      <ThemeIcon c="gray.6">
        <IconRefresh />
      </ThemeIcon>
      <Text fz="lg" ta="center">
        Modifications à sauvegarder
      </Text>
      <Text ta="center" c="gray.6">
        Enregistrez les modifications en cours pour voir les résultats de la
        simulation
      </Text>
    </Stack>
  </Overlay>
)
