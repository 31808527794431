import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { RoofSectionConfig } from "@ensol/types/forms/houses"

import { PhotovoltaicCostsBuilder } from "@ensol/shared/entities/installations/costs/products/PhotovoltaicCostsBuilder"
import { computePanelsCapacity } from "@ensol/shared/entities/installations/energy"
import { CurrentType } from "@ensol/shared/material/currentType"
import { roundDecimalNumber } from "@ensol/shared/utils/format"

export const PhotovoltaicSubsidyType = {
  AUTO_CONSUMPTION: "AUTO_CONSUMPTION",
  GREEN_DEAL: "GREEN_DEAL",
} as const

export type PhotovoltaicSubsidyType = keyof typeof PhotovoltaicSubsidyType

type PhotovoltaicSubsidyAmountInput = {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation
  house: Pick<
    HousesResponses.House<{ include: { roofSections: true } }>,
    "constructionYear" | "currentType" | "roofType" | "roofSections"
  >
  totalDiscount: number
}

type PhotovoltaicSubsidyEligibilityInput = {
  postcode: string
  roofSections: RoofSectionConfig[]
}

const isAutoConsumptionEligible = ({
  roofSections,
}: PhotovoltaicSubsidyEligibilityInput): boolean =>
  !roofSections.some((roofSection) => roofSection.hasGroundInstallation)

const computeAutoConsumptionSubsidy = ({
  photovoltaicInstallation,
  house,
}: PhotovoltaicSubsidyAmountInput): number => {
  const installedPowerCapacity = computePanelsCapacity(photovoltaicInstallation)

  if (house.currentType === CurrentType.SINGLE_PHASE) {
    if (installedPowerCapacity <= 3)
      return roundDecimalNumber(installedPowerCapacity * 220)
    else if (installedPowerCapacity <= 6)
      return roundDecimalNumber(installedPowerCapacity * 160)
    else return roundDecimalNumber(6 * 160)
  }

  if (house.currentType === CurrentType.TRI_PHASE) {
    if (installedPowerCapacity <= 3)
      return roundDecimalNumber(installedPowerCapacity * 220)
    else if (installedPowerCapacity <= 9)
      return roundDecimalNumber(installedPowerCapacity * 160)
    else if (installedPowerCapacity <= 36)
      return roundDecimalNumber(installedPowerCapacity * 190)
    else if (installedPowerCapacity <= 100)
      return roundDecimalNumber(installedPowerCapacity * 100)
    else return 0
  }

  return 0
}

const isGreenDealEligible = ({
  postcode,
}: PhotovoltaicSubsidyEligibilityInput): boolean => postcode.startsWith("06")

const computeGreenDealSubsidy = ({
  photovoltaicInstallation,
  house,
  totalDiscount,
}: PhotovoltaicSubsidyAmountInput): number => {
  const costsBuilder = new PhotovoltaicCostsBuilder(
    photovoltaicInstallation,
    house,
    totalDiscount,
  )
  const installationCost = costsBuilder.computeInstallationCost()
  const vatRate = costsBuilder.computeVatRate()

  const installationCostWithDiscountBeforeTax =
    (installationCost - totalDiscount) / (1 + vatRate)

  return Math.min(
    10000,
    roundDecimalNumber(installationCostWithDiscountBeforeTax / 2),
  )
}

type PhotovoltaicSubsidyInfo = {
  name: string
  shortName: string
  payoutConditions: string
  disclaimer?: string
  isEligible: (input: PhotovoltaicSubsidyEligibilityInput) => boolean
  computeAmount: (input: PhotovoltaicSubsidyAmountInput) => number
  adminFeeDescription: string
  disabled?: boolean
}

export const PHOTOVOLTAIC_SUBSIDIES: Record<
  PhotovoltaicSubsidyType,
  PhotovoltaicSubsidyInfo
> = {
  AUTO_CONSUMPTION: {
    name: "prime à l'autoconsommation",
    shortName: "Prime Autoconsommation",
    payoutConditions: "versée 1 an après l'installation",
    disclaimer:
      "Le montant de la prime est calculé chaque trimestre par la CRE (Commission de Régulation de l'énergie)",
    isEligible: isAutoConsumptionEligible,
    computeAmount: computeAutoConsumptionSubsidy,
    adminFeeDescription: "Contrat d'obligation d'achat pour revente de surplus",
  },
  GREEN_DEAL: {
    name: "prime Green Deal",
    shortName: "Green Deal",
    payoutConditions: "versée 9 mois après l'installation",
    isEligible: isGreenDealEligible,
    computeAmount: computeGreenDealSubsidy,
    adminFeeDescription: "Prise en charge des démarches Green Deal",
    disabled: true,
  },
}

export const computePhotovoltaicSubsidyType = (
  input: PhotovoltaicSubsidyEligibilityInput,
): PhotovoltaicSubsidyType | null => {
  if (PHOTOVOLTAIC_SUBSIDIES.AUTO_CONSUMPTION.isEligible(input)) {
    return PhotovoltaicSubsidyType.AUTO_CONSUMPTION
  }
  return null
}
