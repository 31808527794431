import { Button, SimpleGrid, Stack } from "@mantine/core"
import { useForm } from "@mantine/form"
import _ from "lodash"
import { useMemo } from "react"

import { getPanel, PanelType } from "@ensol/shared/material/photovoltaic/panels"

import { NumberInput } from "@ensol/entool/components/form/NumberInput"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"

import { Panel, PanelOrientation } from "./Panel"

export type AddPanelsGridValues = {
  columns: number
  rows: number
  orientation: PanelOrientation
}

type Props = {
  panelType: PanelType
  onAddPanel: (panels: AddPanelsGridValues, panelType: PanelType) => void
}

export const AddPanelsGridForm = ({ panelType, onAddPanel }: Props) => {
  const panelDimensionsRatio = useMemo(() => {
    const panel = getPanel(panelType)
    return panel.widthInMillimeters / panel.lengthInMillimeters
  }, [panelType])

  const form = useForm<AddPanelsGridValues>({
    initialValues: {
      columns: 1,
      rows: 1,
      orientation: "portrait",
    },
  })

  const panelsCount = useMemo(
    () => form.values.columns * form.values.rows,
    [form.values.columns, form.values.rows],
  )

  return (
    <form
      onSubmit={form.onSubmit((values) => {
        onAddPanel(values, panelType)
      })}
    >
      <Stack align="flex-start">
        <SimpleGrid cols={2}>
          <Stack align="flex-start">
            <RadioGroup
              options={[
                { label: "Portrait", value: "portrait" },
                { label: "Paysage", value: "landscape" },
              ]}
              {...form.getInputProps("orientation")}
            />
            <NumberInput
              min={1}
              label="Colonnes"
              isNullable={false}
              {...form.getInputProps("columns")}
            />
            <NumberInput
              min={1}
              isNullable={false}
              label="Lignes"
              {...form.getInputProps("rows")}
            />
          </Stack>
          <Stack p={12} align="center" justify="center">
            <SimpleGrid
              cols={form.values.columns}
              spacing={0}
              verticalSpacing={0}
            >
              {_.times(form.values.columns, (i) =>
                _.times(form.values.rows, (j) => (
                  <Panel
                    key={`row_${i}_${j}`}
                    dimensionsRatio={panelDimensionsRatio}
                    orientation={form.values.orientation}
                  />
                )),
              )}
            </SimpleGrid>
          </Stack>
        </SimpleGrid>
        <Button type="submit" disabled={!panelsCount}>
          {panelsCount > 0 ? `Ajouter ${panelsCount} panneaux` : "Ajouter"}
        </Button>
      </Stack>
    </form>
  )
}
