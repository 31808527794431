import { Group, Text } from "@mantine/core"
import { IconCheck, IconInfoCircle } from "@tabler/icons-react"

import { colors } from "@ensol/shared/styles/colors"

import { FoldableBox } from "@ensol/entool/components/FoldableBox"
import { useGetTownHall } from "@ensol/entool/queries/townHalls"

type Props = {
  townHallId: string | null
}

export const TownHallRequirements = ({ townHallId }: Props) => {
  const { townHall, isPending } = useGetTownHall(townHallId)

  if (townHallId === null) {
    return null
  }

  if (isPending || townHall === undefined) {
    return null
  }

  const hasRequirements =
    !isPending && townHall !== undefined && townHall.requirements !== ""

  if (!hasRequirements) {
    return (
      <Group gap="8" p="16" bg="green.0">
        <IconCheck size={18} color={colors.green[600]} />
        <Text c="green.5" fw="bold">
          Pas d&apos;exigences de la mairie
        </Text>
      </Group>
    )
  }

  return (
    <FoldableBox
      isOpened={false}
      bg="yellow.0"
      name={
        <Group gap="8">
          <IconInfoCircle size={18} color={colors.yellow[600]} />
          <Text c="yellow.5" fw="bold">
            La mairie a des exigences
          </Text>
        </Group>
      }
    >
      <Text p="0 16px 16px 16px">{townHall.requirements}</Text>
    </FoldableBox>
  )
}
