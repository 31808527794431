import { Anchor, Group } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import {
  IconExternalLink,
  IconMailFilled,
  IconMapPinFilled,
  IconPhoneFilled,
} from "@tabler/icons-react"
import { Link } from "react-router-dom"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { getFullName } from "@ensol/shared/entities/prospects"
import { devices } from "@ensol/shared/styles/theme"
import { formatPhoneNum } from "@ensol/shared/utils/format"

import { IconButton } from "@ensol/entool/components/IconButton"
import { InfoIconPopover } from "@ensol/entool/components/InfoIconPopover"

type Props = {
  project: ProjectResponses.ProjectOverview
}

export const ClientInfo = ({ project }: Props) => {
  const { prospect, house } = project
  const isMobile = useMediaQuery(devices.sm)

  return (
    <Group justify="space-between" w="fit-content" gap="4" flex={2}>
      <Anchor
        component={Link}
        to={`/projects/${project.id}${isMobile ? "/technical-overview" : ""}`}
        size="md"
        fw="500"
        c="black"
      >
        {getFullName(prospect)}
      </Anchor>
      <Group gap={4}>
        <InfoIconPopover
          Icon={IconPhoneFilled}
          name="Téléphone"
          value={formatPhoneNum(prospect.phone)}
        />
        <InfoIconPopover
          Icon={IconMailFilled}
          name="Email"
          value={prospect.email}
        />
        <InfoIconPopover
          Icon={IconMapPinFilled}
          name="Adresse"
          value={house.address}
          Link={
            <IconButton
              Icon={<IconExternalLink size={18} />}
              href={`https://maps.google.com/?q=${house.address}`}
              ariaLabel="Ouvrir l'adresse dans Google Maps"
              isExternal
            />
          }
        />
      </Group>
    </Group>
  )
}
