import { Button, Loader, Stack } from "@mantine/core"
import { IconDownload } from "@tabler/icons-react"
import dayjs from "dayjs"
import { Document, Page } from "react-pdf"

import { colors } from "@ensol/shared/styles/colors"
import { downloadFile } from "@ensol/shared/utils/download"
import { formatDate } from "@ensol/shared/utils/format"

import { useGetInstallationMonthlyReport } from "@ensol/entool/queries/installations"

type Props = {
  slug: string
  date: string
}

export const MonthlyReport = ({ slug, date }: Props) => {
  const { data, isLoading } = useGetInstallationMonthlyReport(slug, date)

  return (
    <Stack w="100%" justify="center" align="center" mih="200">
      {isLoading ? (
        <Loader />
      ) : (
        <Document file={data} loading={null}>
          <Stack align="flex-end">
            <Button
              variant="light"
              leftSection={<IconDownload size={16} />}
              onClick={() =>
                data &&
                downloadFile(
                  data,
                  `rapport-${formatDate(dayjs(date), "MMMM-YYYY")}-${slug}.pdf`,
                  "application/pdf",
                )
              }
            >
              Télécharger
            </Button>
            <ReportPage pageNumber={1} />
            <ReportPage pageNumber={2} />
          </Stack>
        </Document>
      )}
    </Stack>
  )
}

const PAGE_WIDTH_IN_PX = 800

const ReportPage = ({ pageNumber }: { pageNumber: number }) => (
  <Stack
    p="16"
    style={{ border: `1px solid ${colors.gray[300]}`, borderRadius: 4 }}
  >
    <Page
      pageNumber={pageNumber}
      width={PAGE_WIDTH_IN_PX}
      renderTextLayer={false}
      renderAnnotationLayer={false}
    />
  </Stack>
)
