import { IconBarrierBlock } from "@tabler/icons-react"
import * as z from "zod"

import { pendingStepFormSchema } from "@ensol/types/forms/projects/enedis"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { EnedisForm } from "@ensol/entool/pages/OpsProject/processes/Enedis/components/EnedisForm"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"
import { validateAction } from "@ensol/entool/utils/projects/validateAction"

export const Pending = ({ project, processId }: StepComponentProps) => (
  <EnedisForm
    schema={pendingStepFormSchema}
    initialValues={getInitialValues(pendingStepFormSchema, project)}
    project={project}
    processId={processId}
    actions={[
      {
        action: "validate",
        label: "Demande soumise",
        validateAction: validateAction(
          z.object({
            enedisReference: z.string().min(1, "Champ obligatoire"),
          }),
        ),
      },
      {
        action: "incomplete",
        color: "orange",
        label: "Marquer comme incomplet",
        Icon: IconBarrierBlock,
      },
    ]}
  />
)
