import {
  Button,
  Divider,
  Grid,
  Group,
  SimpleGrid,
  Stack,
  TextInput,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { useState } from "react"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import {
  DataConsentInput,
  dataConsentSchema,
} from "@ensol/types/forms/houses/dataConsent"
import { Nullable } from "@ensol/types/utils"

import { ContractHolderType } from "@ensol/shared/entities/houses/switchgrid"
import { Gender, getFullName } from "@ensol/shared/entities/prospects"
import { getZIndex } from "@ensol/shared/styles/zIndex"

import { Section } from "@ensol/entool/components/Section"
import { Field } from "@ensol/entool/components/form/Field"
import { AddressAutocomplete } from "@ensol/entool/components/form/House/AddressAutocomplete"
import { PdlAutocomplete } from "@ensol/entool/components/form/House/PdlAutocomplete"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { useAskConsentMutation } from "@ensol/entool/queries/switchgrid"
import { useDebounce } from "@ensol/entool/utils/helpers/useDebounce"
import { CONTRACT_HOLDER_TYPE_OPTIONS } from "@ensol/entool/utils/house/options"
import { GENDER_OPTIONS } from "@ensol/entool/utils/prospects/options"

type Props = {
  house: HousesResponses.House<{
    include: { prospect: true }
  }>
  closeConsentModal: () => void
}

const FIELD_WIDTH = { base: "100%", md: 300 }

export const ConsentForm = ({ house, closeConsentModal }: Props) => {
  const { mutateAsync: askConsent, isPending } = useAskConsentMutation(house.id)
  const [contractSearch, setContractSearch] = useState<{
    holderName: string | null
    address: string
  }>({
    holderName: getFullName(house.prospect),
    address: house.address,
  })
  const debouncedSetContractSearch = useDebounce(setContractSearch, 500)

  const { prospect } = house

  const form = useForm<Nullable<DataConsentInput>>({
    validateInputOnChange: true,
    onValuesChange: (values) => {
      debouncedSetContractSearch({
        holderName: getHolderName(values.heldBy),
        address: getFullAddress(values.location),
      })
    },
    validate: zodResolver(dataConsentSchema),
    initialValues: {
      pdl: house.pdl ?? "",
      contractId: house.contractId,
      location: house,
      heldBy: {
        type: ContractHolderType.INDIVIDUAL,
        gender: prospect.gender ? Gender[prospect.gender] : null,
        firstName: prospect.firstName,
        lastName: prospect.lastName,
      },
      signer: {
        gender: prospect.gender ? Gender[prospect.gender] : null,
        firstName: prospect.firstName,
        lastName: prospect.lastName,
        email: prospect.email,
        phone: prospect.phone,
      },
    },
  })

  const isIndividualContract =
    form.values.heldBy.type === ContractHolderType.INDIVIDUAL

  return (
    <Stack p="24px 24px 84px 24px" gap="32" bg="gray.0">
      <Section title="Maison" titleOrder={3}>
        <SimpleGrid cols={2} spacing="32">
          <Stack gap="24">
            <Field name="Adresse" withAsterisk>
              <AddressAutocomplete
                w={FIELD_WIDTH}
                defaultValue={house.streetAddress}
                handleSelectOption={(placeDetails) =>
                  form.setFieldValue("location", placeDetails)
                }
              />
            </Field>
            <Field name="Code postal" withAsterisk>
              <TextInput
                w={FIELD_WIDTH}
                {...form.getInputProps("location.postcode")}
              />
            </Field>
            <Field name="PDL" withAsterisk>
              <PdlAutocomplete
                w={FIELD_WIDTH}
                {...contractSearch}
                {...form.getInputProps("pdl")}
                onChange={(pdl, contractId) => {
                  form.getInputProps("pdl").onChange(pdl)
                  form.getInputProps("contractId").onChange(contractId)
                }}
              />
            </Field>
          </Stack>
          <Stack gap="24">
            <Field name="Ville" withAsterisk>
              <TextInput
                w={FIELD_WIDTH}
                {...form.getInputProps("location.city")}
              />
            </Field>
            <Field name="Pays" withAsterisk>
              <TextInput
                w={FIELD_WIDTH}
                {...form.getInputProps("location.country")}
              />
            </Field>
          </Stack>
        </SimpleGrid>
      </Section>
      <Section title="Titulaire du contrat" titleOrder={3}>
        <Field name="Type" withAsterisk>
          <RadioGroup
            options={CONTRACT_HOLDER_TYPE_OPTIONS}
            {...form.getInputProps("heldBy.type")}
          />
        </Field>
        <Grid columns={25} gutter="32">
          <Grid.Col span={12}>
            <Stack gap="24">
              <Field name="Civilité" withAsterisk={isIndividualContract}>
                <RadioGroup
                  options={GENDER_OPTIONS}
                  disabled={!isIndividualContract}
                  {...form.getInputProps("heldBy.gender")}
                />
              </Field>
              <Field name="Prénom" withAsterisk={isIndividualContract}>
                <TextInput
                  w={FIELD_WIDTH}
                  disabled={!isIndividualContract}
                  {...form.getInputProps("heldBy.firstName")}
                />
              </Field>
              <Field name="Nom" withAsterisk={isIndividualContract}>
                <TextInput
                  w={FIELD_WIDTH}
                  disabled={!isIndividualContract}
                  {...form.getInputProps("heldBy.lastName")}
                />
              </Field>
            </Stack>
          </Grid.Col>
          <Grid.Col span={1}>
            <Divider orientation="vertical" h="100%" />
          </Grid.Col>
          <Grid.Col span={12}>
            <Stack gap="24">
              <Field name="Nom" withAsterisk={!isIndividualContract}>
                <TextInput
                  w={FIELD_WIDTH}
                  disabled={isIndividualContract}
                  {...form.getInputProps("heldBy.name")}
                />
              </Field>
              <Field name="Numéro Siret" withAsterisk={!isIndividualContract}>
                <TextInput
                  w={FIELD_WIDTH}
                  disabled={isIndividualContract}
                  {...form.getInputProps("heldBy.siret")}
                />
              </Field>
            </Stack>
          </Grid.Col>
        </Grid>
      </Section>
      <Section title="Signataire" titleOrder={3}>
        <SimpleGrid cols={2} spacing="32">
          <Stack gap="24">
            <Field name="Civilité" withAsterisk>
              <RadioGroup
                options={GENDER_OPTIONS}
                {...form.getInputProps("signer.gender")}
              />
            </Field>
            <Field name="Prénom" withAsterisk>
              <TextInput
                w={FIELD_WIDTH}
                {...form.getInputProps("signer.firstName")}
              />
            </Field>
            <Field name="Nom" withAsterisk>
              <TextInput
                w={FIELD_WIDTH}
                {...form.getInputProps("signer.lastName")}
              />
            </Field>
          </Stack>
          <Stack gap="24">
            <Field name="Email" withAsterisk>
              <TextInput
                w={FIELD_WIDTH}
                {...form.getInputProps("signer.email")}
              />
            </Field>
            <Field name="Téléphone" withAsterisk>
              <TextInput
                w={FIELD_WIDTH}
                {...form.getInputProps("signer.phone")}
              />
            </Field>
          </Stack>
        </SimpleGrid>
      </Section>
      <Group
        pos="fixed"
        bottom={0}
        left={0}
        p={16}
        w="100%"
        bg="white"
        justify="flex-end"
        style={{ zIndex: getZIndex("stickyHeader") }}
      >
        <Button
          disabled={!form.isValid()}
          loading={isPending}
          onClick={async () => {
            await askConsent(form.values as DataConsentInput)
            closeConsentModal()
          }}
        >
          Envoyer la demande
        </Button>
      </Group>
    </Stack>
  )
}

const getHolderName = (heldBy: Nullable<DataConsentInput["heldBy"]>) => {
  if (heldBy.type === ContractHolderType.INDIVIDUAL) {
    return getFullName(heldBy)
  }

  if (heldBy.type === ContractHolderType.COMPANY) {
    return heldBy.name ?? null
  }

  return null
}

const getFullAddress = ({
  streetAddress,
  postcode,
  city,
}: Nullable<DataConsentInput>["location"]) =>
  `${streetAddress} ${postcode} ${city}`
