import * as Sentry from "@sentry/react"
import type { Dimensions, TypedArray } from "geotiff"
import _ from "lodash"
import { useCallback, useEffect, useMemo, useRef, useState } from "react"

import { Coords } from "@ensol/types/forms/houses"
import { panelsLayoutSchema } from "@ensol/types/forms/panelsLayout"
import { PanelsLayoutPaths } from "@ensol/types/panelsLayout"

import { validateBody } from "@ensol/shared/utils/validation"

import { FetchDataLayersConfig, DataLayers, useDataLayers } from "./dataLayers"
import {
  createHeatmapLayerFromTiffRaster,
  createImageFromTiffRGB,
} from "./geoTiff"
import { getLoader } from "./loader"

export type TiffGranularity = "annualFlux" | "monthlyFlux"

type OverlayKey = `${TiffGranularity}${number}`

export type DrawMapParams = {
  apiKey: string
  coords: Coords
  mapTypeId?: "satellite" | "roadmap"
  panelsLayoutPaths?: PanelsLayoutPaths | null
  onClickMap?: (coords: Coords) => void
  zoomLevel?: number
  minZoomLevel?: number
  maxZoomLevel?: number
  withControls?: boolean
  hidePin?: boolean
  mapId?: string
  withOrthorectifiedMap?: boolean
  fetchDataLayersConfig?: FetchDataLayersConfig
}

const MAP_STYLES = {
  satellite: "51b567e73c179af1",
  roadmap: "51b567e73c179af1",
} as const

export type PlaceMarkerHandler = (
  coords: Coords,
  popupContent?: HTMLElement,
) => Promise<google.maps.marker.AdvancedMarkerElement>

// TODO: We might want to work on performance at some point. Some useEffects are called more than necessary
export const useDrawMap = ({
  apiKey,
  coords,
  panelsLayoutPaths,
  onClickMap,
  minZoomLevel,
  maxZoomLevel,
  fetchDataLayersConfig,
  withOrthorectifiedMap = false,
  zoomLevel = 20,
  withControls = false,
  mapTypeId = "satellite",
  hidePin = false,
  mapId = "map",
}: DrawMapParams) => {
  const {
    data: dataLayers,
    isPending: areDataLayersLoading,
    isError: isDataLayersError,
  } = useDataLayers(apiKey, coords, fetchDataLayersConfig)

  const map = useRef<google.maps.Map>()
  const mapBounds = useRef<google.maps.LatLngBounds>()

  const markers = useRef<
    Record<
      string,
      {
        marker: google.maps.marker.AdvancedMarkerElement
        infoWindow?: google.maps.InfoWindow
      }
    >
  >({})

  const panelsGrids = useRef<google.maps.Polygon[]>([])
  const sunshineOverlays = useRef<
    Record<OverlayKey, google.maps.GroundOverlay>
  >({})
  const orthorectifiedMapOverlay = useRef<google.maps.GroundOverlay>()
  const currentShownOverlay = useRef<OverlayKey>()

  // Whether the map has been loaded (without overlay)
  const [isBaseMapLoaded, setIsBaseMapLoaded] = useState(false)
  // Whether the map has been drawn with pin or panels
  const [isMapOverlayDrawn, setIsMapOverlayDrawn] = useState(false)
  // Whether the sunshine rasters are ready to be displayed
  const [areSunshineRastersReady, setAreSunshineRastersReady] = useState(true)

  const loader = useMemo(() => getLoader(apiKey), [apiKey])

  const handlePlaceMarker = useCallback<PlaceMarkerHandler>(
    async (coords, popupContent) => {
      const { AdvancedMarkerElement, PinElement } =
        await loader.importLibrary("marker")

      // 1. Create marker pin element
      const glyphImg = document.createElement("img")
      glyphImg.src = "/pinpoint.svg"
      const pinElement = new PinElement({
        glyph: glyphImg,
      })

      // 2. Create & place marker on map
      const marker = new AdvancedMarkerElement({
        map: map.current,
        position: coords,
        content: pinElement.element,
      })

      // 3. Fit map bounds to new marker
      const { LatLngBounds } = await loader.importLibrary("core")
      if (!mapBounds.current) {
        mapBounds.current = new LatLngBounds()
      }
      mapBounds.current.extend(coords)
      map.current?.fitBounds(mapBounds.current)

      // 4. Link popup content to marker's info window
      const { InfoWindow } = await loader.importLibrary("maps")
      let infoWindow: google.maps.InfoWindow | undefined = undefined
      if (popupContent) {
        // 4.1. If popup content is provided, open it on marker click and close all others
        infoWindow = new InfoWindow({ content: popupContent })
        marker.addListener("click", () => {
          Object.values(markers.current).forEach(({ infoWindow }) => {
            infoWindow?.close()
          })

          infoWindow?.open({
            anchor: marker,
            map: map.current,
          })
        })
      }

      // 5. Add marker to markers list
      const currentMarker = markers.current[JSON.stringify(coords)]
      if (currentMarker) {
        currentMarker.marker.map = null
      }
      markers.current[JSON.stringify(coords)] = { marker, infoWindow }

      return marker
    },
    [loader],
  )

  const handleRemoveMarkers = useCallback(async () => {
    Object.values(markers.current).forEach(({ marker }) => (marker.map = null))

    const { LatLngBounds } = await loader.importLibrary("core")
    mapBounds.current = new LatLngBounds()
    map.current?.fitBounds(mapBounds.current)

    markers.current = {}
  }, [loader])

  const handleMapClickListener = useCallback(
    async (event: google.maps.MapMouseEvent) => {
      if (onClickMap) {
        if (event.latLng) {
          onClickMap?.(event.latLng.toJSON())
        }
      }
    },
    [onClickMap],
  )

  const handleDrawSunshineRasters = useCallback(
    async (isShown: boolean, granularity: TiffGranularity, layerIndex = 0) => {
      if (currentShownOverlay.current) {
        sunshineOverlays.current[currentShownOverlay.current]?.setMap(null)
      }

      if (!isShown) {
        return
      }

      const overlayKey: OverlayKey = `${granularity}${
        granularity === "annualFlux" ? 0 : layerIndex
      }`

      sunshineOverlays.current[overlayKey]?.setMap(map.current!)
      currentShownOverlay.current = overlayKey
    },
    [],
  )

  const handleDrawPanelsGrid = useCallback(
    async (paths: (google.maps.LatLng | google.maps.LatLngLiteral)[][]) => {
      const panelsGrid = new google.maps.Polygon({
        strokeOpacity: 1,
        strokeWeight: 1,
        strokeColor: "#AAAAAA",
        fillColor: "#000000",
        zIndex: 10,
        fillOpacity: 0.8,
        paths,
        draggable: withControls,
        map: map.current,
      })

      panelsGrids.current.push(panelsGrid)

      return panelsGrid
    },
    [withControls],
  )

  const drawMap = useCallback(
    async (houseCoords: Coords) => {
      const { Map } = await loader.importLibrary("maps")

      const newMap = new Map(document.getElementById(mapId) as HTMLDivElement, {
        zoom: zoomLevel,
        center: houseCoords,
        mapTypeId,
        mapId: MAP_STYLES[mapTypeId],
        tilt: 0,
        minZoom: minZoomLevel,
        maxZoom: maxZoomLevel,
        disableDefaultUI: true,
        gestureHandling: withControls ? "cooperative" : "none",
        zoomControl: withControls,
      })

      map.current = newMap

      setTimeout(() => {
        setIsBaseMapLoaded(true)
      }, 0)

      newMap.addListener("click", handleMapClickListener)
    },
    [
      loader,
      mapId,
      zoomLevel,
      mapTypeId,
      minZoomLevel,
      maxZoomLevel,
      withControls,
      handleMapClickListener,
    ],
  )

  const computeLayerBounds = useCallback(
    async (coords: Coords, radiusMeters: number) => {
      const { spherical } = await loader.importLibrary("geometry")
      const center = coords

      const north = spherical.computeOffset(center, radiusMeters, 0)
      const south = spherical.computeOffset(center, radiusMeters, 180)

      const northEast = spherical.computeOffset(north, radiusMeters, 90)
      const southWest = spherical.computeOffset(south, radiusMeters, -90)

      return new google.maps.LatLngBounds(southWest, northEast)
    },
    [loader],
  )

  /**
   * Prepare sunshine overlays so they can be displayed on demand
   */
  const initHeatmapsOverlays = useCallback(
    async (
      { annualFluxTiffRaster, monthlyFluxTiffRaster }: DataLayers,
      bounds: google.maps.LatLngBounds,
    ) => {
      if (!_.isEmpty(sunshineOverlays.current)) {
        return
      }

      sunshineOverlays.current["annualFlux0"] =
        await new google.maps.GroundOverlay(
          createHeatmapLayerFromTiffRaster(annualFluxTiffRaster, 0),
          bounds,
          { opacity: 0.7 },
        )
      _.times(12, async (i) => {
        sunshineOverlays.current[`monthlyFlux${i}`] =
          await new google.maps.GroundOverlay(
            createHeatmapLayerFromTiffRaster(monthlyFluxTiffRaster, i),
            bounds,
            { opacity: 0.7 },
          )
      })
    },
    [],
  )

  const initOrthorectifiedMapOverlay = useCallback(
    async (
      { baseImageTiffRGB }: DataLayers,
      bounds: google.maps.LatLngBounds,
    ) => {
      if (orthorectifiedMapOverlay.current !== undefined) {
        if (withOrthorectifiedMap === false) {
          orthorectifiedMapOverlay.current.setMap(null)
        } else if (orthorectifiedMapOverlay.current.getMap() === null) {
          orthorectifiedMapOverlay.current.setMap(map.current!)
        }
        return
      }

      orthorectifiedMapOverlay.current = new google.maps.GroundOverlay(
        // readRGB() type is not precise enough but if interleave prop is true we do get a TypedArray
        createImageFromTiffRGB(baseImageTiffRGB as TypedArray & Dimensions),
        bounds,
        { opacity: 1 },
      )
      orthorectifiedMapOverlay.current.addListener(
        "click",
        handleMapClickListener,
      )
      orthorectifiedMapOverlay.current.setMap(map.current!)
    },
    [handleMapClickListener, withOrthorectifiedMap],
  )

  const handleInitMapOverlays = useCallback(async () => {
    map.current?.panTo(coords)

    if (fetchDataLayersConfig !== undefined) {
      setAreSunshineRastersReady(false)
      if (areDataLayersLoading) {
        return
      } else if (dataLayers !== undefined && !isDataLayersError) {
        try {
          const bounds = await computeLayerBounds(
            coords,
            fetchDataLayersConfig.radiusMeters,
          )
          await Promise.all([
            initOrthorectifiedMapOverlay(dataLayers, bounds),
            initHeatmapsOverlays(dataLayers, bounds),
          ])
        } catch (e) {
          console.error(e)
          Sentry.captureException(e)
        }
        setAreSunshineRastersReady(true)
      }
    }

    if (
      !hidePin &&
      !panelsLayoutPaths &&
      !markers.current[JSON.stringify(coords)]
    ) {
      await handleRemoveMarkers()
      await handlePlaceMarker(coords)
    } else if (
      panelsLayoutPaths &&
      panelsGrids.current.length === 0 &&
      !isMapOverlayDrawn
    ) {
      try {
        const paths = await validateBody(panelsLayoutPaths, panelsLayoutSchema)
        paths.forEach((path) => {
          handleDrawPanelsGrid(path)
        })
      } catch (e) {
        console.error(e)
        Sentry.captureException(e, {
          extra: { coords },
        })
      }
    }

    setIsMapOverlayDrawn(true)
  }, [
    coords,
    handleDrawPanelsGrid,
    hidePin,
    panelsLayoutPaths,
    handlePlaceMarker,
    dataLayers,
    areDataLayersLoading,
    isDataLayersError,
    computeLayerBounds,
    fetchDataLayersConfig,
    initOrthorectifiedMapOverlay,
    initHeatmapsOverlays,
    isMapOverlayDrawn,
    handleRemoveMarkers,
  ])

  useEffect(() => {
    if (!isBaseMapLoaded && typeof window !== "undefined") {
      drawMap({ lat: coords.lat, lng: coords.lng })
    }
  }, [coords.lat, coords.lng, drawMap, isBaseMapLoaded])

  useEffect(() => {
    if (isBaseMapLoaded) {
      handleInitMapOverlays()
    }
  }, [handleInitMapOverlays, isBaseMapLoaded])

  return {
    map: map.current!, // TODO: no undefined
    panelsGrids: panelsGrids.current,
    onPlaceMarker: handlePlaceMarker,
    onDrawPanelsGrid: handleDrawPanelsGrid,
    onDrawSunshineRasters: handleDrawSunshineRasters,
    areSunshineRastersReady,
    areSunshineRastersNotAvailable: isDataLayersError,
    rastersImageryDate: dataLayers?.imageryDate,
    isMapLoaded: isBaseMapLoaded && isMapOverlayDrawn,
  }
}
