import { Stack, Group, Badge, Text } from "@mantine/core"
import { IconGavel } from "@tabler/icons-react"

type Props = {
  requiredWorks: string[]
}

export const RequiredWorks = ({ requiredWorks }: Props) => {
  return (
    <Stack>
      <Text fz="lg" fw={500}>
        Travaux requis
      </Text>
      <Group gap={8}>
        {requiredWorks.map((work) => (
          <Badge
            key={work}
            color="red"
            fw="bold"
            variant="light"
            leftSection={<IconGavel size={16} />}
          >
            {work}
          </Badge>
        ))}
      </Group>
    </Stack>
  )
}
