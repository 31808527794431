import { Stack, Text } from "@mantine/core"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { DownloadDocument } from "./DownloadDocument"

type Props = {
  project: ProjectResponses.Project
  config: {
    getFilePaths: (project: ProjectResponses.Project) => string[]
    fileName: string
  }[]
}

export const DocumentsSubSection = ({ project, config }: Props) => {
  const documents = config.filter(
    ({ getFilePaths }) => getFilePaths(project).length > 0,
  )
  if (documents.length === 0) {
    return null
  }

  return (
    <Stack>
      <Text fz="lg" fw={500}>
        Documents
      </Text>
      <Stack gap={8}>
        {documents.map(({ getFilePaths, fileName }) => {
          const filePaths = getFilePaths(project)
          return filePaths.map((path, index) => (
            <DownloadDocument
              key={path}
              filePath={path}
              fileName={`${fileName}${index > 0 ? `-${index}` : ""}`}
            />
          ))
        })}
      </Stack>
    </Stack>
  )
}
