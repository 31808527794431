import * as Sentry from "@sentry/react"
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from "react-router-dom"

import { Role } from "@ensol/shared/entities/users"

import { AppShell } from "@ensol/entool/components/layout/AppShell"
import { Home } from "@ensol/entool/pages/Home"
import { OpsProjectsAlerts } from "@ensol/entool/pages/OpsProjectsAlerts"
import { PanelsLayoutPage } from "@ensol/entool/pages/PanelsLayout"
import { ProjectFeedbackPage } from "@ensol/entool/pages/ProjectFeedback"
import { ProjectsEventsPage } from "@ensol/entool/pages/ProjectsEvents"
import { ProspectsPage } from "@ensol/entool/pages/Prospects"
import { SalesTasksPage } from "@ensol/entool/pages/SalesTasks"
import { SalesVisitPage } from "@ensol/entool/pages/SalesVisit"

import { AuthGuard } from "./AuthGuard"
import { AuthProvider } from "./AuthProvider"
import { DefaultTeamRedirect } from "./DefaultTeamRedirect"
import { InstallationPage } from "./Installation"
import { LegacyDomainRedirect } from "./LegacyDomainRedirect"
import { NewProspectPage } from "./NewProspect"
import { OpsProjectPage } from "./OpsProject"
import { OpsProjectsByProcess } from "./OpsProjectsByProcess"
import { OpsProjectsOverview } from "./OpsProjectsOverview"
import { ProjectTechnicalOverviewPage } from "./ProjectTechnicalOverview"
import { ProspectPage } from "./Prospect"
import { RouteRoleGuard } from "./RouteRoleGuard"

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouterV6(createBrowserRouter)

export const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route element={<LegacyDomainRedirect />}>
      <Route element={<AuthProvider />}>
        <Route element={<AuthGuard />}>
          <Route element={<AppShell />}>
            <Route element={<DefaultTeamRedirect />}>
              <Route element={<RouteRoleGuard roles={[Role.EMPLOYEE]} />}>
                <Route index element={<Home />} />
              </Route>
            </Route>
            <Route path="/projects">
              <Route
                element={
                  <RouteRoleGuard roles={[Role.INSTALLER, Role.EMPLOYEE]} />
                }
              >
                <Route index element={<OpsProjectsOverview />} />
                <Route
                  path=":projectId/technical-overview"
                  element={<ProjectTechnicalOverviewPage />}
                />
              </Route>
              <Route element={<RouteRoleGuard roles={[Role.EMPLOYEE]} />}>
                <Route path="alerts" element={<OpsProjectsAlerts />} />
                <Route
                  path="processes/:processId"
                  element={<OpsProjectsByProcess />}
                />
                <Route path=":projectId" element={<OpsProjectPage />} />
              </Route>
            </Route>
            <Route element={<RouteRoleGuard roles={[Role.EMPLOYEE]} />}>
              <Route path="/prospects">
                <Route index element={<ProspectsPage />} />
                <Route path="new" element={<NewProspectPage />} />
                <Route path=":prospectId">
                  <Route index element={<ProspectPage />} />
                </Route>
              </Route>
              <Route path="/installations">
                <Route path=":installationId">
                  <Route index element={<InstallationPage />} />
                  <Route path="panels-layout" element={<PanelsLayoutPage />} />
                </Route>
              </Route>
              <Route path="/events">
                <Route index element={<ProjectsEventsPage />} />
              </Route>
              <Route path="/sales">
                <Route index element={<SalesTasksPage />} />
                <Route path=":projectId" element={<SalesVisitPage />} />
              </Route>
              <Route path="/feedbacks">
                <Route path=":projectId" element={<ProjectFeedbackPage />} />
              </Route>
            </Route>
          </Route>
          {/* This route is an unauthenticated one used by Cypress tests to programmatically login*/}
        </Route>
        <Route path="/cypress/sign-in" />
      </Route>
    </Route>,
  ),
)
