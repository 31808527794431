import * as z from "zod"
import { transformSchemaToNullish } from "../utils"
import { integrationKitTypeField } from "./shared"

export const preorderPendingStepFormSchema = z.object({
  integrationKitType: integrationKitTypeField,
  isPLCNeeded: z.boolean().nullable().optional(),
  hardwareNotes: z.string().optional(),
  supplierId: z.string({ invalid_type_error: "Champ obligatoire" }),
  hardwareDeliveryMode: z.string().nullable().optional(),
  hardwareDeliveryAddress: z.string().nullable().optional(),
})

export const preorderDoneStepFormSchema = preorderPendingStepFormSchema.extend({
  orderQuoteNumber: z.string().optional(),
  orderAmount: z.number().nullable(),
})

export const preparationPendingStepFormSchema =
  preorderDoneStepFormSchema.extend({
    hardwareDeliveryMode: z.string({
      invalid_type_error: "Champ obligatoire",
    }),
    hardwareDeliveryAddress: z.string({
      invalid_type_error: "Champ obligatoire",
    }),
  })

export const orderDeliveryPendingStepFormSchema =
  preparationPendingStepFormSchema.extend({
    orderDeliveryDate: z.date({ invalid_type_error: "Champ obligatoire" }),
    isFinalDeliveryDate: z.boolean(),
  })

export const orderDeliveredStepSchema =
  orderDeliveryPendingStepFormSchema.extend({
    orderDeliveryComment: z.string().optional(),
  })

export type HardwareStepInput =
  | z.infer<typeof preorderPendingStepFormSchema>
  | z.infer<typeof preorderDoneStepFormSchema>
  | z.infer<typeof preparationPendingStepFormSchema>
  | z.infer<typeof orderDeliveryPendingStepFormSchema>
  | z.infer<typeof orderDeliveredStepSchema>

export const hardwareProcessSchema = transformSchemaToNullish(
  orderDeliveredStepSchema,
)
