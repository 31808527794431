import { Group, Text } from "@mantine/core"
import { IconArrowRight } from "@tabler/icons-react"

import { StatCard } from "./StatCard"

type StatCardWithExtraProps = {
  label: string
  startStat?: number | null
  targetStat?: number | null
  unit?: string
  isLoading?: boolean
}

export const StatComparisonCard = ({
  label,
  startStat,
  targetStat,
  unit,
  isLoading = false,
}: StatCardWithExtraProps) => (
  <StatCard
    label={label}
    stat={startStat}
    unit={unit}
    isLoading={isLoading}
    extra={
      <Group gap="4">
        <IconArrowRight size={16} />
        <Text fz="xl" fw={600} c="green">
          {targetStat ?? "-"}
        </Text>
        {unit && (
          <Text c="green" size="sm">
            {unit}
          </Text>
        )}
      </Group>
    }
  />
)
