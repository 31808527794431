import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { EvChargerQuoteDetails } from "@ensol/types/quote"

import { EvChargerCostsBuilder } from "@ensol/shared/entities/installations/costs/products/EvChargerCostsBuilder"
import { computeExtraWorksQuoteDetails } from "@ensol/shared/entities/quotes/details/extraWorks"
import { getEvCharger } from "@ensol/shared/material/evCharger"

export const computeEvChargerQuoteDetails = (
  evChargerInstallation: InstallationsResponses.EvChargerInstallation,
  productsCount: number,
): EvChargerQuoteDetails => {
  const costsBuilder = new EvChargerCostsBuilder(
    evChargerInstallation,
    productsCount,
  )
  const {
    vatRate,
    installationCost,
    installationCostBeforeTax,
    installationCostWithSubsidies,
    subsidy,
  } = costsBuilder.computeAllCosts()

  const evCharger = getEvCharger(evChargerInstallation.type)

  return {
    material: {
      evCharger: {
        name: "Borne de recharge",
        description: `${evCharger.longName}\nGarantie ${evCharger.warranty} ans`,
        quantity: 1,
        price: costsBuilder.computeEvChargerUnitPrice(),
      },
      smartMeter: {
        name: "Passerelle de communication borne",
        description: "Délestage / charge solaire",
        quantity: 1,
        price: costsBuilder.computeEvChargerSmartMeterPrice(),
      },
      accessories: {
        name: "Accessoires borne",
        description: "Support mural",
        quantity: 1,
        price: costsBuilder.computeEvChargerAccessoriesPrice(),
      },
    },
    extraWorks: computeExtraWorksQuoteDetails(evChargerInstallation.extraWorks),
    workforce: {
      installation: {
        name: "Installation borne",
        description: "Forfait pose en intérieur et raccordement de la borne",
        quantity: 1,
        price: costsBuilder.computeWorkforceCosts(),
      },
      additionalCost: {
        name: "Supplément borne",
        description: `Pose externe, tranchée, câblage ${evChargerInstallation.additionalCost === 0 ? "(supplément déterminé après la visite technique)" : ""}`,
        quantity: 1,
        price: evChargerInstallation.additionalCost,
      },
    },
    subsidy: subsidy
      ? {
          amount: subsidy.subsidyAmount,
          type: subsidy.subsidyType,
        }
      : null,
    vatRate,
    totals: {
      installationCost,
      installationCostBeforeTax,
      installationCostWithSubsidies,
    },
  }
}
