import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import { withMargin } from "@ensol/shared/entities/installations/costs"
import { CostsBuilder } from "@ensol/shared/entities/installations/costs/products/CostsBuilder"
import {
  EV_CHARGER_SUBSIDIES,
  EvChargerSubsidyType,
} from "@ensol/shared/entities/installations/subsidies/evCharger"
import {
  getEvCharger,
  getEvChargerSmartMeter,
} from "@ensol/shared/material/evCharger"

const EV_CHARGER_ACCESSORIES_PRICE_IN_EURO = 39.84
const STANDALONE_EV_CHARGER_WORKFORCE_IN_EURO = 800
const BUNDLE_EV_CHARGER_WORKFORCE_IN_EURO = 500
export class EvChargerCostsBuilder extends CostsBuilder<
  InstallationsResponses.EvChargerInstallation,
  EvChargerSubsidyType
> {
  constructor(
    installation: InstallationsResponses.EvChargerInstallation,
    private productsCount: number,
  ) {
    super(installation)
  }

  public computeVatRate() {
    return 0.055
  }

  public computeEvChargerUnitPrice() {
    const { type } = this.installation
    const evCharger = getEvCharger(type)

    return withMargin(evCharger.price)
  }

  public computeEvChargerSmartMeterPrice() {
    const { type } = this.installation

    const smartMeter = getEvChargerSmartMeter(type)
    return withMargin(smartMeter?.price ?? 0)
  }

  public computeEvChargerAccessoriesPrice() {
    return withMargin(EV_CHARGER_ACCESSORIES_PRICE_IN_EURO)
  }

  public computeMaterialCosts() {
    return (
      this.computeEvChargerAccessoriesPrice() +
      this.computeEvChargerUnitPrice() +
      this.computeEvChargerSmartMeterPrice()
    )
  }

  public computeWorkforceCosts() {
    const workforceCost = withMargin(
      this.productsCount === 1
        ? STANDALONE_EV_CHARGER_WORKFORCE_IN_EURO
        : BUNDLE_EV_CHARGER_WORKFORCE_IN_EURO,
    )
    return workforceCost + this.installation.additionalCost
  }

  public computeAdminCosts() {
    return 0
  }

  public computeSubsidy() {
    const { subsidyType } = this.installation
    return subsidyType !== null
      ? {
          subsidyType,
          subsidyAmount: EV_CHARGER_SUBSIDIES[subsidyType].computeAmount({
            evChargerInstallation: this.installation,
            productsCount: this.productsCount,
          }),
        }
      : null
  }
}
