import {
  Button,
  Checkbox,
  Group,
  Paper,
  Radio,
  SimpleGrid,
  Stack,
  Text,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconDeviceFloppy } from "@tabler/icons-react"
import _ from "lodash"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import {
  RoofSectionConfig,
  roofSectionConfigSchema,
} from "@ensol/types/forms/houses"
import { RoofSection } from "@ensol/types/prisma-client"

import {
  getOrientation,
  ORIENTATIONS,
} from "@ensol/shared/entities/houses/orientation"
import {
  DEFAULT_ROOF_SECTION,
  FREE_INCLINATION_ROOF_SECTION,
} from "@ensol/shared/entities/houses/roofSection"

import { Field } from "@ensol/entool/components/form/Field"
import { NumberInput } from "@ensol/entool/components/form/NumberInput"
import { useUpsertRoofSectionMutation } from "@ensol/entool/queries/houses"

type Props = {
  house: HousesResponses.House<{
    include: { roofSections: true }
  }>
  roofSection?: RoofSectionConfig
  onSuccess: (roofSection: RoofSection) => void
}

export const RoofSectionForm = ({ house, roofSection, onSuccess }: Props) => {
  const form = useForm({
    validate: zodResolver(roofSectionConfigSchema),
    initialValues: roofSection ?? DEFAULT_ROOF_SECTION,
  })

  const isFormLocked =
    form.values.hasGroundInstallation || form.values.hasFlatRoof

  const { mutateAsync: upsertRoofSection, isPending } =
    useUpsertRoofSectionMutation(house.id, async (roofSection) => {
      form.reset()
      onSuccess(roofSection)
    })

  return (
    <Paper p={24} mt={24} withBorder>
      <form onSubmit={form.onSubmit((values) => upsertRoofSection(values))}>
        <Group gap={32} align="stretch">
          <Field name="Orientation du toit" w={220}>
            <Radio.Group
              {...form.getInputProps("orientation")}
              onChange={(value) => form.setFieldValue("orientation", +value)}
            >
              <SimpleGrid cols={2} spacing={4}>
                {_.map(ORIENTATIONS, ({ name, numericValue }, key) => {
                  const isOrientationSelected =
                    getOrientation(form.values.orientation) === key
                  return (
                    <Radio.Card
                      key={key}
                      value={numericValue.toString()}
                      withBorder={false}
                    >
                      <Paper
                        p={12}
                        withBorder={false}
                        bg={isOrientationSelected ? "blue" : "white"}
                      >
                        <Text
                          ta="center"
                          c={isOrientationSelected ? "white" : "gray.7"}
                        >
                          {name}
                        </Text>
                      </Paper>
                    </Radio.Card>
                  )
                })}
              </SimpleGrid>
            </Radio.Group>
          </Field>
          <Stack gap={24} justify="space-between" style={{ flexGrow: 1 }}>
            <Stack gap={24}>
              <SimpleGrid cols={2} spacing={24}>
                <Field name="Azimuth">
                  <NumberInput
                    {...form.getInputProps("orientation")}
                    suffix="°"
                    isNullable={false}
                  />
                </Field>
                <Field name="Inclinaison du pan">
                  <NumberInput
                    {...form.getInputProps("inclination")}
                    disabled={isFormLocked}
                    suffix="°"
                    isNullable={false}
                  />
                </Field>
              </SimpleGrid>
              <Group>
                <Checkbox
                  size="sm"
                  label="Pose au sol"
                  {...form.getInputProps("hasGroundInstallation", {
                    type: "checkbox",
                  })}
                  disabled={form.values.hasFlatRoof}
                  onChange={(e) => {
                    const roofSectionConfig = e.target.checked
                      ? { ...FREE_INCLINATION_ROOF_SECTION }
                      : { ...roofSection }
                    form.setValues({
                      ...roofSectionConfig,
                      hasGroundInstallation: e.target.checked,
                    })
                  }}
                />
                <Checkbox
                  size="sm"
                  label="Toit plat"
                  disabled={form.values.hasGroundInstallation}
                  {...form.getInputProps("hasFlatRoof", { type: "checkbox" })}
                  onChange={(e) => {
                    const roofSectionConfig = e.target.checked
                      ? { ...FREE_INCLINATION_ROOF_SECTION }
                      : { ...roofSection }
                    form.setValues({
                      ...roofSectionConfig,
                      hasFlatRoof: e.target.checked,
                    })
                  }}
                />
              </Group>
            </Stack>
            <Group justify="flex-end">
              <Button
                size="xs"
                type="submit"
                leftSection={<IconDeviceFloppy size={16} />}
                disabled={!form.isValid()}
                loading={isPending}
              >
                Enregistrer le pan
              </Button>
            </Group>
          </Stack>
        </Group>
      </form>
    </Paper>
  )
}
