import { UseFormReturnType } from "@mantine/form"
import { useEffect } from "react"

import { InstallationProductsInput } from "@ensol/types/forms/installations"

import { getInstallationProductsCount } from "@ensol/shared/entities/installations/characteristics"

export const useUpdateProductsCount = <
  Values extends InstallationProductsInput,
>(
  form: UseFormReturnType<Values>,
) => {
  useEffect(() => {
    const value = getInstallationProductsCount(form.values)
    if (value !== form.values.productsCount) {
      form.getInputProps("productsCount").onChange(value)
    }
  }, [form])
}
