import { BrandType, InverterBrandType } from "@ensol/shared/material/brands"

type ApplicationInfo = {
  name: string
  url: string
}

export const FOLLOW_UP_APPLICATIONS: Record<
  InverterBrandType,
  ApplicationInfo
> = {
  [BrandType.ENPHASE]: {
    name: "Enlighten",
    url: "https://enlighten.enphaseenergy.com",
  },
  [BrandType.HUAWEI]: {
    name: "FusionSolar",
    url: "https://eu5.fusionsolar.huawei.com",
  },
}

export const getApplication = (
  inverterBrand: InverterBrandType,
): ApplicationInfo => FOLLOW_UP_APPLICATIONS[inverterBrand]

export const getEnphaseUrl = (systemId: string | null) => {
  const baseUrl = FOLLOW_UP_APPLICATIONS.ENPHASE.url

  if (!systemId) {
    return baseUrl
  }

  return `${baseUrl}/systems/${systemId}`
}
