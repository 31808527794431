import _ from "lodash"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import {
  ProductionDataSource,
  ConsumptionDataSource,
} from "@ensol/types/simulation"

import { DEFAULT_HOUSE_CONSTRUCTION_YEAR } from "@ensol/shared/entities/houses"
import { MountingType } from "@ensol/shared/entities/installations/batteries"
import { computeYearlyConsumption } from "@ensol/shared/entities/installations/energy"
import { EvChargerSubsidyType } from "@ensol/shared/entities/installations/subsidies/evCharger"
import {
  computePhotovoltaicSubsidyType,
  PhotovoltaicSubsidyType,
} from "@ensol/shared/entities/installations/subsidies/photovoltaic"
import { BatteryType } from "@ensol/shared/material/batteries"
import { InverterBrandType } from "@ensol/shared/material/brands"
import { CurrentType } from "@ensol/shared/material/currentType"
import { EvChargerType } from "@ensol/shared/material/evCharger"
import {
  getInverter,
  InverterType,
} from "@ensol/shared/material/photovoltaic/inverters"
import { PanelType } from "@ensol/shared/material/photovoltaic/panels"
import { RoofType } from "@ensol/shared/material/roofType"

const DEFAULT_MONTHLY_BILL = 120 // €
const DEFAULT_PANELS_COUNT = 6
export const DEFAULT_PHOTOVOLTAIC_INSTALLATION = {
  panelType: PanelType.DUALSUN_500_TOPCON,
  inverterType: InverterType.ENPHASE_IQ_8_P,
  optimizerType: null,
  optimizerCount: null,
  extraWorks: [],
  subsidyType: PhotovoltaicSubsidyType.AUTO_CONSUMPTION,
}

export const getDefaultInstallationBody = (
  isUpsell: boolean,
  house: HousesResponses.House<{
    include: { roofSections: true; installations: true }
  }>,
) => ({
  name: `Offre ${house.installations.length + 1}`,
  productionDataSource: ProductionDataSource.PVGIS,
  consumptionDataSource: ConsumptionDataSource.BILL,
  monthlyBill: DEFAULT_MONTHLY_BILL,
  yearlyConsumption: computeYearlyConsumption(DEFAULT_MONTHLY_BILL),
  monthlyConsumptions: [],
  manualAutoConsumptionPercent: 0,
  computedAutoConsumptionPercent: 0,
  houseId: house.id,
  prospectId: house.prospectId,
  batteryInstallation: null,
  evChargerInstallation: null,
  horizonProfileFilePath: null,
  photovoltaicInstallation: !isUpsell
    ? getDefaultPhotovoltaicInstallation({ house })
    : null,
  extraPanelsInstallation: null,
  isUpsell,
  productsCount: 1,
})

const getDefaultPhotovoltaicInstallation = ({
  house,
}: {
  house: HousesResponses.House<{ include: { roofSections: true } }>
}) => {
  const defaultInstallation = {
    ...DEFAULT_PHOTOVOLTAIC_INSTALLATION,
    subsidyType: computePhotovoltaicSubsidyType(house),
  }

  return {
    ...defaultInstallation,
    panelsCount: DEFAULT_PANELS_COUNT,
    roofSectionsWithPanels: _.sortBy(house.roofSections, (roofSection) =>
      Math.abs(roofSection.orientation - 180),
    ).map((section, index) => ({
      roofSection: section,
      panelsCount: index === 0 ? DEFAULT_PANELS_COUNT : 0,
    })),
  }
}

export const getDefaultExtraPanelsInstallation = ({
  house,
  signedPhotovoltaicInstallation,
}: {
  house: HousesResponses.House<{ include: { roofSections: true } }>
  signedPhotovoltaicInstallation?: InstallationsResponses.PhotovoltaicInstallation | null
}) => {
  const defaultInstallation = {
    ...DEFAULT_PHOTOVOLTAIC_INSTALLATION,
    subsidyType: computePhotovoltaicSubsidyType(house),
  }

  if (!signedPhotovoltaicInstallation) {
    return null
  }

  return {
    ...defaultInstallation,
    panelsCount: 0,
    roofSectionsWithPanels: [],
    panelType: signedPhotovoltaicInstallation.panelType,
    inverterType: signedPhotovoltaicInstallation.inverterType,
  }
}

const DEFAULT_BATTERY_INSTALLATION = {
  type: BatteryType.ENPHASE_5P,
  mountingType: MountingType.ON_FLOOR,
  extraWorks: [],
}

export const getDefaultBatteryInstallation = (inverterType?: InverterType) => {
  if (!inverterType) {
    return DEFAULT_BATTERY_INSTALLATION
  }

  const inverter = getInverter(inverterType)
  return {
    ...DEFAULT_BATTERY_INSTALLATION,
    type:
      inverter.brand === InverterBrandType.ENPHASE
        ? BatteryType.ENPHASE_5P
        : BatteryType.HUAWEI_7KWH,
  }
}

export const DEFAULT_EV_CHARGER_INSTALLATION = {
  type: EvChargerType.WALLBOX_PULSAR_PLUS_SOCKET_MONO,
  additionalCost: 0,
  subsidyType: EvChargerSubsidyType.TAX_REDUCTION,
  extraWorks: [],
}

export const DEFAULT_HOUSE_CONFIG = {
  constructionYear: DEFAULT_HOUSE_CONSTRUCTION_YEAR,
  surface: null,
  floors: null,
  isIndividualHouse: null,
  hasAsbestos: null,
  isHistoricBuilding: null,
  isAtticAccessible: null,
  roofFramingMaterial: null,
  currentType: CurrentType.SINGLE_PHASE,
  roofType: RoofType.OTHER,
  pdl: null,
  hasLinky: null,
  equipments: [],
}
