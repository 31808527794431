import { UsersResponses } from "@ensol/types/endpoints/users"

import { Role } from "@ensol/shared/entities/users"

export const isUserAuthorized = (
  authorizedRoles: Role[],
  user?: UsersResponses.User,
) => {
  if (!user) {
    return false
  }

  return authorizedRoles.includes(user.role)
}
