export const DEPOSIT_PAYMENT_RATE = 0.5

const MARGIN = 0.01
/**
 * We use a margin to compare paid amount vs target amount because
 * it can happen that deposit is slightly less than half of the total amount
 * or that the client doesn't pay exactly the good amount by a few cents
 */
export const isLowerThanWithMargin = (amount: number, target: number) =>
  amount < target * (1 - MARGIN)

export const isGreaterThanWithMargin = (amount: number, target: number) =>
  amount > target * (1 - MARGIN)
