import { LoadingOverlay, Stack } from "@mantine/core"

import { getAutoConsumptionGraphConfig } from "@ensol/shared/entities/installations/reports/autoConsumption"
import { getZIndex } from "@ensol/shared/styles/zIndex"

import { Chart } from "@ensol/entool/components/Chart"
import { useGetEnergyReportQuery } from "@ensol/entool/queries/energy"

type Props = {
  slug: string
  startDate: Date
  endDate: Date
}

export const AutoConsumptionReport = ({ slug, startDate, endDate }: Props) => {
  const { data, isPending } = useGetEnergyReportQuery({
    slug,
    startDate,
    endDate,
    isSimulated: true,
  })

  return (
    <Stack pos="relative" mih={300} justify="center">
      <LoadingOverlay visible={isPending} zIndex={getZIndex("overlay")} />
      {data && (
        <Chart aspectRatio={6 / 3} data={getAutoConsumptionGraphConfig(data)} />
      )}
    </Stack>
  )
}
