import { NumberInput, SimpleGrid, Stack, Text, Textarea } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import {
  HouseUpdateType,
  updateExistingInstallationSchema,
} from "@ensol/types/forms/houses"

import { getProspectFileName } from "@ensol/shared/utils/files"

import { Field } from "@ensol/entool/components/form/Field"
import { MultiFileField } from "@ensol/entool/components/form/File/MultiFileField"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useUpdateHouseMutation } from "@ensol/entool/queries/houses"
import { useDebounce } from "@ensol/entool/utils/helpers/useDebounce"
import {
  EXISTING_INSTALLATION_AGE_OPTIONS,
  EXISTING_INSTALLATION_CONTRACT_OPTIONS,
} from "@ensol/entool/utils/house/options"

type Props = {
  house: HousesResponses.House<{ include: { prospect: true } }>
}

export const UpdateExistingInstallation = ({
  house: {
    id,
    existingInstallationCapacity,
    existingInstallationContract,
    existingInstallationAge,
    existingInstallationDetails,
    existingInstallationFilesPaths,
    prospect,
  },
}: Props) => {
  const { mutateAsync: updateHouse } = useUpdateHouseMutation(id)
  const debouncedUpdateHouse = useDebounce(updateHouse, 250)

  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(updateExistingInstallationSchema),
    onValuesChange: async (values) => debouncedUpdateHouse(values),
    initialValues: {
      updateType: HouseUpdateType.UPDATE_EXISTING_INSTALLATION,
      existingInstallationCapacity,
      existingInstallationContract,
      existingInstallationAge,
      existingInstallationDetails,
      existingInstallationFilesPaths,
    },
  })

  return (
    <Stack>
      <Text size="md" fw="bold">
        Installation existante
      </Text>
      <SimpleGrid cols={2} spacing="32" mt="16" mb="32">
        <Stack gap="24">
          <Field name="Puissance installée existante (kWc)">
            <NumberInput
              w={FIELD_WIDTH}
              suffix="kWc"
              hideControls
              {...form.getInputProps("existingInstallationCapacity")}
            />
          </Field>
          <Field name="Contrat de revente">
            <RadioGroup
              nullable
              options={EXISTING_INSTALLATION_CONTRACT_OPTIONS}
              {...form.getInputProps("existingInstallationContract")}
            />
          </Field>
          <Field name="Ancienneté de l'installation actuelle">
            <RadioGroup
              nullable
              options={EXISTING_INSTALLATION_AGE_OPTIONS}
              {...form.getInputProps("existingInstallationAge")}
            />
          </Field>
        </Stack>
        <Stack gap="24">
          <Field name="Description de l'installation existante">
            <Textarea
              autosize
              placeholder={
                "Ex: 4 panneaux DualSun, 4 micro-onduleurs Enphase\nAnnée installation : 2016\nNuméro de BTA : TF45678\nNuméro d'affaires : 7890098"
              }
              w={FIELD_WIDTH}
              minRows={3}
              {...form.getInputProps("existingInstallationDetails")}
            />
          </Field>
          <Field
            name="Documents complémentaires"
            subtitle="Demande préalable, facture, etc."
          >
            <MultiFileField
              previewProps={{
                fileName: getProspectFileName(
                  "doc-installation-existante",
                  prospect,
                ),
              }}
              inputProps={{ accept: ["application/pdf"] }}
              {...form.getInputProps("existingInstallationFilesPaths")}
            />
          </Field>
        </Stack>
      </SimpleGrid>
    </Stack>
  )
}
