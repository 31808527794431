import dayjs from "dayjs"

import { PaymentStep } from "@ensol/shared/entities/projects/processes"
import {
  SHORT_DISPLAY_DATE_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { FundingSourceInfo } from "@ensol/entool/components/entities/Project/FundingSourceInfo"
import { ProcessInfos } from "@ensol/entool/components/entities/Project/ProcessInfos"

import { InfoComponentProps } from "../config"

export const PaymentInfo = ({ project }: InfoComponentProps) => {
  const {
    paymentStep,
    paymentHoldEndDate,
    installationStartDate,
    fundingSource,
    fundingSourceStatus,
  } = project

  return (
    <ProcessInfos
      infos={[
        {
          name: "Date installation",
          value: formatDate(installationStartDate, SHORT_DISPLAY_DATE_FORMAT),
          color: dayjs().add(2, "weeks").isAfter(installationStartDate)
            ? "red.5"
            : undefined,
        },
        {
          name: "Date relance",
          value: formatDate(paymentHoldEndDate, SHORT_DISPLAY_DATE_FORMAT),
          color: dayjs().isAfter(paymentHoldEndDate) ? "red.5" : undefined,
          isHidden:
            paymentStep !== PaymentStep.DEPOSIT_INVOICE_TO_SEND &&
            paymentStep !== PaymentStep.DEPOSIT_INVOICE_WAITING,
        },
        {
          name: "Financement",
          value: fundingSource ? (
            <FundingSourceInfo
              fundingSource={fundingSource}
              fundingSourceStatus={fundingSourceStatus}
            />
          ) : null,
          dataType: "component",
        },
      ]}
    />
  )
}
