import { Text, Anchor, Stack } from "@mantine/core"

import { NumberInput, NumberInputProps } from "./NumberInput"

type Props = NumberInputProps & {
  suggestion?: number
}

export const NumberInputWithSuggestion = ({ suggestion, ...props }: Props) => {
  return (
    <Stack align="flex-end" gap={4}>
      <NumberInput w={200} ml="auto" {...props} defaultValue={0} />
      {suggestion != null && (
        <Text size="sm" c="gray.7">
          Suggestion:{" "}
          <Anchor
            fw={600}
            onClick={() => {
              if (props.onChange && suggestion) {
                props.onChange?.(suggestion)
              }
            }}
          >
            {suggestion}
            {props.suffix}
          </Anchor>
        </Text>
      )}
    </Stack>
  )
}
