import { Group, Stack, Tabs } from "@mantine/core"
import { Helmet } from "react-helmet-async"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { GENDERS, getFullName } from "@ensol/shared/entities/prospects"

import { queryClient } from "@ensol/entool/backend/queryClient"
import { BreadCrumb } from "@ensol/entool/components/BreadCrumb"
import { ProjectHeader } from "@ensol/entool/components/entities/Project/Header"
import { EditProspectButton } from "@ensol/entool/components/form/Prospect/EditProspectButton"
import { ContentContainer } from "@ensol/entool/components/layout/ContentContainer"
import {
  CONTENT_PADDING_IN_PX,
  PROJECT_HEADER_HEIGHT_IN_PX,
  useResponsiveDimensions,
} from "@ensol/entool/styles/constants"
import { useTabsNavigation } from "@ensol/entool/utils/helpers/useTabsNavigation"

import { ProjectTechnicalOverview } from "../ProjectTechnicalOverview/ProjectTechnicalOverview"

import { Overview } from "./Overview"
import { PROJECT_PROCESS_DETAILS_VIEWS } from "./config"

type Props = {
  project: ProjectResponses.Project
}

const OVERVIEW_TAB_ID = "overview"
const TECHNICAL_OVERVIEW_TAB_ID = "technicalOverview"

export const Project = ({ project }: Props) => {
  const { navbarHeight } = useResponsiveDimensions()
  const { currentTab, handleTabChange } = useTabsNavigation([
    { id: OVERVIEW_TAB_ID },
    { id: TECHNICAL_OVERVIEW_TAB_ID },
    ...PROJECT_PROCESS_DETAILS_VIEWS,
  ])

  const { house } = project.installation
  const { prospect } = house

  return (
    <Stack flex="1" gap="0" h="100%">
      <Helmet>
        <title>{getFullName(prospect)} | Entool</title>
      </Helmet>
      <ProjectHeader
        project={project}
        BreadCrumbs={[
          <BreadCrumb
            key="projects"
            to={
              currentTab === OVERVIEW_TAB_ID ||
              currentTab === TECHNICAL_OVERVIEW_TAB_ID
                ? "/projects"
                : `/projects/processes/${currentTab}`
            }
            label="Projets"
          />,
          <Group gap="4" key="prospectName" wrap="nowrap">
            <BreadCrumb
              to={`/prospects/${prospect.id}`}
              label={`${prospect.gender ? GENDERS[prospect.gender].short : ""} ${getFullName(prospect)}`}
            />
            <EditProspectButton
              prospect={prospect}
              onSuccess={async () =>
                await queryClient.invalidateQueries({
                  queryKey: ["projects", project.id],
                })
              }
            />
          </Group>,
        ]}
      />

      <ContentContainer flex="1" px="0" mt="0" bg="gray.0">
        <Tabs
          value={currentTab}
          onChange={handleTabChange}
          style={{ display: "flex", flexDirection: "column", flex: 1 }}
          h="100%"
          orientation="vertical"
          variant="outline"
          keepMounted={false}
        >
          <Group flex="0" wrap="nowrap" align="flex-start" gap="24">
            <Tabs.List
              pt={CONTENT_PADDING_IN_PX}
              pl={CONTENT_PADDING_IN_PX}
              h={`calc(100dvh - ${navbarHeight + PROJECT_HEADER_HEIGHT_IN_PX}px)`}
              pos="sticky"
              top={navbarHeight + PROJECT_HEADER_HEIGHT_IN_PX}
              flex="0"
              bg="white"
            >
              <Tabs.Tab key="overview" value="overview">
                Résumé
              </Tabs.Tab>
              <Tabs.Tab key="technicalOverview" value="technicalOverview">
                Résumé technique
              </Tabs.Tab>
              {PROJECT_PROCESS_DETAILS_VIEWS.map(({ id, name }) => (
                <Tabs.Tab
                  key={id}
                  value={id}
                  style={{ color: project[id] === null ? "grey" : "black" }}
                >
                  {name}
                </Tabs.Tab>
              ))}
            </Tabs.List>
            <Stack
              gap="0"
              pt={CONTENT_PADDING_IN_PX}
              pr={CONTENT_PADDING_IN_PX}
              flex="1"
            >
              <Tabs.Panel flex="1" mih="0" value="overview">
                <Overview project={project} />
              </Tabs.Panel>
              <Tabs.Panel flex="1" mih="0" value="technicalOverview">
                <ProjectTechnicalOverview
                  project={project}
                  pb={CONTENT_PADDING_IN_PX}
                />
              </Tabs.Panel>
              {PROJECT_PROCESS_DETAILS_VIEWS.map(
                ({ id, milestones, abortedMilestones, Component }) => (
                  <Tabs.Panel key={id} flex="1" mih="0" value={id}>
                    <Component
                      processId={id}
                      project={project}
                      milestonesConfig={milestones}
                      abortedMilestones={abortedMilestones}
                    />
                  </Tabs.Panel>
                ),
              )}
            </Stack>
          </Group>
        </Tabs>
      </ContentContainer>
    </Stack>
  )
}
