import { SimpleGrid, Textarea } from "@mantine/core"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import {
  PostDepositStepInput,
  postDepositStepsFormSchema,
} from "@ensol/types/forms/projects/payment"

import { Section } from "@ensol/entool/components/Section"
import { Field } from "@ensol/entool/components/form/Field"
import { FundingSourceField } from "@ensol/entool/components/form/Project/FundingSourceField"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { useProcessForm } from "@ensol/entool/utils/projects/useProcessForm"

import { PennylaneEstimate } from "./PennylaneEstimate"

type Props = {
  project: ProjectResponses.Project
  estimate: ProjectResponses.Estimate
}

export const PostDepositPaymentForm = ({ project, estimate }: Props) => {
  const form = useProcessForm<PostDepositStepInput>({
    schema: postDepositStepsFormSchema,
    initialValues: {
      paymentNotes: project.paymentNotes,
      fundingSource: project.fundingSource,
    },
    projectId: project.id,
    processId: "paymentStep",
  })

  return (
    <>
      <Section>
        <SimpleGrid cols={2} spacing="32">
          <FundingSourceField
            fundingSourceStatus={project.fundingSourceStatus}
            form={form}
          />
          <Field name="Notes">
            <Textarea
              autosize
              w={FIELD_WIDTH}
              minRows={3}
              {...form.getInputProps("paymentNotes")}
            />
          </Field>
        </SimpleGrid>
      </Section>

      <PennylaneEstimate estimate={estimate} project={project} />
    </>
  )
}
