import { Stack, Loader } from "@mantine/core"
import {
  IconAlertTriangleFilled,
  IconCircleCheckFilled,
  IconCircleXFilled,
  IconLink,
} from "@tabler/icons-react"

import { SwitchgridResponses } from "@ensol/types/endpoints/switchgrid"

import { ConsentAskStatus } from "@ensol/shared/entities/houses/switchgrid"

import { CopiableText } from "@ensol/entool/components/CopiableText"
import { Message } from "@ensol/entool/components/Message"

type Props = { switchgridConsent: SwitchgridResponses.SwitchgridConsent | null }

export const ConsentAskStatusMessages = ({ switchgridConsent }: Props) => {
  if (switchgridConsent === null) {
    return null
  }

  if (switchgridConsent.expirationDate < new Date()) {
    return (
      <Message
        color="red.3"
        Icon={<IconAlertTriangleFilled />}
        content="Le consentement a expiré, veuillez en demander un nouveau"
      />
    )
  }

  const { askStatus, askUrl } = switchgridConsent

  if (askStatus === ConsentAskStatus.PREPARING_ASK) {
    return (
      <Message
        color="gray.5"
        Icon={<Loader color="gray" size="xs" />}
        content="La préparation de la demande de consentement est en cours"
      />
    )
  }

  if (askStatus === ConsentAskStatus.ADDRESS_CHECK_FAILED) {
    return (
      <Message
        color="red"
        Icon={<IconCircleXFilled />}
        content="La validation de l'adresse et du PDL de la demande a échoué."
      />
    )
  }

  if (askStatus === ConsentAskStatus.PENDING_USER_ACTION && askUrl) {
    return (
      <Stack gap="8">
        <Message
          color="gray.5"
          Icon={<Loader color="gray" size="xs" />}
          content="En attente de la signature du client, copiez et envoyez le lien
            ci-dessous"
        />
        <CopiableText Icon={IconLink} text={askUrl} w="fit-content" />
      </Stack>
    )
  }

  if (askStatus === ConsentAskStatus.ACCEPTED) {
    return (
      <Message
        color="green.5"
        Icon={<IconCircleCheckFilled />}
        content="Le consentement a été signé par le client"
      />
    )
  }
}
