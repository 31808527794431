import { Badge, Divider, ScrollArea, Stack, Stepper } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import {
  IconAdjustmentsAlt,
  IconCircleCheck,
  IconPhoneRinging,
  IconTools,
} from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import {
  getInstallationAlert,
  isAlertOpened,
} from "@ensol/shared/entities/installations/alerts"
import {
  getStatusIndex,
  ProjectStatus,
} from "@ensol/shared/entities/projects/statuses"
import { getFullName } from "@ensol/shared/entities/prospects"
import { devices } from "@ensol/shared/styles/theme"
import { formatDate } from "@ensol/shared/utils/format"

import { Section } from "@ensol/entool/components/Section"
import { IntercomConversation } from "@ensol/entool/pages/OpsProjectsAlerts/IntercomConversation"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { HARDWARE_DELIVERY_MODE_OPTIONS } from "@ensol/entool/utils/projects/options"

import { InfoDisplay } from "./InfoDisplay"
import { ProcessBadge } from "./ProcessBadge"

type Props = {
  project: ProjectResponses.Project
}

const getActiveStep = (project: ProjectResponses.Project): number => {
  if (
    getStatusIndex(project.status) <=
    getStatusIndex(ProjectStatus.TECHNICAL_VISIT_COMPLETED)
  ) {
    return 0
  }

  if (
    getStatusIndex(project.status) <=
    getStatusIndex(ProjectStatus.INSTALLATION_COMPLETED)
  ) {
    return 1
  }

  return 2
}

export const ProcessesStatusSection = ({ project }: Props) => {
  const isSmallScreen = useMediaQuery(devices.md)

  return (
    <Section title="Statut du projet" isCollapsible w="100%">
      <Stepper
        active={getActiveStep(project)}
        completedIcon={<IconCircleCheck size={18} />}
        orientation={isSmallScreen ? "vertical" : "horizontal"}
        styles={{
          verticalSeparator: { display: "none" },
          separator: { marginTop: 19, flex: 0.5 },
          steps: { alignItems: "flex-start", gap: 16 },
          step: { flex: 1, alignItems: "flex-start" },
        }}
      >
        <Stepper.Step
          icon={<IconTools size={18} />}
          label="Visite technique"
          description={<TechnicalVisitStep project={project} />}
        />
        <Stepper.Step
          icon={<IconAdjustmentsAlt size={18} />}
          label="Installation"
          description={<InstallationStep project={project} />}
        />
        <Stepper.Step
          icon={<IconPhoneRinging size={18} />}
          label="Service après vente"
          description={<AlertsStep project={project} />}
        />
      </Stepper>
    </Section>
  )
}

const TechnicalVisitStep = ({ project }: Props) => {
  return (
    <Stack gap="8">
      <ProcessBadge
        processStep={project.technicalVisitStep}
        processId="technicalVisitStep"
      />
      <InfoDisplay
        label="Date de la visite"
        value={formatDate(project.technicalVisitStartDate)}
      />
      <InfoDisplay
        label="Installateur"
        value={project.technicalVisitInstaller?.name}
      />
    </Stack>
  )
}

const InstallationStep = ({ project }: Props) => {
  return (
    <Stack gap="8">
      <ProcessBadge
        processStep={project.installationStep}
        processId="installationStep"
      />
      <InfoDisplay
        label="Date d'installation"
        value={
          project.installationStartDate
            ? formatDate(project.installationStartDate)
            : undefined
        }
      />
      <InfoDisplay label="Installateur" value={project.installer?.name} />
      <Divider />
      <InfoDisplay
        label="Chantier"
        value={
          project.installationEstimatedDuration
            ? `${project.installationEstimatedDuration} jours`
            : undefined
        }
      />
      <InfoDisplay
        label="Res. chantier"
        value={
          project.siteManager ? getFullName(project.siteManager) : undefined
        }
      />
      <InfoDisplay label="Fournisseur" value={project.supplier?.name} />
      <Divider />
      <InfoDisplay
        label="Mode livraison"
        value={
          findSelectedOption(
            project.hardwareDeliveryMode,
            HARDWARE_DELIVERY_MODE_OPTIONS,
          )?.label
        }
      />
      <InfoDisplay
        label="Adresse livraison"
        value={project.hardwareDeliveryAddress}
      />
    </Stack>
  )
}

const AlertsStep = ({ project }: Props) => {
  const openedAlerts = project.installation.alerts.filter(isAlertOpened)

  return (
    <ScrollArea.Autosize mah="400px">
      {openedAlerts.length === 0 ? (
        <Badge color="green" variant="light">
          Aucun SAV
        </Badge>
      ) : (
        <Stack gap={8}>
          <Badge color="orange" variant="light">
            SAV
          </Badge>
          {openedAlerts.map((alert, index) => (
            <Stack key={alert.id} gap={8} pr={10}>
              <InfoDisplay
                label="Date du ticket"
                value={formatDate(alert.date)}
              />
              <InfoDisplay
                label="Problème"
                value={getInstallationAlert(alert.type).name}
              />
              <IntercomConversation
                alertId={alert.id}
                intercomConversationId={alert.intercomConversationId}
              />
              {index < openedAlerts.length - 1 && <Divider />}
            </Stack>
          ))}
        </Stack>
      )}
    </ScrollArea.Autosize>
  )
}
