import _ from "lodash"

import { RoofSectionWithPanels } from "@ensol/types/forms/installations/photovoltaic"

import { withMargin } from "@ensol/shared/entities/installations/costs"
import { CurrentType } from "@ensol/shared/material/currentType"
import { getCompatibleElectricalSafeGuard } from "@ensol/shared/material/photovoltaic/electricalSafeGuards"
import {
  getInverter,
  InverterType,
} from "@ensol/shared/material/photovoltaic/inverters"
import {
  getOptimizer,
  OptimizerType,
} from "@ensol/shared/material/photovoltaic/optimizers"
import { getPanel, PanelType } from "@ensol/shared/material/photovoltaic/panels"
import { getPhotovoltaicSmartMeter } from "@ensol/shared/material/photovoltaic/smartMeters"

export const computePanelUnitPrice = (panelType: PanelType) => {
  const panel = getPanel(panelType)
  return withMargin(panel.price)
}

export const computeInverterUnitPrice = (
  inverterType: InverterType,
): number => {
  const inverter = getInverter(inverterType)
  return withMargin(inverter.price)
}

export const computeOptimizerUnitPrice = (
  optimizerType: OptimizerType | null,
): number => {
  if (optimizerType === null) {
    return 0
  }
  const optimizer = getOptimizer(optimizerType)
  return withMargin(optimizer!.price)
}

export const computePhotovoltaicSmartMeterPrice = (
  inverterType: InverterType,
): number => {
  const smartMeter = getPhotovoltaicSmartMeter(inverterType)
  return withMargin(smartMeter.price)
}

export const computeElectricalSafeGuardUnitPrice = (
  panelsCount: number,
  inverterType: InverterType,
  currentType: CurrentType,
) => {
  const electricalSafeGuard = getCompatibleElectricalSafeGuard(
    panelsCount,
    inverterType,
    currentType,
  )
  const inverter = getInverter(inverterType)

  return withMargin(
    electricalSafeGuard.acPrice +
      (inverter.isCentralInverter ? electricalSafeGuard.dcPrice : 0),
  )
}

// Build kits
const BUILD_KIT_PRICE_IN_EURO = 57.5
const FLAT_ROOF_BUILD_KIT_PRICE_IN_EURO = 75

const computeBuildKitUnitPrice = (hasFlatRoof: boolean): number => {
  if (hasFlatRoof) return withMargin(FLAT_ROOF_BUILD_KIT_PRICE_IN_EURO)
  return withMargin(BUILD_KIT_PRICE_IN_EURO)
}

export const computeBuildKitPrice = (roofSections: RoofSectionWithPanels[]) =>
  _.sumBy(
    roofSections,
    ({ roofSection, panelsCount }) =>
      panelsCount * computeBuildKitUnitPrice(roofSection.hasFlatRoof),
  )
