import { HousesResponses } from "@ensol/types/endpoints/houses"

import { getHousePhotoFileName } from "@ensol/shared/entities/houses/photos"

import { Field } from "@ensol/entool/components/form/Field"
import { MultiFileField } from "@ensol/entool/components/form/File/MultiFileField"

type Props = {
  houseStreetAddress: HousesResponses.House["streetAddress"]
  value?: string[]
  error?: string
  onChange: (filePath: string[]) => void
  label: string
  fileNameExtension: string
  isRequired: boolean
  placeholderImage?: string
}

export const HousePhotosField = ({
  houseStreetAddress,
  value,
  error,
  onChange,
  label,
  fileNameExtension,
  isRequired,
  placeholderImage,
}: Props) => (
  <Field name={label} withAsterisk={isRequired}>
    <MultiFileField
      previewProps={{
        fileName: getHousePhotoFileName(houseStreetAddress, fileNameExtension),
      }}
      inputProps={{
        accept: ["image/png", "image/jpeg", "image/heic"],
        capture: "environment",
      }}
      value={value ?? []}
      error={error}
      onChange={onChange}
      placeholderImage={placeholderImage}
    />
  </Field>
)
