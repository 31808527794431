import { Stack, Group, Badge, Text } from "@mantine/core"
import { IconExclamationCircle } from "@tabler/icons-react"

import { HousesResponses } from "@ensol/types/endpoints/houses"

import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { POTENTIAL_SHADINGS_OPTIONS } from "@ensol/entool/utils/house/options"

const ADDITIONAL_INFORMATIONS_CONFIG = [
  {
    condition: (house: HousesResponses.House) =>
      house.existingInstallationCapacity !== null ||
      house.existingInstallationContract !== null ||
      house.existingInstallationAge !== null,
    getLabel: () => "Panneaux existants",
    color: "red",
  },
  {
    condition: (house: HousesResponses.House) => house.isPodNeeded,
    getLabel: () => "Nacelle nécessaire",
    color: "red",
  },
  {
    condition: (house: HousesResponses.House) =>
      house.isPowerLineNearby === false,
    getLabel: () => "Pas de ligne électrique à moins de 5m de la toiture",
    color: "red",
  },
  {
    condition: (house: HousesResponses.House) =>
      house.potentialShading && house.potentialShading !== "no",
    getLabel: (house: HousesResponses.House) =>
      `Présence d'ombrage : ${
        findSelectedOption(house.potentialShading, POTENTIAL_SHADINGS_OPTIONS)
          ?.label
      }`,
    color: "red",
  },
  {
    condition: (house: HousesResponses.House) =>
      house.isAtticAccessible === false,
    getLabel: () => "Pas d'accès aux combles",
    color: "red",
  },
  {
    condition: (house: HousesResponses.House) => house.hasLinky === false,
    getLabel: () => "Pas de compteur Linky",
    color: "red",
  },
  {
    condition: (house: HousesResponses.House) => house.floors,
    getLabel: (house: HousesResponses.House) =>
      `Nombre d'étages : R${house.floors}`,
    color: "yellow",
  },
]

export const AdditionalInformation = ({
  house,
}: {
  house: HousesResponses.House
}) => {
  const badges = ADDITIONAL_INFORMATIONS_CONFIG.filter(({ condition }) =>
    condition(house),
  )

  if (badges.length === 0) {
    return null
  }

  return (
    <Stack>
      <Text fz="lg" fw={500}>
        Informations supplémentaires
      </Text>
      <Group gap={8}>
        {badges.map(({ getLabel, color }) => (
          <Badge
            key={getLabel(house)}
            color={color}
            fw="bold"
            variant="light"
            leftSection={<IconExclamationCircle size={16} />}
          >
            {getLabel(house)}
          </Badge>
        ))}
      </Group>
    </Stack>
  )
}
