import {
  IconHome,
  IconBriefcase,
  IconHomeCog,
  IconAlertCircle,
  IconCalendarMonth,
  IconUsers,
  IconHomeMove,
  TablerIcon,
} from "@tabler/icons-react"

import { Role } from "@ensol/shared/entities/users"

import { PROJECTS_PROCESS_VIEWS } from "@ensol/entool/utils/projects/views"

export type SubMenuItem = {
  id?: string
  to: string
  name: string
  color?: string
  isExternal?: boolean
  Icon: TablerIcon
}

export type MenuItem =
  | {
      id?: string
      to: string
      name: string
      Icon: TablerIcon
      isExternal?: boolean
      subItems?: undefined
      roles: Role[]
    }
  | {
      id?: string
      to?: undefined
      name: string
      Icon: TablerIcon
      isExternal?: undefined
      subItems: SubMenuItem[]
      roles: Role[]
    }

export const HOME_MENU_ITEM: MenuItem = {
  id: "/$",
  to: "/",
  name: "Accueil",
  Icon: IconHome,
  roles: [Role.EMPLOYEE],
}

export const GLOBAL_MENU_ITEMS: MenuItem[] = [
  HOME_MENU_ITEM,
  {
    id: "(projects$|projects\\?)",
    to: "/projects",
    name: "Projets",
    Icon: IconBriefcase,
    roles: [Role.EMPLOYEE, Role.INSTALLER],
  },
  {
    id: "projects/",
    name: "Opérations",
    Icon: IconHomeCog,
    roles: [Role.EMPLOYEE],
    subItems: [
      ...PROJECTS_PROCESS_VIEWS.map(({ id, name, color, Icon }) => ({
        id,
        to: `/projects/processes/${id}`,
        name,
        color,
        Icon,
      })),
      {
        id: "alerts",
        to: "/projects/alerts",
        name: "Alertes",
        color: "red",
        Icon: IconAlertCircle,
      },
    ],
  },
  {
    id: "events",
    to: "/events",
    name: "Calendrier",
    Icon: IconCalendarMonth,
    roles: [Role.EMPLOYEE],
  },
  {
    id: "prospects",
    to: "/prospects",
    name: "Prospects",
    Icon: IconUsers,
    roles: [Role.EMPLOYEE],
  },
  {
    id: "sales",
    to: "/sales",
    name: "Tâches AE",
    Icon: IconHomeMove,
    roles: [Role.EMPLOYEE],
  },
]
