import { ThemeIcon, ThemeIconProps } from "@mantine/core"
import {
  IconProgress,
  IconCircleCheck,
  IconCircleX,
  IconCancel,
  IconProgressAlert,
} from "@tabler/icons-react"

import { FundingSourceStatus } from "@ensol/shared/entities/projects/fundingSource"

type Props = {
  type: FundingSourceStatus | null
} & Omit<ThemeIconProps, "c" | "size">

export const FundingSourceStatusInfo = ({ type, ...themeIconProps }: Props) => {
  switch (type) {
    case FundingSourceStatus.PENDING: {
      const label = "En attente"
      return (
        <ThemeIcon {...themeIconProps} c="orange" size="20">
          <IconProgress title={label} aria-label={label} />
        </ThemeIcon>
      )
    }
    case FundingSourceStatus.APPROVED: {
      const label = "Approuvé"
      return (
        <ThemeIcon {...themeIconProps} c="green.4" size="20">
          <IconCircleCheck title={label} aria-label={label} />
        </ThemeIcon>
      )
    }
    case FundingSourceStatus.REFUSED: {
      const label = "Refusé"
      return (
        <ThemeIcon {...themeIconProps} c="red" size="20">
          <IconCircleX color="red" size={20} title={label} aria-label={label} />
        </ThemeIcon>
      )
    }
    case FundingSourceStatus.CANCELLED: {
      const label = "Abandonné"
      return (
        <ThemeIcon {...themeIconProps} c="orange.6" size="20">
          <IconCancel title={label} aria-label={label} />
        </ThemeIcon>
      )
    }
    case FundingSourceStatus.INCOMPLETE: {
      const label = "Incomplet"
      return (
        <ThemeIcon {...themeIconProps} c="gray.6" size="20">
          <IconProgressAlert title={label} aria-label={label} />
        </ThemeIcon>
      )
    }
    default:
      return null
  }
}
