import { approvedStepFormSchema } from "@ensol/types/forms/projects/preliminaryRequest"

import { EmailEvent } from "@ensol/shared/entities/projects/emails"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { PreliminaryRequestForm } from "@ensol/entool/pages/OpsProject/processes/PreliminaryRequest/components/PreliminaryRequestForm"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"

export const AuthorisationReceived = ({
  project,
  ...props
}: StepComponentProps) => (
  <PreliminaryRequestForm
    schema={approvedStepFormSchema}
    initialValues={getInitialValues(approvedStepFormSchema, project)}
    project={project}
    actions={[]}
    emails={[
      {
        eventName: EmailEvent.PRELIMINARY_REQUEST_VALIDATED,
        label: "Envoyer le mail de demande préalable validée",
      },
    ]}
    {...props}
  />
)
