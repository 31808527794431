import {
  Badge,
  Button,
  Group,
  Paper,
  Stack,
  Tabs,
  Text,
  ThemeIcon,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconPencil, IconPlus } from "@tabler/icons-react"
import { useEffect, useState } from "react"
import { z } from "zod"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import {
  RoofSectionWithPanels,
  roofSectionWithPanelsSchema,
} from "@ensol/types/forms/installations/photovoltaic"
import { HouseSignedInstallations } from "@ensol/types/installation"

import { formatRoofSectionName } from "@ensol/shared/entities/houses/roofSection"
import { computeSignedInstallationsPanelsCountForRoofSection } from "@ensol/shared/entities/installations/energy"

import { Field } from "@ensol/entool/components/form/Field"
import { NumberInputWithSuggestion } from "@ensol/entool/components/form/NumberInputWithSuggestion"
import { isFormValid } from "@ensol/entool/utils/form/validation"

import { RoofSectionForm } from "./RoofSectionForm"

type Props = {
  roofSectionsWithPanels: RoofSectionWithPanels[]
  house: HousesResponses.House<{
    include: { roofSections: true }
  }>
  onChange: (data: RoofSectionWithPanels[]) => void
  setError: (error: string) => void
  clearError: () => void
  signedInstallations?: HouseSignedInstallations
}

export const RoofSectionsPanelsForm = ({
  roofSectionsWithPanels,
  house,
  onChange,
  setError,
  clearError,
  signedInstallations,
}: Props) => {
  const [isEditingRoofSection, setIsEditingRoofSection] = useState(false)
  const [selectedRoofSectionId, setSelectedRoofSectionId] = useState<string>(
    roofSectionsWithPanels[0]?.roofSection.id ?? "",
  )

  const form = useForm({
    onValuesChange: ({ roofSectionsWithPanels }) =>
      onChange(roofSectionsWithPanels),
    validate: zodResolver(
      z.object({
        roofSectionsWithPanels: z.array(roofSectionWithPanelsSchema),
      }),
    ),
    initialValues: { roofSectionsWithPanels },
  })

  // Validate the form and update error state of the main installation form
  useEffect(() => {
    if (!isFormValid(form)) {
      setError("roof sections form is invalid")
    } else {
      clearError()
    }
  })

  return (
    <Stack>
      <Tabs
        value={selectedRoofSectionId}
        onChange={(tabIndex) => {
          if (tabIndex) {
            setIsEditingRoofSection(false)
            setSelectedRoofSectionId(tabIndex)
          }
        }}
      >
        <Tabs.List>
          {roofSectionsWithPanels.map(({ roofSection }, index) => {
            const installedPanelsCountOnRoofSection =
              computeSignedInstallationsPanelsCountForRoofSection(
                roofSection.id,
                signedInstallations,
              )

            return (
              <Tabs.Tab key={roofSection.id} value={roofSection.id}>
                <Group wrap="nowrap" justify="space-between">
                  <Text>{formatRoofSectionName(roofSection)}</Text>
                  <Badge variant="light" color="blue">
                    {installedPanelsCountOnRoofSection
                      ? `${installedPanelsCountOnRoofSection} (+${roofSectionsWithPanels[index].panelsCount})`
                      : roofSectionsWithPanels[index].panelsCount}
                  </Badge>
                </Group>
              </Tabs.Tab>
            )
          })}
          <Tabs.Tab key="addRoofSection" value="addRoofSection">
            <Group gap={4}>
              <ThemeIcon c="blue">
                <IconPlus />
              </ThemeIcon>
              <Text c="blue" fw={600}>
                Ajouter Pan
              </Text>
            </Group>
          </Tabs.Tab>
        </Tabs.List>
        {roofSectionsWithPanels.map(({ roofSection }, index) => {
          return (
            <Tabs.Panel key={roofSection.id} value={roofSection.id}>
              {isEditingRoofSection ? (
                <RoofSectionForm
                  house={house}
                  roofSection={roofSection}
                  onSuccess={(updatedRoofSection) => {
                    form.setFieldValue(
                      `roofSectionsWithPanels.${index}.roofSection`,
                      updatedRoofSection,
                    )
                    setIsEditingRoofSection(false)
                  }}
                />
              ) : (
                <Paper p={24} mt={24} withBorder>
                  <Stack gap={24}>
                    <Field name="Nombre de Panneaux" align="flex-start">
                      <NumberInputWithSuggestion
                        w={300}
                        min={0}
                        max={80}
                        data-test="panelsCount"
                        isNullable={false}
                        {...form.getInputProps(
                          `roofSectionsWithPanels.${index}.panelsCount`,
                        )}
                      />
                    </Field>
                    <Group justify="flex-end">
                      <Button
                        size="xs"
                        variant="light"
                        leftSection={<IconPencil size={16} />}
                        onClick={() => setIsEditingRoofSection(true)}
                      >
                        Éditer pan
                      </Button>
                    </Group>
                  </Stack>
                </Paper>
              )}
            </Tabs.Panel>
          )
        })}
        <Tabs.Panel key="addRoofSection" value="addRoofSection">
          <RoofSectionForm
            house={house}
            onSuccess={(roofSection) => {
              form.insertListItem("roofSectionsWithPanels", {
                roofSection,
                panelsCount: 0,
              })
              setSelectedRoofSectionId(roofSection.id)
            }}
          />
        </Tabs.Panel>
      </Tabs>
    </Stack>
  )
}
