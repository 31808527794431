import { SimpleGrid } from "@mantine/core"
import { ReactNode } from "react"

import { getZIndex } from "@ensol/shared/styles/zIndex"

import {
  INSTALLATION_HEADER_HEIGHT_IN_PX,
  useResponsiveDimensions,
} from "@ensol/entool/styles/constants"

type Props = {
  children: ReactNode[]
}

export const StickyStatsSection = ({ children }: Props) => {
  const { navbarHeight } = useResponsiveDimensions()

  return (
    <SimpleGrid
      cols={children.length}
      spacing={12}
      pos="sticky"
      top={INSTALLATION_HEADER_HEIGHT_IN_PX + navbarHeight}
      px={4}
      mx={-4}
      pt={24}
      mt={-24}
      pb={16}
      mb={-16}
      bg="gray.0"
      style={{ zIndex: getZIndex("stickyHeader") }}
    >
      {children}
    </SimpleGrid>
  )
}
