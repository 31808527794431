import {
  NumberInputProps as MantineNumberINput,
  NumberInput as BaseNumberInput,
} from "@mantine/core"

export type NumberInputProps = {
  onChange: (value: number | null) => void
  isNullable?: boolean
} & Omit<MantineNumberINput, "onChange">

export const NumberInput = ({
  isNullable = true,
  ...props
}: NumberInputProps) => {
  const handleChange = (value: string | number) => {
    if (value === "") {
      props.onChange(isNullable ? null : 0)
    } else props.onChange(+value)
  }
  return <BaseNumberInput {...props} onChange={handleChange} />
}
