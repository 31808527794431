import { z } from "zod"

import { installationToValidateFormSchema } from "@ensol/types/forms/projects/followUp"
import { checkField } from "@ensol/types/forms/projects/shared"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { FollowUpForm } from "@ensol/entool/pages/OpsProject/processes/FollowUp/components/FollowUpForm"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"
import { validateAction } from "@ensol/entool/utils/projects/validateAction"

export const InstallationToValidate = ({
  project,
  ...props
}: StepComponentProps) => (
  <FollowUpForm
    schema={installationToValidateFormSchema}
    initialValues={getInitialValues(installationToValidateFormSchema, project)}
    project={project}
    actions={[
      {
        action: "validate",
        label: "Valider l'installation",
        validateAction: validateAction(
          z.object({
            isPanelsLayoutValid: checkField,
            areClampsWorking: checkField,
            hasNoAlerts: checkField,
          }),
        ),
      },
    ]}
    {...props}
  />
)
