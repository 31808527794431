import { IconBarrierBlock } from "@tabler/icons-react"

import { submittedStepFormSchema } from "@ensol/types/forms/projects/enedis"

import { EmailEvent } from "@ensol/shared/entities/projects/emails"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { EnedisForm } from "@ensol/entool/pages/OpsProject/processes/Enedis/components/EnedisForm"
import { ENEDIS_PROPERTIES } from "@ensol/entool/pages/OpsProject/processes/Enedis/config"
import { pickDefinedProperties } from "@ensol/entool/utils/helpers/pick"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"

export const Submitted = ({ project, processId }: StepComponentProps) => (
  <EnedisForm
    schema={submittedStepFormSchema}
    initialValues={{
      ...getInitialValues(submittedStepFormSchema, project),
      ...pickDefinedProperties(ENEDIS_PROPERTIES, project),
    }}
    project={project}
    processId={processId}
    actions={[
      {
        action: "incomplete",
        color: "orange",
        label: "Marquer comme incomplet",
        Icon: IconBarrierBlock,
      },
      { action: "validate", label: "Demande validée" },
    ]}
    emails={[
      {
        eventName: EmailEvent.ENEDIS_EDF_OA_SUBMITTED,
        label: "Envoyer le mail ENEDIS + EDF OA soumis",
      },
    ]}
  />
)
