import * as z from "zod"
import { transformSchemaToNullish } from "../utils"

export const emailSentStepFormSchema = z.object({
  edfOaNotes: z.string().optional(),
})

export const certificateToSendStepFormSchema = emailSentStepFormSchema.extend({
  edfOaCertificatePath: z.string({
    invalid_type_error: "Champ obligatoire",
  }),
})

export type EdfOaStepInput =
  | z.infer<typeof emailSentStepFormSchema>
  | z.infer<typeof certificateToSendStepFormSchema>

export const edfOaProcessSchema = transformSchemaToNullish(
  certificateToSendStepFormSchema,
)
