import { IconArrowBackUp } from "@tabler/icons-react"

import { scheduledStepFormSchema } from "@ensol/types/forms/projects/installation"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"

import { InstallationForm } from "./components/InstallationForm"

export const ProjectPrepared = ({ project, ...props }: StepComponentProps) => (
  <InstallationForm
    schema={scheduledStepFormSchema}
    initialValues={getInitialValues(scheduledStepFormSchema, project)}
    project={project}
    actions={[
      {
        action: "reschedule",
        label: "Repasser en prêt à planifier",
        color: "orange",
        Icon: IconArrowBackUp,
        confirmationMessage:
          "Les évènements installateur et client seront supprimés. L’installateur seul sera averti. Continuer ?",
      },
      { action: "validate", label: "Installation en cours" },
    ]}
    {...props}
  />
)
