import { Group, Paper, Stack, Text, ThemeIcon } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import { IconInfoCircle } from "@tabler/icons-react"

import { computeYearlyConsumption } from "@ensol/shared/entities/installations/energy"
import { formatCurrency } from "@ensol/shared/utils/format"

import { Field } from "@ensol/entool/components/form/Field"
import { NumberInput } from "@ensol/entool/components/form/NumberInput"

type Props<Values> = {
  form: UseFormReturnType<Values>
  linkBillAndConsumption: boolean
  isDisabled: boolean
}

export const MonthlyBillInput = <
  Values extends { yearlyConsumption: number; monthlyBill: number },
>({
  form,
  linkBillAndConsumption,
  isDisabled,
}: Props<Values>) => {
  const { yearlyConsumption, monthlyBill } = form.values

  return (
    <Field name="Facture mensuelle">
      <Stack gap="8">
        <NumberInput
          w={200}
          min={0}
          step={50}
          ml="auto"
          suffix=" €/mois"
          isNullable={false}
          disabled={isDisabled}
          {...form.getInputProps("monthlyBill")}
          onChange={(value) => {
            form.getInputProps("monthlyBill").onChange(value)
            if (linkBillAndConsumption) {
              form
                .getInputProps("yearlyConsumption")
                .onChange(computeYearlyConsumption(value ?? 0))
            }
          }}
        />
        {yearlyConsumption > 0 && (
          <Paper bg="blue.0" shadow="none">
            <Group gap="4" px="12" py="8">
              <ThemeIcon color="blue.5" variant="transparent" size="18">
                <IconInfoCircle />
              </ThemeIcon>
              <Text c="blue.5" size="sm">
                Prix moyen:{" "}
                {formatCurrency((12 * monthlyBill) / yearlyConsumption)} /kWh
              </Text>
            </Group>
          </Paper>
        )}
      </Stack>
    </Field>
  )
}
