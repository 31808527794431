import { Group, Text, ThemeIcon } from "@mantine/core"
import { IconInfoCircle } from "@tabler/icons-react"
import { PropsWithChildren } from "react"

type Props = PropsWithChildren<{
  color?: "gray" | "red"
}>

export const InfoBadge = ({ color = "gray", children }: Props) => {
  return (
    <Group
      w="fit-content"
      px={10}
      wrap="nowrap"
      mih={36}
      bg={`${color}.1`}
      style={{ borderRadius: 4 }}
      gap={4}
    >
      <ThemeIcon variant="transparent" c={`${color}.6`} size="xs">
        <IconInfoCircle />
      </ThemeIcon>
      <Text c={`${color}.6`} style={{ textWrap: "wrap" }}>
        {children}
      </Text>
    </Group>
  )
}
