import { useEffect, useState } from "react"

import { EnergyResponses } from "@ensol/types/endpoints/energy"
import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationFormInput } from "@ensol/types/forms/installations"
import { HouseSignedInstallations } from "@ensol/types/installation"
import { SimulationResults } from "@ensol/types/simulation"

import { computeTotalDiscount } from "@ensol/shared/entities/installations/savings"
import { runSimulation } from "@ensol/shared/entities/installations/simulation"

type UseSimulationResultsProps = {
  installationDate: Date
  installationValues: InstallationFormInput
  energyStats?: EnergyResponses.Stats | null
  autoConsumptionPercent?: number
  house: HousesResponses.House<{ include: { roofSections: true } }>
  signedInstallations?: HouseSignedInstallations
  isInstallationValid: boolean
}

export const useSimulationResults = ({
  installationDate,
  installationValues,
  energyStats,
  autoConsumptionPercent,
  house,
  signedInstallations,
  isInstallationValid,
}: UseSimulationResultsProps) => {
  const [simulationResults, setSimulationResults] =
    useState<SimulationResults>()

  useEffect(() => {
    if (isInstallationValid && energyStats && autoConsumptionPercent) {
      const result = runSimulation({
        monthlyBill: installationValues.monthlyBill,
        yearlyConsumption: energyStats.yearlyConsumption,
        autoConsumptionPercent,
        estimatedYearlyProduction: energyStats.yearlyProduction,
        photovoltaicInstallation: installationValues.photovoltaicInstallation,
        extraPanelsInstallation: installationValues.extraPanelsInstallation,
        evChargerInstallation: installationValues.evChargerInstallation,
        batteryInstallation: installationValues.batteryInstallation,
        productsCount: installationValues.productsCount,
        house,
        totalDiscount: computeTotalDiscount(installationValues.discount),
        signedInstallations,
        electricityPriceAnnualRaise:
          installationValues.electricityPriceAnnualRaise,
      })
      setSimulationResults(result)
    }
  }, [
    energyStats,
    autoConsumptionPercent,
    house,
    installationDate,
    installationValues.batteryInstallation,
    installationValues.discount,
    installationValues.electricityPriceAnnualRaise,
    installationValues.evChargerInstallation,
    installationValues.extraPanelsInstallation,
    installationValues.monthlyBill,
    installationValues.photovoltaicInstallation,
    installationValues.productsCount,
    isInstallationValid,
    signedInstallations,
  ])

  return simulationResults
}
