import { Quote } from "@ensol/types/prisma-client"

export const QuoteStatus = {
  CREATED: "CREATED",
  SENT: "SENT",
  VIEWED: "VIEWED",
  SIGNED: "SIGNED",
  CANCELLED: "CANCELLED",
} as const

export type QuoteStatus = keyof typeof QuoteStatus

export const STATUSES: Record<QuoteStatus, { label: string; color: string }> = {
  [QuoteStatus.CREATED]: { label: "Créé", color: "blue" },
  [QuoteStatus.SENT]: { label: "Envoyé", color: "blue" },
  [QuoteStatus.VIEWED]: { label: "Vu", color: "blue" },
  [QuoteStatus.SIGNED]: { label: "Signé", color: "green" },
  [QuoteStatus.CANCELLED]: { label: "Annulé", color: "red" },
}

export const isQuoteOutdated = (
  installationUpdatedAt: Date,
  quote?: Quote | null,
) =>
  quote === undefined ||
  quote === null ||
  installationUpdatedAt >= quote.updatedAt
