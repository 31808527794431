import * as z from "zod"

import {
  ConsumptionDataSource,
  ProductionDataSource,
} from "@ensol/types/simulation"
import {
  extraPanelsInstallationSchema,
  photovoltaicInstallationSchema,
} from "@ensol/types/forms/installations/photovoltaic"
import { batteryInstallationSchema } from "@ensol/types/forms/installations/battery"
import { extraWorksSchema } from "@ensol/types/forms/installations/extraWorks"
import { evChargerInstallationSchemaWithExtraWorks } from "@ensol/types/forms/installations/evCharger"

export const photovoltaicInstallationAndExtraWorksSchema =
  photovoltaicInstallationSchema.merge(extraWorksSchema)

export type PhotovoltaicInstallationAndExtraWorks = z.infer<
  typeof photovoltaicInstallationAndExtraWorksSchema
>

const installationProductsSchema = z.object({
  photovoltaicInstallation:
    photovoltaicInstallationAndExtraWorksSchema.nullable(),
  extraPanelsInstallation: extraPanelsInstallationSchema
    .merge(extraWorksSchema)
    .nullable(),
  batteryInstallation: batteryInstallationSchema
    .merge(extraWorksSchema)
    .nullable(),
  evChargerInstallation: evChargerInstallationSchemaWithExtraWorks.nullable(),
  productsCount: z.number().nonnegative().min(1),
})

export type InstallationProductsInput = z.infer<
  typeof installationProductsSchema
>

export const monthlyConsumptionsSchema = z.array(
  z.object({ month: z.string(), value: z.number() }),
)

export const createInstallationSchema = z.object({
  houseId: z.string(),
  isUpsell: z.boolean(),
})

export const updateInstallationSchema = installationProductsSchema.extend({
  name: z.string(),
  productionDataSource: z.nativeEnum(ProductionDataSource),
  consumptionDataSource: z.nativeEnum(ConsumptionDataSource),
  monthlyBill: z
    .number()
    .positive({ message: "Cette valeur doit être supérieure à 0" }),
  yearlyConsumption: z
    .number()
    .positive({ message: "Cette valeur doit être supérieure à 0" }),
  monthlyConsumptions: monthlyConsumptionsSchema,
  manualAutoConsumptionPercent: z.number().nonnegative(),
  computedAutoConsumptionPercent: z.number().nonnegative(),
  discount: z.number().nonnegative().optional().nullable(),
  referrerId: z.string().optional().nullable(),
  houseId: z.string(),
  prospectId: z.string(),
  bundled: z.boolean().optional(),
  isUpsell: z.boolean(),
  horizonProfileFilePath: z.string().nullable(),
})

export type UpdateInstallationInput = z.infer<typeof updateInstallationSchema>

export const installationFormSchema = updateInstallationSchema.extend({
  electricityPriceAnnualRaise: z.number(),
})

export type InstallationFormInput = z.infer<typeof installationFormSchema>
