import { FundingSource } from "@ensol/shared/entities/projects/fundingSource"

import {
  StepActions,
  StepComponentProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { GenerateEstimateForm } from "@ensol/entool/pages/OpsProject/processes/Payment/components/GenerateEstimateForm"

export const DepositWaiting = ({ project, processId }: StepComponentProps) => {
  const validateAction =
    project.fundingSource === FundingSource.CETELEM
      ? {
          action: "validateCetelem",
          label: "Demande Cetelem à envoyer au client",
          confirmationMessage:
            "Cette étape devrait être passée automatiquement lorsque l'installation est terminée, êtes-vous sûr de vouloir la passer manuellement ?",
        }
      : {
          action: "validate",
          label: "Facture d'acompte à envoyer",
        }

  return (
    <StepActions
      project={project}
      processId={processId}
      actions={[validateAction]}
    >
      <GenerateEstimateForm project={project} />
    </StepActions>
  )
}
