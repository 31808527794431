import { InstallationFormInput } from "@ensol/types/forms/installations"

import { computeBatteryInstallationName } from "./battery"
import { computeEvChargerInstallationName } from "./evCharger"
import { computePhotovoltaicInstallationName } from "./photovoltaic"

export const computeInstallationName = ({
  photovoltaicInstallation,
  extraPanelsInstallation,
  batteryInstallation,
  evChargerInstallation,
}: InstallationFormInput) => {
  const productsName = new Array<string>()

  if (photovoltaicInstallation !== null) {
    productsName.push(
      computePhotovoltaicInstallationName(photovoltaicInstallation),
    )
  }
  if (extraPanelsInstallation !== null) {
    productsName.push(
      computePhotovoltaicInstallationName(extraPanelsInstallation),
    )
  }

  if (batteryInstallation !== null) {
    productsName.push(computeBatteryInstallationName(batteryInstallation))
  }

  if (evChargerInstallation !== null) {
    productsName.push(computeEvChargerInstallationName(evChargerInstallation))
  }

  return productsName.join(" - ")
}
