import { Group, Stack, Text } from "@mantine/core"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"

import { getCoords } from "@ensol/shared/entities/houses/coords"

import { Map } from "@ensol/entool/components/Map"
import { Section } from "@ensol/entool/components/Section"
import { Installations } from "@ensol/entool/pages/Prospect/Installations"

type HouseProps = {
  house: ProspectResponses.ProspectDetails["houses"][number]
}

export const House = ({ house }: HouseProps) => (
  <Section>
    <Stack gap="24">
      <Group>
        <Map
          mapId={`house-${house.id}-map`}
          coords={getCoords(house)}
          style={{ width: 100, height: 60 }}
          zoomLevel={18}
          hidePin
        />
        <Stack gap="0">
          <Text size="xl" data-test="houseAddress">
            {house.streetAddress}
          </Text>
          <Text size="md" c="gray.5">
            {house.postcode} {house.city}, {house.country}
          </Text>
        </Stack>
      </Group>
      <Installations house={house} />
    </Stack>
  </Section>
)
