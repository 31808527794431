import _ from "lodash"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { RoofType } from "@ensol/types/prisma"

import { withMargin } from "@ensol/shared/entities/installations/costs"
import {
  computeInvertersCount,
  computePanelsCapacity,
} from "@ensol/shared/entities/installations/energy"
import {
  PHOTOVOLTAIC_SUBSIDIES,
  PhotovoltaicSubsidyType,
} from "@ensol/shared/entities/installations/subsidies/photovoltaic"
import { CURRENT_CONNECTIONS } from "@ensol/shared/material/currentType"
import { ROOF_TYPES } from "@ensol/shared/material/roofType"

import { CostsBuilder } from "./CostsBuilder"
import {
  computePanelUnitPrice,
  computeOptimizerUnitPrice,
  computeElectricalSafeGuardUnitPrice,
  computeInverterUnitPrice,
  computePhotovoltaicSmartMeterPrice,
  computeBuildKitPrice,
} from "./common"

// WORKFORCE COSTS {€)
const SECURING_COST_FOR_R1_LEVEL = 110 // €, for R1 level
const SECURING_COST_FOR_I35 = 400 // €, if inclination is > =35°
const TECHNICAL_VISIT_COST = 250 // €
const DELIVERY_COST = 360 // €
// Fixed installation cost defined by Solutions30 is 1300€, including single phase connections & roof with tiles.
// So individual costs are clearer, we subtract those values to the fixed installation cost.
// Details:
// - Proposed Solutions30 fixed installation cost: 1300€
// - Single phase connections cost: 180€
// - Roof with tiles cost: 250€
// - Rationalized fixed installation cost: 1300 - 180 - 250 = 870€
const INSTALLATION_FIXED_COST = 1300 - 180 - 250
const ROOF_MULTI_SECTIONS_COST = 250 // €

const computeLogisticsCost = (panelsCount: number): number => {
  if (panelsCount <= 16) return 90
  if (panelsCount <= 24) return 150
  return 330
}

const computeVariablePanelUnitInstallationUnitCost = (
  panelNumber: number,
): number => {
  if (panelNumber <= 8) return 80
  if (panelNumber <= 16) return 75
  if (panelNumber <= 24) return 70
  return 65
}

const computeVariablePanelInstallationUnitCost = (
  panelsCount: number,
): number => {
  return _.sum(
    _.times(panelsCount, (i) =>
      computeVariablePanelUnitInstallationUnitCost(i + 1),
    ),
  )
}

const computeRoofTypeInstallationCost = (roofType: RoofType): number => {
  return ROOF_TYPES[roofType].cost
}

export const computeAdditionalRoofSectionsCost = (
  roofSectionsWithPanels: InstallationsResponses.PhotovoltaicInstallation["roofSectionsWithPanels"],
) => {
  const roofSectionsCount = roofSectionsWithPanels.reduce(
    (acc, section) => (section.panelsCount > 0 ? acc + 1 : acc),
    0,
  )
  return (roofSectionsCount - 1) * ROOF_MULTI_SECTIONS_COST
}

const PHOTOVOLTAIC_ADMIN_FEE_COST_IN_EURO = 249

export class PhotovoltaicCostsBuilder extends CostsBuilder<
  InstallationsResponses.PhotovoltaicInstallation,
  PhotovoltaicSubsidyType
> {
  constructor(
    installation: InstallationsResponses.PhotovoltaicInstallation,
    private house: Pick<
      HousesResponses.House<{ include: { roofSections: true } }>,
      "constructionYear" | "currentType" | "roofSections" | "roofType"
    >,
    private totalDiscount: number,
  ) {
    super(installation)
  }

  public computeVatRate() {
    const currentYear = new Date().getFullYear()
    const houseAge = currentYear - this.house.constructionYear
    const installedCapacity = computePanelsCapacity(this.installation)

    if (installedCapacity <= 3 && houseAge >= 2) return 0.1
    return 0.2
  }

  public computeMaterialCosts() {
    const {
      panelType,
      panelsCount,
      inverterType,
      optimizerType,
      optimizerCount,
      roofSectionsWithPanels,
    } = this.installation
    const invertersCount = computeInvertersCount(inverterType, panelsCount)

    return (
      panelsCount * computePanelUnitPrice(panelType) +
      computeBuildKitPrice(roofSectionsWithPanels) +
      (optimizerCount ?? 0) * computeOptimizerUnitPrice(optimizerType) +
      invertersCount * computeInverterUnitPrice(inverterType) +
      computePhotovoltaicSmartMeterPrice(inverterType) +
      computeElectricalSafeGuardUnitPrice(
        panelsCount,
        inverterType,
        this.house.currentType,
      )
    )
  }

  public computeWorkforceCosts() {
    const { panelsCount, roofSectionsWithPanels } = this.installation

    const inclinationSecuringCost = roofSectionsWithPanels
      .filter((sec) => sec.panelsCount > 0)
      .some((section) => section.roofSection.inclination >= 35)
      ? SECURING_COST_FOR_I35
      : 0

    return withMargin(
      INSTALLATION_FIXED_COST +
        computeVariablePanelInstallationUnitCost(panelsCount) +
        computeRoofTypeInstallationCost(this.house.roofType) +
        computeLogisticsCost(panelsCount) +
        SECURING_COST_FOR_R1_LEVEL +
        inclinationSecuringCost +
        computeAdditionalRoofSectionsCost(roofSectionsWithPanels) +
        CURRENT_CONNECTIONS[this.house.currentType].cost +
        TECHNICAL_VISIT_COST +
        DELIVERY_COST,
    )
  }

  public computeAdminCosts() {
    return PHOTOVOLTAIC_ADMIN_FEE_COST_IN_EURO
  }

  public computeSubsidy() {
    const { subsidyType } = this.installation
    return subsidyType !== null
      ? {
          subsidyType,
          subsidyAmount: PHOTOVOLTAIC_SUBSIDIES[subsidyType].computeAmount({
            photovoltaicInstallation: this.installation,
            house: this.house,
            totalDiscount: this.totalDiscount,
          }),
        }
      : null
  }
}
