import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { QuoteDetails } from "@ensol/types/quote"

import { computeAllCosts } from "@ensol/shared/entities/installations/costs"
import { computeTotalDiscount } from "@ensol/shared/entities/installations/savings"
import { computeBatteryQuoteDetails } from "@ensol/shared/entities/quotes/details/battery"
import { computeEvChargerQuoteDetails } from "@ensol/shared/entities/quotes/details/evCharger"
import { computePanelsQuoteDetails } from "@ensol/shared/entities/quotes/details/panels"
import { computePhotovoltaicQuoteDetails } from "@ensol/shared/entities/quotes/details/photovoltaic"

export const computeQuoteDetails = (
  installation: InstallationsResponses.Installation,
): QuoteDetails => {
  const {
    photovoltaicInstallation,
    extraPanelsInstallation,
    batteryInstallation,
    evChargerInstallation,
    productsCount,
  } = installation
  const totalDiscount = computeTotalDiscount(installation.discount)

  const { finalCost, finalCostBeforeTax, finalCostWithSubsidies } =
    computeAllCosts({
      photovoltaicInstallation,
      extraPanelsInstallation,
      batteryInstallation,
      evChargerInstallation,
      house: installation.house,
      totalDiscount,
      productsCount,
    })

  return {
    ...(photovoltaicInstallation
      ? {
          photovoltaic: computePhotovoltaicQuoteDetails({
            photovoltaicInstallation,
            house: installation.house,
            totalDiscount,
          }),
        }
      : {}),
    ...(extraPanelsInstallation
      ? {
          panels: computePanelsQuoteDetails({
            extraPanelsInstallation,
            house: installation.house,
          }),
        }
      : {}),
    ...(batteryInstallation
      ? { battery: computeBatteryQuoteDetails(batteryInstallation) }
      : {}),
    ...(evChargerInstallation
      ? {
          evCharger: computeEvChargerQuoteDetails(
            evChargerInstallation,
            productsCount,
          ),
        }
      : {}),
    discounts: computeTotalDiscount(installation.discount),
    totals: { finalCost, finalCostBeforeTax, finalCostWithSubsidies },
  }
}
