export const HOUSE_EQUIPMENTS = [
  "electric-car",
  "battery",
  "panels",
  "air-air-pump",
  "air-water-pump",
  "washing-machine",
  "charging-point",
  "water-tank",
  "radiator",
  "air-conditioning",
  "pool",
  "pump",
  "stove",
  "organic-boiler",
  "electric-boiler",
  "boiler",
  "water-heater",
] as const

export type HouseEquipment = (typeof HOUSE_EQUIPMENTS)[number]

type EquipmentCategory = {
  title: string
  options: {
    label: string
    value: HouseEquipment
    displayInSimulator?: boolean
  }[]
}

export const EQUIPMENT_CATEGORIES: EquipmentCategory[] = [
  {
    title: "Système de chauffage",
    options: [
      {
        label: "Chaudière à Gaz / Bois / Granulés / Fioul",
        value: "organic-boiler",
      },
      {
        label: "Pompe à Chaleur Air-Air",
        value: "air-air-pump",
        displayInSimulator: true,
      },
      { label: "Chaudière Électrique", value: "electric-boiler" },
      {
        label: "Pompe à Chaleur Air-Eau",
        value: "air-water-pump",
        displayInSimulator: true,
      },
      {
        label: "Radiateur électrique",
        value: "radiator",
        displayInSimulator: true,
      },
      {
        label: "Cheminée / Poêle à Bois / Poêle à Granulés",
        value: "stove",
      },
    ],
  },
  {
    title: "Système de climatisation",
    options: [
      {
        label: "Air conditionné",
        value: "air-conditioning",
        displayInSimulator: true,
      },
    ],
  },
  {
    title: "Eau chaude",
    options: [
      { label: "Par la chaudière", value: "boiler" },
      {
        label: "Ballon électrique",
        value: "water-tank",
        displayInSimulator: true,
      },
      { label: "Chauffe-eau thermodynamique", value: "water-heater" },
    ],
  },
  {
    title: "Photovoltaïque",
    options: [
      { label: "PV", value: "panels" },
      { label: "Batterie", value: "battery" },
    ],
  },
  {
    title: "Mobilité",
    options: [
      {
        label: "Voiture électrique",
        value: "electric-car",
        displayInSimulator: true,
      },
      { label: "Borne de recharge", value: "charging-point" },
    ],
  },
  {
    title: "Autres équipements",
    options: [
      { label: "Piscine", value: "pool", displayInSimulator: true },
      { label: "Pompe de tirage", value: "pump" },
      { label: "Machine à laver", value: "washing-machine" },
    ],
  },
]
