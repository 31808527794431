import { Group, Badge } from "@mantine/core"
import {
  IconBatteryCharging,
  IconChargingPile,
  IconSolarPanel,
} from "@tabler/icons-react"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"

type Props = {
  installation: Pick<
    InstallationsResponses.Installation,
    | "photovoltaicInstallation"
    | "extraPanelsInstallation"
    | "batteryInstallation"
    | "evChargerInstallation"
  >
}

export const InstallationProducts = ({ installation }: Props) => (
  <Group gap="4">
    {installation.photovoltaicInstallation !== null && (
      <Badge variant="light" leftSection={<IconSolarPanel size={14} />}>
        PV
      </Badge>
    )}
    {installation.extraPanelsInstallation !== null && (
      <Badge variant="light" leftSection={<IconSolarPanel size={14} />}>
        Panneaux
      </Badge>
    )}
    {installation.batteryInstallation !== null && (
      <Badge variant="light" leftSection={<IconBatteryCharging size={16} />}>
        Batterie
      </Badge>
    )}
    {installation.evChargerInstallation !== null && (
      <Badge variant="light" leftSection={<IconChargingPile size={16} />}>
        Borne
      </Badge>
    )}
  </Group>
)
