import {
  Button,
  ScrollArea,
  Stack,
  StackProps,
  Text,
  Textarea,
} from "@mantine/core"
import { Form } from "@mantine/form"

import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"

import { Note } from "./Note"
import { useProjectNotes } from "./useProjectNotes"

type Props = {
  projectId: string
  inputPlaceholder?: string
} & StackProps

export const ProjectNotes = ({
  projectId,
  inputPlaceholder,
  ...props
}: Props) => {
  const {
    noteForm,
    setViewport,
    notesQuery,
    isAddNoteLoading,
    onSubmit,
    groupNotesByRelativeTime,
  } = useProjectNotes(projectId)

  return (
    <Stack mih={0} w="100%" {...props}>
      <QueryWrapper query={notesQuery}>
        {({ data: notes }) => {
          const groupedNotes = groupNotesByRelativeTime(notes)
          return (
            <ScrollArea viewportRef={(ref) => setViewport(ref)} pr={16}>
              {Object.entries(groupedNotes).map(([group, notes]) => (
                <div key={group}>
                  <Text size="xs" c="gray.7" ta="center" pt="12px">
                    {group}
                  </Text>
                  {notes.map((note) => (
                    <Note key={note.id} note={note} />
                  ))}
                </div>
              ))}
            </ScrollArea>
          )
        }}
      </QueryWrapper>
      <Form onSubmit={(values) => onSubmit(values.content)} form={noteForm}>
        <Textarea
          autosize
          minRows={4}
          maxRows={4}
          mb={16}
          placeholder={inputPlaceholder}
          {...noteForm.getInputProps("content")}
        />
        <Button
          type="submit"
          style={{ float: "right" }}
          mr="auto"
          loading={isAddNoteLoading}
          w={150}
          disabled={!noteForm.isValid() || isAddNoteLoading}
        >
          Post
        </Button>
      </Form>
    </Stack>
  )
}
