import {
  PreliminaryRequestStep,
  TechnicalVisitStep,
  GreendealStep,
  PaymentStep,
  HardwareStep,
  EnedisStep,
  ConsuelStep,
  InstallationStep,
  EdfOaStep,
  FollowUpStep,
  ProcessId,
  ProcessStep,
} from "@ensol/shared/entities/projects/processes"

import { ProjectMilestone } from "./types"

export const PRELIMINARY_REQUEST_STEPS: Record<
  PreliminaryRequestStep,
  ProjectMilestone<PreliminaryRequestStep>
> = {
  [PreliminaryRequestStep.WAITING_FOR_INFO]: {
    id: PreliminaryRequestStep.WAITING_FOR_INFO,
    label: "En attente info AE",
    category: "En préparation",
    color: "blue",
    nodePosition: { x: 0, y: 100 },
    nextSteps: [PreliminaryRequestStep.PRELIMINARY_VALIDATION],
  },
  [PreliminaryRequestStep.PRELIMINARY_VALIDATION]: {
    id: PreliminaryRequestStep.PRELIMINARY_VALIDATION,
    label: "Projet à valider",
    category: "En préparation",
    color: "blue",
    nodePosition: { x: 200, y: 100 },
    nextSteps: [
      PreliminaryRequestStep.WAITING_FOR_SOLTEO,
      PreliminaryRequestStep.SUBMISSION_ON_HOLD,
    ],
  },
  [PreliminaryRequestStep.SUBMISSION_ON_HOLD]: {
    id: PreliminaryRequestStep.SUBMISSION_ON_HOLD,
    label: "En attente",
    category: "En préparation",
    color: "orange",
    nodePosition: { x: 400, y: 150 },
    nextSteps: [PreliminaryRequestStep.WAITING_FOR_SOLTEO],
  },
  [PreliminaryRequestStep.WAITING_FOR_SOLTEO]: {
    id: PreliminaryRequestStep.WAITING_FOR_SOLTEO,
    label: "En attente de Solteo",
    category: "En préparation",
    color: "blue",
    nodePosition: { x: 600, y: 100 },
    nextSteps: [PreliminaryRequestStep.TO_SUBMIT],
  },
  [PreliminaryRequestStep.TO_SUBMIT]: {
    id: PreliminaryRequestStep.TO_SUBMIT,
    label: "À déposer en mairie",
    category: "En préparation",
    color: "blue",
    nodePosition: { x: 800, y: 100 },
    nextSteps: [PreliminaryRequestStep.SUBMITTED],
  },
  [PreliminaryRequestStep.SUBMITTED]: {
    id: PreliminaryRequestStep.SUBMITTED,
    label: "En attente accusé réception",
    category: "Déposé",
    color: "blue",
    nodePosition: { x: 1000, y: 90 },
    nextSteps: [PreliminaryRequestStep.ACKNOWLEDGMENT],
  },
  [PreliminaryRequestStep.ACKNOWLEDGMENT]: {
    id: PreliminaryRequestStep.ACKNOWLEDGMENT,
    label: "En attente validation",
    category: "Déposé",
    color: "blue",
    nodePosition: { x: 1200, y: 99 },
    nextSteps: [
      PreliminaryRequestStep.INCOMPLETE,
      PreliminaryRequestStep.REJECTED,
      PreliminaryRequestStep.APPROVED,
    ],
  },
  [PreliminaryRequestStep.INCOMPLETE]: {
    id: PreliminaryRequestStep.INCOMPLETE,
    label: "Incomplet - à analyser",
    category: "Incomplet",
    color: "orange",
    nodePosition: { x: 1400, y: 40 },
    nextSteps: [
      PreliminaryRequestStep.INCOMPLETE_WAITING_FOR_STAKEHOLDER,
      PreliminaryRequestStep.INCOMPLETE_TO_SUBMIT,
    ],
  },
  [PreliminaryRequestStep.INCOMPLETE_WAITING_FOR_STAKEHOLDER]: {
    id: PreliminaryRequestStep.INCOMPLETE_WAITING_FOR_STAKEHOLDER,
    label: "Incomplet - en attente",
    category: "Incomplet",
    color: "orange",
    nodePosition: { x: 1600, y: 0 },
    nextSteps: [PreliminaryRequestStep.INCOMPLETE_TO_SUBMIT],
  },
  [PreliminaryRequestStep.INCOMPLETE_TO_SUBMIT]: {
    id: PreliminaryRequestStep.INCOMPLETE_TO_SUBMIT,
    label: "Incomplet - à déposer en mairie",
    category: "Incomplet",
    color: "orange",
    nodePosition: { x: 1800, y: 31 },
    nextSteps: [PreliminaryRequestStep.INCOMPLETE_SUBMITTED],
  },
  [PreliminaryRequestStep.INCOMPLETE_SUBMITTED]: {
    id: PreliminaryRequestStep.INCOMPLETE_SUBMITTED,
    label: "Incomplet - attente validation",
    category: "Incomplet",
    color: "blue",
    nodePosition: { x: 2000, y: 31 },
    nextSteps: [PreliminaryRequestStep.APPROVED],
  },
  [PreliminaryRequestStep.REJECTED]: {
    id: PreliminaryRequestStep.REJECTED,
    label: "Rejeté - à analyser",
    category: "Rejeté",
    color: "red",
    nodePosition: { x: 1400, y: 160 },
    nextSteps: [
      PreliminaryRequestStep.REJECTED_WAITING_FOR_STAKEHOLDER,
      PreliminaryRequestStep.REJECTED_TO_SUBMIT,
    ],
  },
  [PreliminaryRequestStep.REJECTED_WAITING_FOR_STAKEHOLDER]: {
    id: PreliminaryRequestStep.REJECTED_WAITING_FOR_STAKEHOLDER,
    label: "Rejeté - en attente",
    category: "Rejeté",
    color: "red",
    nodePosition: { x: 1600, y: 200 },
    nextSteps: [PreliminaryRequestStep.REJECTED_TO_SUBMIT],
  },
  [PreliminaryRequestStep.REJECTED_TO_SUBMIT]: {
    id: PreliminaryRequestStep.REJECTED_TO_SUBMIT,
    label: "Rejeté - à déposer en mairie",
    category: "Rejeté",
    color: "red",
    nodePosition: { x: 1800, y: 151 },
    nextSteps: [PreliminaryRequestStep.REJECTED_SUBMITTED],
  },
  [PreliminaryRequestStep.REJECTED_SUBMITTED]: {
    id: PreliminaryRequestStep.REJECTED_SUBMITTED,
    label: "Rejeté - En attente validation",
    category: "Rejeté",
    color: "blue",
    nodePosition: { x: 2000, y: 151 },
    nextSteps: [PreliminaryRequestStep.APPROVED],
  },
  [PreliminaryRequestStep.APPROVED]: {
    id: PreliminaryRequestStep.APPROVED,
    label: "Approuvé - en att. autorisation",
    category: "Approuvé",
    color: "blue",
    nodePosition: { x: 2200, y: 90 },
    nextSteps: [PreliminaryRequestStep.AUTHORISATION_RECEIVED],
  },
  [PreliminaryRequestStep.AUTHORISATION_RECEIVED]: {
    id: PreliminaryRequestStep.AUTHORISATION_RECEIVED,
    label: "Autorisation reçue",
    category: "Approuvé",
    color: "green",
    nodePosition: { x: 2400, y: 100 },
    nextSteps: [],
  },
  [PreliminaryRequestStep.ABORTED]: {
    id: PreliminaryRequestStep.ABORTED,
    label: "Rejeté - définitivement",
    category: "Rejeté",
    color: "red",
    nodePosition: { x: 2600, y: 100 },
    nextSteps: [],
  },
}

export const TECHNICAL_VISIT_STEPS: Record<
  TechnicalVisitStep,
  ProjectMilestone<TechnicalVisitStep>
> = {
  [TechnicalVisitStep.PENDING]: {
    id: TechnicalVisitStep.PENDING,
    label: "À planifier",
    category: "Planification en cours",
    color: "blue",
    nodePosition: { x: 0, y: 50 },
    nextSteps: [
      TechnicalVisitStep.REQUESTED,
      TechnicalVisitStep.SCHEDULING_ON_HOLD,
    ],
  },
  [TechnicalVisitStep.SCHEDULING_ON_HOLD]: {
    id: TechnicalVisitStep.SCHEDULING_ON_HOLD,
    label: "À rappeler",
    category: "Planification en cours",
    color: "orange",
    nodePosition: { x: 200, y: 85 },
    nextSteps: [
      TechnicalVisitStep.CANNOT_SCHEDULE,
      TechnicalVisitStep.REQUESTED,
    ],
  },
  [TechnicalVisitStep.CANNOT_SCHEDULE]: {
    id: TechnicalVisitStep.CANNOT_SCHEDULE,
    label: "Impossible à planifier",
    category: "Planification en cours",
    color: "red",
    nodePosition: { x: 400, y: 125 },
    nextSteps: [],
  },
  [TechnicalVisitStep.REQUESTED]: {
    id: TechnicalVisitStep.REQUESTED,
    label: "En attente valid. installateur",
    category: "Planification en cours",
    color: "blue",
    nodePosition: { x: 400, y: 40 },
    nextSteps: [
      TechnicalVisitStep.INVITATION_REJECTED,
      TechnicalVisitStep.SCHEDULED,
    ],
  },
  [TechnicalVisitStep.INVITATION_REJECTED]: {
    id: TechnicalVisitStep.INVITATION_REJECTED,
    label: "Invitation installateur rejetée",
    category: "Rejetée",
    color: "orange",
    nodePosition: { x: 600, y: 0 },
    nextSteps: [],
  },
  [TechnicalVisitStep.SCHEDULED]: {
    id: TechnicalVisitStep.SCHEDULED,
    label: "En attente VT",
    category: "Planifiée",
    color: "blue",
    nodePosition: { x: 600, y: 90 },
    nextSteps: [TechnicalVisitStep.COMPLETED],
  },
  [TechnicalVisitStep.COMPLETED]: {
    id: TechnicalVisitStep.COMPLETED,
    label: "En attente CR",
    category: "Réalisée en cours d'étude",
    color: "blue",
    nodePosition: { x: 800, y: 90 },
    nextSteps: [TechnicalVisitStep.BEING_PROCESSED],
  },
  [TechnicalVisitStep.BEING_PROCESSED]: {
    id: TechnicalVisitStep.BEING_PROCESSED,
    label: "CR à analyser",
    category: "Réalisée en cours d'étude",
    color: "blue",
    nodePosition: { x: 1000, y: 90 },
    nextSteps: [
      TechnicalVisitStep.VALIDATED,
      TechnicalVisitStep.UPDATE_INSTALLATION,
    ],
  },
  [TechnicalVisitStep.UPDATE_INSTALLATION]: {
    id: TechnicalVisitStep.UPDATE_INSTALLATION,
    label: "Mise à jour de l'installation",
    category: "Changement, validation en cours",
    color: "blue",
    nodePosition: { x: 1200, y: 130 },
    nextSteps: [TechnicalVisitStep.VALIDATED],
  },
  [TechnicalVisitStep.VALIDATED]: {
    id: TechnicalVisitStep.VALIDATED,
    label: "Validée",
    category: "Validée",
    color: "green",
    nodePosition: { x: 1400, y: 90 },
    nextSteps: [],
  },
  [TechnicalVisitStep.REJECTED]: {
    id: TechnicalVisitStep.REJECTED,
    label: "Rejetée",
    category: "Rejetée",
    color: "red",
    nextSteps: [],
  },
}

export const GREENDEAL_STEPS: Record<
  GreendealStep,
  ProjectMilestone<GreendealStep>
> = {
  [GreendealStep.EMAIL_PENDING]: {
    id: GreendealStep.EMAIL_PENDING,
    label: "Email à envoyer",
    category: "Email à envoyer",
    color: "blue",
    nodePosition: { x: 0, y: 50 },
    nextSteps: [GreendealStep.SUBMISSION_PENDING],
  },
  [GreendealStep.SUBMISSION_PENDING]: {
    id: GreendealStep.SUBMISSION_PENDING,
    label: "En attente",
    category: "En attente",
    color: "blue",
    nodePosition: { x: 200, y: 50 },
    nextSteps: [GreendealStep.SUBMITTED, GreendealStep.DONE_BY_CLIENT],
  },
  [GreendealStep.SUBMITTED]: {
    id: GreendealStep.SUBMITTED,
    label: "Soumis",
    category: "Soumis",
    color: "blue",
    nodePosition: { x: 400, y: 0 },
    nextSteps: [GreendealStep.FILE_COMPLETED],
  },
  [GreendealStep.DONE_BY_CLIENT]: {
    id: GreendealStep.DONE_BY_CLIENT,
    label: "Fait par le client",
    category: "Fait par le client",
    color: "green",
    nodePosition: { x: 400, y: 100 },
    nextSteps: [],
  },
  [GreendealStep.FILE_COMPLETED]: {
    id: GreendealStep.FILE_COMPLETED,
    label: "Dossier complété",
    category: "Dossier complété",
    color: "blue",
    nodePosition: { x: 600, y: 0 },
    nextSteps: [GreendealStep.VALIDATED],
  },
  [GreendealStep.VALIDATED]: {
    id: GreendealStep.VALIDATED,
    label: "Validé",
    category: "Validé",
    color: "green",
    nodePosition: { x: 800, y: 0 },
    nextSteps: [],
  },
}

export const INSTALLATION_STEPS: Record<
  InstallationStep,
  ProjectMilestone<InstallationStep>
> = {
  [InstallationStep.PENDING]: {
    id: InstallationStep.PENDING,
    label: "À planifier",
    category: "Planification en cours",
    color: "blue",
    nodePosition: { x: 0, y: 20 },
    nextSteps: [
      InstallationStep.REQUESTED,
      InstallationStep.SCHEDULING_ON_HOLD,
    ],
  },
  [InstallationStep.SCHEDULING_ON_HOLD]: {
    id: InstallationStep.SCHEDULING_ON_HOLD,
    label: "À rappeler",
    category: "Planification en cours",
    color: "orange",
    nodePosition: { x: 200, y: 65 },
    nextSteps: [InstallationStep.CANNOT_SCHEDULE, InstallationStep.REQUESTED],
  },
  [InstallationStep.CANNOT_SCHEDULE]: {
    id: InstallationStep.CANNOT_SCHEDULE,
    label: "Impossible à planifier",
    category: "Planification en cours",
    color: "red",
    nodePosition: { x: 400, y: 105 },
    nextSteps: [],
  },
  [InstallationStep.REQUESTED]: {
    id: InstallationStep.REQUESTED,
    label: "En attente valid. installateur",
    category: "Planification en cours",
    color: "blue",
    nodePosition: { x: 400, y: 10 },
    nextSteps: [InstallationStep.SCHEDULED],
  },
  [InstallationStep.SCHEDULED]: {
    id: InstallationStep.SCHEDULED,
    label: "En attente prépa. chantier",
    category: "Planifiée",
    color: "blue",
    nodePosition: { x: 600, y: 10 },
    nextSteps: [InstallationStep.PROJECT_PREPARED],
  },
  [InstallationStep.PROJECT_PREPARED]: {
    id: InstallationStep.PROJECT_PREPARED,
    label: "Chantier préparé",
    category: "Planifiée et préparée",
    color: "blue",
    nodePosition: { x: 800, y: 20 },
    nextSteps: [InstallationStep.IN_PROGRESS],
  },
  [InstallationStep.IN_PROGRESS]: {
    id: InstallationStep.IN_PROGRESS,
    label: "En cours",
    category: "Installation en cours",
    color: "blue",
    nodePosition: { x: 1000, y: 20 },
    nextSteps: [InstallationStep.COMPLETED, InstallationStep.NEW_VISIT_NEEDED],
  },
  [InstallationStep.NEW_VISIT_NEEDED]: {
    id: InstallationStep.NEW_VISIT_NEEDED,
    label: "Besoin de repasser",
    category: "Installation en cours",
    color: "orange",
    nodePosition: { x: 1200, y: 90 },
    nextSteps: [InstallationStep.COMPLETED],
  },
  [InstallationStep.COMPLETED]: {
    id: InstallationStep.COMPLETED,
    label: "Terminée - Bon à envoyer",
    category: "Terminée",
    color: "blue",
    nodePosition: { x: 1400, y: 10 },
    nextSteps: [
      InstallationStep.UPDATE_INSTALLATION,
      InstallationStep.CERTIFICATE_SENT,
    ],
  },
  [InstallationStep.UPDATE_INSTALLATION]: {
    id: InstallationStep.UPDATE_INSTALLATION,
    label: "Mise à jour de l'installation",
    category: "Terminée",
    color: "blue",
    nodePosition: { x: 1600, y: 80 },
    nextSteps: [InstallationStep.CERTIFICATE_SENT],
  },
  [InstallationStep.CERTIFICATE_SENT]: {
    id: InstallationStep.CERTIFICATE_SENT,
    label: "Bon de fin de chantier envoyé",
    category: "Terminée",
    color: "blue",
    nodePosition: { x: 1800, y: 10 },
    nextSteps: [InstallationStep.CERTIFICATE_SIGNED],
  },
  [InstallationStep.CERTIFICATE_SIGNED]: {
    id: InstallationStep.CERTIFICATE_SIGNED,
    label: "Bon de fin de chantier signé",
    category: "Bon de fin de chantier signé",
    color: "green",
    nodePosition: { x: 2000, y: 10 },
    nextSteps: [],
  },
}

export const PAYMENT_STEPS: Record<
  PaymentStep,
  ProjectMilestone<PaymentStep>
> = {
  [PaymentStep.DEPOSIT_INVOICE_WAITING]: {
    id: PaymentStep.DEPOSIT_INVOICE_WAITING,
    label: "En attente",
    category: "En attente paiement acompte",
    color: "blue",
    nodePosition: { x: 0, y: 40 },
    nextSteps: [
      PaymentStep.DEPOSIT_INVOICE_TO_SEND,
      PaymentStep.CETELEM_REQUEST_TO_SEND,
    ],
  },
  [PaymentStep.DEPOSIT_INVOICE_TO_SEND]: {
    id: PaymentStep.DEPOSIT_INVOICE_TO_SEND,
    label: "Facture d'acompte à envoyer",
    category: "En attente paiement acompte",
    color: "blue",
    nodePosition: { x: 200, y: 0 },
    nextSteps: [PaymentStep.WAITING_FOR_DEPOSIT_PAYMENT],
  },
  [PaymentStep.CETELEM_REQUEST_TO_SEND]: {
    id: PaymentStep.CETELEM_REQUEST_TO_SEND,
    label: "Demande Cetelem à envoyer client",
    category: "Demande Cetelem à envoyer client",
    color: "blue",
    nodePosition: { x: 200, y: 80 },
    nextSteps: [PaymentStep.CETELEM_REQUEST_SENT],
  },
  [PaymentStep.CETELEM_REQUEST_SENT]: {
    id: PaymentStep.CETELEM_REQUEST_SENT,
    label: "Demande Cetelem envoyée client",
    category: "Demande Cetelem envoyée client",
    color: "blue",
    nodePosition: { x: 400, y: 80 },
    nextSteps: [PaymentStep.CETELEM_WAITING_FOR_MAIN_PAYMENT],
  },
  [PaymentStep.WAITING_FOR_DEPOSIT_PAYMENT]: {
    id: PaymentStep.WAITING_FOR_DEPOSIT_PAYMENT,
    label: "En attente paiement acompte",
    category: "En attente paiement acompte",
    color: "blue",
    nodePosition: { x: 400, y: 0 },
    nextSteps: [PaymentStep.DEPOSIT_INVOICE_PAID],
  },
  [PaymentStep.DEPOSIT_INVOICE_PAID]: {
    id: PaymentStep.DEPOSIT_INVOICE_PAID,
    label: "Acompte payé",
    category: "Facture d'acompte payée",
    color: "blue",
    nodePosition: { x: 600, y: 10 },
    nextSteps: [PaymentStep.FINAL_INVOICE_TO_SEND],
  },
  [PaymentStep.CETELEM_WAITING_FOR_MAIN_PAYMENT]: {
    id: PaymentStep.CETELEM_WAITING_FOR_MAIN_PAYMENT,
    label: "Attente 80% Cetelem",
    category: "Attente 80% Cetelem",
    color: "blue",
    nodePosition: { x: 600, y: 90 },
    nextSteps: [PaymentStep.CETELEM_PAID_WAITING_CONSUEL],
  },
  [PaymentStep.FINAL_INVOICE_TO_SEND]: {
    id: PaymentStep.FINAL_INVOICE_TO_SEND,
    label: "Facture finale à envoyer",
    category: "En attente paiement solde",
    color: "blue",
    nodePosition: { x: 800, y: 0 },
    nextSteps: [PaymentStep.WAITING_FOR_FINAL_PAYMENT],
  },
  [PaymentStep.WAITING_FOR_FINAL_PAYMENT]: {
    id: PaymentStep.WAITING_FOR_FINAL_PAYMENT,
    label: "En attente de paiement solde",
    category: "En attente paiement solde",
    color: "blue",
    nodePosition: { x: 1000, y: 0 },
    nextSteps: [PaymentStep.FINAL_INVOICE_PAID],
  },
  [PaymentStep.CETELEM_PAID_WAITING_CONSUEL]: {
    id: PaymentStep.CETELEM_PAID_WAITING_CONSUEL,
    label: "Cetelem payé, attente Consuel",
    category: "Cetelem payé, attente Consuel",
    color: "blue",
    nodePosition: { x: 800, y: 80 },
    nextSteps: [PaymentStep.CETELEM_CONSUEL_TO_SEND],
  },
  [PaymentStep.CETELEM_CONSUEL_TO_SEND]: {
    id: PaymentStep.CETELEM_CONSUEL_TO_SEND,
    label: "Consuel à envoyer à Cetelem",
    category: "Consuel à envoyer à Cetelem",
    color: "blue",
    nodePosition: { x: 1000, y: 80 },
    nextSteps: [PaymentStep.CETELEM_WAITING_LAST_PAYMENT],
  },
  [PaymentStep.CETELEM_WAITING_LAST_PAYMENT]: {
    id: PaymentStep.CETELEM_WAITING_LAST_PAYMENT,
    label: "Attente paiement 20% Cetelem",
    category: "Attente paiement 20% Cetelem",
    color: "blue",
    nodePosition: { x: 1200, y: 80 },
    nextSteps: [PaymentStep.CETELEM_PAID],
  },
  [PaymentStep.FINAL_INVOICE_PAID]: {
    id: PaymentStep.FINAL_INVOICE_PAID,
    label: "Paiement solde effectué",
    category: "Facture de solde payée",
    color: "green",
    nodePosition: { x: 1200, y: 0 },
    nextSteps: [],
  },
  [PaymentStep.CETELEM_PAID]: {
    id: PaymentStep.CETELEM_PAID,
    label: "Paiement Cetelem terminé",
    category: "Paiement Cetelem terminé",
    color: "green",
    nodePosition: { x: 1400, y: 80 },
    nextSteps: [],
  },
}

export const HARDWARE_STEPS: Record<
  HardwareStep,
  ProjectMilestone<HardwareStep>
> = {
  [HardwareStep.PREORDER_PENDING]: {
    id: HardwareStep.PREORDER_PENDING,
    label: "Précommande en attente",
    category: "En attente commande",
    color: "blue",
    nodePosition: { x: 0, y: 20 },
    nextSteps: [HardwareStep.PREORDER_DONE],
  },
  [HardwareStep.PREORDER_DONE]: {
    id: HardwareStep.PREORDER_DONE,
    label: "Précommande faite",
    category: "En attente commande",
    color: "blue",
    nodePosition: { x: 200, y: 30 },
    nextSteps: [HardwareStep.ORDER_PENDING],
  },
  [HardwareStep.ORDER_PENDING]: {
    id: HardwareStep.ORDER_PENDING,
    label: "Commande en attente",
    category: "En attente commande",
    color: "blue",
    nodePosition: { x: 400, y: 30 },
    nextSteps: [HardwareStep.PREPARATION_PENDING],
  },
  [HardwareStep.PREPARATION_PENDING]: {
    id: HardwareStep.PREPARATION_PENDING,
    label: "Préparation en attente",
    category: "En attente livraison",
    color: "blue",
    nodePosition: { x: 600, y: 30 },
    nextSteps: [HardwareStep.DELIVERY_PENDING],
  },
  [HardwareStep.DELIVERY_PENDING]: {
    id: HardwareStep.DELIVERY_PENDING,
    label: "Livraison en attente",
    category: "En attente livraison",
    color: "blue",
    nodePosition: { x: 800, y: 30 },
    nextSteps: [HardwareStep.ORDER_DELIVERED],
  },
  [HardwareStep.ORDER_DELIVERED]: {
    id: HardwareStep.ORDER_DELIVERED,
    label: "Commande livrée",
    category: "Matériel livré",
    color: "blue",
    nodePosition: { x: 1000, y: 30 },
    nextSteps: [HardwareStep.ORDER_CHECKED],
  },
  [HardwareStep.ORDER_CHECKED]: {
    id: HardwareStep.ORDER_CHECKED,
    label: "Commande livrée & vérifiée",
    category: "Matériel livré",
    color: "blue",
    nodePosition: { x: 1200, y: 20 },
    nextSteps: [HardwareStep.ORDER_INSTALLED],
  },
  [HardwareStep.ORDER_INSTALLED]: {
    id: HardwareStep.ORDER_INSTALLED,
    label: "Commande livrée & installée",
    category: "Matériel livré",
    color: "green",
    nodePosition: { x: 1400, y: 20 },
    nextSteps: [],
  },
}

export const ENEDIS_STEPS: Record<EnedisStep, ProjectMilestone<EnedisStep>> = {
  [EnedisStep.PENDING]: {
    id: EnedisStep.PENDING,
    label: "À soumettre",
    category: "À soumettre",
    color: "blue",
    nodePosition: { x: 0, y: 40 },
    nextSteps: [EnedisStep.SUBMITTED],
  },
  [EnedisStep.SUBMITTED]: {
    id: EnedisStep.SUBMITTED,
    label: "En attente validation",
    category: "Soumis",
    color: "blue",
    nodePosition: { x: 200, y: 40 },
    nextSteps: [EnedisStep.INCOMPLETE, EnedisStep.VALIDATED],
  },
  [EnedisStep.INCOMPLETE]: {
    id: EnedisStep.INCOMPLETE,
    label: "Incomplet - à analyser",
    category: "Incomplet",
    color: "orange",
    nodePosition: { x: 400, y: 0 },
    nextSteps: [],
  },
  [EnedisStep.VALIDATED]: {
    id: EnedisStep.VALIDATED,
    label: "Validé",
    category: "Validé",
    color: "blue",
    nodePosition: { x: 400, y: 100 },
    nextSteps: [EnedisStep.ACTIVATION_PENDING],
  },
  [EnedisStep.ACTIVATION_PENDING]: {
    id: EnedisStep.ACTIVATION_PENDING,
    label: "En attente mise en service",
    category: "En attente mise en service",
    color: "blue",
    nodePosition: { x: 600, y: 90 },
    nextSteps: [EnedisStep.ACTIVATED],
  },
  [EnedisStep.ACTIVATED]: {
    id: EnedisStep.ACTIVATED,
    label: "Mis en service",
    category: "Mis en service",
    color: "green",
    nodePosition: { x: 800, y: 100 },
    nextSteps: [],
  },
}

export const CONSUEL_STEPS: Record<
  ConsuelStep,
  ProjectMilestone<ConsuelStep>
> = {
  [ConsuelStep.PENDING]: {
    id: ConsuelStep.PENDING,
    label: "À soumettre à Solteo",
    category: "En attente",
    color: "blue",
    nodePosition: { x: 0, y: 0 },
    nextSteps: [ConsuelStep.WAITING_FOR_SOLTEO],
  },
  [ConsuelStep.WAITING_FOR_SOLTEO]: {
    id: ConsuelStep.WAITING_FOR_SOLTEO,
    label: "En attente Solteo",
    category: "En attente",
    color: "blue",
    nodePosition: { x: 200, y: 0 },
    nextSteps: [ConsuelStep.SUBMITTED],
  },
  [ConsuelStep.SUBMITTED]: {
    id: ConsuelStep.SUBMITTED,
    label: "En attente validation",
    category: "Soumis",
    color: "blue",
    nodePosition: { x: 400, y: 0 },
    nextSteps: [
      ConsuelStep.VISIT_NEEDED,
      ConsuelStep.QUALITY_PV_VISIT_NEEDED,
      ConsuelStep.VALIDATED,
    ],
  },
  [ConsuelStep.VISIT_NEEDED]: {
    id: ConsuelStep.VISIT_NEEDED,
    label: "Avis de visite à partager",
    category: "Visite nécessaire",
    color: "orange",
    nodePosition: { x: 600, y: 50 },
    nextSteps: [ConsuelStep.VISIT_SCHEDULED],
  },
  [ConsuelStep.VISIT_SCHEDULED]: {
    id: ConsuelStep.VISIT_SCHEDULED,
    label: "Date de visite à partager",
    category: "Visite nécessaire",
    color: "orange",
    nodePosition: { x: 900, y: 50 },
    nextSteps: [ConsuelStep.VISIT_WAITING_VALIDATION],
  },
  [ConsuelStep.VISIT_WAITING_VALIDATION]: {
    id: ConsuelStep.VISIT_WAITING_VALIDATION,
    label: "En attente validation visite",
    category: "Visite programmée",
    color: "blue",
    nodePosition: { x: 1200, y: 50 },
    nextSteps: [ConsuelStep.WORK_NEEDED],
  },
  [ConsuelStep.QUALITY_PV_VISIT_NEEDED]: {
    id: ConsuelStep.QUALITY_PV_VISIT_NEEDED,
    label: "Quali PV - Avis visite à partager",
    category: "Quali PV - Visite nécessaire",
    color: "orange",
    nodePosition: { x: 600, y: 150 },
    nextSteps: [ConsuelStep.QUALITY_PV_VISIT_SCHEDULED],
  },
  [ConsuelStep.QUALITY_PV_VISIT_SCHEDULED]: {
    id: ConsuelStep.QUALITY_PV_VISIT_SCHEDULED,
    label: "Quali PV - Date visite à partager",
    category: "Quali PV - Visite nécessaire",
    color: "orange",
    nodePosition: { x: 800, y: 150 },
    nextSteps: [ConsuelStep.QUALITY_PV_DOC_TO_SEND],
  },
  [ConsuelStep.QUALITY_PV_DOC_TO_SEND]: {
    id: ConsuelStep.QUALITY_PV_DOC_TO_SEND,
    label: "Quali PV - Documents à envoyer",
    category: "Quali PV - Docs à envoyer",
    color: "orange",
    nodePosition: { x: 1000, y: 150 },
    nextSteps: [ConsuelStep.QUALITY_PV_WAITING_VALIDATION],
  },
  [ConsuelStep.QUALITY_PV_WAITING_VALIDATION]: {
    id: ConsuelStep.QUALITY_PV_WAITING_VALIDATION,
    label: "Quali PV - en attente validation",
    category: "Quali PV - Visite programmée",
    color: "blue",
    nodePosition: { x: 1200, y: 150 },
    nextSteps: [ConsuelStep.WORK_NEEDED],
  },
  [ConsuelStep.WORK_NEEDED]: {
    id: ConsuelStep.WORK_NEEDED,
    label: "Travaux à prévoir",
    category: "Travaux nécessaires",
    color: "orange",
    nodePosition: { x: 1400, y: 100 },
    nextSteps: [ConsuelStep.WORK_SCHEDULED],
  },
  [ConsuelStep.WORK_SCHEDULED]: {
    id: ConsuelStep.WORK_SCHEDULED,
    label: "En attente travaux",
    category: "Travaux prévus",
    color: "orange",
    nodePosition: { x: 1600, y: 100 },
    nextSteps: [ConsuelStep.WORK_DONE],
  },
  [ConsuelStep.WORK_DONE]: {
    id: ConsuelStep.WORK_DONE,
    label: "Attestation à signer",
    category: "Travaux terminés",
    color: "blue",
    nodePosition: { x: 1800, y: 100 },
    nextSteps: [],
  },
  [ConsuelStep.VALIDATED]: {
    id: ConsuelStep.VALIDATED,
    label: "Validé",
    category: "Validé",
    color: "green",
    nodePosition: { x: 2000, y: 0 },
    nextSteps: [],
  },
}

export const EDF_OA_STEPS: Record<EdfOaStep, ProjectMilestone<EdfOaStep>> = {
  [EdfOaStep.EMAIL_SENT]: {
    id: EdfOaStep.EMAIL_SENT,
    label: "Email envoyé",
    category: "Email envoyé",
    color: "blue",
    nodePosition: { x: 0, y: 20 },
    nextSteps: [EdfOaStep.CERTIFICATE_TO_SEND],
  },
  [EdfOaStep.CERTIFICATE_TO_SEND]: {
    id: EdfOaStep.CERTIFICATE_TO_SEND,
    label: "Attestation à envoyer",
    category: "Attestation à envoyer",
    color: "blue",
    nodePosition: { x: 200, y: 20 },
    nextSteps: [EdfOaStep.CERTIFICATE_SENT],
  },
  [EdfOaStep.CERTIFICATE_SENT]: {
    id: EdfOaStep.CERTIFICATE_SENT,
    label: "Attestation envoyée",
    category: "Attestation envoyée",
    color: "blue",
    nodePosition: { x: 400, y: 20 },
    nextSteps: [],
  },
}

export const FOLLOW_UP_STEPS: Record<
  FollowUpStep,
  ProjectMilestone<FollowUpStep>
> = {
  [FollowUpStep.INSTALLATION_TO_VALIDATE]: {
    id: FollowUpStep.INSTALLATION_TO_VALIDATE,
    label: "Installation à valider",
    category: "Validation installation",
    color: "blue",
    nodePosition: { x: 0, y: 30 },
    nextSteps: [FollowUpStep.LESS_THAN_1_MONTH_PRODUCING],
  },
  [FollowUpStep.LESS_THAN_1_MONTH_PRODUCING]: {
    id: FollowUpStep.LESS_THAN_1_MONTH_PRODUCING,
    label: "Production < 1 mois",
    category: "Validation installation",
    color: "blue",
    nodePosition: { x: 200, y: 30 },
    nextSteps: [FollowUpStep.ONBOARDING_PENDING],
  },
  [FollowUpStep.ONBOARDING_PENDING]: {
    id: FollowUpStep.ONBOARDING_PENDING,
    label: "Onboarding à faire",
    category: "Onboarding à faire",
    color: "blue",
    nodePosition: { x: 400, y: 30 },
    nextSteps: [FollowUpStep.ONBOARDING_DONE],
  },
  [FollowUpStep.ONBOARDING_DONE]: {
    id: FollowUpStep.ONBOARDING_DONE,
    label: "Onboarding fait",
    category: "Onboarding fait",
    color: "green",
    nodePosition: { x: 600, y: 30 },
    nextSteps: [FollowUpStep.MORE_THAN_3_MONTHS_PRODUCING],
  },
  [FollowUpStep.MORE_THAN_3_MONTHS_PRODUCING]: {
    id: FollowUpStep.MORE_THAN_3_MONTHS_PRODUCING,
    label: "Production > 3 mois",
    category: "Onboarding fait",
    color: "green",
    nodePosition: { x: 800, y: 30 },
    nextSteps: [FollowUpStep.MORE_THAN_6_MONTHS_PRODUCING],
  },
  [FollowUpStep.MORE_THAN_6_MONTHS_PRODUCING]: {
    id: FollowUpStep.MORE_THAN_6_MONTHS_PRODUCING,
    label: "Production > 6 mois",
    category: "Onboarding fait",
    color: "green",
    nodePosition: { x: 1000, y: 30 },
    nextSteps: [FollowUpStep.MORE_THAN_12_MONTHS_PRODUCING],
  },
  [FollowUpStep.MORE_THAN_12_MONTHS_PRODUCING]: {
    id: FollowUpStep.MORE_THAN_12_MONTHS_PRODUCING,
    label: "Production > 12 mois",
    category: "Onboarding fait",
    color: "green",
    nodePosition: { x: 1200, y: 30 },
    nextSteps: [],
  },
}

const ALL_PROJECT_MILESTONES_CONFIG: {
  [key in ProcessId]: Record<
    ProcessStep<key>,
    ProjectMilestone<ProcessStep<key>>
  >
} = {
  preliminaryRequestStep: PRELIMINARY_REQUEST_STEPS,
  technicalVisitStep: TECHNICAL_VISIT_STEPS,
  installationStep: INSTALLATION_STEPS,
  paymentStep: PAYMENT_STEPS,
  hardwareStep: HARDWARE_STEPS,
  greendealStep: GREENDEAL_STEPS,
  enedisStep: ENEDIS_STEPS,
  consuelStep: CONSUEL_STEPS,
  edfOaStep: EDF_OA_STEPS,
  followUpStep: FOLLOW_UP_STEPS,
}

export const getProcessStepConfig = <T extends ProcessId>(
  step: ProcessStep<T>,
  processId: T,
): ProjectMilestone<ProcessStep<T>> => {
  const config = ALL_PROJECT_MILESTONES_CONFIG[processId]
  return config[step]
}
