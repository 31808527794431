import { cetelemFormSchema } from "@ensol/types/forms/projects/payment"

import {
  ConsuelStep,
  getStepIndex,
} from "@ensol/shared/entities/projects/processes"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"

import { CetelemForm } from "./components/CetelemForm"
import { getCetelemFormInitialValues } from "./util"

export const WaitingMainPayment = ({
  project,
  processId,
}: StepComponentProps) => {
  const validateAction =
    getStepIndex(project.consuelStep, "consuelStep") >=
    getStepIndex(ConsuelStep.VALIDATED, "consuelStep")
      ? {
          action: "validateWithConsuel",
          label: "Paiement reçu et Consuel validé",
        }
      : {
          action: "validate",
          label: "Paiement reçu, en attente Consuel",
        }

  return (
    <CetelemForm
      schema={cetelemFormSchema}
      initialValues={getCetelemFormInitialValues(project)}
      project={project}
      processId={processId}
      actions={[validateAction]}
    />
  )
}
