import { TechnicalVisitStepInput } from "@ensol/types/forms/projects/technicalVisit"

import { StepActionsProps } from "@ensol/entool/components/entities/Project/StepActions"
import { HouseForm } from "@ensol/entool/components/form/House/SalesVisit/HouseForm"
import { useHouseForm } from "@ensol/entool/components/form/House/SalesVisit/useHouseForm"
import { ProjectForm } from "@ensol/entool/components/form/Project/ProjectForm"
import { useProjectForm } from "@ensol/entool/components/form/Project/useProjectForm"
import { ProcessFormProps } from "@ensol/entool/utils/projects/useProcessForm"

import { TechnicalVisitForm } from "./TechnicalVisitForm"

export const BeforeVisitCompletedForm = <
  Input extends TechnicalVisitStepInput,
>({
  project,
  ...props
}: ProcessFormProps<Input> & StepActionsProps) => {
  const installation = project.installation
  const house = installation.house

  const houseForm = useHouseForm(house)
  const projectForm = useProjectForm(project)

  return (
    <TechnicalVisitForm project={project} {...props}>
      <ProjectForm project={project} form={projectForm} titleOrder={3} />
      <HouseForm house={house} form={houseForm} titleOrder={3} />
    </TechnicalVisitForm>
  )
}
