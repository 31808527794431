import { Button, SimpleGrid, Stack } from "@mantine/core"

import { CetelemFormInput } from "@ensol/types/forms/projects/payment"
import { ProjectDocumentType } from "@ensol/types/project"

import {
  getStepIndex,
  PaymentStep,
} from "@ensol/shared/entities/projects/processes"
import { getProspectFileName } from "@ensol/shared/utils/files"

import { Section } from "@ensol/entool/components/Section"
import { FILE_THUMBNAIL_WIDTH_IN_PX } from "@ensol/entool/components/entities/File/constants"
import {
  StepActions,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { CetelemFields } from "@ensol/entool/components/form/Project/CetelemFields"
import { FormCheckbox } from "@ensol/entool/components/form/Project/FormCheckbox"
import { FormField } from "@ensol/entool/components/form/Project/FormField"
import { GenerateEstimateForm } from "@ensol/entool/pages/OpsProject/processes/Payment/components/GenerateEstimateForm"
import { useCreateDocumentMutation } from "@ensol/entool/queries/projects"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"

export const CetelemForm = <Input extends CetelemFormInput>({
  project,
  processId,
  actions,
  initialValues,
  schema,
}: ProcessFormProps<Input> & StepActionsProps) => {
  const {
    mutateAsync: createCetelemFinancingRequest,
    isPending: isFinancingRequestLoading,
  } = useCreateDocumentMutation(
    project.id,
    ProjectDocumentType.CETELEM_FINANCING_REQUEST,
  )
  const {
    mutateAsync: createCetelemRequestForAdvanceOnFinancing,
    isPending: isRequestForAdvanceOnFinancingLoading,
  } = useCreateDocumentMutation(
    project.id,
    ProjectDocumentType.CETELEM_REQUEST_FOR_ADVANCE_ON_FUNDING,
  )

  const form = useProcessForm<Input>({
    schema,
    initialValues,
    projectId: project.id,
    processId: "paymentStep",
  })

  return (
    <StepActions
      project={project}
      processId={processId}
      actions={actions}
      validateStep={() => schema.parse(form.values)}
    >
      <GenerateEstimateForm project={project} />
      <Section title="Cetelem">
        <SimpleGrid cols={2} spacing={32}>
          <CetelemFields form={form} initialValues={initialValues} />
          <Stack>
            <FormCheckbox
              initialValues={initialValues}
              propertyName="arePennylaneInvoicesGenerated"
              {...form.getInputProps("arePennylaneInvoicesGenerated")}
            />
            <FormField
              initialValues={initialValues}
              propertyName="cetelemFinancingRequestPath"
              isRequired
            >
              <Stack gap="8">
                {getStepIndex(project.paymentStep, "paymentStep") <
                  getStepIndex(
                    PaymentStep.CETELEM_REQUEST_SENT,
                    "paymentStep",
                  ) && (
                  <Button
                    onClick={async () => {
                      const { cetelemFinancingRequestPath } =
                        await createCetelemFinancingRequest()
                      form
                        .getInputProps("cetelemFinancingRequestPath")
                        .onChange(cetelemFinancingRequestPath)
                    }}
                    loading={isFinancingRequestLoading}
                    disabled={project.cetelemFinancingRequestPath !== null}
                    w={FILE_THUMBNAIL_WIDTH_IN_PX}
                  >
                    Générer la demande de financement Cetelem
                  </Button>
                )}
                <FileField
                  previewProps={{
                    fileName: getProspectFileName(
                      "demande-financement-cetelem",
                      project.installation.house.prospect,
                    ),
                  }}
                  inputProps={{ accept: ["application/pdf"] }}
                  {...form.getInputProps("cetelemFinancingRequestPath")}
                />
              </Stack>
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="cetelemRequestForAdvanceOnFundingPath"
              isRequired
            >
              <Stack gap="8">
                <Button
                  onClick={async () => {
                    const { cetelemRequestForAdvanceOnFundingPath } =
                      await createCetelemRequestForAdvanceOnFinancing()
                    form
                      .getInputProps("cetelemRequestForAdvanceOnFundingPath")
                      .onChange(cetelemRequestForAdvanceOnFundingPath)
                  }}
                  loading={isRequestForAdvanceOnFinancingLoading}
                  disabled={
                    project.cetelemRequestForAdvanceOnFundingPath !== null
                  }
                  w={FILE_THUMBNAIL_WIDTH_IN_PX}
                >
                  Générer la demande d&apos;avance
                </Button>

                <FileField
                  previewProps={{
                    fileName: getProspectFileName(
                      "demande-avance-financement-cetelem",
                      project.installation.house.prospect,
                    ),
                  }}
                  inputProps={{ accept: ["application/pdf"] }}
                  {...form.getInputProps(
                    "cetelemRequestForAdvanceOnFundingPath",
                  )}
                />
              </Stack>
            </FormField>
          </Stack>
        </SimpleGrid>
      </Section>
    </StepActions>
  )
}
