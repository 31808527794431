import { createContext, ReactNode, useState } from "react"

import { GLOBAL_MENU_ITEMS, MenuItem } from "./config"

type Props = {
  children: ReactNode
}

export const MenuProviderContext = createContext<{
  menuItems: MenuItem[]
  setMenuItems: (newMenuItems: MenuItem[]) => void
}>({
  menuItems: GLOBAL_MENU_ITEMS,
  setMenuItems: (_: MenuItem[]) => {},
})

export const MenuProvider = ({ children }: Props) => {
  const [menuItems, setMenuItems] = useState<MenuItem[]>(GLOBAL_MENU_ITEMS)

  return (
    <MenuProviderContext.Provider value={{ menuItems, setMenuItems }}>
      {children}
    </MenuProviderContext.Provider>
  )
}
