import { ActionIcon, Group, Table, Text, Tooltip } from "@mantine/core"
import { IconReplace } from "@tabler/icons-react"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import {
  formatDate,
  SHORT_DISPLAY_DATE_TIME_FORMAT,
} from "@ensol/shared/utils/format"

import { DownloadFileButton } from "@ensol/entool/components/entities/File/DownloadFileButton"
import { QuoteStatusBadge } from "@ensol/entool/components/entities/Installation/QuoteStatusBadge"
import { useUpdateActiveQuoteMutation } from "@ensol/entool/queries/quotes"

type Props = {
  installationId: string
  previousQuotes: InstallationsResponses.Quotes["previousQuotes"]
  quoteFileName: string
  onClose: () => void
}

export const PreviousQuotes = ({
  installationId,
  previousQuotes,
  quoteFileName,
  onClose,
}: Props) => {
  const { mutateAsync: updateActiveQuote, isPending: isUpdatingActiveQuote } =
    useUpdateActiveQuoteMutation(installationId)

  return (
    <Table>
      <Table.Thead>
        <Table.Tr>
          <Table.Th>Créé le</Table.Th>
          <Table.Th>Statut</Table.Th>
          <Table.Th />
        </Table.Tr>
      </Table.Thead>
      <Table.Tbody>
        {previousQuotes.map((quote) => (
          <Table.Tr key={quote.id} p="0">
            <Table.Td w="140">
              <Text size="sm">
                {formatDate(quote.createdAt, SHORT_DISPLAY_DATE_TIME_FORMAT)}
              </Text>
            </Table.Td>
            <Table.Td>
              <QuoteStatusBadge status={quote.status!} />
            </Table.Td>
            <Table.Td w="80">
              <Group gap="8">
                <Tooltip label="Remplacer le devis actuel par celui-ci">
                  <ActionIcon
                    aria-label="Remplacer"
                    loading={isUpdatingActiveQuote}
                    onClick={async () => {
                      await updateActiveQuote(quote.id)
                      onClose()
                    }}
                  >
                    <IconReplace />
                  </ActionIcon>
                </Tooltip>
                {quote.quotePath !== null && (
                  <DownloadFileButton
                    filePath={quote.quotePath}
                    fileName={quoteFileName}
                    type="pdf"
                  />
                )}
              </Group>
            </Table.Td>
          </Table.Tr>
        ))}
      </Table.Tbody>
    </Table>
  )
}
