import { IconX, IconBarrierBlock } from "@tabler/icons-react"
import * as z from "zod"

import { acknowledgmentStepFormSchema } from "@ensol/types/forms/projects/preliminaryRequest"

import { EmailEvent } from "@ensol/shared/entities/projects/emails"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { PreliminaryRequestForm } from "@ensol/entool/pages/OpsProject/processes/PreliminaryRequest/components/PreliminaryRequestForm"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"
import { validateAction } from "@ensol/entool/utils/projects/validateAction"

export const Acknowledgment = ({ project, ...props }: StepComponentProps) => (
  <PreliminaryRequestForm
    schema={acknowledgmentStepFormSchema}
    initialValues={getInitialValues(acknowledgmentStepFormSchema, project)}
    project={project}
    emails={
      project.preliminaryRequestABFAcknowledgmentPath !== null
        ? [
            {
              eventName: EmailEvent.PRELIMINARY_REQUEST_ABF_ACKNOWLEDGED,
              label: "Récépissé ABF",
            },
          ]
        : []
    }
    actions={[
      {
        action: "reject",
        label: "Rejeter la demande préalable",
        color: "red",
        validateAction: validateAction(
          z.object({
            preliminaryRequestRejectionPaths: z
              .array(z.string())
              .min(1, "Champ obligatoire"),
          }),
        ),
        Icon: IconX,
      },
      {
        action: "incomplete",
        label: "Marquer comme incomplète",
        color: "orange",
        validateAction: validateAction(
          z.object({
            preliminaryRequestRequestedChangesPaths: z
              .array(z.string())
              .min(1, "Champ obligatoire"),
          }),
        ),
        Icon: IconBarrierBlock,
      },
      {
        action: "validate",
        label: "Approuver la demande préalable",
        validateAction: validateAction(
          z.object({
            preliminaryRequestApprovalDate: z.date({
              invalid_type_error: "Champ obligatoire",
            }),
          }),
        ),
      },
    ]}
    {...props}
  />
)
