import { Button, Text } from "@mantine/core"
import { modals } from "@mantine/modals"
import { IconCheck, IconCirclePlus, IconReload } from "@tabler/icons-react"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import { isQuoteOutdated, QuoteStatus } from "@ensol/shared/entities/quotes"

import { LinkLoader } from "@ensol/entool/components/LinkLoader"
import { useCreateQuoteMutation } from "@ensol/entool/queries/quotes"

type Props = {
  activeQuote: InstallationsResponses.Quote | null
  installationId: string
  installationDate: Date
  isInstallationValid: boolean
}

export const GenerateQuoteButton = ({
  activeQuote,
  installationId,
  installationDate,
  isInstallationValid,
}: Props) => {
  const isNew = activeQuote === null
  const isSigned = activeQuote?.status === QuoteStatus.SIGNED
  const isOutdated = isQuoteOutdated(installationDate, activeQuote)

  const { mutateAsync: sendQuote, isPending: isSendingQuote } =
    useCreateQuoteMutation(installationId)

  const { label, Icon } = getQuoteButtonAttributes(
    isNew,
    isOutdated,
    isSendingQuote,
  )

  return (
    <Button
      data-testid="generate-quote"
      variant="light"
      onClick={() => {
        if (isSigned) {
          return modals.openConfirmModal({
            title: "Le devis de cette installation est signé",
            children: (
              <Text size="sm">
                Êtes-vous sûr(e) de vouloir remplacer ce devis signé par un
                nouveau ? Cette action archivera l&apos;ancien devis et
                nécessitera que le nouveau devis soit signé à la place.
              </Text>
            ),
            confirmProps: { color: "red" },
            centered: true,
            labels: {
              confirm: "Remplacer le devis",
              cancel: "Annuler",
            },
            onConfirm: sendQuote,
          })
        }

        sendQuote()
      }}
      leftSection={<Icon size={16} />}
      disabled={!isInstallationValid || !isOutdated || isSendingQuote}
      color="blue"
    >
      {label}
    </Button>
  )
}

const getQuoteButtonAttributes = (
  isNew: boolean,
  isOutdated: boolean,
  isSending: boolean,
) => {
  if (isSending) {
    return {
      label: "Devis en cours de création...",
      Icon: LinkLoader,
    }
  }

  if (isNew) {
    return {
      label: "Créer le devis",
      Icon: IconCirclePlus,
    }
  }

  if (!isOutdated) {
    return {
      label: "Le devis est à jour",
      Icon: IconCheck,
    }
  }

  return {
    label: "Mettre à jour le devis",
    Icon: IconReload,
  }
}
