import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import { withMargin } from "@ensol/shared/entities/installations/costs/index"
import { computeInvertersCount } from "@ensol/shared/entities/installations/energy"
import { SubsidyType } from "@ensol/shared/entities/installations/subsidies"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"

import { CostsBuilder } from "./CostsBuilder"
import {
  computeInverterUnitPrice,
  computeBuildKitPrice,
  computePanelUnitPrice,
} from "./common"

const VARIABLE_PANEL_COST_IN_EURO = 100
const WORKFORCE_COST_IN_EURO = 150
export class ExtraPanelsCostsBuilder extends CostsBuilder<
  InstallationsResponses.ExtraPanelsInstallation,
  SubsidyType
> {
  constructor(installation: InstallationsResponses.ExtraPanelsInstallation) {
    super(installation)
  }

  public computeVatRate() {
    return 0.2
  }

  public computeMaterialCosts() {
    const { panelType, panelsCount, inverterType, roofSectionsWithPanels } =
      this.installation

    // Only if micro-inverters are already installed. Otherwise we keep the current central inverter
    const inverter = getInverter(inverterType)
    const invertersCost = inverter.isCentralInverter
      ? 0
      : computeInvertersCount(inverterType, panelsCount) *
        computeInverterUnitPrice(inverterType)

    return (
      panelsCount * computePanelUnitPrice(panelType) +
      computeBuildKitPrice(roofSectionsWithPanels) +
      invertersCost
    )
  }

  public computeWorkforceCosts() {
    return withMargin(
      WORKFORCE_COST_IN_EURO +
        this.installation.panelsCount * VARIABLE_PANEL_COST_IN_EURO,
    )
  }

  public computeAdminCosts() {
    return 0
  }

  public computeSubsidy() {
    return null
  }
}
