import { Badge } from "@mantine/core"

import {
  ProcessStep,
  ProcessId,
} from "@ensol/shared/entities/projects/processes"

import { getProcessStepConfig } from "@ensol/entool/utils/projects/processes"

export const ProcessBadge = ({
  processStep,
  processId,
}: {
  processStep: ProcessStep<ProcessId> | null
  processId: ProcessId
}) => {
  if (!processStep)
    return (
      <Badge color="gray" variant="light">
        Non commencé
      </Badge>
    )

  const processConfig = getProcessStepConfig(processStep, processId)

  if (!processConfig) return null
  return (
    <Badge color={processConfig.color} variant="light">
      {processConfig.label}
    </Badge>
  )
}
