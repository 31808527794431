import { Divider, SimpleGrid, Stack } from "@mantine/core"
import { IconInfoCircle } from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import {
  getHousePhotos,
  getHousePhotoFileName,
} from "@ensol/shared/entities/houses/photos"
import {
  PreliminaryRequestStep,
  getStepIndex,
} from "@ensol/shared/entities/projects/processes"
import { getProspectFileName } from "@ensol/shared/utils/files"

import { NotFound } from "@ensol/entool/components/NotFound"
import { Section } from "@ensol/entool/components/Section"
import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"
import { TextInfo } from "@ensol/entool/components/entities/Project/TextInfo"
import { Field } from "@ensol/entool/components/form/Field"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { UNREGULATED_CONSTRUCTION_OPTIONS } from "@ensol/entool/utils/house/options"

type Props = {
  project: ProjectResponses.Project
}

const MISSING_INFO_MESSAGE =
  "Information manquante, demander à l'AE en charge du projet de la compléter."

export const PreliminaryRequestInfo = ({ project }: Props) => {
  const { house } = project.installation
  const { prospect } = house
  const currentStep = project.preliminaryRequestStep

  const housePhotos = getHousePhotos(house)

  return (
    <Section
      title="Informations"
      icon={IconInfoCircle}
      titleOrder={3}
      isCollapsible={
        getStepIndex(currentStep, "preliminaryRequestStep") >=
        getStepIndex(PreliminaryRequestStep.SUBMITTED, "preliminaryRequestStep")
      }
      isCollapsed
    >
      <SimpleGrid cols={2} spacing="32">
        <Field name="Calepinage">
          {project.salesVisitPanelsLayoutPath !== null ? (
            <MultiFilePreview
              files={[{ path: project.salesVisitPanelsLayoutPath }]}
              fileName={getProspectFileName(
                "calepinage-visite-commerciale",
                prospect,
              )}
            />
          ) : (
            <NotFound message={MISSING_INFO_MESSAGE} type="file" />
          )}
        </Field>
        <Field name="Photos de la maison">
          {housePhotos.length > 0 ? (
            <MultiFilePreview
              files={housePhotos.map((path) => ({ path }))}
              fileName={getHousePhotoFileName(house.streetAddress, "photo")}
            />
          ) : (
            <NotFound message={MISSING_INFO_MESSAGE} type="file" />
          )}
        </Field>
        <Field name="Rapport K2">
          {project.salesVisitK2ReportPath !== null ? (
            <MultiFilePreview
              files={[{ path: project.salesVisitK2ReportPath }]}
              fileName={getProspectFileName(
                "rapport-k2-visite-commerciale",
                prospect,
              )}
            />
          ) : (
            <NotFound message={MISSING_INFO_MESSAGE} type="file" />
          )}
        </Field>
        <Field name="Mandat de représentation">
          {house.mandatePath !== null ? (
            <MultiFilePreview
              files={[{ path: house.mandatePath }]}
              fileName={getProspectFileName("mandat", prospect)}
            />
          ) : (
            <NotFound type="file" />
          )}
        </Field>
        <Stack>
          {house.hasUnRegulatedConstruction && (
            <Field name="Y'a il des constructions non régularisées ?">
              <TextInfo
                value={
                  findSelectedOption(
                    house.hasUnRegulatedConstruction,
                    UNREGULATED_CONSTRUCTION_OPTIONS,
                  )?.label ?? null
                }
              />
            </Field>
          )}
          {house.unRegulatedConstructionNote && (
            <>
              <Divider />
              <Field name="Commentaires pour les constructions non régularisées">
                <TextInfo value={house.unRegulatedConstructionNote} />
              </Field>
            </>
          )}
        </Stack>
      </SimpleGrid>
    </Section>
  )
}
