import { Button, Group, Stack } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import dayjs from "dayjs"
import { useCallback, useEffect, useMemo } from "react"

import {
  CreateHouseInput,
  createHouseSchema,
  Location,
} from "@ensol/types/forms/houses"

import { getCoords } from "@ensol/shared/entities/houses/coords"
import { DEFAULT_HOUSE_CONFIG } from "@ensol/shared/entities/installations/defaults"
import { getZIndex } from "@ensol/shared/styles/zIndex"
import { useLocationData } from "@ensol/shared/thirdParties/google/location"

import { httpClient } from "@ensol/entool/backend/axios"
import { Map } from "@ensol/entool/components/Map"
import { Field } from "@ensol/entool/components/form/Field"
import { AddressAutocomplete } from "@ensol/entool/components/form/House/AddressAutocomplete"
import { NumberInput } from "@ensol/entool/components/form/NumberInput"

type Props = {
  onSave: (values: CreateHouseInput) => void
  isLoading: boolean
}

export const CreateHouseForm = ({ onSave, isLoading }: Props) => {
  const form = useForm<CreateHouseInput>({
    validate: zodResolver(createHouseSchema),
    validateInputOnChange: true,
    // @ts-expect-error Allow partial initial values
    initialValues: {
      ...DEFAULT_HOUSE_CONFIG,
      roofSections: [],
    },
  })

  const coords = useMemo(
    () =>
      form.values.lat && form.values.lng ? getCoords(form.values) : undefined,
    [form.values],
  )

  const handleSelectOption = useCallback(
    async (placeDetails: Location) => {
      form.setValues(placeDetails)
    },
    [form],
  )

  const { data: locationData } = useLocationData(httpClient, coords)

  useEffect(() => {
    if (locationData?.roofSections) {
      form.setFieldValue("roofSections", locationData.roofSections)
    }
  }, [form, locationData?.roofSections])

  return (
    <Stack>
      <Stack mb={44}>
        <Group align="flex-start" justify="space-between">
          <Field name="Adresse" w="100%">
            <AddressAutocomplete
              w="100%"
              handleSelectOption={handleSelectOption}
            />
          </Field>
          {coords && (
            <Field
              name="Coordonnées"
              subtitle="Cliquez sur le toit"
              helpText={
                locationData?.imageryDate
                  ? `Prise de vue: ${dayjs(locationData.imageryDate).format(
                      "MMMM YYYY",
                    )}`
                  : ""
              }
              w="100%"
            >
              <Stack w="100%">
                <Map
                  coords={coords}
                  mapId="modal-map"
                  onPlacePinPoint={(coords) => form.setValues(coords)}
                  style={{ height: 400 }}
                />
                <Group>
                  <Field name="Latitude" flex="1">
                    <NumberInput suffix="°" {...form.getInputProps("lat")} />
                  </Field>
                  <Field name="Longitude" flex="1">
                    <NumberInput suffix="°" {...form.getInputProps("lng")} />
                  </Field>
                </Group>
              </Stack>
            </Field>
          )}
        </Group>
      </Stack>
      <Group
        pos="fixed"
        bottom={0}
        left={0}
        p={16}
        w="100%"
        bg="white"
        justify="flex-end"
        style={{ zIndex: getZIndex("stickyHeader") }}
      >
        <Button
          disabled={!form.isValid()}
          loading={isLoading}
          data-test="submitButton"
          onClick={() => onSave(form.values)}
        >
          Enregistrer
        </Button>
      </Group>
    </Stack>
  )
}
