import { Gender } from "@ensol/shared/entities/prospects"
import { z } from "zod"

export const cetelemFieldsSchema = z.object({
  cetelemReference: z
    .string()
    .refine((value) => /^[0-9\s]+$/.test(value), "Référence invalide"),
  cetelemFinancingRequestAmount: z.number({
    invalid_type_error: "Champ obligatoire",
  }),
  cetelemHasCoBorrower: z.boolean({
    invalid_type_error: "Champ obligatoire",
  }),
  cetelemCoBorrowerGender: z.nativeEnum(Gender).nullable(),
  cetelemCoBorrowerFirstName: z.string(),
  cetelemCoBorrowerLastName: z.string(),
  cetelemCoBorrowerMaidenName: z.string(),
  cetelemCoBorrowerBirthDate: z.date().optional().nullable(),
  cetelemCoBorrowerBirthPlace: z.string(),
})

export const conditionalCetelemCoBorrowerValidation = (
  data: CetelemFieldsInput,
  ctx: z.RefinementCtx,
) => {
  if (!data.cetelemCoBorrowerGender) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Champ obligatoire",
      path: ["cetelemCoBorrowerGender"],
    })
  }
  if (!data.cetelemCoBorrowerLastName) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Champ obligatoire",
      path: ["cetelemCoBorrowerLastName"],
    })
  }
  if (!data.cetelemCoBorrowerFirstName) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Champ obligatoire",
      path: ["cetelemCoBorrowerFirstName"],
    })
  }
  if (!data.cetelemCoBorrowerBirthDate) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Champ obligatoire",
      path: ["cetelemCoBorrowerBirthDate"],
    })
  }
  if (!data.cetelemCoBorrowerBirthPlace) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: "Champ obligatoire",
      path: ["cetelemCoBorrowerBirthPlace"],
    })
  }
}

export type CetelemFieldsInput = z.infer<typeof cetelemFieldsSchema>
