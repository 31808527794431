import {
  Checkbox,
  Tooltip,
  Group,
  Menu,
  ActionIcon,
  rem,
  Text,
  Anchor,
  Paper,
  Stack,
  Badge,
} from "@mantine/core"
import {
  IconBoxMultiple,
  IconDotsVertical,
  IconTrash,
} from "@tabler/icons-react"
import { Link as ReactRouterLink } from "react-router-dom"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"

import {
  SHORT_DISPLAY_DATE_FORMAT,
  formatDate,
} from "@ensol/shared/utils/format"

import { InstallationProducts } from "@ensol/entool/components/entities/Installation/InstallationProducts"
import { ProjectStatusBadge } from "@ensol/entool/components/entities/Installation/ProjectStatusBadge"
import {
  useDeleteInstallation,
  useDuplicateInstallation,
  useBundleInstallationMutation,
} from "@ensol/entool/queries/installations"
import { getSummaryUrl } from "@ensol/entool/utils/projects/reports"

type InstallationRowProps = {
  installation: ProspectResponses.ProspectDetails["houses"][number]["installations"][number]
  canBundle: boolean
}

export const InstallationRow = ({
  installation,
  canBundle,
}: InstallationRowProps) => {
  const { mutateAsync: updateInstallationBundled } =
    useBundleInstallationMutation(installation.prospectId, installation.id)

  const { mutateAsync: deleteInstallation } = useDeleteInstallation(
    installation.prospectId,
    installation.id,
  )

  const { mutateAsync: duplicateInstallation } = useDuplicateInstallation(
    installation.prospectId,
    installation.id,
  )

  return (
    <Paper withBorder p="12">
      <Group justify="space-between">
        <Stack gap="8">
          <Group gap="8">
            <Anchor
              component={ReactRouterLink}
              size="lg"
              fw="500"
              c="black"
              to={`/installations/${installation.id}`}
            >
              {installation.name}
            </Anchor>
            <ProjectStatusBadge status={installation.project?.status} />
            {installation.isUpsell && (
              <Badge variant="outline" color="blue">
                Upsell
              </Badge>
            )}
          </Group>
          <Group gap="8">
            <Text c="gray.5">Produits :</Text>
            <InstallationProducts installation={installation} />
          </Group>
        </Stack>
        <Stack gap="8">
          <Group>
            <Tooltip
              disabled={canBundle}
              label="Le bundle doit contenir maximum 3 offres"
            >
              <Checkbox
                label="Bundle"
                checked={installation.bundled}
                onChange={(event) =>
                  updateInstallationBundled(event.target.checked)
                }
                disabled={!installation.bundled && !canBundle}
              />
            </Tooltip>
            <Anchor href={getSummaryUrl(installation.slug)} target="_blank">
              Résumé
            </Anchor>
            <Menu>
              <Menu.Target>
                <ActionIcon color="gray.5" variant="light">
                  <IconDotsVertical aria-label="Menu" />
                </ActionIcon>
              </Menu.Target>
              <Menu.Dropdown>
                <Menu.Item
                  leftSection={
                    <IconBoxMultiple
                      style={{ width: rem(14), height: rem(14) }}
                    />
                  }
                  onClick={async () => {
                    await duplicateInstallation()
                  }}
                >
                  Dupliquer
                </Menu.Item>
                <Menu.Item
                  color="red"
                  disabled={installation.bundled}
                  leftSection={
                    <IconTrash style={{ width: rem(14), height: rem(14) }} />
                  }
                  onClick={async () => {
                    await deleteInstallation()
                  }}
                >
                  Supprimer
                </Menu.Item>
              </Menu.Dropdown>
            </Menu>
          </Group>
          <Text c="gray.5">
            Créée le{" "}
            {formatDate(installation.createdAt, SHORT_DISPLAY_DATE_FORMAT)}
          </Text>
        </Stack>
      </Group>
    </Paper>
  )
}
