import { Stack, Group, Badge, Text } from "@mantine/core"
import { IconTools } from "@tabler/icons-react"

type Props = {
  requiredChanges: string[]
}

export const RequiredChanges = ({ requiredChanges }: Props) => {
  return (
    <Stack>
      <Text fz="lg" fw={500}>
        Changement requis
      </Text>
      <Group gap={8}>
        {requiredChanges.map((change) => (
          <Badge
            key={change}
            color="yellow"
            fw="bold"
            variant="light"
            leftSection={<IconTools size={16} />}
          >
            {change}
          </Badge>
        ))}
      </Group>
    </Stack>
  )
}
