import { Divider, Group, Stack, Text } from "@mantine/core"

type InfoValue =
  | { name: string; value: JSX.Element | null; dataType: "component" }
  | {
      name?: string
      value?: string | null
      dataType?: "long-text"
    }
type Props = {
  infos: (InfoValue & {
    color?: string

    isHidden?: boolean
  })[]
}

export const ProcessInfos = ({ infos }: Props) => (
  <Stack gap="0">
    {infos.some(({ value, isHidden }) => !!value && !isHidden) && (
      <Divider mt="8" mb="8" />
    )}
    {infos.map((config) => {
      const { value, color, dataType, isHidden, name } = config

      if (!value || isHidden) {
        return null
      }

      if (dataType === "long-text") {
        return (
          <Text
            key={name ?? value}
            c={color}
            size="sm"
            style={{ whiteSpace: "break-spaces" }}
          >
            {name ? `${name} : ` : ""}
            {value}
          </Text>
        )
      }

      return (
        <Group key={config.name} mih="24" gap="4" justify="space-between">
          <Text size="sm" mr="8">
            {config.name}
          </Text>
          {dataType === "component" ? (
            value
          ) : (
            <Text
              c={color}
              fw={color !== undefined ? "bold" : "normal"}
              size="sm"
            >
              {value}
            </Text>
          )}
        </Group>
      )
    })}
  </Stack>
)
