import { IconRotate360 } from "@tabler/icons-react"

import { acknowledgmentStepFormSchema } from "@ensol/types/forms/projects/preliminaryRequest"

import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { PreliminaryRequestForm } from "@ensol/entool/pages/OpsProject/processes/PreliminaryRequest/components/PreliminaryRequestForm"
import { useResumeProjectMutation } from "@ensol/entool/queries/projects"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"

export const Aborted = ({ project, ...props }: StepComponentProps) => {
  const { mutateAsync: resumeProject } = useResumeProjectMutation(project.id)

  return (
    <PreliminaryRequestForm
      schema={acknowledgmentStepFormSchema}
      initialValues={getInitialValues(acknowledgmentStepFormSchema, project)}
      project={project}
      actions={[
        {
          action: "restore",
          label: "Réintégrer le projet",
          Icon: IconRotate360,
          onClick: async (project) => {
            if (project.status === ProjectStatus.ABORTED) {
              await resumeProject()
            }
          },
        },
      ]}
      {...props}
    />
  )
}
