import { Group, Badge } from "@mantine/core"
import { IconGavel } from "@tabler/icons-react"

import { SubSection } from "@ensol/entool/components/SubSection"

type Props = {
  requiredWorks: string[]
}

export const RequiredWorks = ({ requiredWorks }: Props) => {
  return (
    <SubSection title="Travaux requis">
      <Group gap={8}>
        {requiredWorks.map((work) => (
          <Badge
            key={work}
            color="red"
            fw="bold"
            variant="light"
            leftSection={<IconGavel size={16} />}
          >
            {work}
          </Badge>
        ))}
      </Group>
    </SubSection>
  )
}
