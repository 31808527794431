import { Stack, Button, Alert } from "@mantine/core"
import {
  IconChevronsLeft,
  IconChevronsRight,
  IconInfoCircle,
} from "@tabler/icons-react"

import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { Pick } from "@ensol/types/prisma-client/runtime/library"

import {
  ProcessId,
  ProcessStep,
} from "@ensol/shared/entities/projects/processes"

import { Section } from "@ensol/entool/components/Section"
import { MilestonesStepper } from "@ensol/entool/components/entities/Project/MilestonesStepper"
import { StepActionsProps } from "@ensol/entool/components/entities/Project/StepActions"
import {
  useCancelProcessStepMutation,
  useStartProcessMutation,
} from "@ensol/entool/queries/projects"
import { ProjectMilestonesConfig } from "@ensol/entool/utils/projects/types"

export type StepComponentGetter = (
  currentStep: ProcessStep<ProcessId> | null,
) => {
  StepComponent: React.ComponentType<
    Pick<StepActionsProps, "project" | "processId">
  > | null
  isCancellable?: boolean
}

export type ViewProps = {
  processId: ProcessId
  project: ProjectResponses.Project
  milestonesConfig: ProjectMilestonesConfig<ProcessStep<ProcessId>>
  abortedMilestones?: ProcessStep<ProcessId>[]
}

type Props = ViewProps & {
  getStepComponent: StepComponentGetter
  stepperHeight: number
}

export const ProcessView = ({
  project,
  processId,
  milestonesConfig,
  stepperHeight,
  abortedMilestones,
  getStepComponent,
}: Props) => {
  const currentStep = project[processId]

  const { mutateAsync: cancelStep, isPending: isCancellingStep } =
    useCancelProcessStepMutation(project.id, processId)
  const { mutateAsync: startProcess, isPending: isStartingProcess } =
    useStartProcessMutation(project.id, processId)

  const { StepComponent, isCancellable = true } = getStepComponent(currentStep)

  return (
    <Stack gap="24" pb="40">
      <Section
        title="Avancement"
        Actions={
          isCancellable && (
            <Button
              leftSection={<IconChevronsLeft size={14} />}
              variant="outline"
              color="gray"
              disabled={isCancellingStep}
              onClick={async () => await cancelStep()}
            >
              Revenir à l&apos;étape précédente
            </Button>
          )
        }
      >
        <MilestonesStepper<ProcessStep<ProcessId>>
          currentMilestone={currentStep}
          milestonesConfig={milestonesConfig}
          abortedMilestones={abortedMilestones}
          height={stepperHeight}
          project={project}
        />
      </Section>
      {currentStep === null && (
        <Alert
          variant="light"
          title="Ce processus n'a pas encore commencé et sera lancé automatiquement lorsque le projet aura avancé"
          icon={<IconInfoCircle size={30} />}
        >
          <Button
            leftSection={<IconChevronsRight />}
            w="300"
            loading={isStartingProcess}
            onClick={async () => await startProcess()}
          >
            Lancer manuellement le processus
          </Button>
        </Alert>
      )}
      {currentStep !== null && StepComponent !== null && (
        <StepComponent project={project} processId={processId} />
      )}
    </Stack>
  )
}
