import { MantineStyleProps } from "@mantine/core"

import { Link } from "@ensol/shared/components/Link"

type Props = {
  href: string
  label: string
} & MantineStyleProps

export const PennylaneLink = ({ href, ...props }: Props) => {
  return (
    <Link
      to={href}
      w="fit-content"
      Icon={<img src="/pennylaneLogo.png" height={20} />}
      isExternal
      {...props}
    />
  )
}
