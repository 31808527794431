import {
  Stack,
  Button,
  TextInput,
  Checkbox,
  Group,
  Text,
  Divider,
} from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"
import { IconTrash } from "@tabler/icons-react"
import _ from "lodash"
import { ReactNode, useState } from "react"

import { extraWorksSchema } from "@ensol/types/forms/installations/extraWorks"
import { ExtraWorks } from "@ensol/types/installation"

import { ExtraWorkType } from "@ensol/shared/material/extraWorks"

import { FoldableBox } from "@ensol/entool/components/FoldableBox"
import { NumberInput } from "@ensol/entool/components/form/NumberInput"
import { EXTRA_WORKS_OPTIONS_BY_DOMAIN } from "@ensol/entool/utils/installations/extraWorks"

type Props = {
  value?: ExtraWorks
  onChange: (value: ExtraWorks) => void
}

export const ExtraWorksForm = ({ value, onChange }: Props) => {
  const [hasExtraWorks, setHasExtraWorks] = useState(
    value ? value.length > 0 : false,
  )

  const form = useForm({
    onValuesChange: _.debounce((values) => {
      form.validate()
      onChange(values.extraWorks)
    }, 350),
    validateInputOnChange: true,
    validate: zodResolver(extraWorksSchema),
    initialValues: {
      extraWorks: value ?? [],
    },
  })

  return (
    <Stack gap={12}>
      <SubSection
        title="Travaux"
        subtitle="Options et modifications additionnelles pour l'installation"
        Actions={
          hasExtraWorks ? (
            <Button
              variant="light"
              color="red"
              onClick={() => {
                form.getInputProps("extraWorks").onChange([])
                setHasExtraWorks(false)
              }}
            >
              Supprimer les travaux
            </Button>
          ) : (
            <Button
              variant="light"
              onClick={() => setHasExtraWorks(true)}
              data-test="addExtraWorksButton"
            >
              Ajouter
            </Button>
          )
        }
      />
      {hasExtraWorks && (
        <>
          <Stack gap="0">
            {EXTRA_WORKS_OPTIONS_BY_DOMAIN.map(({ name, Icon, works }) => (
              <FoldableBox
                key={name}
                name={
                  <Group gap="8">
                    <Icon size={18} />
                    {name}
                  </Group>
                }
                isOpened
                buttonProps={{ p: 0, mb: 16, size: "lg", fw: "normal", h: 24 }}
              >
                <Stack gap="8">
                  {works.map((extraWorkConfig) => {
                    const index = form.values.extraWorks
                      .map(({ type }) => type)
                      .indexOf(extraWorkConfig.type)
                    const isSelected = index !== -1

                    return (
                      <Group
                        key={extraWorkConfig.type}
                        justify="space-between"
                        h="32"
                        data-test={extraWorkConfig.type.toString()}
                      >
                        <Checkbox
                          label={extraWorkConfig.name}
                          value={extraWorkConfig.type}
                          styles={{ label: { fontSize: 14 } }}
                          checked={isSelected}
                          onChange={({ currentTarget: { checked } }) => {
                            if (checked) {
                              form.insertListItem("extraWorks", extraWorkConfig)
                            } else {
                              form.removeListItem("extraWorks", index)
                            }
                          }}
                        />
                        {isSelected && (
                          <NumberInput
                            suffix="€ (HT)"
                            min={0}
                            w="100"
                            size="xs"
                            hideControls
                            isNullable={false}
                            styles={{ input: { fontSize: 12 } }}
                            {...form.getInputProps(`extraWorks.${index}.price`)}
                          />
                        )}
                      </Group>
                    )
                  })}
                </Stack>
                <Divider my="24" />
              </FoldableBox>
            ))}
          </Stack>
          <SubSection
            title="Ajouter des travaux supplémentaires"
            subtitle="Ajoutez tout type de travaux non proposés dans les options"
            Actions={
              <Button
                variant="light"
                data-test="addCustomExtraWorksButton"
                onClick={() =>
                  form.insertListItem("extraWorks", {
                    type: ExtraWorkType.CUSTOM,
                    name: "Autre",
                    category: "",
                    description: "",
                    price: 0,
                  })
                }
              >
                Ajouter
              </Button>
            }
          />
          <Stack mt="16" gap="24">
            {form.values.extraWorks.map((extraWork, index) => {
              if (extraWork.type !== ExtraWorkType.CUSTOM) {
                return null
              }
              return (
                <Group
                  key={`extraWork_${extraWork.type}_${index}`}
                  data-test="customExtraWork"
                  align="flex-start"
                  gap="24"
                >
                  <TextInput
                    flex="1"
                    placeholder="Catégorie"
                    {...form.getInputProps(`extraWorks.${index}.category`)}
                  />
                  <TextInput
                    flex="1"
                    placeholder="Description"
                    {...form.getInputProps(`extraWorks.${index}.description`)}
                  />
                  <Stack align="flex-end">
                    <NumberInput
                      suffix="€ (HT)"
                      min={0}
                      w="100"
                      hideControls
                      isNullable={false}
                      styles={{ input: { fontSize: 12 } }}
                      {...form.getInputProps(`extraWorks.${index}.price`)}
                    />
                    <Button
                      variant="light"
                      color="red"
                      leftSection={<IconTrash size={16} />}
                      size="xs"
                      onClick={() => form.removeListItem("extraWorks", index)}
                    >
                      Supprimer
                    </Button>
                  </Stack>
                </Group>
              )
            })}
          </Stack>
        </>
      )}
    </Stack>
  )
}

type SubSectionProps = {
  title: string
  subtitle: string
  Actions: ReactNode
}

const SubSection = ({ title, subtitle, Actions }: SubSectionProps) => (
  <Group justify="space-between" align="center">
    <Stack gap="0">
      <Text fw={600} size="lg">
        {title}
      </Text>
      <Text c="gray.6">{subtitle}</Text>
    </Stack>
    {Actions}
  </Group>
)
