import { Card, Group, ThemeIcon, Text, Stack } from "@mantine/core"
import {
  IconBatteryCharging,
  IconChargingPile,
  IconSolarPanel,
  IconWaveSine,
} from "@tabler/icons-react"

const PRODUCT_TYPES = {
  panels: {
    name: "Panneaux",
    icon: IconSolarPanel,
  },
  inverter: {
    name: "Onduleur(s)",
    icon: IconWaveSine,
  },
  battery: {
    name: "Batterie",
    icon: IconBatteryCharging,
  },
  evCharger: {
    name: "Borne de recharge",
    icon: IconChargingPile,
  },
}

type Props = {
  products: {
    type: keyof typeof PRODUCT_TYPES
    name: string
  }[]
}

export const SignedInstallationCard = ({ products }: Props) => {
  if (products.length === 0) return null

  return (
    <Card shadow="none" withBorder radius="md" p={8} bg="blue.0">
      <Group mb={2} gap={8} wrap="nowrap">
        {products.map(({ type, name }) => {
          const installationInfo = PRODUCT_TYPES[type]
          return (
            <Stack gap={0} key={name} w={160}>
              <ThemeIcon variant="subtle" size="sm">
                <installationInfo.icon />
              </ThemeIcon>
              <Text fw={500}>{installationInfo.name}</Text>
              <Text c="gray.6">{name}</Text>
            </Stack>
          )
        })}
      </Group>
    </Card>
  )
}
