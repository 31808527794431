import { Button } from "@mantine/core"

import { SwitchgridResponses } from "@ensol/types/endpoints/switchgrid"

import { ConsentAskStatus } from "@ensol/shared/entities/houses/switchgrid"

type Props = {
  switchgridConsent: SwitchgridResponses.SwitchgridConsent | null
  openConsentModal: () => void
}

export const ConsentAskButton = ({
  switchgridConsent,
  openConsentModal,
}: Props) => {
  if (switchgridConsent === null) {
    return (
      <Button onClick={openConsentModal} variant="light">
        Demander le consentement
      </Button>
    )
  }

  if (switchgridConsent.expirationDate < new Date()) {
    return (
      <Button onClick={openConsentModal} variant="light">
        Demander à nouveau le consentement
      </Button>
    )
  }

  const { askStatus } = switchgridConsent

  if (askStatus === ConsentAskStatus.ACCEPTED) {
    return null
  }

  return (
    <Button onClick={openConsentModal} variant="light">
      Remplacer la demande
    </Button>
  )
}
