import { RoofSectionConfig } from "@ensol/types/forms/houses"

export namespace GoogleResponses {
  export type Location = {
    imageryDate: Date
    maxSunshineHoursPerYear: number
    roofSections: RoofSectionConfig[]
  } | null

  export type DataLayers = {
    imageryDate: Date
    annualFluxUrl: string
    monthlyFluxUrl: string
    baseImageUrl: string
  }

  export type CalendarEvent = {
    name: string
    start: Date | undefined
    end: Date | undefined
    attendees: (string | null | undefined)[]
    isAllDay: boolean
    installerId?: string
  }
}

export enum GoogleCalendarEventType {
  SAV = "SAV",
  AUDIT = "AUDIT",
  INSTALLER = "INSTALLER",
}
