import { Button, SimpleGrid, TextInput } from "@mantine/core"
import { useForm, zodResolver } from "@mantine/form"

import {
  ProspectInput,
  UpdateProspectInput,
  updateProspectSchema,
} from "@ensol/types/forms/prospects"

import { ResidentialStatus } from "@ensol/shared/entities/prospects"
import { STANDARD_DATE_FORMAT } from "@ensol/shared/utils/format"

import { DateInput } from "@ensol/entool/components/form/DateInput"
import { Field } from "@ensol/entool/components/form/Field"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { GENDER_OPTIONS } from "@ensol/entool/utils/prospects/options"

type Props = {
  prospect: ProspectInput
  submitAction: (values: UpdateProspectInput) => void
  isPending: boolean
}

export const ProspectForm = ({
  prospect: {
    gender,
    firstName,
    lastName,
    email,
    phone,
    hubspotContactId,
    birthDate,
    birthPlace,
    residentialStatus,
  },
  submitAction,
  isPending,
}: Props) => {
  const form = useForm({
    validateInputOnChange: true,
    validate: zodResolver(updateProspectSchema),
    initialValues: {
      gender,
      firstName,
      lastName,
      email,
      phone,
      hubspotContactId: hubspotContactId ?? "",
      birthDate,
      birthPlace: birthPlace ?? "",
      residentialStatus,
    },
  })

  const handleSubmit = (values: ProspectInput) => {
    const validateData: UpdateProspectInput = updateProspectSchema.parse(values)
    submitAction(validateData)
  }

  return (
    <form onSubmit={form.onSubmit((values) => handleSubmit(values))}>
      <SimpleGrid cols={2} spacing={16}>
        <Field name="Civilité" withAsterisk>
          <RadioGroup
            options={GENDER_OPTIONS}
            {...form.getInputProps("gender")}
          />
        </Field>
        <Field name="Statut résidentiel">
          <RadioGroup
            options={[
              { label: "Propriétaire", value: ResidentialStatus.OWNER },
              { label: "Locataire", value: ResidentialStatus.RENTER },
            ]}
            {...form.getInputProps("residentialStatus")}
          />
        </Field>
        <Field name="Prénom" withAsterisk>
          <TextInput {...form.getInputProps("firstName")} />
        </Field>
        <Field name="Nom" withAsterisk>
          <TextInput {...form.getInputProps("lastName")} />
        </Field>
        <Field name="Email" withAsterisk>
          <TextInput {...form.getInputProps("email")} />
        </Field>
        <Field name="Téléphone" withAsterisk>
          <TextInput {...form.getInputProps("phone")} />
        </Field>
        <Field name="Date de naissance">
          <DateInput
            clearable
            {...form.getInputProps("birthDate")}
            maxDate={new Date()}
            valueFormat={STANDARD_DATE_FORMAT}
          />
        </Field>
        <Field name="Lieu de naissance">
          <TextInput {...form.getInputProps("birthPlace")} />
        </Field>
        <Field name="Id Hubspot">
          <TextInput {...form.getInputProps("hubspotContactId")} />
        </Field>
      </SimpleGrid>
      <Button
        display="flex"
        justify="flex-end"
        disabled={!form.isValid()}
        loading={isPending}
        type="submit"
        mt={16}
        ml="auto"
      >
        Enregistrer
      </Button>
    </form>
  )
}
