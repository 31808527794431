import { SimpleGrid } from "@mantine/core"

import { HousesResponses } from "@ensol/types/endpoints/houses"

import { colors } from "@ensol/shared/styles/colors"

import { NotFound } from "@ensol/entool/components/NotFound"
import { Section } from "@ensol/entool/components/Section"
import { SubSection } from "@ensol/entool/components/SubSection"
import { MultiFilePreview } from "@ensol/entool/components/entities/File/MultiFilePreview"

type Props = {
  house: HousesResponses.House
}

export const HouseSection = ({
  house: {
    fromStreetPhotos,
    fromFrontPhotos,
    electricMeterPhotos,
    electricalPanelPhotos,
    roofPhotos,
    atticPhotos,
  },
}: Props) => {
  const accessibilityPhotos = [...fromStreetPhotos, ...fromFrontPhotos]
  const electricityPhotos = [...electricMeterPhotos, ...electricalPanelPhotos]
  const roofAndAtticPhotos = [...roofPhotos, ...atticPhotos]
  return (
    <Section title="Maison" isCollapsible>
      <SimpleGrid spacing={24} cols={{ base: 1, sm: 3 }}>
        <PhotoSection title="Accessibilité" photos={accessibilityPhotos} />
        <PhotoSection title="Électricité" photos={electricityPhotos} />
        <PhotoSection title="Toiture" photos={roofAndAtticPhotos} />
      </SimpleGrid>
    </Section>
  )
}

const PhotoSection = ({
  title,
  photos,
}: {
  title: string
  photos: string[]
}) => (
  <SubSection title={title}>
    {photos.length > 0 ? (
      <MultiFilePreview
        h="300px"
        w="100%"
        files={photos.map((path) => ({
          path,
          tag: {
            label: "Visite commerciale",
            color: colors.blue[500],
          },
        }))}
        fileName={title}
      />
    ) : (
      <NotFound w="100%" h="300px" type="file" />
    )}
  </SubSection>
)
