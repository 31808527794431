import { showNotification } from "@mantine/notifications"
import { useMutation } from "@tanstack/react-query"

import { GeneralUpdateHouseInput } from "@ensol/types/forms/houses"
import { UpdateInstallationInput } from "@ensol/types/forms/installations"

import { queryClient } from "@ensol/entool/backend/queryClient"
import { updateHouse } from "@ensol/entool/queries/houses"
import { updateInstallation } from "@ensol/entool/queries/installations"

type SaveInstallationAndHouseBody = {
  house: GeneralUpdateHouseInput
  installation: UpdateInstallationInput
}

export const useSaveHouseAndInstallationData = (installationId: string) => {
  return useMutation({
    mutationFn: async ({
      house,
      installation,
    }: SaveInstallationAndHouseBody) => {
      const updatedInstallation = await updateInstallation(
        installationId,
        installation,
      )
      await updateHouse(updatedInstallation.houseId, house)
      return updatedInstallation
    },
    onSuccess: async (data) => {
      await queryClient.invalidateQueries({
        queryKey: ["houses", data.houseId, "signed-installations", data.id],
      })
      await queryClient.invalidateQueries({
        queryKey: ["installations", installationId],
      })
      await queryClient.removeQueries({
        predicate: ({ queryKey }) => {
          return (
            queryKey.includes("installations") &&
            !queryKey.includes(installationId)
          )
        },
      })
      await queryClient.invalidateQueries({
        queryKey: ["houses", data.houseId],
      })
      await queryClient.invalidateQueries({
        queryKey: ["prospects", data.prospectId],
      })
      showNotification({
        message: "Installation et maison sauvegardées avec succès",
      })
    },
  })
}
