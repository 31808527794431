import _ from "lodash"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { ProjectResponses } from "@ensol/types/endpoints/projects"
import { InstallationProductsInput } from "@ensol/types/forms/installations"
import { ExtraWorks } from "@ensol/types/installation"

import { computePanelsCapacity } from "@ensol/shared/entities/installations/energy"
import { getBattery } from "@ensol/shared/material/batteries"
import { getEvCharger } from "@ensol/shared/material/evCharger"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"
import { getOptimizer } from "@ensol/shared/material/photovoltaic/optimizers"
import { getPanel } from "@ensol/shared/material/photovoltaic/panels"

export type InstallationCharacteristic = {
  label: string
  value: string | null
}

export const computeInstallationCharacteristics = (
  installation: ProjectResponses.Project["installation"],
) => {
  const {
    house,
    photovoltaicInstallation,
    extraPanelsInstallation,
    batteryInstallation,
    evChargerInstallation,
  } = installation

  return {
    house: [
      {
        label: "Coordonnées de la maison",
        value: `${house.lat},${house.lng}`,
      },
      {
        label: "Besoin de nacelle",
        value:
          house.isPodNeeded !== null
            ? house.isPodNeeded
              ? "Oui"
              : "Non"
            : null,
      },
      {
        label: "Accès aux combles",
        value:
          house.isAtticAccessible !== null
            ? house.isAtticAccessible
              ? "Oui"
              : "Non"
            : null,
      },
    ],
    photovoltaicInstallation:
      photovoltaicInstallation !== null
        ? computePhotovoltaicInstallationCharacteristics(
            photovoltaicInstallation,
          )
        : [],
    extraPanelsInstallation:
      extraPanelsInstallation !== null
        ? computePanelsInstallationCharacteristics(extraPanelsInstallation)
        : [],
    batteryInstallation:
      batteryInstallation !== null
        ? computeBatteryInstallationCharacteristics(batteryInstallation)
        : [],
    evChargerInstallation:
      evChargerInstallation !== null
        ? computeEvChargerInstallationCharacteristics(evChargerInstallation)
        : [],
    extraWorks: computeInstallationExtraWorksCharacteristics([
      ...(photovoltaicInstallation?.extraWorks ?? []),
      ...(extraPanelsInstallation?.extraWorks ?? []),
      ...(batteryInstallation?.extraWorks ?? []),
      ...(evChargerInstallation?.extraWorks ?? []),
    ]),
  }
}

const computePhotovoltaicInstallationCharacteristics = (
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation,
): InstallationCharacteristic[] => {
  const {
    panelType,
    panelsCount,
    inverterType,
    optimizerType,
    optimizerCount,
  } = photovoltaicInstallation

  const panel = getPanel(panelType)
  const inverter = getInverter(inverterType)
  const optimizer = getOptimizer(optimizerType)
  const installedCapacity = computePanelsCapacity(photovoltaicInstallation)

  return [
    { label: "Capacité", value: `${installedCapacity} kWc` },
    { label: "Panneaux", value: `${panelsCount} × ${panel.name}` },
    { label: "Onduleur", value: inverter.name },
    {
      label: "Optimiseurs",
      value:
        optimizer && optimizerCount
          ? `${optimizerCount} × ${optimizer.name}`
          : null,
    },
  ]
}

const computePanelsInstallationCharacteristics = (
  extraPanelsInstallation: InstallationsResponses.ExtraPanelsInstallation,
): InstallationCharacteristic[] => {
  const { panelType, panelsCount } = extraPanelsInstallation

  const panel = getPanel(panelType)
  const installedCapacity = computePanelsCapacity(extraPanelsInstallation)

  return [
    { label: "Capacité", value: `${installedCapacity} kWc` },
    { label: "Panneaux", value: `${panelsCount} × ${panel.name}` },
  ]
}

const computeBatteryInstallationCharacteristics = (
  batteryInstallation: InstallationsResponses.BatteryInstallation,
): InstallationCharacteristic[] => {
  const battery = getBattery(batteryInstallation.type)

  return [{ label: "Batterie", value: battery.name }]
}

const computeEvChargerInstallationCharacteristics = (
  evChargerInstallation: InstallationsResponses.EvChargerInstallation,
): InstallationCharacteristic[] => {
  const evCharger = getEvCharger(evChargerInstallation.type)

  return [{ label: "Borne", value: evCharger.name }]
}

const computeInstallationExtraWorksCharacteristics = (
  extraWorks: ExtraWorks,
) => {
  if (extraWorks.length === 0) {
    return []
  }

  return [
    {
      label: "Travaux supplémentaires",
      value: extraWorks.map(({ name }) => name).join(", "),
    },
  ]
}

export const getInstallationCharacteristicsTextSummary = (
  installation: ProjectResponses.Project["installation"],
) =>
  _.chain(computeInstallationCharacteristics(installation))
    .values()
    .flatten()
    .map(
      ({ label, value }: InstallationCharacteristic) =>
        `${label}: ${value !== null ? value : "-"}`,
    )
    .join("\n")
    .value()

export const hasPhotovoltaicInstallation = (installation: {
  photovoltaicInstallation: Pick<
    InstallationsResponses.PhotovoltaicInstallation,
    "panelsCount"
  > | null
}): installation is InstallationsResponses.Installation & {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation
} => {
  return (
    installation.photovoltaicInstallation != null &&
    installation.photovoltaicInstallation.panelsCount > 0
  )
}

export const hasPanelsInstallation = (installation: {
  photovoltaicInstallation: Pick<
    InstallationsResponses.PhotovoltaicInstallation,
    "panelsCount"
  > | null
  extraPanelsInstallation: Pick<
    InstallationsResponses.ExtraPanelsInstallation,
    "panelsCount"
  > | null
}): installation is InstallationsResponses.Installation & {
  photovoltaicInstallation: InstallationsResponses.PhotovoltaicInstallation
} => {
  return (
    hasPhotovoltaicInstallation(installation) ||
    (installation.extraPanelsInstallation != null &&
      installation.extraPanelsInstallation.panelsCount > 0)
  )
}

export const getInstallationProductsCount = ({
  photovoltaicInstallation,
  batteryInstallation,
  extraPanelsInstallation,
  evChargerInstallation,
}: Omit<InstallationProductsInput, "productsCount">): number => {
  let productsCount = 0

  if (photovoltaicInstallation !== null) {
    productsCount += 1
  }

  if (extraPanelsInstallation !== null) {
    productsCount += 1
  }

  if (batteryInstallation !== null) {
    productsCount += 1
  }

  if (evChargerInstallation !== null) {
    productsCount += 1
  }

  return productsCount
}
