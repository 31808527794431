import { Avatar, Stack } from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import _ from "lodash"

import { ProjectStatus } from "@ensol/shared/entities/projects/statuses"
import { devices } from "@ensol/shared/styles/theme"

import { ProjectCard } from "@ensol/entool/components/entities/Project/Card"
import {
  ColumnWrapper,
  Column,
} from "@ensol/entool/components/entities/Project/Column"
import { ProjectSearch } from "@ensol/entool/components/entities/Project/ProjectSearch"
import { QueryWrapper } from "@ensol/entool/components/layout/QueryWrapper"
import { useListProjectsQuery } from "@ensol/entool/queries/projects"
import { CONTENT_PADDING_IN_PX } from "@ensol/entool/styles/constants"
import {
  PROJECT_SEARCHABLE_PROPS,
  useProjectsSearch,
} from "@ensol/entool/utils/projects/search"

import { SALES_TASKS_VIEWS } from "./config"

export const SalesTasks = () => {
  const isMobile = useMediaQuery(devices.sm)

  const { search, salesOwnerId } = useProjectsSearch()
  const projectsQuery = useListProjectsQuery({
    filters: {
      search,
      salesOwnerId: salesOwnerId ?? undefined,
      statuses: [
        { status: ProjectStatus.CREATED, isIncluded: false },
        { status: ProjectStatus.ABORTED, isIncluded: false },
      ],
    },
    enabled:
      isMobile !== undefined &&
      (isMobile ? search !== "" || salesOwnerId !== null : true),
  })

  return (
    <Stack flex="1" miw="0">
      <ProjectSearch
        filteredProps={[
          PROJECT_SEARCHABLE_PROPS.search,
          PROJECT_SEARCHABLE_PROPS.salesOwnerId,
        ]}
        px={CONTENT_PADDING_IN_PX}
      />
      <QueryWrapper
        query={projectsQuery}
        disabledQueryMessage="Entrez le nom, le téléphone ou l'email d'un client pour commencer"
      >
        {({ data }) => (
          <ColumnWrapper>
            {SALES_TASKS_VIEWS.map(
              ({
                name,
                filter,
                getLink,
                getLastEventDate,
                Component,
                orderBy,
              }) => {
                const projects = data.filter(filter)

                return (
                  <Column
                    key={name}
                    backgroundColor="blue"
                    title={name}
                    titleColor="blue"
                    count={projects.length}
                  >
                    {_.orderBy(projects, ...(orderBy ?? [])).map((project) => {
                      const lastEventDate = getLastEventDate?.(project)
                      const lastEventProps = lastEventDate
                        ? {
                            lastEventDate,
                            warnDelayInDays: 30,
                            shouldDisplayDaysSinceLastEvent: true,
                          }
                        : ({
                            lastEventDate: undefined,
                            warnDelayInDays: undefined,
                            shouldDisplayDaysSinceLastEvent: false,
                          } as const)

                      return (
                        <ProjectCard
                          key={project.id}
                          project={project}
                          to={getLink(project)}
                          {...lastEventProps}
                        >
                          <Avatar
                            pos="absolute"
                            right={12}
                            top={lastEventProps.lastEventDate ? 40 : 12}
                            size="32"
                            src={project.salesOwner?.avatarUrl}
                          />
                          <Component project={project} />
                        </ProjectCard>
                      )
                    })}
                  </Column>
                )
              },
            )}
          </ColumnWrapper>
        )}
      </QueryWrapper>
    </Stack>
  )
}
