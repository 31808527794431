import {
  IconBolt,
  IconHome2,
  IconPlugConnected,
  TablerIcon,
} from "@tabler/icons-react"
import _ from "lodash"

import { typedKeys } from "@ensol/types/utils"

import { EXTRA_WORKS, ExtraWorkDomain } from "@ensol/shared/material/extraWorks"

const EXTRA_WORKS_DOMAINS_CONFIG: Record<
  ExtraWorkDomain,
  { name: string; Icon: TablerIcon }
> = {
  [ExtraWorkDomain.ROOF]: {
    name: "Changement lié à la toiture",
    Icon: IconHome2,
  },
  [ExtraWorkDomain.ELECTRICAL_INSTALLATION]: {
    name: "Changement lié à l'installation électrique",
    Icon: IconBolt,
  },
  [ExtraWorkDomain.CABLE_ROUTING]: {
    name: "Changement lié au cheminement des câbles",
    Icon: IconPlugConnected,
  },
}

export const EXTRA_WORKS_OPTIONS_BY_DOMAIN = _.chain(typedKeys(EXTRA_WORKS))
  .map((workType) => ({
    type: workType,
    ...EXTRA_WORKS[workType],
  }))
  .filter(({ domain }) => domain !== undefined)
  .groupBy(({ domain }) => domain)
  .entries()
  .map(([domain, works]) => ({
    ...EXTRA_WORKS_DOMAINS_CONFIG[domain as ExtraWorkDomain],
    works,
  }))
  .value()
