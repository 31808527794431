import { MountingType } from "@ensol/shared/entities/installations/batteries"
import * as z from "zod"

import { BatteryType } from "@ensol/shared/material/batteries"
import { extraWorksSchema } from "./extraWorks"

export const batteryInstallationSchema = z.object({
  type: z.nativeEnum(BatteryType),
  mountingType: z.nativeEnum(MountingType).default(MountingType.ON_FLOOR),
})

const batteryInstallationWithWExtraWorksSchema =
  batteryInstallationSchema.merge(extraWorksSchema)
export type BatteryInstallationWithExtraWorksInput = z.infer<
  typeof batteryInstallationWithWExtraWorksSchema
>
