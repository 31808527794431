import { Text, Flex, Stack, Group } from "@mantine/core"

import { colors } from "@ensol/shared/styles/colors"
import { formatCurrency } from "@ensol/shared/utils/format"

type Props = {
  totalBill: number
  autoConsumedAmount: number
  resellAmount: number
  maxHeight: number
}

export const SavingsDistribution = ({
  totalBill,
  autoConsumedAmount,
  resellAmount,
  maxHeight,
}: Props) => {
  const autoConsumedBillRatio = autoConsumedAmount / totalBill
  const resellBillRatio = resellAmount / totalBill
  const remainingBillAmount = totalBill - autoConsumedAmount - resellAmount
  const remainingBillRatio = remainingBillAmount / totalBill

  const blockWidth = { base: 80, lg: 110 }

  return (
    <Stack align="center">
      <Group align="flex-start">
        <Stack align="center" h={maxHeight} pos="relative">
          <Flex
            bg="red.1"
            style={{ borderRadius: 16 }}
            w={blockWidth}
            h="100%"
            align="center"
            justify="center"
          >
            <Text size="sm" c="red.5" fw="bold">
              - {formatCurrency(totalBill, 0)}
            </Text>
          </Flex>
        </Stack>
        <Stack
          h={autoConsumedBillRatio * maxHeight}
          align="flex-start"
          pos="relative"
        >
          <Flex
            bg="green.1"
            style={{ borderRadius: 16 }}
            w={blockWidth}
            h="100%"
            align="center"
            justify="center"
          >
            <Text size="sm" c="green.5" fw="bold">
              + {formatCurrency(autoConsumedAmount, 0)}
            </Text>
          </Flex>
        </Stack>
        {resellAmount > 0 && (
          <Stack
            h={resellBillRatio * maxHeight}
            mt={autoConsumedBillRatio * maxHeight}
            align="flex-start"
            pos="relative"
          >
            <Flex
              bg="green.1"
              style={{ borderRadius: 16 }}
              w={blockWidth}
              h="100%"
              align="center"
              justify="center"
            >
              <Text size="sm" c="green.5" fw="bold">
                + {formatCurrency(resellAmount, 0)}
              </Text>
            </Flex>
          </Stack>
        )}
        <Stack
          align="center"
          h={Math.abs(remainingBillRatio) * maxHeight}
          mt={
            remainingBillRatio > 0
              ? (1 - remainingBillRatio) * maxHeight
              : maxHeight
          }
          pos="relative"
        >
          <Flex
            bg={remainingBillAmount > 0 ? "gray.2" : "green.1"}
            style={{ borderRadius: 16 }}
            w={blockWidth}
            h="100%"
            align="center"
            justify="center"
          >
            <Text size="sm" c="green.5" fw="bold">
              {remainingBillAmount > 0 ? "-" : "+"}{" "}
              {formatCurrency(Math.abs(remainingBillAmount), 0)}
            </Text>
          </Flex>
        </Stack>
      </Group>
      <Group style={{ borderTop: `1px solid ${colors.gray[300]}` }} py={12}>
        <Text c="gray.6" size="sm" w={blockWidth} ta="center">
          Coût initial de votre électricité
        </Text>
        <Text c="gray.6" size="sm" w={blockWidth} ta="center">
          Autoconsommation
        </Text>
        {resellAmount > 0 && (
          <Text c="gray.6" size="sm" w={blockWidth} ta="center">
            Revente du surplus
          </Text>
        )}
        <Text c="gray.6" size="sm" w={blockWidth} ta="center">
          {remainingBillAmount > 0
            ? "Coût final de votre électricité"
            : "Revenu de votre installation"}
        </Text>
      </Group>
    </Stack>
  )
}
