import { Button, CopyButton, Stack } from "@mantine/core"
import { IconCopy, IconCopyCheck, IconSignature } from "@tabler/icons-react"

import { InstallationsResponses } from "@ensol/types/endpoints/installations"

import { QuoteStatus } from "@ensol/shared/entities/quotes"

import { LinkLoader } from "@ensol/entool/components/LinkLoader"
import { useGenerateQuoteSigningUrlMutation } from "@ensol/entool/queries/quotes"

type Props = {
  activeQuote: InstallationsResponses.Quote
  installationId: string
}

export const ActiveQuoteActions = ({ activeQuote, installationId }: Props) => {
  const { mutateAsync: generateQuoteSigningUrl, isPending: isQuoteUrlPending } =
    useGenerateQuoteSigningUrlMutation(activeQuote.id, installationId)

  if (activeQuote === null) {
    return null
  }

  return (
    <Stack data-testid="quote-links">
      {activeQuote.pandadocSigningUrl === null ? (
        activeQuote.status === QuoteStatus.CREATED && (
          <Button
            data-testid="generate-quote-link"
            leftSection={isQuoteUrlPending ? <LinkLoader /> : <IconSignature />}
            onClick={() => generateQuoteSigningUrl()}
            disabled={isQuoteUrlPending}
            variant="light"
          >
            Générer l&apos;url de signature du devis
          </Button>
        )
      ) : (
        <CopyButton value={activeQuote.pandadocSigningUrl}>
          {({ copied, copy }) => (
            <Button
              data-testid="copy-quote-link"
              variant="light"
              leftSection={
                copied ? <IconCopyCheck size={16} /> : <IconCopy size={16} />
              }
              onClick={copy}
            >
              {copied
                ? "Url de signature copiée !"
                : "Copier l'url de signature"}
            </Button>
          )}
        </CopyButton>
      )}
    </Stack>
  )
}
