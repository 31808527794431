import { useMediaQuery } from "@mantine/hooks"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"

import { Link } from "@ensol/shared/components/Link"
import { devices } from "@ensol/shared/styles/theme"

import { queryClient } from "@ensol/entool/backend/queryClient"
import { ProspectHeader } from "@ensol/entool/components/entities/Prospect/ProspectHeader"
import { K2_LOGIN_URL } from "@ensol/entool/config"

type Props = {
  projectId: string
  prospect: ProspectResponses.Prospect
  children?: React.ReactNode
}

export const Header = ({ projectId, prospect, children }: Props) => {
  const isMobile = useMediaQuery(devices.sm)

  return (
    <ProspectHeader
      prospect={prospect}
      Infos={
        !isMobile && (
          <Link
            label="Raccourci K2"
            to={K2_LOGIN_URL}
            Icon={<img src="/k2Logo.webp" height={20} />}
            color="red"
            isExternal
            isIconButton
          />
        )
      }
      RightSection={!isMobile && children}
      onProspectEditSuccess={() => {
        queryClient.invalidateQueries({ queryKey: ["projects", projectId] })
      }}
    />
  )
}
