import { SimpleGrid, Stack, Textarea, Input, MultiSelect } from "@mantine/core"
import {
  IconMessageCheck,
  IconMessageX,
  IconReceipt,
  IconSend,
} from "@tabler/icons-react"
import { useEffect } from "react"

import { updateTownHallSchema } from "@ensol/types/forms/houses"
import { PreliminaryRequestStepInput } from "@ensol/types/forms/projects/preliminaryRequest"

import { Link } from "@ensol/shared/components/Link"
import { getProspectFileName } from "@ensol/shared/utils/files"
import { DISPLAY_DATE_FORMAT } from "@ensol/shared/utils/format"

import { Section } from "@ensol/entool/components/Section"
import { Tooltip } from "@ensol/entool/components/Tooltip"
import {
  StepActions,
  StepActionsProps,
} from "@ensol/entool/components/entities/Project/StepActions"
import { DateInput } from "@ensol/entool/components/form/DateInput"
import { FileField } from "@ensol/entool/components/form/File/FileField"
import { MultiFileField } from "@ensol/entool/components/form/File/MultiFileField"
import { FormField } from "@ensol/entool/components/form/Project/FormField"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { FIELD_WIDTH } from "@ensol/entool/components/form/constants"
import { SOLTEO_URL } from "@ensol/entool/config"
import { useGetTownHalls } from "@ensol/entool/queries/townHalls"
import { booleanOptions } from "@ensol/entool/utils/form/options"
import { confirmAction } from "@ensol/entool/utils/helpers/confirmModal"
import {
  PRELIMINARY_REQUEST_AWAITED_STAKEHOLDER_OPTIONS,
  PRELIMINARY_REQUEST_REQUESTED_CHANGES_OPTIONS,
  PRELIMINARY_REQUEST_REQUESTED_CHANGES_REASONS_OPTIONS,
} from "@ensol/entool/utils/projects/options"
import {
  ProcessFormProps,
  useProcessForm,
} from "@ensol/entool/utils/projects/useProcessForm"
import { getSolteoProjectLink } from "@ensol/entool/utils/solteo"

import { PreliminaryRequestInfo } from "./PreliminaryRequestInfo"
import { TownHallForm } from "./TownHallForm"
import { useTownHallForm } from "./useTownHallForm"

export const PreliminaryRequestForm = <
  Input extends PreliminaryRequestStepInput,
>({
  schema,
  initialValues,
  project,
  children,
  ...props
}: ProcessFormProps<Input> & StepActionsProps) => {
  const form = useProcessForm<Input>({
    schema,
    initialValues,
    projectId: project.id,
    processId: props.processId,
  })

  const townHallForm = useTownHallForm(project.installation.house)

  const { data: townHalls, isPending: isGetTownHallsPending } =
    useGetTownHalls()

  const selectedTownHall = townHalls?.find(
    (townHall) => townHall.id === townHallForm.values.townHallId,
  )
  const selectedTownHallHasValidContact = !!selectedTownHall?.contacts.find(
    (contact) => Boolean(contact.email),
  )

  useEffect(() => {
    if (selectedTownHall && !selectedTownHallHasValidContact) {
      form
        .getInputProps("preliminaryRequestShouldSendReminderMail")
        .onChange(false)
    }
  }, [form, selectedTownHall, selectedTownHallHasValidContact])

  const { prospect } = project.installation.house

  return (
    <StepActions
      project={project}
      validateStep={() => {
        updateTownHallSchema.parse(townHallForm.values)
        schema.parse(form.values)
      }}
      {...props}
    >
      {children}
      <Section>
        <Link
          label="Accéder à Solteo"
          to={
            project.solteoProjectId
              ? getSolteoProjectLink(project.solteoProjectId)
              : SOLTEO_URL
          }
          Icon={<img src="/solteoLogo.png" height={20} />}
          w="fit-content"
          isExternal
        />
        <SimpleGrid cols={2} spacing={{ base: 16, lg: 32 }} mt="16" mb="16">
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="preliminaryRequestLastContactDate"
            >
              <DateInput
                w={FIELD_WIDTH}
                clearable
                valueFormat={DISPLAY_DATE_FORMAT}
                {...form.getInputProps("preliminaryRequestLastContactDate")}
              />
            </FormField>
          </Stack>
          <Stack gap="24">
            <FormField
              initialValues={initialValues}
              propertyName="preliminaryRequestNotes"
            >
              <Textarea
                autosize
                w={FIELD_WIDTH}
                minRows={3}
                {...form.getInputProps("preliminaryRequestNotes")}
              />
            </FormField>
          </Stack>
        </SimpleGrid>
      </Section>
      <TownHallForm
        form={townHallForm}
        townHalls={townHalls}
        selectedTownHall={selectedTownHall}
        isGetTownHallsPending={isGetTownHallsPending}
      />
      <PreliminaryRequestInfo project={project} />
      {("preliminaryRequestSubmissionDate" in initialValues ||
        "preliminaryRequestExpectedValidationDate" in initialValues ||
        "preliminaryRequestDeadlineDate" in initialValues) && (
        <Section icon={IconSend} title="Demande" titleOrder={3}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <FormField
              initialValues={initialValues}
              propertyName="preliminaryRequestSubmissionDate"
              isRequired
            >
              <DateInput
                w={FIELD_WIDTH}
                clearable
                valueFormat={DISPLAY_DATE_FORMAT}
                {...form.getInputProps("preliminaryRequestSubmissionDate")}
              />
            </FormField>
            <FormField
              initialValues={initialValues}
              propertyName="preliminaryRequestExpectedValidationDate"
            >
              <DateInput
                w={FIELD_WIDTH}
                valueFormat={DISPLAY_DATE_FORMAT}
                {...form.getInputProps(
                  "preliminaryRequestExpectedValidationDate",
                )}
                onChange={(value) => {
                  const setValue = () =>
                    form
                      .getInputProps("preliminaryRequestExpectedValidationDate")
                      .onChange(value)
                  if (
                    project.installationStartDate !== null &&
                    value !== null &&
                    project.installationStartDate <= value
                  ) {
                    return confirmAction({
                      message:
                        "L'installation du projet a déjà été planifiée avant cette date, si vous confirmez, prévenez l'équipe concernée.",
                      onConfirm: setValue,
                    })
                  } else {
                    setValue()
                  }
                }}
              />
            </FormField>
            <FormField
              propertyName="preliminaryRequestDeadlineDate"
              initialValues={initialValues}
              isRequired
            >
              <DateInput
                w={FIELD_WIDTH}
                clearable
                valueFormat={DISPLAY_DATE_FORMAT}
                {...form.getInputProps("preliminaryRequestDeadlineDate")}
              />
            </FormField>
          </SimpleGrid>
        </Section>
      )}
      {"preliminaryRequestReference" in initialValues &&
        "preliminaryRequestAcknowledgedDate" in initialValues &&
        "preliminaryRequestAcknowledgmentPath" in initialValues &&
        "preliminaryRequestABFAcknowledgmentPath" in initialValues &&
        "preliminaryRequestAcknowledgmentExtraPaths" in initialValues && (
          <Section
            icon={IconReceipt}
            title="Accusé de reception"
            titleOrder={3}
          >
            <SimpleGrid cols={2} spacing="32" mt="16">
              <Stack gap="24">
                <FormField
                  initialValues={initialValues}
                  propertyName="preliminaryRequestReference"
                  isRequired
                >
                  <Input
                    w={FIELD_WIDTH}
                    {...form.getInputProps("preliminaryRequestReference")}
                  />
                </FormField>
                <FormField
                  initialValues={initialValues}
                  propertyName="preliminaryRequestAcknowledgedDate"
                  isRequired
                >
                  <DateInput
                    w={FIELD_WIDTH}
                    clearable
                    valueFormat={DISPLAY_DATE_FORMAT}
                    {...form.getInputProps(
                      "preliminaryRequestAcknowledgedDate",
                    )}
                  />
                </FormField>
                <FormField
                  initialValues={initialValues}
                  propertyName="preliminaryRequestShouldSendReminderMail"
                  isRequired
                >
                  <Tooltip
                    label={
                      !selectedTownHallHasValidContact &&
                      "La mairie doit avoir un email valide !"
                    }
                  >
                    <RadioGroup
                      w="fit-content"
                      disabled={
                        !selectedTownHall || !selectedTownHallHasValidContact
                      }
                      options={booleanOptions}
                      {...form.getInputProps(
                        "preliminaryRequestShouldSendReminderMail",
                      )}
                    />
                  </Tooltip>
                </FormField>
              </Stack>
              <Stack gap="24">
                <FormField
                  initialValues={initialValues}
                  propertyName="preliminaryRequestAcknowledgmentPath"
                  isRequired
                >
                  <FileField
                    previewProps={{
                      fileName: getProspectFileName(
                        "accusé-reception-DP",
                        prospect,
                      ),
                    }}
                    inputProps={{
                      accept: ["application/pdf", "image/jpeg", "image/png"],
                    }}
                    {...form.getInputProps(
                      "preliminaryRequestAcknowledgmentPath",
                    )}
                  />
                </FormField>
                <FormField
                  initialValues={initialValues}
                  propertyName="preliminaryRequestABFAcknowledgmentPath"
                >
                  <FileField
                    previewProps={{
                      fileName: getProspectFileName(
                        "accusé-reception-ABF",
                        prospect,
                      ),
                    }}
                    inputProps={{
                      accept: ["application/pdf", "image/jpeg", "image/png"],
                    }}
                    {...form.getInputProps(
                      "preliminaryRequestABFAcknowledgmentPath",
                    )}
                  />
                </FormField>
                <FormField
                  initialValues={initialValues}
                  propertyName="preliminaryRequestAcknowledgmentExtraPaths"
                >
                  <MultiFileField
                    previewProps={{
                      fileName: getProspectFileName(
                        "accusé-reception-DP-extra",
                        prospect,
                      ),
                    }}
                    inputProps={{
                      accept: ["application/pdf", "image/jpeg", "image/png"],
                    }}
                    {...form.getInputProps(
                      "preliminaryRequestAcknowledgmentExtraPaths",
                    )}
                  />
                </FormField>
              </Stack>
            </SimpleGrid>
          </Section>
        )}
      {("preliminaryRequestApprovalDate" in initialValues ||
        "preliminaryRequestApprovalPaths" in initialValues) && (
        <Section icon={IconMessageCheck} title="Approuvé" titleOrder={3}>
          <SimpleGrid cols={2} spacing="32" mt="16">
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="preliminaryRequestApprovalDate"
              >
                <DateInput
                  w={FIELD_WIDTH}
                  clearable
                  valueFormat={DISPLAY_DATE_FORMAT}
                  {...form.getInputProps("preliminaryRequestApprovalDate")}
                />
              </FormField>
            </Stack>
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="preliminaryRequestApprovalPaths"
              >
                <MultiFileField
                  previewProps={{
                    fileName: getProspectFileName("validation-DP", prospect),
                  }}
                  inputProps={{
                    accept: ["application/pdf", "image/jpeg", "image/png"],
                  }}
                  {...form.getInputProps("preliminaryRequestApprovalPaths")}
                />
              </FormField>
            </Stack>
          </SimpleGrid>
        </Section>
      )}
      {("preliminaryRequestRequestedChangesPaths" in initialValues ||
        "preliminaryRequestRejectionPaths" in initialValues) && (
        <Section
          icon={IconMessageX}
          title="Changements demandé / Refus"
          titleOrder={3}
        >
          <SimpleGrid cols={2} spacing="32" mt="16">
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="preliminaryRequestRequestedChangesPaths"
              >
                <MultiFileField
                  previewProps={{
                    fileName: getProspectFileName(
                      "demande-changement-DP",
                      prospect,
                    ),
                  }}
                  inputProps={{
                    accept: ["application/pdf", "image/jpeg", "image/png"],
                  }}
                  {...form.getInputProps(
                    "preliminaryRequestRequestedChangesPaths",
                  )}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="preliminaryRequestRequestedChangesReasons"
              >
                <MultiSelect
                  w={FIELD_WIDTH}
                  searchable
                  hidePickedOptions
                  data={PRELIMINARY_REQUEST_REQUESTED_CHANGES_REASONS_OPTIONS}
                  {...form.getInputProps(
                    "preliminaryRequestRequestedChangesReasons",
                  )}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="preliminaryRequestAwaitedStakeholder"
              >
                <RadioGroup
                  options={PRELIMINARY_REQUEST_AWAITED_STAKEHOLDER_OPTIONS}
                  nullable
                  {...form.getInputProps(
                    "preliminaryRequestAwaitedStakeholder",
                  )}
                />
              </FormField>
            </Stack>
            <Stack gap="24">
              <FormField
                initialValues={initialValues}
                propertyName="preliminaryRequestRejectionPaths"
              >
                <MultiFileField
                  previewProps={{
                    fileName: getProspectFileName(
                      "justificatif-rejet-DP",
                      prospect,
                    ),
                  }}
                  inputProps={{
                    accept: ["application/pdf", "image/jpeg", "image/png"],
                  }}
                  {...form.getInputProps("preliminaryRequestRejectionPaths")}
                />
              </FormField>
              <FormField
                initialValues={initialValues}
                propertyName="preliminaryRequestRequestedChanges"
              >
                <MultiSelect
                  w={FIELD_WIDTH}
                  searchable
                  hidePickedOptions
                  data={PRELIMINARY_REQUEST_REQUESTED_CHANGES_OPTIONS}
                  {...form.getInputProps("preliminaryRequestRequestedChanges")}
                />
              </FormField>
            </Stack>
          </SimpleGrid>
        </Section>
      )}
    </StepActions>
  )
}
