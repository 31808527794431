import { pendingStepFormSchema } from "@ensol/types/forms/projects/consuel"

import { StepComponentProps } from "@ensol/entool/components/entities/Project/StepActions"
import { getInitialValues } from "@ensol/entool/utils/projects/initialValues"

import { ConsuelForm } from "./components/ConsuelForm"

export const WaitingForSolteo = ({
  project,
  processId,
}: StepComponentProps) => (
  <ConsuelForm
    schema={pendingStepFormSchema}
    initialValues={getInitialValues(pendingStepFormSchema, project)}
    project={project}
    processId={processId}
    actions={[{ action: "validate", label: "Consuel soumis" }]}
  />
)
