import { Checkbox, Group, SimpleGrid, Stack, Text } from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import _ from "lodash"

import { GeneralUpdateHouseInput } from "@ensol/types/forms/houses"
import { HouseSignedInstallations } from "@ensol/types/installation"

import { EQUIPMENT_CATEGORIES } from "@ensol/shared/entities/houses/equipments"
import { getBattery } from "@ensol/shared/material/batteries"
import { getEvCharger } from "@ensol/shared/material/evCharger"
import { getInverter } from "@ensol/shared/material/photovoltaic/inverters"
import { getPanel } from "@ensol/shared/material/photovoltaic/panels"

import { Section } from "@ensol/entool/components/Section"
import { SignedInstallationCard } from "@ensol/entool/components/form/House/SignedInstallationCard"

type Props = {
  form: UseFormReturnType<GeneralUpdateHouseInput>
  signedInstallations?: HouseSignedInstallations
}
export const HouseEquipmentsForm = ({ form, signedInstallations }: Props) => {
  return (
    <>
      {signedInstallations && (
        <Section title="Équipements Ensol déjà installés">
          <Group gap={12} align="stretch">
            <SignedInstallationCard
              products={[
                {
                  type: "panels",
                  name: `${signedInstallations.photovoltaicInstallation.panelsCount} ${
                    getPanel(
                      signedInstallations.photovoltaicInstallation.panelType,
                    ).name
                  }`,
                },
                {
                  type: "inverter",
                  name: getInverter(
                    signedInstallations.photovoltaicInstallation.inverterType,
                  ).name,
                },
              ]}
            />
            <SignedInstallationCard
              products={signedInstallations.extraPanelsInstallations.map(
                (installation) => ({
                  type: "panels",
                  name: `${installation.panelsCount} ${getPanel(installation.panelType).name}`,
                }),
              )}
            />
            <SignedInstallationCard
              products={signedInstallations.batteryInstallations.map(
                (installation) => ({
                  type: "battery",
                  name: getBattery(installation.type).name,
                }),
              )}
            />
            <SignedInstallationCard
              products={signedInstallations.evChargerInstallations.map(
                (installation) => ({
                  type: "evCharger",
                  name: getEvCharger(installation.type).name,
                }),
              )}
            />
          </Group>
        </Section>
      )}
      <Section title="Équipements">
        <Checkbox.Group {...form.getInputProps("equipments")}>
          <Stack gap={32}>
            {EQUIPMENT_CATEGORIES.map((category) => (
              <Stack gap={12} key={category.title}>
                <Text fz="lg" fw={500}>
                  {category.title}
                </Text>
                <SimpleGrid cols={2}>
                  {category.options.map((option) => (
                    <Checkbox
                      key={option.value}
                      label={option.label}
                      value={option.value}
                      data-test={_.camelCase(option.value)}
                      styles={{ label: { fontSize: 14 } }}
                    />
                  ))}
                </SimpleGrid>
              </Stack>
            ))}
          </Stack>
        </Checkbox.Group>
      </Section>
    </>
  )
}
