import { ActionIcon, Badge, Button, Group, Input } from "@mantine/core"
import { useForm, UseFormReturnType, zodResolver } from "@mantine/form"
import {
  IconBriefcase,
  IconBulb,
  IconChevronLeft,
  IconChevronRight,
  IconDeviceFloppy,
  IconMapPin,
} from "@tabler/icons-react"
import _ from "lodash"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"
import { InstallationFormInput } from "@ensol/types/forms/installations"
import { extraWorksSchema } from "@ensol/types/forms/installations/extraWorks"

import { Link } from "@ensol/shared/components/Link"
import { colors } from "@ensol/shared/styles/colors"

import { queryClient } from "@ensol/entool/backend/queryClient"
import { InfoIconPopover } from "@ensol/entool/components/InfoIconPopover"
import { Tooltip } from "@ensol/entool/components/Tooltip"
import { ProspectHeader } from "@ensol/entool/components/entities/Prospect/ProspectHeader"
import { INSTALLATION_FIELDS_LABELS } from "@ensol/entool/utils/form/fieldLabels/installations"
import { computeInstallationName } from "@ensol/entool/utils/installations"

type Props = {
  installationId: string
  projectId: string | null
  name: string
  address: string
  prospect: ProspectResponses.Prospect
  form: UseFormReturnType<InstallationFormInput>
  isInstallationValid: boolean
  onSave: () => void
  isSaving: boolean
  isOverviewVisible: boolean
  toggleOverview: () => void
}

export const InstallationHeader = ({
  installationId,
  projectId,
  name,
  address,
  prospect,
  form,
  isInstallationValid,
  onSave,
  isSaving,
  isOverviewVisible,
  toggleOverview,
}: Props) => {
  const headerForm = useForm({
    onValuesChange: _.debounce(
      ({ name }) => form.getInputProps("name").onChange(name),
      350,
    ),
    validate: zodResolver(extraWorksSchema),
    initialValues: { name },
  })

  const formErrors = Object.keys(form.errors).map((field) =>
    field in INSTALLATION_FIELDS_LABELS
      ? INSTALLATION_FIELDS_LABELS[field]
      : field,
  )

  return (
    <ProspectHeader
      prospect={prospect}
      BreadCrumbs={[
        <Group key="installationName">
          <Group gap="0" mr="8" wrap="nowrap">
            <Input
              autoFocus
              variant="unstyled"
              placeholder="Nom de l'installation"
              styles={{
                input: {
                  fieldSizing: "content",
                  paddingRight: 8,
                  maxWidth: 500,
                  height: 0,
                  fontSize: 18,
                  fontWeight: 500,
                  color: colors.blue[500],
                },
              }}
              data-test="installationName"
              {...headerForm.getInputProps("name")}
            />
            <Tooltip label="Générer un nom">
              <ActionIcon>
                <IconBulb
                  size={18}
                  onClick={() => {
                    const name = computeInstallationName(form.values)
                    headerForm.setValues({ name })
                  }}
                />
              </ActionIcon>
            </Tooltip>
          </Group>
          {form.values.isUpsell && (
            <Badge variant="outline" color="blue">
              Upsell
            </Badge>
          )}
        </Group>,
      ]}
      Infos={
        <>
          <InfoIconPopover Icon={IconMapPin} name="Adresse" value={address} />
          {projectId !== null && (
            <Link
              label="Projet"
              to={`/projects/${projectId}`}
              Icon={<IconBriefcase />}
              color="gray.5"
              isIconButton
            />
          )}
        </>
      }
      RightSection={
        <>
          <Button
            onClick={toggleOverview}
            variant="transparent"
            rightSection={
              isOverviewVisible ? (
                <IconChevronRight size={16} />
              ) : (
                <IconChevronLeft size={16} />
              )
            }
          >
            Résumé du projet
          </Button>
          <Tooltip
            disabled={isInstallationValid}
            position="left"
            maw="400"
            multiline
            label={`L'installation est invalide${formErrors.length > 0 ? `, voici les sections qui comportent des erreurs : ${formErrors.join(", ")}` : ", vérifiez que le options sélectionnées sont disponibles"}`}
          >
            <Button
              loading={isSaving}
              onClick={onSave}
              leftSection={<IconDeviceFloppy size={18} />}
              disabled={!isInstallationValid}
              data-test="submitButton"
            >
              Enregistrer
            </Button>
          </Tooltip>
        </>
      }
      onProspectEditSuccess={() => {
        queryClient.invalidateQueries({
          queryKey: ["installations", installationId],
        })
      }}
    />
  )
}
