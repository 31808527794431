import { useForm, zodResolver } from "@mantine/form"
import { useEffect } from "react"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { InstallationsResponses } from "@ensol/types/endpoints/installations"
import { extraWorksSchema } from "@ensol/types/forms/installations/extraWorks"
import { extraPanelsInstallationSchema } from "@ensol/types/forms/installations/photovoltaic"
import { HouseSignedInstallations } from "@ensol/types/installation"

import {
  DEFAULT_PHOTOVOLTAIC_INSTALLATION,
  getDefaultExtraPanelsInstallation,
} from "@ensol/shared/entities/installations/defaults"
import {
  computePanelsCapacity,
  computePanelsCount,
  computeSignedInstallationsCapacity,
  computeSignedInstallationsPanelsCount,
} from "@ensol/shared/entities/installations/energy"

import { Section } from "@ensol/entool/components/Section"
import { ExtraWorksForm } from "@ensol/entool/pages/Installation/components/ExtraWorksForm"
import {
  ProductWrapper,
  ProductFormProps,
} from "@ensol/entool/pages/Installation/components/ProductWrapper"
import { findSelectedOption } from "@ensol/entool/utils/form/radio"
import { isFormValid } from "@ensol/entool/utils/form/validation"
import { computeHouseRoofSectionsWithPanels } from "@ensol/entool/utils/house/roofSections"
import { usePhotovoltaicMaterialOptions } from "@ensol/entool/utils/installations/photovoltaic"

import { MaterialSection } from "./MaterialSection"
import { PanelsSection } from "./PanelsSection"

type ExtraProps = {
  house: HousesResponses.House<{
    include: { roofSections: true }
  }>
  panelsLayout?: InstallationsResponses.Installation["panelsLayout"]
  signedInstallations: HouseSignedInstallations
  yearlyConsumption?: number
}

type Props = {
  extraPanelsInstallation: InstallationsResponses.ExtraPanelsInstallation | null
  onChange: (
    data: InstallationsResponses.ExtraPanelsInstallation | null,
  ) => void
  setError: (error: string) => void
  clearError: () => void
} & ExtraProps

export const ExtraPanelsInstallation = ({
  extraPanelsInstallation,
  ...props
}: Props) => {
  return (
    <ProductWrapper<InstallationsResponses.ExtraPanelsInstallation, ExtraProps>
      type="Panels"
      title="Installation de panneaux supplémentaires"
      addButtonLabel="Ajouter des panneaux"
      deleteButtonLabel="Supprimer les panneaux"
      pictureUrl="/panel.png"
      onAdd={() =>
        props.onChange(
          getDefaultExtraPanelsInstallation({
            house: props.house,
            signedPhotovoltaicInstallation:
              props.signedInstallations.photovoltaicInstallation,
          }),
        )
      }
      ProductForm={ExtraPanelsInstallationForm}
      formProps={{
        productInstallation: extraPanelsInstallation,
        ...props,
      }}
    />
  )
}

const ExtraPanelsInstallationForm = ({
  productInstallation: extraPanelsInstallation,
  house,
  onChange,
  setError,
  clearError,
  panelsLayout,
  signedInstallations,
  yearlyConsumption,
  DeleteButton,
}: ProductFormProps<InstallationsResponses.ExtraPanelsInstallation> &
  ExtraProps) => {
  const form = useForm({
    onValuesChange: (values) => onChange(values),
    validate: zodResolver(
      extraPanelsInstallationSchema.merge(extraWorksSchema),
    ),
    initialValues: extraPanelsInstallation
      ? {
          ...extraPanelsInstallation,
          roofSectionsWithPanels: computeHouseRoofSectionsWithPanels(
            house.roofSections,
            extraPanelsInstallation.roofSectionsWithPanels,
          ),
        }
      : undefined,
  })

  const { panelType, panelsCount, roofSectionsWithPanels, inverterType } =
    form.values

  const totalPanelsCount =
    panelsCount + computeSignedInstallationsPanelsCount(signedInstallations)

  const installedCapacity =
    computePanelsCapacity({ panelType, panelsCount }) +
    computeSignedInstallationsCapacity(signedInstallations)

  const { panelsOptions, invertersOptions } = usePhotovoltaicMaterialOptions({
    panelType,
    installedCapacity,
    panelsCount: totalPanelsCount,
    inverterType,
    currentType: house.currentType,
  })

  useEffect(() => {
    const selectedInverterOption = findSelectedOption(
      inverterType,
      invertersOptions,
    )
    if (selectedInverterOption?.disabled) {
      const firstRecommendedInverter = invertersOptions.find(
        (inverter) => inverter.metadata?.recommended,
      )
      form.setFieldValue(
        "inverterType",
        firstRecommendedInverter?.value ??
          DEFAULT_PHOTOVOLTAIC_INSTALLATION.inverterType,
      )
    }
  }, [form, inverterType, invertersOptions])

  useEffect(() => {
    const selectedPanelOption = findSelectedOption(panelType, panelsOptions)
    if (selectedPanelOption?.disabled) {
      const firstRecommendedPanel = panelsOptions.find(
        (panel) => !panel.disabled,
      )
      form.setFieldValue(
        "panelType",
        firstRecommendedPanel?.value ??
          DEFAULT_PHOTOVOLTAIC_INSTALLATION.panelType,
      )
    }
  }, [form, panelType, panelsOptions])

  useEffect(() => {
    form.setFieldValue(
      "panelsCount",
      computePanelsCount(roofSectionsWithPanels),
    )
  }, [form, roofSectionsWithPanels])

  // Validate the form and update error state of the main installation form
  useEffect(() => {
    if (!isFormValid(form)) {
      setError("panels installation is invalid")
    } else {
      clearError()
    }
  })

  return (
    <>
      <PanelsSection
        title="Installation de panneaux supplémentaires"
        house={house}
        installationForm={form}
        totalPanelsCount={totalPanelsCount}
        panelsLayout={panelsLayout}
        signedInstallations={signedInstallations}
        yearlyConsumption={yearlyConsumption}
        DeleteButton={DeleteButton}
      />
      <MaterialSection
        form={form}
        panelsOptions={panelsOptions}
        invertersOptions={invertersOptions}
        totalPanelsCount={totalPanelsCount}
        installedCapacity={installedCapacity}
        currentType={house.currentType}
        isExtraPanelsInstallation
      />
      <Section title="Paramètres">
        <ExtraWorksForm {...form.getInputProps("extraWorks")} />
      </Section>
    </>
  )
}
