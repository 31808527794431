export const EMAILS_FIELDS_LABELS = {
  formalName: "Nom du client",
  opsOwnerName: "Nom du responsable ops",
  opsOwnerPhone: "Téléphone du responsable ops",
  acknowledgmentLink: "Lien du récépissé",
  approvalLink: "Lien de l'autorisation d'urbanisme",
  acknowledgmentABFLink: "Lien du récépissé ABF",
  technicalVisitReportLink: "Lien du rapport de visite technique",
  panelNoticeLink: "Lien notice panneaux",
  inverterNoticeLink: "Lien notice onduleurs",
  applicationName: "Nom de l'application de suivi",
  applicationLink: "Lien de l'application de suivi",
  installationStartDate: "Date de l'installation",
  installationDurationInDays: "Durée de l'installation (jours)",
  consuelFirstNoticeOfVisitLink: "Lien du premier avis de passage",
  consuelVisitWeek: "Semaine de la visite",
  consuelSecondNoticeOfVisitLink: "Lien du second avis de passage",
  consuelVisitDateTime: "Date et heure de la visite",
  consuelAttestationLink: "Attestation visée du Consuel",
  enedisActivationDate: "Date de mise en service",
  signatureLink: "Lien de signature",
  certificateLink: "Lien de l'attestation",
}
