import { Alert, Button, Group, Stack, Text } from "@mantine/core"
import { modals } from "@mantine/modals"
import { IconAlertTriangle } from "@tabler/icons-react"
import _ from "lodash"
import { useCallback, useMemo } from "react"
import { useNavigate } from "react-router-dom"

import { MAX_BUNDLE_SIZE } from "@ensol/shared/entities/installations/bundle"
import { QuoteStatus } from "@ensol/shared/entities/quotes"

import { useCreateInstallationMutation } from "@ensol/entool/queries/installations"

import { InstallationsProps } from "./Installations"

export const useInstallations = ({ house }: InstallationsProps) => {
  const navigate = useNavigate()

  const { mutateAsync: createInstallation, isPending } =
    useCreateInstallationMutation()

  const bundleSize = useMemo(() => {
    return _.sum(
      house.installations.map((installation) => (installation.bundled ? 1 : 0)),
    )
  }, [house.installations])

  const hasSignedQuote = house.installations.some(
    ({ activeQuote }) => activeQuote?.status === QuoteStatus.SIGNED,
  )

  const createNewInstallation = useCallback(
    async ({ isUpsell }: { isUpsell: boolean }) => {
      const newInstallation = await createInstallation({
        houseId: house.id,
        isUpsell,
      })
      navigate(`/installations/${newInstallation.id}`)
    },
    [createInstallation, house, navigate],
  )

  return {
    isCreatingInstallation: isPending,
    createNewInstallation: () =>
      hasSignedQuote
        ? modals.open({
            title: "Cette maison a déjà une installation signée",
            children: (
              <Stack>
                <Alert
                  icon={<IconAlertTriangle />}
                  title="Pour mettre à jour le devis signé, vous devez modifier la
                même installation et non en créer une nouvelle."
                  color="yellow"
                />
                <Text>
                  Vous pouvez choisir entre un <b>upsell</b> et une{" "}
                  <b>première vente</b>. L&apos;upsell va prendre en compte les
                  installations déjà signées dans la simulation énergétique
                  contrairement à une première vente.
                </Text>
                <Group justify="flex-end">
                  <Button
                    variant="light"
                    onClick={() => {
                      createNewInstallation({ isUpsell: false })
                      modals.closeAll()
                    }}
                  >
                    Première vente
                  </Button>
                  <Button
                    onClick={() => {
                      createNewInstallation({ isUpsell: true })
                      modals.closeAll()
                    }}
                  >
                    Upsell
                  </Button>
                </Group>
              </Stack>
            ),
            centered: true,
          })
        : createNewInstallation({ isUpsell: false }),
    canBundle: bundleSize < MAX_BUNDLE_SIZE,
  }
}
