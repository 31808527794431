import {
  Divider,
  Group,
  NumberInput,
  SimpleGrid,
  Stack,
  Text,
} from "@mantine/core"
import { UseFormReturnType } from "@mantine/form"
import _ from "lodash"
import { useEffect, useMemo } from "react"

import { HousesResponses } from "@ensol/types/endpoints/houses"
import { PhotovoltaicInstallationAndExtraWorks } from "@ensol/types/forms/installations"

import { checkHouseHasGroundInstallation } from "@ensol/shared/entities/houses/roofSection"
import { PHOTOVOLTAIC_SUBSIDIES } from "@ensol/shared/entities/installations/subsidies/photovoltaic"
import { formatCurrency } from "@ensol/shared/utils/format"

import { Section } from "@ensol/entool/components/Section"
import { RadioGroup } from "@ensol/entool/components/form/RadioGroup"
import { ExtraWorksForm } from "@ensol/entool/pages/Installation/components/ExtraWorksForm"
import { isFormValid } from "@ensol/entool/utils/form/validation"
import { OptimizerOption } from "@ensol/entool/utils/installations/photovoltaic"

type Props = {
  form: UseFormReturnType<PhotovoltaicInstallationAndExtraWorks>
  house: HousesResponses.House<{
    include: { roofSections: true }
  }>
  optimizerOptions: OptimizerOption[]
  minOptimizerCount: number
  totalDiscount: number
}

export const SettingsSection = ({
  form,
  house,
  optimizerOptions,
  minOptimizerCount,
  totalDiscount,
}: Props) => {
  const { panelsCount, optimizerType, optimizerCount, roofSectionsWithPanels } =
    form.values
  const roofSections = roofSectionsWithPanels.map(
    ({ roofSection }) => roofSection,
  )

  useEffect(() => {
    if (checkHouseHasGroundInstallation(roofSections)) {
      form.setFieldValue("subsidyType", null)
    }
  }, [form, roofSections])

  const subsidyOptions = useMemo(() => {
    return _.map(PHOTOVOLTAIC_SUBSIDIES, (subsidyInfo, subsidyType) => {
      if (!isFormValid(form)) {
        return { label: subsidyInfo.shortName, value: subsidyType }
      }

      const isEligible = subsidyInfo.isEligible({
        postcode: house.postcode,
        roofSections,
      })

      const amount = subsidyInfo.computeAmount({
        photovoltaicInstallation: form.values,
        house,
        totalDiscount,
      })

      return {
        label: subsidyInfo.shortName,
        value: subsidyType,
        disabled: subsidyInfo.disabled || !isEligible,
        subtitle: subsidyInfo.disabled
          ? "Indisponible"
          : isEligible
            ? `Montant: ${formatCurrency(amount)}`
            : "Non éligible",
      }
    })
  }, [form, house, roofSections, totalDiscount])

  return (
    <Section title="Paramètres" isCollapsible>
      <Stack gap={32}>
        {optimizerOptions.length > 0 && (
          <>
            <Stack gap={16}>
              <Stack gap={0}>
                <Text fw={600} fz="lg">
                  Optimiseurs
                </Text>
                <Text c="gray.6">
                  Utiliser les données de consommation permet d&apos;améliorer
                  la simulation
                </Text>
              </Stack>
              <Group gap={24} align="flex-start">
                <RadioGroup
                  nullable
                  options={optimizerOptions}
                  OptionsWrapper={({ children }) => (
                    <Stack gap={12}>{children}</Stack>
                  )}
                  {...form.getInputProps("optimizerType")}
                  onChange={(value) => {
                    if (value !== null && optimizerCount === null) {
                      form.getInputProps("optimizerCount").onChange(panelsCount)
                    }
                    form.getInputProps("optimizerType").onChange(value)
                  }}
                />
                {optimizerType !== null && (
                  <NumberInput
                    min={minOptimizerCount}
                    {...form.getInputProps("optimizerCount")}
                    value={optimizerCount ?? panelsCount}
                  />
                )}
              </Group>
            </Stack>
            <Divider />
          </>
        )}
        <ExtraWorksForm {...form.getInputProps("extraWorks")} />
        <Divider />
        <Stack gap={12}>
          <Text fw={600} fz="lg">
            Subventions
          </Text>
          <RadioGroup
            nullable
            options={subsidyOptions}
            OptionsWrapper={({ children }) => (
              <SimpleGrid cols={2}>{children}</SimpleGrid>
            )}
            {...form.getInputProps("subsidyType")}
          />
        </Stack>
      </Stack>
    </Section>
  )
}
