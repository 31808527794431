import { Alert } from "@mantine/core"
import { IconAlertCircle } from "@tabler/icons-react"

import { ProspectInput } from "@ensol/types/forms/prospects"

import { ProspectForm } from "@ensol/entool/components/form/Prospect/ProspectForm"
import { ContentContainer } from "@ensol/entool/components/layout/ContentContainer"
import { useCreateProspectMutation } from "@ensol/entool/queries/prospects"

export const FallbackForm = (prospect: ProspectInput) => {
  const { mutateAsync: createProspect, isPending } = useCreateProspectMutation()

  return (
    <ContentContainer flex="1">
      <Alert
        color="red"
        title="Nous n'avons pas pu créer le client automatiquement, ajouter les informations manquantes"
        icon={<IconAlertCircle />}
        mb={16}
      />
      <ProspectForm
        prospect={prospect}
        submitAction={createProspect}
        isPending={isPending}
      />
    </ContentContainer>
  )
}
