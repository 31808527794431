import { Modal, ScrollArea, Group, Title, ActionIcon } from "@mantine/core"
import { useDisclosure, useMediaQuery } from "@mantine/hooks"
import { notifications } from "@mantine/notifications"
import { IconPencil, IconUser } from "@tabler/icons-react"

import { ProspectResponses } from "@ensol/types/endpoints/prospects"

import { devices } from "@ensol/shared/styles/theme"

import { useUpdateProspectMutation } from "@ensol/entool/queries/prospects"

import { ProspectForm } from "./ProspectForm"

type Props = {
  prospect: ProspectResponses.Prospect
  onSuccess?: () => void
}

export const EditProspectButton = ({ prospect, onSuccess }: Props) => {
  const [isModalOpen, { open, close }] = useDisclosure(false)
  const isSmallScreen = useMediaQuery(devices.md)

  const { mutateAsync: updateProspect, isPending } = useUpdateProspectMutation(
    prospect.id,
    () => {
      onSuccess?.()
      notifications.show({ message: "Client mise à jour !" })
      close()
    },
  )

  return (
    <>
      <ActionIcon size="sm" color="gray.5" variant="transparent" onClick={open}>
        <IconPencil />
      </ActionIcon>
      <Modal
        size={700}
        radius="lg"
        padding={isSmallScreen ? "md" : "xl"}
        opened={isModalOpen}
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={close}
        title={
          <Group gap={8}>
            <IconUser size={26} />
            <Title order={2}>Client</Title>
          </Group>
        }
      >
        <ProspectForm
          prospect={prospect}
          submitAction={updateProspect}
          isPending={isPending}
        />
      </Modal>
    </>
  )
}
