import { Text, Anchor, Stack, Group } from "@mantine/core"
import {
  IconHeartHandshake,
  IconHomeBolt,
  IconMailFilled,
  IconMapPin,
  IconPhoneFilled,
} from "@tabler/icons-react"
import { ReactNode } from "react"
import { Link as ReactRouterLink } from "react-router-dom"

import { ProjectResponses } from "@ensol/types/endpoints/projects"

import { Link } from "@ensol/shared/components/Link"
import { FundingSource } from "@ensol/shared/entities/projects/fundingSource"
import { getFullName } from "@ensol/shared/entities/prospects"
import { Role } from "@ensol/shared/entities/users"
import { formatPhoneNum } from "@ensol/shared/utils/format"

import { DisplayRoleGuard } from "@ensol/entool/components/DisplayRoleGuard"
import { Header } from "@ensol/entool/components/Header"
import { InfoIconPopover } from "@ensol/entool/components/InfoIconPopover"
import { InstallationCharacteristics } from "@ensol/entool/components/entities/Installation/InstallationCharacteristics"
import { InstallationProducts } from "@ensol/entool/components/entities/Installation/InstallationProducts"
import { FundingSourceInfoIndicator } from "@ensol/entool/components/entities/Project/FundingSourceInfoIndicator"
import { ProjectOwners } from "@ensol/entool/components/entities/Project/ProjectOwners"
import { WorksAndChangesIndicators } from "@ensol/entool/components/entities/Project/WorksAndChangesIndicators"
import { INTERCOM_USERS_URL } from "@ensol/entool/config"
import { PROJECT_HEADER_HEIGHT_IN_PX } from "@ensol/entool/styles/constants"
import { getProjectInstallers } from "@ensol/entool/utils/projects/installers"

type Props = {
  project: ProjectResponses.Project
  BreadCrumbs?: ReactNode[]
}
export const DesktopHeader = ({ project, BreadCrumbs }: Props) => {
  const { installation } = project
  const { house, referrer } = installation
  const { prospect } = house

  const installers = getProjectInstallers(project)

  return (
    <Header
      height={PROJECT_HEADER_HEIGHT_IN_PX}
      BreadCrumbs={BreadCrumbs}
      Infos={
        <Group gap="16">
          <WorksAndChangesIndicators
            technicalVisitRequiredChanges={
              project.technicalVisitRequiredChanges
            }
            technicalVisitRequiredWorks={project.technicalVisitRequiredWorks}
            technicalVisitEndOfWorksDate={project.technicalVisitEndOfWorksDate}
          />
          <DisplayRoleGuard roles={[Role.EMPLOYEE, Role.ADMIN]}>
            {project.fundingSource &&
              project.fundingSource !== FundingSource.EQUITY && (
                <FundingSourceInfoIndicator
                  fundingSource={project.fundingSource}
                  fundingSourceStatus={project.fundingSourceStatus}
                />
              )}
          </DisplayRoleGuard>
          <Group gap="4">
            <InfoIconPopover
              Icon={IconMailFilled}
              name="Email"
              value={prospect.email}
            />
            <InfoIconPopover
              Icon={IconPhoneFilled}
              name="Téléphone"
              value={formatPhoneNum(prospect.phone)}
            />
            <InfoIconPopover
              Icon={IconMapPin}
              name="Adresse"
              value={house.address}
            />
            <DisplayRoleGuard roles={[Role.EMPLOYEE, Role.ADMIN]}>
              <Link
                label="Installation"
                to={`/installations/${installation.id}?section=description`}
                Icon={<IconHomeBolt />}
                color="gray.5"
                isIconButton
              />
              {prospect.intercomContactId !== null && (
                <Link
                  label="Profil Intercom"
                  to={`${INTERCOM_USERS_URL}/${prospect.intercomContactId}`}
                  Icon={<img src="/intercomLogo.svg" height={18} />}
                  isIconButton
                  isExternal
                />
              )}
            </DisplayRoleGuard>
          </Group>
          <ProjectOwners project={project} />
          <DisplayRoleGuard roles={[Role.EMPLOYEE, Role.ADMIN]}>
            {referrer && (
              <Group gap="4">
                <IconHeartHandshake size={16} />
                <Text size="sm">Parrainé par</Text>
                <Anchor
                  component={ReactRouterLink}
                  size="sm"
                  fw="bold"
                  to={`/prospects/${referrer.id}`}
                >
                  {getFullName(referrer)}
                </Anchor>
              </Group>
            )}
          </DisplayRoleGuard>
        </Group>
      }
      BottomSection={
        <Group gap="4px 16px">
          <InstallationCharacteristics
            installation={installation}
            installers={installers}
          />
        </Group>
      }
      RightSection={
        <Stack h="100%" align="flex-start">
          <InstallationProducts installation={project.installation} />
        </Stack>
      }
    />
  )
}
